<script setup lang="ts">
import { useElementVisibility } from "@vueuse/core";
import { createCSVImporter } from "csv-import-js";
import { computed, nextTick, onMounted, ref, watch, watchEffect } from "vue";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownMenuWithSelection from "~/components/dumb/DropdownMenuWithSelection.vue";
import { IMPORT_SOURCES } from "~/constants/import";
import { BACKUP_FONTS, colorsByTheme } from "~/constants/style";
import { ArrowLeftIcon } from "~/icons";
import { ButtonStyle, DialogMode } from "~/shared/enums";
import { usePageStore } from "~/stores";

const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const wrapper = ref<HTMLElement | null>(null);
const csvImporterWrapper = ref<HTMLElement | null>(null);

const selectedSource = ref<string | null>(null);
const sourceOptions = computed(() =>
  IMPORT_SOURCES.map((title) => ({
    title,
    selected: title === selectedSource.value,
    onClick: () => {
      selectedSource.value = title;
    },
  }))
);

const hideCancelButton = ref(false);
const hideSelectedSource = ref(false);

const template = {
  columns: [
    {
      name: "ID",
      key: "id",
      description:
        "The unique ID of the task. If the tasks do not already have IDs, you can just number the tasks 1, 2, 3, etc. in this column",
    },
    {
      name: "Title",
      key: "title",
      required: true,
      description: "The short explanation of the task",
    },
    {
      name: "Dartboard",
      key: "dartboard",
      description: "The list, folder, project, sprint, or team of the task",
    },
    {
      name: "Status",
      key: "status",
      description: "The status of the task. Common statuses are 'To-do', 'Doing', and 'Done'",
    },
    {
      name: "Description",
      key: "description",
      description: "The full description, scope, or requirements of the task, in text or markdown format",
    },
    {
      name: "Parent ID",
      key: "parentId",
      description: "If this task is a subtask, this is the ID of the tasks's parent",
    },
    {
      name: "Assignee emails",
      key: "assigneeEmails",
      description: "A comma-separated list of emails of the assignees",
    },
    {
      name: "Tags",
      key: "tags",
      description: "A comma-separated list of tags or labels for the task",
    },
    {
      name: "Priority",
      key: "priority",
      description: "One of 'Critical', 'High', 'Medium', or 'Low'",
    },
    {
      name: "Start date",
      key: "startAt",
      description: "The date that work on the task started in a standard format like ISO8601",
    },
    {
      name: "Due date",
      key: "dueAt",
      description: "The date that the task is due or work on it ended in a standard format like ISO8601",
    },
    {
      name: "Size",
      key: "size",
      description: "The estimate or story points of the task, one of 'XS', 'S', 'M', 'L', or 'XL'",
    },
  ],
};

const isVisible = useElementVisibility(csvImporterWrapper);

watch(isVisible, (visible) => {
  if (!visible) {
    hideSelectedSource.value = true;
  }
});

const cancelImport = () => {
  selectedSource.value = null;
  hideSelectedSource.value = false;

  nextTick(() => {
    csvImporterWrapper.value = wrapper.value?.querySelector(
      'div[class*="Uploader-module_content"] div[class*="csv-importer"]'
    ) as HTMLElement;
  });
};

onMounted(() => {
  cancelImport();
});

watchEffect(() => {
  if (!wrapper.value || hideSelectedSource.value) {
    return;
  }

  createCSVImporter({
    isModal: false,
    domElement: wrapper.value,
    template,
    showDownloadTemplateButton: true,
    onComplete: (data) => {
      backendOld.workspace.import(data, selectedSource.value ?? "Unknown");
      hideCancelButton.value = true;
      hideSelectedSource.value = true;
    },
    customStyles: {
      "font-family": `Inter var, ${BACKUP_FONTS}`,
      "border-radius": "4px",
    },
    // TODO Improve text as needed https://github.com/tableflowhq/csv-import/blob/main/src/i18n/es.ts
    customTranslations: {
      en: {
        "- Select one -": "Select one",
      },
    },
  });
});
</script>

<template>
  <div class="relative !m-0 flex h-[630px] w-full !p-0 scrollbar-hide">
    <div ref="wrapper" class="absolute w-full min-w-[500px]" />
    <div v-if="!hideSelectedSource" class="absolute bottom-7 left-7">
      <DropdownMenuWithSelection :options="sourceOptions" border>
        <div class="w-36 select-none rounded px-3 py-2 text-left text-sm shadow-sm focus-ring-std hover:bg-lt">
          {{ selectedSource ?? "Select source" }}
        </div>
      </DropdownMenuWithSelection>
    </div>
    <div v-else class="absolute bottom-7 left-7" :class="hideCancelButton && 'hidden'">
      <ConfirmationDialog
        :mode="DialogMode.DELETE"
        title="Cancel import"
        description="Are you sure you want to go back? Your import progress will be lost."
        confirm-text="Confirm"
        cancel-text="Cancel"
        :icon="ArrowLeftIcon"
        @confirm="cancelImport">
        <Button :btn-style="ButtonStyle.SECONDARY" text="Cancel" class="!px-3 !py-2" is-contrast />
      </ConfirmationDialog>
    </div>
  </div>
</template>

<style>
/* https://github.com/tableflowhq/csv-import/blob/main/src/importer/style/themes/light.scss */
.csv-importer.CSVImporter-div {
  --color-background: v-bind("colors.bgStd");
  --color-background-modal: v-bind("colors.bgStd");

  --color-text: v-bind("colors.textLt");
  --color-primary: v-bind("colors.primary");
  --color-primary-hover: v-bind("colors.primaryHover");

  --color-input-background: v-bind("colors.bgLt");
  --color-input-background-soft: v-bind("colors.bgMd");
  --color-input-border: v-bind("colors.borderVlt");
  --color-input-placeholder: v-bind("colors.textMd");
  --color-input-text-disabled: v-bind("colors.textMd");

  --color-border: v-bind("colors.borderMd");

  --color-background-small-button-selected: v-bind("colors.bgLt");
  --color-background-small-button-hover: v-bind("colors.bgMd");
  --color-text-small-button: v-bind("colors.textLt");

  height: 630px;

  /* Icons */
  --color-icon: v-bind("colors.textVlt");
}

div[class*="CSVImporter-div"] {
  @apply !overflow-hidden;
}

.chakra-button[class*="csv-importer-"] {
  @apply !rounded !border-gray-300 !px-3 !py-2 !text-sm !text-gray-700 dark:!border-zinc-600 dark:!text-zinc-300;
}

.chakra-button[class*="csv-importer-"]:hover {
  @apply !bg-gray-200 !text-gray-700 dark:!bg-zinc-700 dark:!text-zinc-300;
}

.chakra-button__icon[class*="csv-importer-"] {
  @apply !me-1.5 !icon-md;
}

.chakra-button[class*="csv-importer-"] {
  @apply !rounded !border !border-solid !border-gray-300 !bg-transparent !px-3 !py-1 !text-sm !text-gray-700 dark:!border-zinc-600 dark:!text-zinc-300;
}

.chakra-button[class*="csv-importer-"]:hover {
  @apply !bg-gray-200 dark:!bg-zinc-700;
}

div[class*="RowSelection-module_actions"],
div[class*="MapColumns-module_actions"],
div[class*="Uploader-module_box"] .chakra-button[class*="csv-importer-"][type="button"] {
  @apply !min-h-[38px];
}

.chakra-button[class*="csv-importer-"][type="submit"] {
  @apply !h-[38px] !border-transparent !bg-primary-base !text-white dark:!text-white;
}

.chakra-button[class*="csv-importer-"][type="submit"]:hover {
  @apply !border-transparent !bg-primary-hover-light !text-white dark:!bg-primary-hover-dark dark:!text-white;
}

.react-icon {
  @apply !icon-md;
}

span[class*="Tooltip-module_tooltip"],
div[class*="Errors-module_errors"] p {
  @apply !gap-1.5;
}

span[class*="Tooltip-module_message"] {
  @apply !w-max !hyphens-auto !rounded !border-none !bg-gray-100 !px-1.5 !py-1 !text-xs !text-lt !break-words dark:!bg-zinc-800;
}

input[class*="Input-module_select"] {
  @apply !text-gray-700 !focus-ring-none dark:!text-zinc-300;
}

div[class*="Input-module_option"] {
  @apply !text-sm !text-gray-700 dark:!text-zinc-300;
}

div[class*="MapColumns-module_samples"] {
  @apply !text-gray-700 dark:!text-zinc-300;
}

label[class*="Checkbox-module_container"] {
  @apply !gap-0 !border-none !bg-transparent;
}

label[class*="Checkbox-module_container"] input[type="checkbox"]:checked,
label[class*="Checkbox-module_container"] input[type="checkbox"]:disabled:checked {
  @apply !border !border-primary-base !bg-primary-base dark:!border-primary-base dark:!bg-primary-base;
}

label[class*="Checkbox-module_container"] input[type="checkbox"] {
  @apply !border !border-gray-300 !bg-white !focus-ring-none dark:!border-zinc-600 dark:!bg-zinc-850;
}

label[class*="Checkbox-module_container"] input[type="checkbox"]:checked::before {
  @apply !shadow-none;
}

div[class*="Default-module_td"] {
  @apply !text-sm !font-medium;
}

button[class*="Input-module_placeholder"],
input[class*="Input-module_select"]::placeholder {
  @apply !text-gray-400 dark:!text-zinc-500;
}

div[class*="Input-module_inputWrapper"] {
  @apply !bg-transparent;
}

div[class*="csv-importer"] {
  @apply !rounded !text-sm;
}

div[class*="Stepper-module_stepper"] {
  @apply !text-base;
}

.csv-importer-0 {
  @apply !text-base !text-gray-500 dark:!text-zinc-400;
}

button[class*="Input-module_option"]:focus {
  @apply !hidden;
}

div[class*="Input-module_inner"] {
  @apply !rounded !border-none !bg-gray-100 dark:!bg-zinc-800;
}

button[class*="Input-module_option"]:hover {
  @apply !bg-gray-300 dark:!bg-zinc-600;
}

button[class*="Input-module_selected"],
button[class*="Input-module_option"]::after {
  @apply !bg-gray-200 dark:!bg-zinc-700;
}

div[class*="Uploader-module_tableContainer"] {
  @apply !mb-7 !rounded;
}

span[class*="Input-module_requiredMark"] {
  @apply !text-gray-500 dark:!text-zinc-400;
}

div[class*="Input-module_inputWrapper"]:has(input[class*="Input-module_open"]) {
  @apply !outline-none;
}

div[class*="Complete-module_actions"] {
  @apply !mt-6;
}

div[class*="Uploader-module_tableContainer"] div[class*="Default-module_element"]:nth-child(2),
div[class*="Default-module_element"]:nth-child(4) {
  @apply !justify-center;
}

div[class*="Uploader-module_content"] div[class*="csv-importer"] {
  @apply !mb-[66px];
}

div[class*="MapColumns-module_actions"] button[class*="csv-importer"][type="button"] {
  @apply !pointer-events-none !opacity-0;
}

div[class*="Complete-module_actions"] {
  @apply !hidden;
}

div[class*="RowSelection-module_actions"] button[class*="csv-importer"][type="button"] {
  @apply !pointer-events-none !opacity-0;
}

input[class*="RowSelection-module_inputRadio"] {
  @apply !border-gray-300 !bg-transparent !focus-ring-none dark:!border-zinc-600;
}

input[class*="RowSelection-module_inputRadio"]:checked {
  @apply !border-gray-300 !bg-primary-base !focus-ring-none dark:!border-zinc-600;
}

div[class*="Default-module_tr"] {
  @apply !w-full !overflow-visible;
}

div[class*="RowSelection-module_tableWrapper"] div[class*="Default-module_element"] {
  @apply !w-32 !justify-start;
}

div[class*="Default-module_tr"]:hover {
  @apply !shadow-none;
}

div[class*="Uploader-module_box"] {
  @apply !justify-between !gap-0;
}

div[class*="Stepper-module_step"] div {
  @apply !truncate;
}

div[class*="Stepper-module_step"] div[class*="Stepper-module_badge"] {
  @apply !shrink-0;
}

div[class*="Stepper-module_stepper"] {
  @apply !mx-6;
}
</style>
