<script setup lang="ts">
import { ref } from "vue";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import { DesktopOrWebAppIcon } from "~/icons";
import { ButtonStyle, IconMode, ModalWidth, TutorialName } from "~/shared/enums";
import { useAppStore, useTenantStore, useUserStore } from "~/stores";

const appStore = useAppStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const isLinkSent = ref(false);

const closeModal = () => {
  appStore.setSwitchToDesktopModalOpen(false);
  userStore.updateTutorialStatuses([{ name: TutorialName.SWITCH_TO_DESKTOP, status: 2 }]);

  if (!tenantStore.name) {
    appStore.setOnboardingModalOpen(true);
  }
};

const sendLink = async () => {
  isLinkSent.value = true;
  backendOld.auth.sendDesktopLinkEmail();
};
</script>

<template>
  <Modal
    :entity="appStore.switchToDesktopModalOpen"
    :title-icon="DesktopOrWebAppIcon"
    :icon-mode="IconMode.PRIMARY"
    title="Try Dart on a computer"
    :width="ModalWidth.S"
    custom-styles="overflow-hidden"
    @close="closeModal">
    <template #default>
      <p class="py-3 text-left text-sm text-lt">
        Some of Dart's features, especially its most powerful AI, are best on a computer rather than a phone. The mobile
        app is still a work in progress. We are improving it quickly and welcome any feedback!
      </p>
      <p class="py-3 text-left text-sm text-lt">
        Use the button below to email yourself a link to quickly log in on a computer.
      </p>
    </template>
    <template #actions>
      <div class="flex w-full flex-col gap-2">
        <Button
          :btn-style="!isLinkSent ? ButtonStyle.PRIMARY : ButtonStyle.SECONDARY"
          :disabled="isLinkSent"
          :text="isLinkSent ? 'Check your email on a computer' : 'Send me a link'"
          block
          class="py-2"
          @click="sendLink" />
      </div>
    </template>
  </Modal>
</template>
