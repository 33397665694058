<script setup lang="ts">
import { CopyIcon, OpenExternalLinkIcon } from "~/icons";
import { ButtonSize, ButtonStyle, IconSize } from "~/shared/enums";

import { copyAndNotify } from "../notifications";
import Button from "./Button.vue";
import Tooltip from "./Tooltip.vue";

const { link, pageTitle } = defineProps<{
  link: string;
  pageTitle: string;
}>();

const viewPublicView = () => {
  window.open(link, "_blank", "noopener,noreferrer");
};

const copyPublicViewLink = () => {
  copyAndNotify("Public link", link, pageTitle);
};
</script>

<template>
  <div class="flex w-full flex-col gap-6">
    <div class="flex w-full flex-col gap-2">
      <div class="flex items-center gap-3">
        <div class="select-none font-medium">Public link</div>
        <div
          class="relative flex select-none items-center gap-1 rounded-full border border-success-base px-1.5 py-0.5 text-xs font-medium text-success-base">
          <div class="size-3 animate-pulse rounded-full bg-success-base/50" />
          <div class="absolute inset-y-[7px] left-[9px] w-1.5 rounded-full bg-success-base" />
          Live
        </div>
      </div>

      <div class="flex items-center gap-2">
        <span title="Public link" class="grow select-none truncate rounded border px-2 py-1 text-sm text-md border-md">
          {{ link }}
        </span>
        <Tooltip text="Copy link">
          <Button
            :size="ButtonSize.SMALL"
            :btn-style="ButtonStyle.SECONDARY"
            :icon-size="IconSize.S"
            :icon="CopyIcon"
            a11y-label="Copy link"
            class="size-7"
            @click="copyPublicViewLink" />
        </Tooltip>
        <Tooltip text="View public site">
          <Button
            :size="ButtonSize.SMALL"
            :btn-style="ButtonStyle.SECONDARY"
            :icon-size="IconSize.S"
            :icon="OpenExternalLinkIcon"
            a11y-label="View public site"
            class="size-7"
            @click="viewPublicView" />
        </Tooltip>
      </div>
    </div>
  </div>
</template>
