import { clamp } from "@vueuse/core";
import { ref } from "vue";
import { type DirectiveBinding, nextTick, type ObjectDirective } from "vue";

const WRAPPER_ID = "auto-width-wrapper";

type InputElement = HTMLInputElement & {
  _autoWidthOnUpdated?: () => void;
  _autoWidthUnbind?: () => void;
};
type Binding = DirectiveBinding<{
  disabled?: boolean;
  minWidthPx?: number;
  maxWidthPx?: number;
  comfortZone?: number;
  extraWidth?: number;
}>;

const initializeAutoWidth = (elem: InputElement, binding: Binding) => {
  const elemNorm = elem;

  if (binding.value.disabled === true) {
    return;
  }

  // Create a hidden span element and copy the styles from the input element
  const span = document.createElement("span");
  span.style.position = "absolute";
  span.style.whiteSpace = "pre";

  const stylesUpdated = ref(false);

  const updateStylesIfNeeded = () => {
    if (stylesUpdated.value) {
      return;
    }

    const computedStyle = getComputedStyle(elemNorm);
    span.style.font = computedStyle.font;
    span.style.fontSize = computedStyle.fontSize;
    span.style.fontStyle = computedStyle.fontStyle;
    span.style.fontWeight = computedStyle.fontWeight;
    span.style.fontFamily = computedStyle.fontFamily;

    span.style.direction = computedStyle.direction;
    span.style.letterSpacing = computedStyle.letterSpacing;
    span.style.wordSpacing = computedStyle.wordSpacing;
    span.style.lineHeight = computedStyle.lineHeight;
    span.style.textIndent = computedStyle.textIndent;
    span.style.textTransform = computedStyle.textTransform;
    span.style.textRendering = computedStyle.textRendering;

    span.style.padding = computedStyle.padding;
    span.style.border = computedStyle.border;
    span.style.boxSizing = computedStyle.boxSizing;
    span.style.setProperty("webkitFontSmoothing", computedStyle.getPropertyValue("webkit-font-smoothing"));
    span.style.setProperty("mozOsxFontSmoothing", computedStyle.getPropertyValue("moz-osx-font-smoothing"));

    let wrapper = document.getElementById(WRAPPER_ID);
    if (!wrapper) {
      wrapper = document.createElement("div");
      wrapper.id = WRAPPER_ID;
      wrapper.style.position = "absolute";
      wrapper.style.visibility = "hidden";
      document.body.appendChild(wrapper);
    }
    wrapper.appendChild(span);

    stylesUpdated.value = true;
  };

  // Update the width of the input element based on the span width
  const updateWidth = () => {
    updateStylesIfNeeded();

    // Use value or placeholder text if available
    span.textContent = elemNorm.value || elemNorm.placeholder || "";
    const baseWidth = Math.ceil(span.scrollWidth) + (binding.value.comfortZone ?? 0) + (binding.value.extraWidth ?? 3);
    const finalWidth = clamp(baseWidth, 0, binding.value.maxWidthPx ?? Infinity);
    elemNorm.style.width = `${finalWidth}px`;
    elemNorm.style.minWidth = `${binding.value.minWidthPx ?? 0}px`;
  };
  nextTick(updateWidth);

  elemNorm.addEventListener("input", () => nextTick(updateWidth));

  const unbind = () => {
    span.remove();
    elemNorm._autoWidthUnbind = undefined;
    elemNorm._autoWidthOnUpdated = undefined;
    elemNorm.removeEventListener("input", () => nextTick(updateWidth));
  };

  elemNorm._autoWidthUnbind = unbind;
  elemNorm._autoWidthOnUpdated = updateWidth;
};

/**
 * Automatically adjust the width of an input element based on its content.
 */
const AutoWidthDirective: ObjectDirective = {
  mounted(elem: InputElement, binding: Binding) {
    initializeAutoWidth(elem, binding);
  },
  updated(elem: InputElement, binding: Binding) {
    // If disabled, clean up
    if (binding.value.disabled === true && elem._autoWidthUnbind) {
      elem._autoWidthUnbind();
      return;
    }

    // If not disabled and has update function, call it
    if (elem._autoWidthOnUpdated) {
      elem._autoWidthOnUpdated();
      return;
    }

    // Enable if re-enabled
    initializeAutoWidth(elem, binding);
  },
  beforeUnmount(elem: InputElement) {
    if (elem._autoWidthUnbind) {
      elem._autoWidthUnbind();
    }
  },
};

export default AutoWidthDirective;
