<script setup lang="ts">
import { computed, ref } from "vue";

import { getPropertyConfig, getPropertyWithConfigList, showPropertiesWithConfig } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { NOT_SHOWN_PROPERTY_KINDS } from "~/constants/property";
import { DotsHorizontalIcon, PlusIcon } from "~/icons";
import { ButtonSize, ButtonStyle, DropdownMenuItemKind, Entitlement, Placement, PropertyKind } from "~/shared/enums";
import type { DropdownMenuSection } from "~/shared/types";
import { useAppStore, useDataStore, useTenantStore } from "~/stores";
import { getItemCountText, getNextTitleInSequence } from "~/utils/common";
import { makePropertyComparator } from "~/utils/comparator";
import { getOrdersBetween } from "~/utils/orderManager";
import { validateAlwaysTrue } from "~/utils/validation";

defineProps<{
  isDotsMenu?: boolean;
}>();

const emit = defineEmits<{
  createProperty: [];
  setPropertyDuidVisibility: [propertyDuid: string, shown: boolean, propertyOrderDuids?: string[]];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const searchValue = ref("");

const upgradeDialog = ref<InstanceType<typeof UpgradeConfirmationDialog> | null>(null);

const allProperties = computed(() => {
  const comparator = makePropertyComparator([]);
  return dataStore.propertyList
    .map((p) => ({ ...p, config: getPropertyConfig(p.kind) }))
    .sort((a, b) => (a.hidden || b.hidden ? 1 : comparator(a.duid, b.duid)));
});

// Shown properties
const cachedShownPropertyDuids = ref<Set<string>>(new Set());
const shownPropertiesWithConfig = computed(() => showPropertiesWithConfig([...cachedShownPropertyDuids.value]));
const shownPropertyDuids = computed(
  () => new Set(shownPropertiesWithConfig.value.map(({ property }) => property.duid))
);
cachedShownPropertyDuids.value = new Set([...shownPropertyDuids.value]);

/* Custom properties */
const customProperties = computed(() => allProperties.value.filter((property) => !property.config.isDefault));
const maxCustomProperties = computed(() => tenantStore.getEntitlementValue(Entitlement.MAX_CUSTOM_PROPERTIES));
const upgradeRequired = computed(() => customProperties.value.length >= maxCustomProperties.value);

const addShownProperty = (propertyDuid: string) => {
  const propertyOrderDuids =
    appStore.propertyOrderDuids.length === 0 ? [...shownPropertyDuids.value] : [...appStore.propertyOrderDuids];

  if (!propertyOrderDuids.includes(propertyDuid)) {
    // Place before last locked property
    const lastLockedPropertyIndex = shownPropertiesWithConfig.value.findLastIndex(({ locked }) => locked);
    if (lastLockedPropertyIndex === -1) {
      propertyOrderDuids.push(propertyDuid);
    } else {
      propertyOrderDuids.splice(lastLockedPropertyIndex, 0, propertyDuid);
    }
  }

  emit("setPropertyDuidVisibility", propertyDuid, true, propertyOrderDuids);
  cachedShownPropertyDuids.value = new Set([...shownPropertyDuids.value]);
};

const createCustomProperty = async (kind: PropertyKind, title: string) => {
  if (upgradeRequired.value) {
    return;
  }

  const indexOfLastDefaultProperty = allProperties.value.map((e) => e.config.isDefault).lastIndexOf(true);
  const bottomDefaultPropertyOrder = allProperties.value[indexOfLastDefaultProperty].order;
  const firstCustomPropertyOrder = allProperties.value[indexOfLastDefaultProperty + 1]?.order;
  const order = getOrdersBetween(bottomDefaultPropertyOrder, firstCustomPropertyOrder)[0];

  const newProperty = await dataStore.createProperty(kind, order, {
    title: getNextTitleInSequence(
      title,
      customProperties.value.map((e) => e.title),
      { noCopy: true }
    ),
  });

  emit("createProperty");

  if (newProperty && newProperty.duid) {
    addShownProperty(newProperty.duid);
  }
};

const openDropdownMaybe = (event: Event) => {
  if (!tenantStore.isPremium) {
    event.preventDefault();
    event.stopPropagation();
    upgradeDialog.value?.openModal();
  }
};

const propertyOptions = computed(() =>
  getPropertyWithConfigList()
    .filter(
      ([property]) => !shownPropertyDuids.value.has(property.duid) && !NOT_SHOWN_PROPERTY_KINDS.has(property.kind)
    )
    .map(([property, config]) => ({
      value: property.duid,
      label: property.title,
      selected: false,
      component: DropdownMenuItemContent,
      componentArgs: {
        title: property.title,
        icon: config.icon,
        isMultiSelect: true,
      },
    }))
);

const customPropertiesSections = computed<DropdownMenuSection[]>(() => [
  {
    title: "Add custom property",
    items: Object.values(PropertyKind)
      .map((e) => getPropertyConfig(e))
      .filter((e) => !e.isDefault)
      .map((config) => ({
        title: config.label,
        kind: DropdownMenuItemKind.BUTTON,
        icon: config.icon,
        onClick: () => createCustomProperty(config.kind, searchValue.value),
      })),
  },
]);
</script>

<template>
  <MultiselectDropdownMenu
    placeholder="Add a property"
    :items="propertyOptions"
    :placement="Placement.BOTTOM_RIGHT"
    :distance="2"
    has-new-entry-slot
    :validate="validateAlwaysTrue"
    :class="isDotsMenu && 'px-1'"
    @add="addShownProperty"
    @update:search="(value) => (searchValue = value)">
    <button v-if="isDotsMenu" type="button" class="flex items-center rounded p-0.5 text-lt focus-ring-std hover:bg-md">
      <span class="sr-only">Property settings</span>
      <DotsHorizontalIcon class="text-vlt icon-sm" />
    </button>
    <Tooltip v-else text="Show more properties in this layout">
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        :size="ButtonSize.CHIP"
        class="rounded-full !p-0.5"
        :icon="PlusIcon"
        borderless
        is-contrast
        a11y-label="Property" />
    </Tooltip>
    <UpgradeConfirmationDialog
      ref="upgradeDialog"
      :disabled="!upgradeRequired"
      :feature-action="`create more than ${getItemCountText(maxCustomProperties, 'custom property', { noSpecial: true, unusualPlural: 'custom properties' })}`">
      <div class="hidden" />
    </UpgradeConfirmationDialog>
    <template #new-entry-slot>
      <DropdownMenu
        :sections="customPropertiesSections"
        :placement="Placement.RIGHT_TOP"
        class="!w-full"
        :distance="0"
        :width-pixels="180"
        show-on-hover
        :disabled="upgradeRequired">
        <DropdownMenuItemContent
          :icon="PlusIcon"
          title="Create a property"
          is-multi-select
          is-submenu
          @click="openDropdownMaybe" />
      </DropdownMenu>
    </template>
  </MultiselectDropdownMenu>
</template>
