<script setup lang="ts">
import { nextTick, onMounted, ref } from "vue";

import { backendOld } from "~/api";
import AiFeedbackButtons from "~/components/dumb/AiFeedbackButtons.vue";
import Button from "~/components/dumb/Button.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import { notify } from "~/components/notifications";
import { AiFilterIcon } from "~/icons";
import { ButtonStyle, EventActor, IconSize } from "~/shared/enums";
import type { Filter, FilterValue } from "~/shared/types";
import { useAppStore } from "~/stores";
import { validateAlwaysTrue } from "~/utils/validation";

const DEFAULT_OPTIONS = ["assigned to me", "due on Friday", "high priority small tasks"];

const emit = defineEmits<{
  select: [values: FilterValue[]];
  afterClose: [];
  removeFilter: [];
  replaceFilter: [];
}>();

const appStore = useAppStore();

const dropdown = ref<InstanceType<typeof MultiselectDropdownMenu> | null>(null);

const working = ref(false);
const feedbackDuids = ref<string[]>([]);
const removing = ref(false);

const filterRecIds = ref<Set<string>>(new Set());

const updateFilters = async (text: string) => {
  working.value = true;

  const thisFilter = appStore.filters.slice(-1)[0];
  appStore.setAllFilters([thisFilter]);

  const res = await backendOld.recommendations.getFilters(text);
  const filterRecommendations: (Filter & { recommendationDuid: string })[] = res.data.items;

  if (filterRecommendations.length === 0) {
    notify({
      message: `${EventActor.DART_AI} has no filter recommendations. Try again with different phrasing.`,
    });

    working.value = false;
    return;
  }

  appStore.setAllFilters([...filterRecommendations, thisFilter]);
  feedbackDuids.value = filterRecommendations.map((e) => e.recommendationDuid);
  filterRecIds.value = new Set(filterRecommendations.map((e) => e.id));

  working.value = false;
};

const onReject = () => {
  appStore.setAllFilters(appStore.filters.filter((e) => !filterRecIds.value.has(e.id)));
  filterRecIds.value = new Set();
};

const dropdownItems = DEFAULT_OPTIONS.map((option) => ({
  value: option,
  label: option,
  selected: false,
  component: DropdownMenuItemContent,
  componentArgs: {
    title: option,
    icon: AiFilterIcon,
    iconArgs: {
      class: "icon-sm text-recommendation-base",
    },
    isMultiSelect: true,
  },
}));

const remove = () => {
  if (removing.value) {
    return;
  }
  removing.value = true;
  emit("removeFilter");
};

const onAfterClose = () => {
  if (working.value) {
    return;
  }
  remove();
};

const open = () => {
  dropdown.value?.open();
};

const close = () => {
  dropdown.value?.close();
};

onMounted(() => {
  nextTick(open);
});

defineExpose({
  open,
  close,
  working,
});
</script>

<template>
  <MultiselectDropdownMenu
    ref="dropdown"
    :disabled="working || feedbackDuids.length > 0"
    :items="dropdownItems"
    placeholder="Filter with AI"
    :distance="-12"
    cover
    :width-pixels="280"
    :new-entry-icon="AiFilterIcon"
    :new-entry-icon-args="{ class: 'text-recommendation-base' }"
    :make-new-entry-text="(search) => `Filter for ${search}`"
    close-on-select
    :validate="validateAlwaysTrue"
    @after-close="onAfterClose"
    @add="updateFilters"
    @create="updateFilters">
    <Button
      v-if="working"
      :btn-style="ButtonStyle.SECONDARY_RECOMMENDATION"
      working
      :icon="AiFilterIcon"
      :icon-size="IconSize.XS"
      a11y-label="Filter with AI" />
    <AiFeedbackButtons
      v-else-if="feedbackDuids.length > 0"
      :recommendation-duids="feedbackDuids"
      @remove="remove"
      @reject="onReject" />
  </MultiselectDropdownMenu>
</template>
