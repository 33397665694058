<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import actions from "~/actions";
import type { Filter, FilterDefinition, FilterValue } from "~/shared/types";
import { hasClassInHierarchy, makeUuid } from "~/utils/common";

const FILTER_APPLICABILITY_CLASSES = new Set(["dart-filter-applicability"]);

const props = defineProps<{
  filter: Filter;
  definition: FilterDefinition | undefined;
  enabled: boolean;
  readOnly?: boolean;
}>();

const emit = defineEmits<{
  select: [values: FilterValue[]];
  afterClose: [];
  removeFilter: [];
  replaceFilter: [];
}>();

const input = ref<HTMLInputElement | null>(null);

const value = computed(() => (props.filter.values.length ? props.filter.values[0] : ""));

const focus = () => {
  if (props.readOnly) {
    return;
  }
  actions.visualization.openKeyboardIfIos();
  input.value?.focus();
};

onMounted(() => {
  if (value.value === "") {
    emit("select", [""]);
    focus();
  }
});

const onChange = (v: string) => {
  emit("select", [v]);
};

const onBlur = (event: FocusEvent) => {
  if (value.value !== "") {
    return;
  }
  const newTarget = event.relatedTarget as HTMLElement | null;
  if (hasClassInHierarchy(newTarget, FILTER_APPLICABILITY_CLASSES)) {
    return;
  }
  emit("replaceFilter");
};

defineExpose({
  open: focus,
});

const id = ref(`filter-text-input-${makeUuid()}`);
</script>

<template>
  <label :for="id" class="sr-only">Filter text</label>
  <input
    :id="id"
    ref="input"
    v-auto-width="{ minWidthPx: 20 }"
    type="text"
    :disabled="!enabled || filter.locked"
    :value="value"
    class="mr-0.5 appearance-none truncate border-none bg-transparent p-0 text-sm font-normal text-md focus-ring-none"
    @blur="onBlur"
    @input="(e) => onChange((e.target as HTMLInputElement).value)" />
</template>
