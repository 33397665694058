<script setup lang="ts">
import { computed } from "vue";

import Animated from "~/components/dumb/Animated.vue";
import Avatar from "~/components/dumb/Avatar.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { UNASSIGNED_ASSIGNEE_LABEL } from "~/components/visualization/constants";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { DART_AI_PSEUDO_USER } from "~/constants/user";
import { UnassignedIcon, UserFieldIcon } from "~/icons";
import { EditorMode, Placement } from "~/shared/enums";
import { useDataStore } from "~/stores";
import { isAgentEmail } from "~/utils/validation";

const props = defineProps<{
  ai?: boolean;
  showAiWorking?: boolean;
  duids: string[];
  editorMode: EditorMode;
  limit?: number;
  firstUserOnly?: boolean;
  vertical?: boolean;
  small?: boolean;
  large?: boolean;
  huge?: boolean;
  tooltipBottom?: boolean;
  unsetLabel?: string;
  hideUnassignedIcon?: boolean;
}>();

const dataStore = useDataStore();

const isRecommendationMode = computed(() => props.editorMode === EditorMode.CHIP_RECOMMENDATION);
const isChipMode = computed(() => props.editorMode === EditorMode.CHIP || isRecommendationMode.value);
const isFolderMode = computed(() => props.editorMode === EditorMode.FOLDER);

const baseSizePx = computed(() => (props.huge ? 48 : props.large ? 24 : props.small ? 16 : 20));
const accordionSizePx = computed(() => (props.huge ? 32 : props.large ? 16 : props.small ? 10 : 13));

const allUsers = computed(() => {
  const users = dataStore.getUserList().filter((e) => props.duids.includes(e.duid));
  if (props.ai) {
    users.unshift(DART_AI_PSEUDO_USER);
  }
  return props.firstUserOnly ? users.slice(0, 1) : users;
});
const shownUsers = computed(() => {
  const limit = props.limit && props.limit < allUsers.value.length ? props.limit - 1 : allUsers.value.length;
  return allUsers.value.slice(0, limit);
});
const hiddenCount = computed(() => allUsers.value.length - shownUsers.value.length);
const hiddenUsersLabels = computed(() =>
  allUsers.value.slice(shownUsers.value.length).map((e) => `• ${e.name || e.email}`)
);
const accordionCount = computed(() => shownUsers.value.length - (hiddenCount.value === 0 ? 1 : 0));
const lastUser = computed(() =>
  shownUsers.value.length > 0 && hiddenCount.value === 0 ? shownUsers.value[shownUsers.value.length - 1] : null
);

const tooltipPlacement = computed(() =>
  props.tooltipBottom ? Placement.BOTTOM : props.vertical ? Placement.RIGHT : Placement.TOP
);
const groupHoverSize = computed(
  () => `${(accordionSizePx.value * accordionCount.value - baseSizePx.value) / (accordionCount.value - 1)}px`
);
const baseClasses = "h-[var(--baseSize)] w-[var(--baseSize)]";
const shrinkClasses = computed(() =>
  props.vertical
    ? "w-[var(--baseSize)] h-[var(--accordionSize)] hover:!h-[var(--baseSize)] transition-[height] group-hover/avatars:h-[var(--groupHoverSize)]"
    : "w-[var(--accordionSize)] hover:!w-[var(--baseSize)] h-[var(--baseSize)] transition-[width] group-hover/avatars:w-[var(--groupHoverSize)]"
);
</script>

<template>
  <Animated
    class="flex"
    :class="vertical && 'flex-col'"
    :style="{
      '--baseSize': `${baseSizePx}px`,
      '--accordionSize': `${accordionSizePx}px`,
      '--groupHoverSize': groupHoverSize,
    }">
    <Animated class="group/avatars flex" :class="vertical && 'flex-col'">
      <div v-for="user in shownUsers.slice(0, accordionCount)" :key="user.duid" class="z-0" :class="shrinkClasses">
        <Tooltip :text="user.name || user.email" :placement="tooltipPlacement" :class="baseClasses">
          <Avatar
            :abrev="user.abrev"
            :is-ai="user.duid === DART_AI_PSEUDO_USER.duid"
            :is-agent="isAgentEmail(user.email)"
            :show-ai-working="showAiWorking"
            circle
            :color-hex="user.colorHex"
            :image-url="user.imageUrl"
            img-border
            :class="baseClasses" />
        </Tooltip>
      </div>
    </Animated>
    <Tooltip
      v-if="lastUser"
      :text="lastUser.name || lastUser.email"
      :placement="tooltipPlacement"
      class="z-0"
      :class="baseClasses">
      <Avatar
        :abrev="lastUser.abrev"
        :is-ai="lastUser.duid === DART_AI_PSEUDO_USER.duid"
        :is-agent="isAgentEmail(lastUser.email)"
        :show-ai-working="showAiWorking"
        circle
        :color-hex="lastUser.colorHex"
        :image-url="lastUser.imageUrl"
        img-border
        :class="baseClasses" />
    </Tooltip>
    <Tooltip
      v-if="hiddenCount > 0"
      :text="hiddenUsersLabels"
      :placement="tooltipPlacement"
      class="z-0"
      :class="baseClasses">
      <Avatar :abrev="`+${hiddenCount}`" circle :color-hex="DEFAULT_CHIP_COLOR" img-border :class="baseClasses" />
    </Tooltip>
    <Tooltip
      v-if="shownUsers.length === 0 && !isFolderMode"
      :text="unsetLabel ?? UNASSIGNED_ASSIGNEE_LABEL"
      :disabled="isChipMode"
      :placement="tooltipPlacement">
      <UserFieldIcon v-if="isChipMode" class="icon-sm" :class="isRecommendationMode && 'text-recommendation-base'" />
      <UnassignedIcon v-else-if="!hideUnassignedIcon" class="text-vlt focus:outline-none" :class="baseClasses" />
    </Tooltip>
  </Animated>
</template>
