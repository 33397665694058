<script setup lang="ts">
import { computed, nextTick, ref } from "vue";

import DocArea from "~/components/docs/DocArea.vue";
import AttachmentEditor from "~/components/dumb/AttachmentEditor.vue";
import Button from "~/components/dumb/Button.vue";
import ButtonWithDropdown from "~/components/dumb/ButtonWithDropdown.vue";
import CollapsibleSection from "~/components/dumb/CollapsibleSection.vue";
import LinkEditor from "~/components/dumb/LinkEditor.vue";
import SubtaskSelectDropdown from "~/components/dumb/SubtaskSelectDropdown.vue";
import RelationshipArea from "~/components/task/RelationshipArea.vue";
import SubtaskProgressBar from "~/components/task/SubtaskProgressBar.vue";
import SubtasksList from "~/components/visualization/subtasks/SubtasksList.vue";
import { RELATIONSHIP_DROPDOWN_KIND_TO_DROPDOWN_OPTION } from "~/constants/relationship";
import { DotsHorizontalIcon, PlusIcon, TaskCreateIcon } from "~/icons";
import {
  ButtonSize,
  ButtonStyle,
  CommandId,
  DartboardKind,
  DropdownMenuItemKind,
  EditorMode,
  IconSize,
  RelationshipKindKind,
  SectionKind,
  ViewKind,
} from "~/shared/enums";
import type { Attachment, DropdownMenuSection, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { asyncState } from "~/utils/async";

const props = defineProps<{
  task: Task;
  editorMode: EditorMode;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const docArea = ref<InstanceType<typeof DocArea> | null>(null);
const docSection = ref<InstanceType<typeof CollapsibleSection> | null>(null);

const relationshipArea = ref<InstanceType<typeof RelationshipArea> | null>(null);
const relationshipSection = ref<InstanceType<typeof CollapsibleSection> | null>(null);

const subtasksList = ref<InstanceType<typeof SubtasksList> | null>(null);
const subtaskSection = ref<InstanceType<typeof CollapsibleSection> | null>(null);
const subtasksMenu = ref<InstanceType<typeof SubtaskSelectDropdown> | null>(null);

const parentKindDuid = ref(dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid);

const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);
const isTcm = computed(() => props.editorMode === EditorMode.TCM);
const subtasksEditorMode = computed(() => (isTcm.value ? EditorMode.LIST_MINI_TCM : EditorMode.LIST_MINI));

const isPublicViewOrTrash = computed(() => pageStore.isPublicView || appStore.currentPage?.kind === ViewKind.TRASH);
const isFinishedDartboard = computed(() => appStore.currentPage?.kind === DartboardKind.FINISHED);

const docAreaManuallyOpen = ref(false);
const relationshipAreaManuallyOpen = ref(false);
const subtaskAreaManuallyOpen = ref(false);
const generatingSubtaskRecommendations = ref(false);

const docRelationships = computed(() => dataStore.getDocDuidsRelatedToTask(props.task.duid));
const { state: relatedDocs, isLoading: isLoadingRelatedDocs } = asyncState(
  () => dataStore.getDocsRelatedToTaskOrdered(props.task.duid),
  { default: [], watch: [() => props.task.duid, docRelationships] }
);
const hasDocRelationships = computed(
  () => (isLoadingRelatedDocs.value && docRelationships.value.length > 0) || relatedDocs.value.length > 0
);
const attachments = computed(() => dataStore.getAttachmentsByDuidsOrdered(props.task.attachmentDuids));

const openFilePicker = () => appStore.taskDetail?.openFilePicker();

const openLinkEditor = () => appStore.setLinkOpenInModal({ taskDuid: props.task.duid });

const addAttachments = (receivedFiles: File[]) => dataStore.addAttachments(props.task.duid, receivedFiles);

const removeAttachment = (attachment: Attachment) => dataStore.removeAttachment(props.task.duid, attachment.duid);

const setDocRelationship = () => {
  docAreaManuallyOpen.value = true;
  docSection.value?.expand();

  nextTick(() => {
    docArea.value?.openMenu();
  });
};

const createSubtask = async (bottom: boolean = false) => {
  subtaskAreaManuallyOpen.value = true;
  subtaskSection.value?.expand();

  await nextTick();
  await subtasksList.value?.createSubtask(bottom);

  await nextTick();
  subtaskAreaManuallyOpen.value = false;
};

const setSubtask = async () => {
  subtaskAreaManuallyOpen.value = true;
  subtaskSection.value?.expand();

  await nextTick();
  subtaskSection.value?.$el.scrollIntoView();

  await nextTick();
  subtasksMenu.value?.open();
};

const handleSetSubtask = (duid: string) => subtasksList.value?.setSubtask(duid);

const deselectSubtasks = () => subtasksList.value?.deselectAll();

const hasSubtasks = computed(
  () =>
    dataStore.getTasksByDuids(
      dataStore
        .getRelationshipsByKindKind(props.task, RelationshipKindKind.PARENT_OF, true, {
          includeTrashed: isTrash.value,
        })
        .map((e) => e.targetDuid),
      { includeDraft: isTcm.value, includeTrashed: isTrash.value }
    ).length > 0
);

const hasNonSubtaskRelationships = computed(
  () =>
    dataStore.getTasksByDuids(
      dataStore
        .getRelationships(props.task, { includeTrashed: isTrash.value })
        .filter((e) => e.kindDuid !== parentKindDuid.value || (e.kindDuid === parentKindDuid.value && !e.isForward))
        .map((e) => e.targetDuid, { includeTrashed: isTrash.value })
    ).length > 0
);

const setRelationships = (kind: RelationshipKindKind, isForward?: boolean) => {
  relationshipAreaManuallyOpen.value = true;
  relationshipSection.value?.expand();

  nextTick(() => {
    relationshipArea.value?.openRelationshipMenu(kind, isForward);
  });
};

const handleRelationshipOptionClick = (relationshipKindKind: RelationshipKindKind, isForward?: boolean) =>
  setRelationships(relationshipKindKind, isForward);

const generateSubtaskRecommendations = async () => {
  generatingSubtaskRecommendations.value = true;
  await nextTick();
  await subtasksList.value?.generateSubtaskRecommendations();
  generatingSubtaskRecommendations.value = false;
};

const relationshipDropdownSections = computed(() => [
  {
    title: "Relationships",
    items: [...RELATIONSHIP_DROPDOWN_KIND_TO_DROPDOWN_OPTION.values()].map((value) => ({
      onClick: () => handleRelationshipOptionClick(value.relationshipKindKind, value.isForward),
      ...value,
    })),
  },
]);

const subtaskDropdownOptions: DropdownMenuSection[] = [
  {
    title: "Subtasks",
    items: [
      {
        title: "Create new subtask",
        kind: DropdownMenuItemKind.BUTTON,
        commandId: CommandId.CREATE_SUBTASKS,
        onClick: () => createSubtask(),
      },
      {
        title: "Set existing subtask",
        kind: DropdownMenuItemKind.BUTTON,
        onClick: setSubtask,
      },
    ],
  },
];

const showLinks = computed(() => props.task.links.length > 0);
const showDocs = computed(() => docAreaManuallyOpen.value || hasDocRelationships.value || isLoadingRelatedDocs.value);
const showAttachments = computed(() => props.task.attachmentDuids.length > 0);
const showSubtasks = computed(
  () => generatingSubtaskRecommendations.value || hasSubtasks.value || subtaskAreaManuallyOpen.value
);
const showNonSubtaskRelationships = computed(
  () => relationshipAreaManuallyOpen.value || hasNonSubtaskRelationships.value
);
const showContainer = computed(
  () =>
    showLinks.value ||
    showDocs.value ||
    showAttachments.value ||
    showSubtasks.value ||
    !isTcm.value ||
    showNonSubtaskRelationships.value
);

defineExpose({
  addAttachments,
  createSubtask,
  deselectSubtasks,
  generateSubtaskRecommendations,
  generatingSubtaskRecommendations,
  setDocRelationship,
  setRelationships,
  setSubtask,
});
</script>

<template>
  <div v-if="showContainer" :class="isTcm && 'mx-1 pt-3'" class="flex flex-col gap-6">
    <CollapsibleSection
      v-if="showLinks"
      title="Links"
      :kind="isPublicViewOrTrash ? SectionKind.DEFAULT : isTcm ? SectionKind.DISABLED : SectionKind.CREATE"
      tooltip="Add links"
      @create="openLinkEditor">
      <LinkEditor :task="task" :links="task.links" />
    </CollapsibleSection>

    <CollapsibleSection
      v-if="showDocs"
      ref="docSection"
      title="Docs"
      :kind="isPublicViewOrTrash ? SectionKind.DEFAULT : isTcm ? SectionKind.DISABLED : SectionKind.CREATE"
      tooltip="Link a doc"
      @create="setDocRelationship">
      <DocArea
        ref="docArea"
        :task="task"
        :editor-mode="editorMode"
        @open="(isOpen) => (docAreaManuallyOpen = isOpen)" />
    </CollapsibleSection>

    <CollapsibleSection
      v-if="showAttachments"
      title="Attachments"
      :kind="isPublicViewOrTrash ? SectionKind.DEFAULT : isTcm ? SectionKind.DISABLED : SectionKind.CREATE"
      tooltip="Add attachment"
      @create="openFilePicker">
      <AttachmentEditor :attachments="attachments" @add="addAttachments" @remove="removeAttachment" />
    </CollapsibleSection>

    <CollapsibleSection
      v-if="showSubtasks"
      ref="subtaskSection"
      title="Subtasks"
      :uncollapsible="isTcm"
      :kind="isPublicViewOrTrash || isFinishedDartboard ? SectionKind.DEFAULT : SectionKind.COMPONENT"
      tooltip="Create subtask"
      :component="ButtonWithDropdown"
      :component-args="{
        dropdownSections: subtaskDropdownOptions,
        commandId: CommandId.CREATE_SUBTASKS,
        btnStyle: ButtonStyle.CHIP,
        icon: PlusIcon,
        iconSize: IconSize.S,
        dropdownIcon: DotsHorizontalIcon,
        right: true,
        borderless: true,
        class: 'text-lt',
        onclick: () => createSubtask(),
      }">
      <SubtaskSelectDropdown
        ref="subtasksMenu"
        :task="task"
        hidden
        class="mx-2"
        @select="handleSetSubtask"
        @create="() => createSubtask()"
        @after-close="subtaskAreaManuallyOpen = false" />
      <SubtaskProgressBar v-if="!isTcm" :task="task" class="mb-4 mt-2 pl-4 pr-2" />
      <SubtasksList
        ref="subtasksList"
       
        :task="task"
        :editor-mode="subtasksEditorMode"
        class="mt-2"
        :class="isTcm && '-mx-1'" />
    </CollapsibleSection>
    <Button
      v-if="!isPublicViewOrTrash && !isFinishedDartboard && !isTcm"
      text="Add subtask"
      :btn-style="ButtonStyle.SECONDARY"
      :size="ButtonSize.SMALL"
      text-style="text-xs text-lt"
      :icon="TaskCreateIcon"
      :icon-size="IconSize.S"
      class="mx-5 w-fit pl-1.5 print:hidden"
      :class="showSubtasks && '-mt-4'"
      @click="() => createSubtask(true)" />

    <CollapsibleSection
      v-if="showNonSubtaskRelationships"
      ref="relationshipSection"
      title="Relationships"
      :kind="isPublicViewOrTrash ? SectionKind.DEFAULT : isTcm ? SectionKind.DISABLED : SectionKind.DROPDOWN_CREATE"
      tooltip="Connect a different task"
      :dropdown-sections="relationshipDropdownSections">
      <RelationshipArea
        ref="relationshipArea"
        :task="task"
        :editor-mode="editorMode"
        @open="(isOpen) => (relationshipAreaManuallyOpen = isOpen)" />
    </CollapsibleSection>
  </div>
</template>
