import * as Sentry from "@sentry/vue";
import { useNetwork } from "@vueuse/core";
import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { computed, ref, watch } from "vue";

import { notify } from "~/components/notifications";
import { type ApiResult, type Transaction, type TransactionResponse, TransactionsService } from "~/shared/common";
import { NotificationType, TransactionKind } from "~/shared/enums";
import type { Operation, StreamCallback } from "~/shared/types";
import { useEnvironmentStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { isObject, makeDuid } from "~/utils/common";
import { lsGet, lsRemove, lsSet, REQUEST_QUEUE_KEY } from "~/utils/localStorageManager";
import { timeout } from "~/utils/wait";

export enum RequestKind {
  TRANSACTION,
  GET,
  PUT,
  POST,
}
type Request = { nonBlocking?: boolean; background?: boolean } & (
  | {
      kind: RequestKind.TRANSACTION;
      transactions: Transaction[];
    }
  | {
      kind: RequestKind.GET;
      duid: string;
      url: string;
    }
  | {
      kind: RequestKind.POST | RequestKind.PUT;
      duid: string;
      url: string;
      data?: object;
      headers?: Record<string, string>;
      onStream?: StreamCallback;
    }
);
type BackendOldError = { response: { status: number; data: { errors: string[] | undefined } } };

const network = useNetwork();

// Capture errors and send to sentry
axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (!!error && error.name !== "AbortError" && (error.response ? error.response.status !== 404 : true)) {
      Sentry.captureException(error);
    }
    throw error;
  }
);

const randomInt = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;

const callWithErrorHandling = async <T>(
  fn: () => Promise<T>,
  retries: number = 2, // TODO put back to 3 when the BE is better
  backoff: number = 2000
): Promise<[T, undefined] | [undefined, unknown]> => {
  for (let attempt = 0; attempt < retries; attempt += 1) {
    try {
      // eslint-disable-next-line no-await-in-loop
      return [await fn(), undefined];
    } catch (error) {
      const errorResponseOrig = error as ApiResult | BackendOldError;
      const errorResponse = "response" in errorResponseOrig ? errorResponseOrig.response : errorResponseOrig;
      const { status } = errorResponse;

      if (status === 401) {
        // The user is not logged in, log out
        useUserStore().forceLogout();
        return [undefined, error];
      }
      if (status === 403) {
        // It's a CSRF error, reset and try again once
        usePageStore().updateCsrf();
        try {
          // eslint-disable-next-line no-await-in-loop
          return [await fn(), undefined];
        } catch (innerError) {
          return [undefined, innerError];
        }
      }
      if (status < 500 && status !== 429) {
        // It's not a server error, don't retry
        return [undefined, error];
      }

      if (attempt === retries - 1) {
        return [undefined, error];
      }

      const jitter = randomInt(0, backoff);
      const delayTime = backoff * 2 ** attempt + jitter;
      // eslint-disable-next-line no-await-in-loop
      await timeout(delayTime);
    }
  }
  throw new Error("Failed to call with error handling");
};

class RequestManager {
  #isOnline = ref(false);

  #queue = ref<Request[]>([]);

  #pending = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #idToFnMap = new Map<string, [(value: unknown) => void, (reason?: any) => void]>();

  working = computed(() => this.#queue.value.filter((e) => !e.nonBlocking).length > 0);

  constructor() {
    this.#loadQueue();
  }

  async init() {
    const environmentStore = useEnvironmentStore();

    this.#isOnline = computed(() => network.isOnline.value || environmentStore.isLocal);

    watch(
      () => this.#isOnline.value,
      (newIsOnline) => {
        if (!newIsOnline) {
          this.#saveQueue();
          return;
        }

        this.#loadQueue();
        this.#send();
      }
    );

    this.#send();
  }

  #loadQueue() {
    const requestQueueStr = lsGet(REQUEST_QUEUE_KEY);
    if (!requestQueueStr) {
      return;
    }

    lsRemove(REQUEST_QUEUE_KEY);
    this.#queue.value = JSON.parse(requestQueueStr);
  }

  #saveQueue() {
    this.#queue.value.forEach((request) => {
      if (request.kind !== RequestKind.POST && request.kind !== RequestKind.PUT) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      delete request.onStream;
    });

    const oldQueue = JSON.parse(lsGet(REQUEST_QUEUE_KEY) ?? "[]");
    lsSet(REQUEST_QUEUE_KEY, JSON.stringify([...oldQueue, ...this.#queue.value]));
    this.#queue.value = [];
  }

  #makePromise(duid: string) {
    return new Promise((resolve, reject) => {
      this.#idToFnMap.set(duid, [resolve, reject]);
    });
  }

  #resolvePromise(duid: string, value: unknown, error: unknown, shouldNotify: boolean) {
    const resolveAndReject = this.#idToFnMap.get(duid);
    if (!resolveAndReject) {
      return;
    }

    const [resolve, reject] = resolveAndReject;
    if (error) {
      if (shouldNotify) {
        const title = error instanceof Error ? error.message : error.toString();
        const detail = isObject(error) && isObject(error.body) ? ` ${JSON.stringify(error.body.items)}` : "";
        notify({
          message: `Request failed: ${title}${detail}`,
          type: NotificationType.ERROR,
        });
      }
      reject(error);
      return;
    }

    resolve(value);
  }

  async #sendRequest(request: Request) {
    const environmentStore = useEnvironmentStore();
    const pageStore = usePageStore();
    const tenantStore = useTenantStore();

    if (tenantStore.isDart && pageStore.networkDelay) {
      await timeout(randomInt(3000, 5000));
    }

    const shouldNotify = environmentStore.isLocal || tenantStore.isDart;
    const { kind } = request;
    switch (kind) {
      case RequestKind.TRANSACTION: {
        const { transactions } = request;
        const [responseBody, error] = await callWithErrorHandling(() =>
          TransactionsService.transactionsCreate(
            {
              items: transactions,
              clientDuid: pageStore.duid,
            },
            pageStore.csrftoken
          )
        );
        const duidToResult = new Map(
          responseBody?.results?.map((transactionResponse) => [transactionResponse.duid, transactionResponse])
        );
        transactions.forEach(({ duid }) => {
          this.#resolvePromise(duid, duidToResult.get(duid), error, shouldNotify);
        });
        break;
      }
      case RequestKind.GET: {
        const { duid, url } = request;
        const [response, error] = await callWithErrorHandling(() => axios.get(url));
        this.#resolvePromise(duid, response, error, shouldNotify);
        break;
      }
      case RequestKind.PUT: {
        const { duid, url, data, headers, onStream } = request;
        const [response, error] = await callWithErrorHandling(() =>
          axios.put(url, data, { headers: { ...headers }, onUploadProgress: onStream })
        );
        this.#resolvePromise(duid, response, error, shouldNotify);
        break;
      }
      case RequestKind.POST: {
        const { duid, url, data, headers, onStream } = request;
        const [response, error] = await callWithErrorHandling(() =>
          axios.post(url, data, {
            headers: { ...headers, "x-csrftoken": pageStore.csrftoken ?? "", "client-duid": pageStore.duid },
            onDownloadProgress: onStream,
          })
        );
        this.#resolvePromise(duid, response, error, shouldNotify);
        break;
      }
      default: {
        throw new Error(`Unknown request kind: ${kind}`);
      }
    }
  }

  async #send() {
    if (this.#pending || !this.#isOnline.value) {
      return;
    }
    this.#pending = true;

    try {
      while (this.#queue.value.length > 0) {
        const first = this.#queue.value[0];
        if ("background" in first && first.background) {
          // Don't wait for background requests
          this.#sendRequest(first);
        } else {
          // eslint-disable-next-line no-await-in-loop
          await this.#sendRequest(first);
        }
        this.#queue.value.shift();
      }
    } finally {
      this.#pending = false;
    }
  }

  async transact(kind: TransactionKind, operations: Operation[], nonBlocking?: boolean, background?: boolean) {
    const duid = makeDuid();
    const transaction = { duid, kind, operations, nonBlocking };

    const addToQueue = () => {
      const last = this.#queue.value[this.#queue.value.length - 1];
      if (
        last &&
        last.background === background &&
        last.kind === RequestKind.TRANSACTION &&
        !(this.#queue.value.length === 1 && this.#pending)
      ) {
        last.transactions.push(transaction);
      } else {
        this.#queue.value.push({ kind: RequestKind.TRANSACTION, transactions: [transaction], background });
      }
    };

    if (!this.#isOnline.value) {
      this.#loadQueue();
      addToQueue();
      this.#saveQueue();
      return undefined as unknown as TransactionResponse;
    }

    const promise = this.#makePromise(duid) as Promise<TransactionResponse>;

    if (background) {
      // Execute the background request immediately, so that it isn't blocked by the queue
      this.#sendRequest({ kind: RequestKind.TRANSACTION, transactions: [transaction], background });
    } else {
      addToQueue();
      this.#send();
    }

    return promise;
  }

  async get(url: string, nonBlocking?: boolean, background?: boolean) {
    const duid = makeDuid();
    const request: Request = { kind: RequestKind.GET, duid, url, nonBlocking, background };

    if (!this.#isOnline.value) {
      this.#loadQueue();
      this.#queue.value.push(request);
      this.#saveQueue();
      return undefined as unknown as AxiosResponse;
    }

    const promise = this.#makePromise(duid) as Promise<AxiosResponse>;

    if (background) {
      // Execute the background request immediately, so that it isn't blocked by the queue
      this.#sendRequest(request);
    } else {
      this.#queue.value.push(request);
      this.#send();
    }

    return promise;
  }

  async put(
    url: string,
    nonBlocking?: boolean,
    data?: object,
    headers?: Record<string, string>,
    onStream?: StreamCallback,
    background?: boolean
  ) {
    const duid = makeDuid();
    const request: Request = { kind: RequestKind.PUT, duid, url, nonBlocking, background, data, headers, onStream };

    if (!this.#isOnline.value) {
      this.#loadQueue();
      this.#queue.value.push(request);
      this.#saveQueue();
      return undefined as unknown as AxiosResponse;
    }

    const promise = this.#makePromise(duid) as Promise<AxiosResponse>;

    if (background) {
      // Execute the background request immediately, so that it isn't blocked by the queue
      this.#sendRequest(request);
    } else {
      this.#queue.value.push(request);
      this.#send();
    }

    return promise;
  }

  async post(
    url: string,
    nonBlocking?: boolean,
    data?: object,
    headers?: Record<string, string>,
    onStream?: StreamCallback,
    background?: boolean
  ) {
    const duid = makeDuid();
    const request: Request = { kind: RequestKind.POST, duid, url, nonBlocking, background, data, headers, onStream };

    if (!this.#isOnline.value) {
      this.#loadQueue();
      this.#queue.value.push(request);
      this.#saveQueue();
      return undefined as unknown as AxiosResponse;
    }

    const promise = this.#makePromise(duid) as Promise<AxiosResponse>;

    if (background) {
      // Execute the background request immediately, so that it isn't blocked by the queue
      this.#sendRequest(request);
    } else {
      this.#queue.value.push(request);
      this.#send();
    }

    return promise;
  }
}

export const requestManager = new RequestManager();
