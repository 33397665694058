<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { backendOld } from "~/api";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { CheckVerifiedIcon, DotsHorizontalIcon, NotionIcon, OpenExternalLinkIcon } from "~/icons";
import { DropdownMenuItemKind, NotionIntegrationStatus, Placement } from "~/shared/enums";
import type { DropdownMenuItem } from "~/shared/types";
import { useEnvironmentStore, useTenantStore } from "~/stores";

const router = useRouter();
const tenantStore = useTenantStore();
const environmentStore = useEnvironmentStore();

const queryOrig = router.currentRoute.value.query;
const { error, code, state } = queryOrig;

// Recursive hack to make sure that it happens in case some other nav is also happening
const resetQuery = async () => {
  const query = { ...router.currentRoute.value.query };
  delete query.state;
  delete query.code;
  delete query.error;
  const result = await router.replace({ query });
  if (!result) {
    return;
  }
  // eslint-disable-next-line no-restricted-syntax
  setTimeout(resetQuery, 100);
};
resetQuery();

const integrationPending = computed(() => tenantStore.notionIntegration?.status === NotionIntegrationStatus.PENDING);
const integrationEnabled = computed(() => tenantStore.notionIntegration?.status === NotionIntegrationStatus.ENABLED);
const logoLoadFailed = ref(false);

// Link integration to tenant
if (integrationPending.value && state === tenantStore.duid && typeof code === "string") {
  backendOld.notion.enableIntegration(code);
}

// Unlink integration
const unlink = () => {
  backendOld.notion.disableIntegration();
};

if (error && error === "access_denied") {
  unlink();
}

const oauthClientId = computed(() =>
  environmentStore.isProd ? "2240460e-c8b4-4458-ae7c-7e9fe2602f2d" : "db1a91cf-6152-41a9-a5a2-fc1132c65baf"
);
const authorizationUrl = computed(() => {
  const params = new URLSearchParams();
  params.set("client_id", oauthClientId.value);
  params.set("response_type", "code");
  params.set("owner", "user");
  params.set("redirect_uri", `${environmentStore.urlBase}/redirect/notion-oauth-finished`);
  params.set("state", tenantStore.duid);
  return `https://api.notion.com/v1/oauth/authorize?${params.toString()}`;
});

// Create integration and redirect to Notion to install
const link = async () => {
  await backendOld.notion.enableIntegration(null);
  window.location.href = authorizationUrl.value;
};

const dropdownItems = computed(() => {
  const items: DropdownMenuItem[] = [
    {
      title: "Configure integration",
      kind: DropdownMenuItemKind.EXTERNAL_LINK,
      navigate: {
        to: authorizationUrl.value,
        newTab: true,
      },
    },
    {
      title: "Disable integration",
      kind: DropdownMenuItemKind.BUTTON,
      onClick: () => unlink(),
    },
  ];
  return [
    {
      title: "Configure",
      items,
    },
  ];
});
</script>

<template>
  <div class="flex flex-col gap-y-16">
    <div class="flex flex-col justify-center space-y-3">
      <div class="flex justify-between">
        <div class="flex gap-x-4">
          <h2 class="flex select-none items-center text-xl text-md">Notion integration</h2>
          <div
            v-if="integrationEnabled"
            class="flex h-[26px] select-none items-center gap-1 rounded bg-primary-base px-1">
            <CheckVerifiedIcon class="text-oncolor icon-sm" />
            <span class="text-xs text-oncolor">Connected</span>
          </div>
        </div>
        <DropdownMenu v-if="integrationEnabled" :sections="dropdownItems" :placement="Placement.BOTTOM_RIGHT">
          <Tooltip text="Modify integration">
            <button type="button" class="flex items-center rounded p-0.5 text-lt focus-ring-std hover:bg-md">
              <span class="sr-only">Modify integration</span>
              <DotsHorizontalIcon class="icon-sm" />
            </button>
          </Tooltip>
        </DropdownMenu>
      </div>
      <p class="select-none text-sm/relaxed text-lt">
        Integrate Notion with Dart to enable convenient linking between Dart task descriptions and Notion documents.
      </p>
      <p v-if="integrationEnabled" class="select-none text-sm/relaxed text-lt">
        To link a Dart task to a document in Notion, copy the link to the document and then open the task's detail menu
        and choose 'Link Notion description'. Once you paste in the link, the document will be fully visible directly
        inside of the task.
      </p>
    </div>

    <button
      v-if="!integrationEnabled"
      type="button"
      class="flex select-none items-center justify-center gap-2 self-center rounded border px-3 py-2 text-center shadow-sm text-md border-md focus-ring-std hover:bg-lt"
      @click="link">
      <NotionIcon class="icon-md" />
      Link Dart and Notion
      <OpenExternalLinkIcon class="icon-md" />
    </button>

    <template v-if="integrationEnabled">
      <div class="flex select-none items-center gap-2">
        <div class="flex flex-1 grow flex-col">
          <span class="font-normal text-md">Workspace</span>
          <span class="text-xs text-lt">The Notion workspace that is connected</span>
        </div>
        <img
          v-if="tenantStore.notionIntegration?.workspaceIcon && !logoLoadFailed"
          :src="tenantStore.notionIntegration.workspaceIcon"
          class="select-none rounded-sm border-none object-cover icon-lg"
          alt="Notion workspace icon"
          @error="logoLoadFailed = true" />
        <span class="text-lg font-normal text-md">
          {{ tenantStore.notionIntegration?.workspaceName }}
        </span>
      </div>
    </template>
  </div>
</template>
