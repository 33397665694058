<script setup lang="ts">
import moment from "moment";
import { computed, onUnmounted, ref, watch } from "vue";

import Avatar from "~/components/dumb/Avatar.vue";
import TimeTrackingEntry from "~/components/timeTracking/TimeTrackingEntry.vue";
import { ChevronRightIcon } from "~/icons";
import type { TimeTracking, TimeTrackingEntry as ITimeTrackingEntry, User } from "~/shared/types";
import { getActiveTotalTimeElapsedText, useTimeTracker } from "~/utils/time";

const { user, timeTracking } = defineProps<{
  user: User;
  timeTracking: TimeTracking;
}>();

const emit = defineEmits<{
  update: [current: ITimeTrackingEntry, updated: ITimeTrackingEntry];
  delete: [entry: ITimeTrackingEntry];
}>();

const expanded = ref(false);

const activeTimeEntry = computed(() => timeTracking.find((entry) => !entry.finishedAt));
const { now, startTimer, stopTimer } = useTimeTracker(activeTimeEntry);

const activeTimeElapsedDuration = computed(() => {
  if (!activeTimeEntry.value) {
    return moment.duration(0);
  }
  return moment.duration(moment(now.value).diff(moment(activeTimeEntry.value.startedAt)));
});

const totalTimeTrackingText = computed(() =>
  getActiveTotalTimeElapsedText(timeTracking, activeTimeElapsedDuration.value)
);

const onClick = (e: Event) => e.stopImmediatePropagation();

watch(
  () => activeTimeEntry.value,
  (newValue) => {
    if (!newValue) {
      return;
    }
    startTimer();
  },
  { immediate: true }
);

onUnmounted(() => {
  stopTimer();
});
</script>
<template>
  <div class="flex w-full flex-col items-center py-1">
    <div
      class="group/user-summary flex w-full cursor-pointer select-none items-center gap-2 px-3 py-1 hover:bg-md"
      @click="expanded = !expanded"
      @keydown.enter="expanded = !expanded">
      <Avatar
        :abrev="user.abrev"
        :image-url="user.imageUrl"
        circle
        :color-hex="user.colorHex"
        class="mr-0.5 size-6 group-hover/user-summary:hidden" />

      <span class="mr-0.5 hidden size-6 items-center justify-center rounded hover:bg-hvy group-hover/user-summary:flex">
        <ChevronRightIcon class="text-lt icon-md" :class="expanded && 'rotate-90'" />
      </span>

      <span class="flex-1 text-sm text-lt">
        {{ user.name || user.email }}
      </span>

      <span class="justify-end text-sm tabular-nums text-lt">
        {{ totalTimeTrackingText }}
      </span>
    </div>

    <div
      v-if="expanded"
      class="flex size-full flex-col gap-2 pb-1 hover:bg-gray-100 hover:dark:bg-zinc-800"
      @click="onClick"
      @keydown.enter="onClick">
      <div class="pointer-events-auto flex flex-col">
        <table class="w-full table-auto border-collapse">
          <TimeTrackingEntry
            v-for="entry in timeTracking"
            :key="entry.startedAt"
            :entry="entry"
            @update="(current, updated) => emit('update', current, updated)"
            @delete="(entry) => emit('delete', entry)" />
        </table>
      </div>
    </div>
  </div>
</template>
