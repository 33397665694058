/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `task.created` - TASK_CREATED
 * * `task.deleted` - TASK_DELETED
 * * `task.updated` - TASK_UPDATED
 * * `doc.created` - DOC_CREATED
 * * `doc.deleted` - DOC_DELETED
 * * `doc.updated` - DOC_UPDATED
 * * `comment.created` - COMMENT_CREATED
 */
export enum WebhookEventKind {
  TASK_CREATED = 'task.created',
  TASK_DELETED = 'task.deleted',
  TASK_UPDATED = 'task.updated',
  DOC_CREATED = 'doc.created',
  DOC_DELETED = 'doc.deleted',
  DOC_UPDATED = 'doc.updated',
  COMMENT_CREATED = 'comment.created',
}
