import { WebhookEventKind } from "~/shared/enums";
import type { Webhook, WebhookUpdate } from "~/shared/types";
import { makeDuid } from "~/utils/common";
import { webhookComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  webhookList: Webhook[];
  getWebhookByDuid: (duid: string) => Webhook | undefined;
};

export type Actions = {
  /* Create a new webhook. */
  createWebhook: (
    url: string,
    order: string,
    partialWebhook?: Partial<Webhook>,
    options?: { awaitBackend?: boolean }
  ) => Promise<Webhook | undefined>;
  /* Update an existing webhook. */
  updateWebhook: (update: WebhookUpdate, options?: { awaitBackend?: boolean }) => Promise<Webhook | undefined>;
  /** Delete a webhook. */
  deleteWebhook: (webhook: Webhook, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Create a webhook without changing the backend.
   * @PRIVATE */
  $createWebhookNoBackend: (url: string, order: string, partialWebhook?: Partial<Webhook>) => Webhook | undefined;
  /** Sort webhooks.
   * @PRIVATE */
  $sortWebhooks: () => void;
  /** Set webhooks internally.
   * @PRIVATE */
  $setWebhooks: (webhooks: Webhook[]) => void;
  /** Create or update webhook from WS.
   * @PRIVATE */
  $createOrUpdateWebhook: (webhook: Webhook) => void;
  /** Delete webhook from WS.
   * @PRIVATE */
  $deleteWebhook: (webhook: Webhook) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  webhookList() {
    return [...this._duidsToWebhooks.values()];
  },
  getWebhookByDuid() {
    return (duid) => this._duidsToWebhooks.get(duid);
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createWebhook(url, order: string, partialWebhook = {}, options = {}) {
    const { awaitBackend = false } = options;
    const webhook = this.$createWebhookNoBackend(url, order, partialWebhook);
    if (!webhook) {
      return undefined;
    }

    const backendAction = this.$backend.webhook.create(webhook);
    if (awaitBackend) {
      await backendAction;
    }
    return webhook;
  },
  async updateWebhook(update, options = {}) {
    const { awaitBackend = false } = options;
    const webhook = this.getWebhookByDuid(update.duid);
    if (!webhook) {
      return undefined;
    }

    Object.assign(webhook, update);
    this.$sortWebhooks();

    const backendAction = this.$backend.webhook.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return webhook;
  },
  async deleteWebhook(webhook, options = {}) {
    const { awaitBackend = false } = options;

    this.$deleteWebhook(webhook);

    const backendAction = this.$backend.webhook.delete(webhook.duid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $createWebhookNoBackend(url, order, partialWebhook = {}) {
    if (url === "") {
      return undefined;
    }

    const webhook: Webhook = {
      duid: makeDuid(),
      enabled: true,
      order,
      title: "",
      url: url.trim(),
      eventKinds: Object.values(WebhookEventKind),
      ...partialWebhook,
    };

    this.$createOrUpdateWebhook(webhook);

    return webhook;
  },
  $sortWebhooks() {
    this._duidsToWebhooks = new Map([...this._duidsToWebhooks].sort((a, b) => webhookComparator(a[1], b[1])));
  },
  $setWebhooks(webhooks) {
    this._duidsToWebhooks = new Map(webhooks.map((e) => [e.duid, e]));
    this.$sortWebhooks();
  },
  $createOrUpdateWebhook(webhook) {
    const currentWebhook = this.getWebhookByDuid(webhook.duid);

    if (!currentWebhook) {
      this._duidsToWebhooks.set(webhook.duid, webhook);
    } else {
      Object.assign(currentWebhook, webhook);
    }

    this.$sortWebhooks();
  },
  $deleteWebhook(webhook) {
    this._duidsToWebhooks.delete(webhook.duid);
  },
};

export { actions, getters };
