import { defineStore } from "pinia";

import { FolderKind, PageKind } from "~/shared/enums";
import type { Comment, Dartboard, Dashboard, Doc, Folder, Form, Page, Space, Task, View } from "~/shared/types";
import { getPageDisplayName, slugifyStr } from "~/utils/common";

type BackendEnvironment = {
  name: string;
  startedAt: Date;
  branch: string;
  prNumber: number | null;
  prTitle: string | null;
  commitMessage: string;
  hash: string;
  changes: boolean;
};

type State = {
  host?: string;
  origin?: string;
  isLocalStandard: boolean;
  isLocalBuild: boolean;
  isLocalNetwork: boolean;
  isLocal: boolean;
  isStag: boolean;
  isProd: boolean;
  isPreview1: boolean;
  isPreview2: boolean;
  isPreview3: boolean;
  isPreview: boolean;
  isProxy: boolean;
  isPlaywright: boolean;
  isReviewerActive: boolean;
  needsUpdate: boolean;
  backendEnvironment?: BackendEnvironment;
};

const isSecure = (state: State) => !(state.isLocalStandard || state.isLocalNetwork || state.isLocalBuild);

const urlBase = (state: State) => (isSecure(state) ? "https://" : "http://") + state.host;

const useEnvironmentStore = defineStore("EnvironmentStore", {
  state: (): State =>
    ({
      host: undefined,
      origin: undefined,
      isLocalStandard: false,
      isLocalBuild: false,
      isLocalNetwork: false,
      isLocal: false,
      isStag: false,
      isProd: false,
      isPreview1: false,
      isPreview2: false,
      isPreview3: false,
      isPreview: false,
      isProxy: false,
      isPlaywright: false,
      // Manually set to false when the mobile app is being reviewed
      isReviewerActive: false,
      platform: undefined,
      needsUpdate: false,
      backendEnvironment: undefined,
    }) as State,
  getters: {
    isSecure,
    isUnknown: (state) => !(state.isLocal || state.isStag || state.isProd || state.isPreview),
    wsBase: (state) => (isSecure(state) ? "wss://" : "ws://") + state.host,
    urlBase,
    name: (state) =>
      state.isLocal ? "local" : state.isStag ? "stag" : state.isProd ? "prod" : state.isPreview ? "preview" : "unknown",
    prettyName: (state) =>
      state.isLocal
        ? `Local${
            state.isLocalBuild && state.backendEnvironment?.hash
              ? ` ${state.backendEnvironment?.hash?.substring(0, 7)}`
              : state.isProxy
                ? " proxy"
                : state.isLocalNetwork
                  ? " network"
                  : ""
          }`
        : state.isStag
          ? "Staging"
          : state.isProd
            ? "Production"
            : state.isPreview
              ? `Preview ${state.isPreview1 ? "1" : state.isPreview2 ? "2" : "3"}`
              : "Unknown",
    // TODO there are sorta three copies of these: in EnvironmentStore; in router/common; and in utils/common
    getSpaceUrl: (state) => (space: Space) =>
      `${urlBase(state)}/s/${space.duid}-${slugifyStr(
        getPageDisplayName(space, () => undefined),
        { trim: { len: 30 } }
      )}`,
    getViewUrl: (state) => (view: View) =>
      `${urlBase(state)}/v/${view.duid}-${slugifyStr(
        getPageDisplayName(view, () => undefined),
        { trim: { len: 30 } }
      )}`,
    getDartboardUrl() {
      return (dartboard: Dartboard) =>
        `${urlBase(this)}/d/${dartboard.duid}-${slugifyStr(getPageDisplayName(dartboard, this.$useDataStore().getSpaceByDuid), { trim: { len: 30 } })}`;
    },
    getTaskUrl: (state) => (task: Task | { duid: string; title: string }) =>
      `${urlBase(state)}/t/${task.duid}-${slugifyStr(task.title, { trim: { len: 30 } })}`,
    getCommentUrl: (state) => (comment: Comment) => `${urlBase(state)}/c/${comment.duid}`,
    getReportsUrl() {
      return (space: Space) =>
        `${urlBase(this)}/reports/${space.duid}-${slugifyStr(
          getPageDisplayName(space, () => undefined),
          { trim: { len: 30 } }
        )}`;
    },
    getFolderUrl() {
      return (folder: Folder) => {
        if (folder.kind === FolderKind.REPORTS) {
          const space = this.$useDataStore().getSpaceByDuid(folder.spaceDuid) as Space;
          return this.getReportsUrl(space);
        }
        return `${urlBase(this)}/f/${folder.duid}-${slugifyStr(
          getPageDisplayName(folder, () => undefined),
          { trim: { len: 30 } }
        )}`;
      };
    },
    getFormUrl: (state) => (form: Form) =>
      `${urlBase(state)}/p/r/${form.duid}-${slugifyStr(form.title, { trim: { len: 30 } })}`,
    getDocUrl: (state) => (doc: Doc) =>
      `${urlBase(state)}/o/${doc.duid}-${slugifyStr(doc.title, { trim: { len: 30 } })}`,
    getHomeUrl: (state) => () => `${urlBase(state)}/home`,
    getInboxUrl: (state) => () => `${urlBase(state)}/inbox`,
    getViewsRootUrl: (state) => () => `${urlBase(state)}/views`,
    getDashboardUrl: (state) => (dashboard: Dashboard) =>
      `${urlBase(state)}/a/${dashboard.duid}-${slugifyStr(dashboard.title, { trim: { len: 30 } })}`,
    getDashboardsRootUrl: (state) => () => `${urlBase(state)}/dashboards`,
    getPageUrl() {
      return (page: Page) => {
        const { pageKind } = page;
        switch (pageKind) {
          case PageKind.DARTBOARD: {
            return this.getDartboardUrl(page);
          }
          case PageKind.DASHBOARD: {
            return this.getDashboardUrl(page);
          }
          case PageKind.DASHBOARDS_ROOT: {
            return this.getDashboardsRootUrl();
          }
          case PageKind.DOC: {
            return this.getDocUrl(page);
          }
          case PageKind.FOLDER: {
            return this.getFolderUrl(page);
          }
          case PageKind.HOME: {
            return this.getHomeUrl();
          }
          case PageKind.INBOX: {
            return this.getInboxUrl();
          }
          case PageKind.VIEWS_ROOT: {
            return this.getViewsRootUrl();
          }
          case PageKind.SPACE: {
            return this.getSpaceUrl(page);
          }
          case PageKind.VIEW: {
            return this.getViewUrl(page);
          }
          default: {
            throw new Error(`Unknown page kind: ${pageKind}`);
          }
        }
      };
    },
    samlEntityId: (state) => `${urlBase(state)}/sso/saml`,
    samlAcsUrl(): string {
      const tenantStore = this.$useTenantStore();
      return `${urlBase(this)}/sso/saml/${tenantStore.duid}`;
    },
    scimUrl: (state) => `${urlBase(state)}/scim/v2`,
    googleRedirectUri(): string {
      return `${this.origin}/google-oauth`;
    },
    hideForReviewer(): boolean {
      const pageStore = this.$usePageStore();
      return this.isReviewerActive && pageStore.isMobileApp;
    },
  },
  actions: {
    setEnvironment(location: Location) {
      this.host = location.host;
      this.origin = location.origin;

      this.isLocalStandard = this.host.startsWith("localhost:5173");
      this.isLocalBuild = this.host.startsWith("localhost:8000");
      this.isLocalNetwork = this.host.match(/^192\.168\.\d{1,3}\.\d{1,3}:5173$/) !== null;
      this.isProxy = this.host.startsWith("dart.ngrok.app");
      this.isLocal = this.isLocalStandard || this.isLocalBuild || this.isProxy || this.isLocalNetwork;
      this.isStag = this.host.startsWith("stag.itsdart.com");
      this.isProd = this.host.startsWith("app.itsdart.com");
      this.isPreview1 = this.host.startsWith("preview-1");
      this.isPreview2 = this.host.startsWith("preview-2");
      this.isPreview3 = this.host.startsWith("preview-3");
      this.isPreview = this.isPreview1 || this.isPreview2 || this.isPreview3;
      this.isPlaywright = __PLAYWRIGHT__ === true;
    },
    setBackendEnvironment(backendEnvironment?: BackendEnvironment) {
      this.backendEnvironment = backendEnvironment
        ? { ...backendEnvironment, startedAt: new Date(backendEnvironment.startedAt) }
        : undefined;
    },
    async checkForUpdate() {
      const { data }: { data: BackendEnvironment } = await this.$backendOld.auth.getEnvironment();
      // An empty hash indicates the requesting a new backend pod while the rolling update of the backend deployment in Kubernetes is still ongoing
      if (!data.hash || data.hash === this.backendEnvironment?.hash) {
        return;
      }
      this.needsUpdate = true;
    },
  },
});

export default useEnvironmentStore;
