import type { RouteLocationNormalized, RouteRecordRaw } from "vue-router";

import CookiesComponent from "~/components/legal/Cookies.vue";
import PrivacyComponent from "~/components/legal/Privacy.vue";
import TermsComponent from "~/components/legal/Terms.vue";
import { UserDataMode, ViewKind } from "~/shared/enums";
import {
  GoogleOauthView,
  JoinWorkspaceView,
  LoginView,
  ResetPasswordView,
  SignUpView,
  VerifyEmailView,
} from "~/views/auth";
import ConsoleView from "~/views/ConsoleView.vue";
import DashboardsRootView from "~/views/DashboardsRootView.vue";
import DefaultRedirectView from "~/views/DefaultRedirectView.vue";
import DocView from "~/views/DocView.vue";
import EntityRedirectView from "~/views/EntityRedirectView.vue";
import ExternalFormView from "~/views/ExternalFormView.vue";
import FolderRootView from "~/views/FolderRootView.vue";
import FolderView from "~/views/FolderView.vue";
import HomePageView from "~/views/HomePageView.vue";
import HomeView from "~/views/HomeView.vue";
import InboxView from "~/views/InboxView.vue";
import DashboardView from "~/views/intelligence/DashboardView.vue";
import ReportsView from "~/views/intelligence/ReportsView.vue";
import LastRedirectView from "~/views/LastRedirectView.vue";
import LatencyView from "~/views/LatencyView.vue";
import LegalView from "~/views/LegalView.vue";
import PublicViewView from "~/views/PublicViewView.vue";
import RedirectView from "~/views/RedirectView.vue";
import SuperuserDashboardView from "~/views/SuperuserDashboardView.vue";
import TaskListView from "~/views/TaskListView.vue";
import ViewsRootView from "~/views/ViewsRootView.vue";

const TASK_LIST_VIEWS = [
  {
    path: "/search",
    name: "search",
    title: "Search",
    propsAdtl: { viewKind: ViewKind.SEARCH },
    userDataMode: UserDataMode.ALL,
  },
  {
    path: "/my-tasks",
    name: "my_tasks",
    title: "My tasks",
    propsAdtl: { viewKind: ViewKind.MY_TASKS },
    userDataMode: UserDataMode.ALL,
  },
  {
    path: "/trash",
    name: "trash",
    title: "Trash",
    propsAdtl: { viewKind: ViewKind.TRASH },
    userDataMode: UserDataMode.ALL,
  },
  {
    path: "/v/:viewDuid([a-zA-Z0-9]{12})",
    name: "view",
    title: "View",
    userDataMode: UserDataMode.ALL,
  },
  {
    path: "/d/:dartboardDuid([a-zA-Z0-9]{12})",
    name: "dartboard",
    title: "Dartboard",
    userDataMode: UserDataMode.SPECIFIC,
  },
];

const ROUTES: RouteRecordRaw[] = [
  {
    path: "/",
    children: [
      {
        path: "/sign-up",
        name: "sign_up",
        component: SignUpView,
        meta: {
          title: "Sign up for Dart",
          requiresAuth: false,
          setsPageLoadedManually: true,
          redirectIfLoggedIn: true,
        },
      },
      {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
          title: "Log in to Dart",
          requiresAuth: false,
          setsPageLoadedManually: true,
          redirectIfLoggedIn: true,
        },
      },
      {
        path: "/google-oauth",
        name: "google_oauth",
        component: GoogleOauthView,
        meta: {
          title: "Logging into Dart with Google",
          requiresAuth: false,
          setsPageLoadedManually: true,
        },
      },
      {
        path: "/verify-email",
        name: "verify_email",
        component: VerifyEmailView,
        meta: {
          title: "Verifying email with Dart",
          requiresAuth: false,
          setsPageLoadedManually: true,
          redirectIfLoggedIn: true,
        },
      },
      {
        path: "/join",
        name: "join",
        component: JoinWorkspaceView,
        meta: {
          title: "Accept Dart invitation",
          requiresAuth: false,
          setsPageLoadedManually: true,
          redirectIfLoggedIn: true,
        },
      },
      {
        path: "/reset-password",
        name: "reset_password",
        component: ResetPasswordView,
        meta: {
          title: "Reset Dart password",
          requiresAuth: false,
          setsPageLoadedManually: true,
          redirectIfLoggedIn: true,
        },
      },
      {
        path: "/logout",
        name: "logout",
        component: LoginView, // don't want this to be a redirect because otherwise nav logic won't be run
      },
    ],
  },
  {
    path: "/latency",
    name: "latency",
    component: LatencyView,
    meta: {
      title: "Latency",
      userDataMode: UserDataMode.NONE,
    },
  },
  {
    path: "/p",
    children: [
      {
        path: "r/:formDuid([a-zA-Z0-9]{12}):slugSep?:slug?",
        name: "public_form",
        component: ExternalFormView,
        meta: {
          title: "Form",
          setsPageTitleManually: true,
          setsPageLoadedManually: true,
          requiresAuth: false,
          userDataMode: UserDataMode.NONE,
        },
      },
      {
        path: "v/:viewDuid([a-zA-Z0-9]{12}):slugSep?:slug?/:taskDuidAndSlug?",
        name: "public_view",
        component: PublicViewView,
        meta: {
          title: "View",
          setsPageTitleManually: true,
          setsPageLoadedManually: true,
          requiresAuth: false,
          userDataMode: UserDataMode.NONE,
        },
      },
    ],
  },
  {
    path: "/console",
    name: "console",
    component: ConsoleView,
    meta: {
      title: "Console",
      userDataMode: UserDataMode.NONE,
    },
  },
  {
    path: "/superuser-dashboard",
    name: "superuser_dashboard",
    component: SuperuserDashboardView,
    meta: {
      title: "Superuser dashboard",
      userDataMode: UserDataMode.NONE,
    },
  },
  {
    path: "/",
    component: HomeView,
    children: [
      {
        path: "/",
        name: "last",
        component: LastRedirectView,
        meta: {
          title: "Last",
          setsPageLoadedManually: true,
          setsPageTitleManually: true,
        },
      },
      {
        path: "/default",
        name: "default",
        component: DefaultRedirectView,
        meta: {
          title: "Default",
          setsPageLoadedManually: true,
          setsPageTitleManually: true,
        },
      },
      ...TASK_LIST_VIEWS.map(({ path, name, title, propsAdtl, userDataMode }) => ({
        path: `${path}:slugSep?:slug?/:taskDuidAndSlug?`,
        name,
        component: TaskListView,
        props: (route: RouteLocationNormalized) => ({ ...route.params, ...(propsAdtl ?? {}) }),
        meta: {
          title,
          setsPageTitleManually: true,
          setsPageLoadedManually: true,
          userDataMode,
        },
      })),
      {
        path: "/f/:folderDuid([a-zA-Z0-9]{12}):slugSep?:slug?",
        component: FolderRootView,
        props: true,
        children: [
          {
            path: "",
            name: "folder",
            component: FolderView,
            props: true,
            meta: {
              title: "Folder",
              setsPageTitleManually: true,
              userDataMode: UserDataMode.SPECIFIC,
            },
          },
        ],
      },
      {
        path: "/f/:folderDuid",
        component: FolderRootView,
        props: true,
        children: [
          {
            path: ":docDuid([a-zA-Z0-9]{12}):slugSep?:slug?",
            name: "doc",
            component: DocView,
            props: true,
            meta: {
              title: "Doc",
              setsPageTitleManually: true,
              userDataMode: UserDataMode.SPECIFIC,
            },
          },
        ],
      },
      {
        path: "/home",
        component: HomePageView,
        name: "home",
        meta: {
          title: "Home",
          userDataMode: UserDataMode.ALL,
        },
      },
      {
        path: "/inbox",
        component: InboxView,
        name: "inbox",
        meta: {
          title: "Inbox",
          userDataMode: UserDataMode.ALL,
        },
      },
      {
        path: "/views",
        component: ViewsRootView,
        name: "views",
        meta: {
          title: "Views",
          userDataMode: UserDataMode.ALL,
        },
      },
      {
        path: "dashboards",
        component: DashboardsRootView,
        name: "dashboards",
        meta: {
          title: "Dashboards",
          userDataMode: UserDataMode.ALL,
        },
      },
      {
        path: "a/:dashboardDuid([a-zA-Z0-9]{12}):slugSep?:slug?",
        alias: "/a/:dashboardDuid([a-zA-Z0-9]{12}):slugSep?:slug?",
        name: "dashboard",
        component: DashboardView,
        props: true,
        meta: {
          title: "Dashboard",
          setsPageTitleManually: true,
          userDataMode: UserDataMode.ALL,
        },
      },
      {
        path: "reports/:spaceDuid([a-zA-Z0-9]{12})?:slugSep?:slug?",
        name: "reports",
        component: ReportsView,
        props: true,
        meta: {
          title: "Reports",
          userDataMode: UserDataMode.SPECIFIC,
        },
      },
      {
        path: "/:entityKindAbbrev([tcou])/:entityDuid([a-zA-Z0-9]{12}):slugSep?:slug?", // spell-checker: disable-line
        name: "entity",
        component: EntityRedirectView,
        props: true,
        meta: {
          setsPageTitleManually: true,
          setsPageLoadedManually: true,
          userDataMode: UserDataMode.SPECIFIC,
        },
      },
    ],
  },
  {
    path: "/legal",
    redirect: { name: "missing" },
    component: LegalView,
    children: [
      {
        path: "privacy-policy",
        name: "privacy",
        component: PrivacyComponent,
        meta: {
          title: "Dart privacy policy",
          requiresAuth: false,
          userDataMode: UserDataMode.NONE,
        },
      },
      {
        path: "terms-of-use",
        name: "terms",
        component: TermsComponent,
        meta: {
          title: "Dart terms of use",
          requiresAuth: false,
          userDataMode: UserDataMode.NONE,
        },
      },
      {
        path: "cookie-policy",
        name: "cookies",
        component: CookiesComponent,
        meta: {
          title: "Dart cookie policy",
          requiresAuth: false,
          userDataMode: UserDataMode.NONE,
        },
      },
    ],
  },
  // TODO refactor these redirects to be all in one place
  {
    path: "/redirect/notion-oauth-finished",
    name: "redirect_notion_oauth_finished",
    redirect: (to) => ({
      ...to,
      name: "home",
      query: {
        settings: "notion",
        ...to.query,
      },
    }),
  },
  {
    path: "/redirect/mobile-app",
    name: "redirect_mobile_app",
    component: RedirectView,
    meta: {
      title: "Loading Dart mobile app",
      requiresAuth: false,
      userDataMode: UserDataMode.NONE,
    },
  },
  {
    path: "/redirect/desktop-app",
    name: "redirect_desktop_app",
    component: RedirectView,
    meta: {
      title: "Downloading Dart desktop app",
      requiresAuth: false,
      userDataMode: UserDataMode.NONE,
    },
  },
  {
    path: "/:path(.*)*",
    name: "missing",
    redirect: { name: "home" },
  },
];

export default ROUTES;
