<script setup lang="ts">
import { computed } from "vue";

import { BACKUP_FONTS, colorsByTheme } from "~/constants/style";
import { EditorMode } from "~/shared/enums";
import { usePageStore } from "~/stores";

const props = defineProps<{
  editorMode: EditorMode;
}>();

const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const isListMiniRoadmapMode = computed(() => props.editorMode === EditorMode.LIST_MINI_ROADMAP);
const isListMiniNonRoadmapMode = computed(
  () => props.editorMode === EditorMode.LIST_MINI || props.editorMode === EditorMode.LIST_MINI_TCM
);

const headerLowerBorderColor = computed(() =>
  isListMiniNonRoadmapMode.value ? colors.value.borderVlt : colors.value.borderLt
);

const isMobile = computed(() => (pageStore.isMobile ? "0px" : "16px"));

// TODO put these back somehow, some options require the combine() function to be fixed

// const rowSoftSelectionTopBorderColor = computed(() => {
//   const background = fromHex(colors.value.bgStandard);
//   const rowBackground = combine(background, fromHex(colors.value.rowSoftSelection));
//   return toHex(combine(rowBackground, fromHex(colors.value.lightBorder)));
// });

// const rowHardSelectionTopBorderColor = computed(() => {
//   return pageStore.theme === "light" ? "#d7d8fb" : "#323464";
//   const background = fromHex(colors.value.bgStandard);
//   const rowBackground = combine(background, fromHex(colors.value.rowHardSelection));
//   return toHex(combine(rowBackground, fromHex(colors.value.rowHardSelection)));
// });

// const rowBothSelectionTopBorderColor = computed(() => {
//   return pageStore.theme === "light" ? "#cacbfb" : "#393b75";
//   const background = fromHex(colors.value.bgStandard);
//   const rowBackground = combine(background, fromHex(colors.value.rowBothSelection));
//   return toHex(combine(rowBackground, fromHex(colors.value.rowHardSelection)));
// });
</script>

<template>
  <slot />
</template>

<style>
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-material.css";

/* set fonts and colors for entire list */
.ag-theme-material {
  font-family: "Inter var", v-bind("BACKUP_FONTS");
  -webkit-font-smoothing: auto;
  --ag-background-color: v-bind("colors.bgStd");
  --ag-foreground-color: v-bind("colors.textMd");
  --ag-header-background-color: v-bind("colors.bgStd");
  --ag-header-cell-hover-background-color: v-bind("colors.bgStd");
  --ag-row-hover-color: transparent;
  --ag-selected-row-background-color: transparent;
  --ag-modal-overlay-background-color: transparent;
}

/* scrolling */
.ag-theme-material .ag-body-viewport {
  overflow-y: auto !important;
}
.ag-sticky-bottom {
  border: none;
}

/* row borders */
.ag-theme-material .ag-row {
  border-width: 0 0 1px 0;
  border-color: v-bind("colors.borderVlt");
}
.ag-theme-material .ag-row.ag-full-width-row:not(.ag-row-group-expanded) {
  border-color: transparent;
}

/* row selection */
.ag-theme-material .ag-row-hover:not(.ag-full-width-row) {
  background-color: v-bind("colors.rowSoftSelection");
  border-color: v-bind("colors.borderLt");
}
/* .ag-theme-material .ag-row:not(.ag-row-selected):has(+ .ag-row.ag-row-hover) {
  border-color: v-bind("rowSoftSelectionTopBorderColor");
} */

.ag-theme-material .ag-row.ag-row-selected {
  background-color: v-bind("colors.rowHardSelection");
  border-color: v-bind("colors.rowHardSelection");
}
/* .ag-theme-material .ag-row:has(+ .ag-row.ag-row-selected) {
  border-color: v-bind("rowHardSelectionTopBorderColor");
} */

.ag-theme-material .ag-row.ag-row-selected.ag-row-hover {
  background-color: v-bind("colors.rowBothSelection");
  border-color: v-bind("colors.rowHardSelection");
}
/* .ag-theme-material .ag-row:has(+ .ag-row.ag-row-selected.ag-row-hover) {
  border-color: v-bind("rowBothSelectionTopBorderColor");
} */

/* cell borders */
.ag-theme-material .ag-ltr .ag-cell {
  outline: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.ag-theme-material .ag-pinned-left-header,
.ag-theme-material .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  border: none;
}
.ag-theme-material
  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
  .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
  border-color: v-bind("colors.borderVlt");
}

/* column resize handle */
.ag-theme-material .ag-header-cell {
  overflow: visible;
}
.ag-theme-material .ag-header-cell:hover .ag-header-cell-resize {
  display: flex;
}
.ag-theme-material .ag-header-cell-resize {
  width: 11px;
  right: -5px;
}
.ag-theme-material .ag-header-cell-resize:hover {
  display: flex;
  border-left: 4px solid v-bind("colors.bgStd");
  border-right: 4px solid v-bind("colors.bgStd");
  background-color: v-bind("colors.bgHvy");
}

/* drag handle for touchscreens */
.ag-theme-material .ag-ltr .ag-drag-handle {
  margin-right: 0px;
  margin-left: v-bind("isMobile");
}
.ag-theme-material .ag-ltr .ag-drag-handle .ag-icon-grip {
  color: v-bind("colors.textLt");
}

/* row drag ghost */
.ag-theme-material.ag-dnd-ghost {
  opacity: 0;
  height: 35px !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.ag-row.ag-full-width-row {
  opacity: 1;
}

/* remove minimum height */
.ag-theme-material .ag-center-cols-clipper,
.ag-theme-material .ag-center-cols-container,
.ag-theme-material .ag-center-cols-viewport {
  min-height: unset !important;
}

/* fix weird problem where select and hover makes the whole thing white  */
.ag-theme-material .ag-row-hover.ag-row-selected::before {
  background-image: none !important;
}

/* header */
.ag-theme-material .ag-header {
  border-bottom-color: v-bind("headerLowerBorderColor");
}
@media print {
  .ag-theme-material .ag-header {
    display: none;
  }
}

/* headers remove padding */
.ag-theme-material .ag-header-cell.ag-focus-managed {
  padding: 0px;
}

/* headers remove focus outline */
.ag-theme-material .ag-keyboard-focus .ag-header-cell:focus::after {
  border: 0;
}

/* hide vertical scrollbar in roadmap */
.ag-body-vertical-scroll {
  display: v-bind("isListMiniRoadmapMode ? 'none' : 'block'");
}
</style>
