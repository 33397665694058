import * as Sentry from "@sentry/vue";
import { useDocumentVisibility, useWindowFocus } from "@vueuse/core";
import moment, { type Moment } from "moment";
import { nextTick, watchEffect } from "vue";

import { backendOld } from "~/api";
import columns from "~/components/visualization/list/columns";
import { StoreDataPreservationMode } from "~/shared/enums";
import type { Task, User } from "~/shared/types";
import { useAppStore, useDataStore, useEnvironmentStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { deleteCookie } from "~/utils/common";
import {
  COLLECT_ALL_DATA_KEY,
  LAST_LOCAL_DATA_RESET_AT_KEY,
  lsClearAll,
  lsGet,
  lsSet,
  USER_DUID_LOCAL_STORAGE_KEY,
} from "~/utils/localStorageManager";

import type { Actions } from ".";

const MAX_IDLE_TIME_MS = 1000 * 60 * 5;

/** Local app related actions */
export default (actions: Actions) => ({
  /** Clear all local data */
  clearAllLocalData: (hard = true): void => {
    lsClearAll();
    if (!hard) {
      // TODO reenable commented out stuff when we resume using IDB
      // idbClearAll().then(() => idbOpen());
      return;
    }
    lsSet(LAST_LOCAL_DATA_RESET_AT_KEY, moment().toISOString());
    // await idbClearAll();
    window.location.reload();
  },
  setUser: (user: User): void => {
    const environmentStore = useEnvironmentStore();
    const pageStore = usePageStore();
    const tenantStore = useTenantStore();
    const userStore = useUserStore();

    userStore.setUser(user);
    actions.commands.setupCommandBar();
    usePageStore().updateTheme();
    const userObj = {
      id: user.duid,
      email: user.email,
      name: user.name,
      displayName: user.name,
      tenantName: tenantStore.name,
      tenantDuid: tenantStore.duid,
      environment: environmentStore.name,
    };

    // set tracking
    if (pageStore.runTrackingTools) {
      Sentry.setUser(userObj);
    }
  },
  setUserData: (
    data: Record<string, never>,
    preservationMode: StoreDataPreservationMode = StoreDataPreservationMode.NONE
  ): void => {
    const appStore = useAppStore();
    const pageStore = usePageStore();
    const dataStore = useDataStore();
    const environmentStore = useEnvironmentStore();
    const tenantStore = useTenantStore();
    const userStore = useUserStore();

    if (!data.isLoggedIn) {
      return;
    }

    const start = performance.now();

    const resetLocalDataBeforeStr = data.resetLocalDataBeforeStr as string | null;
    const lastLocalDataResetStr = lsGet(LAST_LOCAL_DATA_RESET_AT_KEY);
    if (!lastLocalDataResetStr) {
      lsSet(LAST_LOCAL_DATA_RESET_AT_KEY, moment().toISOString());
    } else if (resetLocalDataBeforeStr && lastLocalDataResetStr < resetLocalDataBeforeStr) {
      actions.app.clearAllLocalData(false);
    }

    const collectAllData = data.collectAllData as boolean;
    const localCollectAllData = lsGet(COLLECT_ALL_DATA_KEY) === "true";
    if (localCollectAllData !== collectAllData) {
      lsSet(COLLECT_ALL_DATA_KEY, collectAllData.toString());
      if (collectAllData) {
        // TODO this isn't great UX
        window.location.reload();
      }
    }

    pageStore.isLoggedIn = true;
    pageStore.onlyLoadedDartboardDuid = data.onlyLoadedDartboardDuid;

    environmentStore.setBackendEnvironment(data.environment);

    appStore.init();
    appStore.setEventKinds(data.eventKinds);
    appStore.setPageIconOptions(data.pageIconOptions);
    appStore.setWebhookEventKinds(data.webhookEventKinds);

    tenantStore.setTenant(data.tenant);

    userStore.setFirstDayOfWeek(data.firstDayOfWeek);
    userStore.setLastUrlPath(data.lastUrlPath);
    userStore.setNotificationSettings(data.notificationSettings);
    userStore.setOpenInNativeApp(data.openInNativeApp);
    userStore.setTutorialStatusMap(data.tutorialStatusMap);
    userStore.setHasPassword(data.hasPassword);

    // these groups must happen in this order for subsequent comparisons
    dataStore.$setSpaces(data.spaces);
    dataStore.$setDartboards(data.dartboards);
    dataStore.$setRelationshipKinds(data.relationshipKinds);

    const newTasks: Task[] = data.tasks;
    dataStore.$setProperties(data.properties);
    if (pageStore.showDebugInfo) {
      pageStore.startCellRendering(newTasks.length * columns(dataStore).columnDefs.value.length);
    }
    dataStore.$setTasks(newTasks, preservationMode);

    dataStore.$setFolders(data.folders);

    // then these are just alphabetical
    dataStore.$setAgents(data.agents);
    dataStore.$setAttachments(data.attachments);
    dataStore.$setBrainstorms(data.brainstorms);
    dataStore.$setChats(data.chats);
    dataStore.$setDashboards(data.dashboards);
    dataStore.$setComments(data.comments, preservationMode);
    dataStore.$setForms(data.forms);
    dataStore.$setFormFields(data.formFields);
    dataStore.$setUsers(data.users);
    dataStore.$setNotifications(data.notifications);
    dataStore.$setLayouts(data.layouts, preservationMode);
    dataStore.$setOptions(data.options);
    dataStore.$setStatuses(data.statuses);
    dataStore.$setTaskDocRelationships(data.taskDocRelationships);
    dataStore.$setTaskKinds(data.taskKinds);
    dataStore.$setWebhooks(data.webhooks);
    dataStore.$setViews(data.views);

    actions.app.setUser(data.user);

    pageStore.logTime("setting user data", start);
  },
  setExtraUserData: (data: Record<string, never>): void => {
    const pageStore = usePageStore();
    const dataStore = useDataStore();

    const start = performance.now();

    pageStore.onlyLoadedDartboardDuid = data.onlyLoadedDartboardDuid;

    dataStore.$setAttachments(data.attachments);
    dataStore.$setBrainstorms(data.brainstorms);
    dataStore.$setComments(data.comments, StoreDataPreservationMode.ALL);
    dataStore.$setTaskDocRelationships(data.taskDocRelationships);
    dataStore.$setTasks(data.tasks, StoreDataPreservationMode.ALL);

    pageStore.logTime("setting extra user data", start);
  },
  // TODO idle this strategy has a number of issues, some of which include that it only works for the types that have PreservationModes set up and it can temporarily un-delete stuff
  watchForIdle: (): void => {
    const visibility = useDocumentVisibility();
    const windowFocus = useWindowFocus();

    let idleStart: undefined | Moment;

    watchEffect(async () => {
      if (visibility.value === "hidden" || !windowFocus.value) {
        idleStart = moment();
        return;
      }
      if (idleStart === undefined || moment().diff(idleStart) <= MAX_IDLE_TIME_MS) {
        idleStart = undefined;
        return;
      }

      idleStart = undefined;
      // TODO idle add back
      // appStore.disconnectWs();
      // const response = await backendOld.getUserData(UserDataMode.ALL);
      // setTimeout(() => {
      //   actions.app.setUserData(response.data, StoreDataPreservationMode.RECENT);
      //   appStore.connectWs(actions.websockets.getManager());
      // });
    });
  },
  /** Logout the user, reset stores and clear all local data  */
  logout: async (): Promise<void> => {
    const appStore = useAppStore();
    const pageStore = usePageStore();
    const dataStore = useDataStore();
    const environmentStore = useEnvironmentStore();
    const tenantStore = useTenantStore();
    const userStore = useUserStore();

    if (!pageStore.isLoggedIn) {
      return;
    }
    actions.app.clearAllLocalData(false);
    appStore.disconnectWs();

    pageStore.isLoggedIn = false;
    // do this after setting pageStore.isLoggedIn to false to avoid rendering errors
    nextTick(() => {
      // reset all of the stores that need it (not EnvironmentStore nor PageStore)
      appStore.$reset();
      dataStore.$reset();
      environmentStore.setBackendEnvironment(undefined);
      tenantStore.$reset();
      userStore.$reset();

      lsSet(USER_DUID_LOCAL_STORAGE_KEY, "");

      pageStore.updateTheme(true);
    });

    try {
      await backendOld.auth.logout();
    } catch (error) {
      deleteCookie("sessionid");
      throw error;
    }
  },
});
