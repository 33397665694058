<script setup lang="ts">
import { ref } from "vue";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import ProgressBar from "~/components/dumb/ProgressBar.vue";
import { CheckIcon, FileIcon } from "~/icons";
import { ButtonStyle } from "~/shared/enums";
import { downloadFile } from "~/utils/api";

const started = ref(false);
const inProgress = ref(false);
const progress = ref(1);

const runExport = async () => {
  started.value = true;
  inProgress.value = true;
  progress.value = 1;

  let nextUpdate: ReturnType<typeof setTimeout> | undefined;

  const waitAndUpdateProgress = () => {
    const minS = 0.5;
    const maxS = 1.5;
    const delay = Math.floor(Math.random() * (maxS * 1000 - minS * 1000 + 1) + minS * 1000);
    // eslint-disable-next-line no-restricted-syntax
    return setTimeout(() => {
      progress.value += Math.floor(Math.random() * 3) * 5 + 5;
      if (progress.value >= 96) {
        progress.value = 96;
        return;
      }
      nextUpdate = waitAndUpdateProgress();
    }, delay);
  };

  nextUpdate = waitAndUpdateProgress();

  const res = await backendOld.workspace.export();
  clearTimeout(nextUpdate);
  downloadFile(res.data.fileUrl, res.data.fileName);
  inProgress.value = false;
  progress.value = 100;
};
</script>

<template>
  <div class="flex flex-col justify-start">
    <span class="mb-4 text-sm font-normal tracking-[-0.15px] text-lt">
      Use the button to export all of your Dart data, for example as a snapshot of your current information or for data
      analysis.
    </span>
    <span class="mb-6 text-sm font-normal tracking-[-0.15px] text-lt">
      It will take a moment to export all of your data, then the file will download automatically and we will send you
      an email.
    </span>
    <Button
      :btn-style="ButtonStyle.PRIMARY"
      text="Export data"
      :class="{ 'bg-hvy': inProgress }"
      :disabled="inProgress"
      class="w-[110px]"
      @click="runExport" />

    <div v-if="started" class="mt-8 h-[74px] w-full rounded-md bg-lt">
      <div class="m-4 flex items-center gap-4">
        <div class="relative flex h-10 items-center justify-center">
          <div
            class="absolute bottom-2 right-4 flex h-4 w-7 items-center justify-center rounded border bg-std border-hvy">
            <span class="text-xs font-medium uppercase text-md">zip</span>
          </div>
          <FileIcon class="size-10 text-vlt" />
        </div>
        <div class="flex h-10 w-full flex-col justify-between">
          <span class="text-sm font-semibold text-md">Dart export</span>
          <ProgressBar v-if="progress < 100" :value="progress" no-content class="h-2" />
          <div v-else class="flex items-center gap-2">
            <div class="text-sm text-lt">Downloaded</div>
            <div class="flex size-4 items-center justify-center rounded-full bg-primary-base">
              <CheckIcon class="size-3 text-oncolor" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
