<script setup lang="ts">
import { computed, nextTick, onUnmounted, ref, watch } from "vue";

import actions from "~/actions";
import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import NumberEditorInput from "~/components/dumb/NumberEditorInput.vue";
import ProgressBar from "~/components/dumb/ProgressBar.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { THROTTLE_MS } from "~/constants/app";
import { colorsByTheme } from "~/constants/style";
import { ChevronDownIcon, ChevronUpIcon, NumberFieldIcon, SizeFieldIcon } from "~/icons";
import { DropdownMenuItemKind, EditorMode, NumberFormat, Placement, PropertyKind } from "~/shared/enums";
import type { PropertyDefaultSize, PropertyNumber, PropertyValueForKind, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { makeUuid } from "~/utils/common";
import { ThrottleManager } from "~/utils/throttleManager";

type Value = PropertyValueForKind<PropertyNumber | PropertyDefaultSize>;

const props = defineProps<{
  property: PropertyNumber | PropertyDefaultSize;
  tasks: Task[];
  defaultValue?: Value;
  editorMode: EditorMode;
  value?: Value;
}>();

const emit = defineEmits<{
  update: [value?: Value];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const inputRef = ref<HTMLInputElement | null>(null);

const interval = ref<ReturnType<typeof setInterval> | null>(null);

const showEditor = ref(false);

const colors = computed(() => colorsByTheme[pageStore.theme]);
const id = ref(`number-${makeUuid()}`);

const isDollars = computed(() => props.property.adtl.format === NumberFormat.DOLLARS);
const isPercentage = computed(() => props.property.adtl.format === NumberFormat.PERCENTAGE);

const numberValue = computed(() => {
  if (props.editorMode === EditorMode.FORM || props.editorMode === EditorMode.PROPERTY_DEFAULT) {
    return props.value ?? null;
  }

  const firstTaskValue = getPropertyValueFromTask(props.property, props.tasks[0]);
  return props.tasks.every((task) => getPropertyValueFromTask(props.property, task) === firstTaskValue)
    ? firstTaskValue
    : null;
});

const isChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isFormOrDefaultPropertyMode = computed(
  () => isFormMode.value || props.editorMode === EditorMode.PROPERTY_DEFAULT
);

const selectedTasks = computed(() =>
  isChipMode.value ? props.tasks : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);

const save = (value: Value, noBackend: boolean) => {
  dataStore.updateTasks(
    selectedTasks.value.map((task) => ({
      duid: task.duid,
      ...getPropertyPartialTask(props.property, task, value),
    })),
    { noBackend }
  );
};

const numberInner = ref(numberValue.value ?? null);
const saveManager = new ThrottleManager(() => {
  save(numberInner.value, false);
}, THROTTLE_MS);

watch(
  () => numberValue.value,
  (value) => {
    if (value === numberInner.value) {
      return;
    }

    numberInner.value = value;
    saveManager.cancel();
  }
);

const descendantTasks = computed(() =>
  props.tasks[0] ? dataStore.getTasksByDuidsOrdered(dataStore.getDescendantDuids(props.tasks[0])) : []
);

const hasSubtasks = computed(() => descendantTasks.value.length > 0);

const totalSize = computed(() => {
  if (!hasSubtasks.value || !props.property.adtl.rollup) {
    return numberInner.value;
  }

  const subtaskValues = descendantTasks.value.map((task) => getPropertyValueFromTask(props.property, task) ?? 0);
  const subtaskSum = subtaskValues.reduce((sum, value) => sum + value, 0);
  const res = isPercentage.value ? subtaskSum / descendantTasks.value.length : subtaskSum;
  if (numberInner.value === null) {
    return res > 0 ? res : null;
  }

  return res + (isPercentage.value ? 0 : numberInner.value);
});

const hasValue = computed(() => numberValue.value !== null || totalSize.value !== null);

const displayedText = computed(() => {
  if (!hasSubtasks.value || !props.property.adtl.rollup) {
    return numberInner.value;
  }
  return `${totalSize.value}${numberInner.value !== null ? ` (${numberInner.value})` : ""}`;
});

const showNumberEditor = computed(
  () =>
    !hasSubtasks.value ||
    !props.property.adtl.rollup ||
    showEditor.value ||
    props.property.kind === PropertyKind.DEFAULT_SIZE ||
    descendantTasks.value.every((task) => getPropertyValueFromTask(props.property, task) === null)
);

const tooltipText = computed(() => {
  const hasValidSubtaskValues = descendantTasks.value.some((task) => {
    const value = getPropertyValueFromTask(props.property, task);
    return value !== null && value !== undefined;
  });

  if (!hasSubtasks.value || !props.property.adtl.rollup || !hasValidSubtaskValues || numberInner.value === null) {
    return [];
  }

  const formatValue = (value: number) => `${isDollars.value ? "$" : ""}${value}${isPercentage.value ? "%" : ""}`;

  return [
    `${formatValue(totalSize.value ?? 0)} including subtasks`,
    `${formatValue(numberInner.value ?? 0)} for this task`,
  ];
});

const onClick = () => {
  actions.visualization.selectRowByIdAndScroll(props.tasks[0].duid);
  showEditor.value = true;
  nextTick(() => inputRef.value?.focus());
};

watch(selectedTasks, (newSelectedTasks) => {
  if (newSelectedTasks.length === 0 && showEditor.value) {
    showEditor.value = false;
  }
});

const updateValue = (value: Value) => {
  numberInner.value = value;
  if (isFormOrDefaultPropertyMode.value) {
    emit("update", value);
    return;
  }

  save(value, true);

  emit("update");
  saveManager.run();
};

const dropdownSections = computed(() => [
  {
    title: props.property.title,
    items: [
      {
        title: "Edit value",
        kind: DropdownMenuItemKind.COMPONENT,
        noFocus: true,
        component: NumberEditorInput,
        componentArgs: {
          value: numberInner.value,
          property: props.property,
          onUpdate: (value: number | null) => {
            updateValue(value);
          },
        },
      },
    ],
  },
]);

const onInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  updateValue(target.value ? parseInt(target.value, 10) : null);
};

const focus = () => inputRef.value?.focus();

const increment = () => {
  updateValue((numberInner.value ?? 0) + 1);
  focus();
};

const decrement = () => {
  updateValue((numberInner.value ?? 0) - 1);
  focus();
};

const startIncrement = (e: MouseEvent | KeyboardEvent) => {
  e.preventDefault();

  if (!interval.value) {
    increment();
  }
  interval.value = setInterval(increment, 200);
};

const startDecrement = (e: MouseEvent | KeyboardEvent) => {
  e.preventDefault();

  if (!interval.value) {
    decrement();
  }
  interval.value = setInterval(decrement, 200);
};

const stopAction = () => {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }

  saveManager.finish();
};

const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === "." || e.key === "e") {
    e.preventDefault();
  }
};

const onBlur = () => {
  if (isFormOrDefaultPropertyMode.value) {
    return;
  }

  saveManager.finish();
};

onUnmounted(() => {
  saveManager.destroy();
});

defineExpose({
  focus,
});
</script>

<template>
  <DropdownMenu
    v-if="isContextMenuMode"
    :sections="dropdownSections"
    show-on-hover
    has-input
    :placement="Placement.RIGHT_TOP"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }">
    <DropdownMenuItemContent
      :icon="props.property.kind === PropertyKind.NUMBER ? NumberFieldIcon : SizeFieldIcon"
      :title="`Change ${property.title.toLowerCase()}`"
      is-submenu />
  </DropdownMenu>
  <Tooltip
    v-else
    :disabled="isFormOrDefaultPropertyMode"
    :text="`Change ${property.title.toLowerCase()}`"
    :block="isListMode || isFormOrDefaultPropertyMode"
    :height-block="isListMode || isFormOrDefaultPropertyMode"
    :class="isTaskDetailMode && 'w-full'">
    <Tooltip
      :text="tooltipText"
      :placement="Placement.TOP"
      :disabled="!tooltipText.length || props.property.kind === PropertyKind.DEFAULT_SIZE"
      class="w-full">
      <div
        class="group/number-input relative flex size-full max-w-full select-none items-center rounded"
        :class="{
          'h-[26px] gap-0.5 border py-0.5 text-sm border-oncolor hover:bg-opposite/10': isChipMode,
          'px-2 hover:bg-lt': isFormOrDefaultPropertyMode,
          'min-h-7 justify-start rounded px-2 hover:bg-lt': isTaskDetailMode,
          'px-1': !isFormOrDefaultPropertyMode,
          'justify-center pb-px': isListMode,
        }">
        <div v-if="isPercentage && hasValue" class="absolute inset-0 flex items-center px-2 py-1.5">
          <ProgressBar :value="totalSize ?? numberValue ?? 0" />
        </div>
        <span v-if="isDollars && hasValue" :class="isTaskDetailMode && 'pt-px'">$</span>

        <button
          v-if="!showNumberEditor"
          type="button"
          :class="[
            isTaskDetailMode && 'px-0 py-1',
            isListMode && 'size-full',
            (isListMode || isPercentage) && 'text-right',
            isDollars && 'items-start pl-1',
            isListMode && !isPercentage && 'pr-[22px]',
            isPercentage && isTaskDetailMode && 'w-full justify-end pr-8',
            isPercentage && !isTaskDetailMode && 'pr-[32px]',
          ]"
          class="relative cursor-pointer truncate p-0 text-sm focus-ring-none"
          @click="onClick">
          {{ displayedText }}
        </button>

        <div v-else class="size-full">
          <label :for="id" class="sr-only">{{ property.title }}</label>
          <input
            :id="id"
            ref="inputRef"
            v-auto-width="{ disabled: !isChipMode, minWidthPx: 9, maxWidthPx: 270 }"
            type="number"
            :value="numberInner"
            :placeholder="isListMode ? (isTaskDetailMode ? 'None' : undefined) : property.title"
            :class="[
              isChipMode || isFormOrDefaultPropertyMode ? 'h-5 border border-hvy focus:border-hvy' : 'size-full',
              isFormOrDefaultPropertyMode && 'w-full',
              isTaskDetailMode && 'size-full px-0 py-1',
              (isListMode || isPercentage) && 'text-right',
              isDollars && 'pl-1',
              isListMode && !isPercentage && 'pr-[22px]',
              isPercentage && 'pr-[32px]',
            ]"
            class="relative cursor-text truncate border-none bg-transparent p-0 text-sm focus-ring-none placeholder:text-vlt"
            @mousedown.stop
            @keydown.stop="onKeyDown"
            @blur="onBlur"
            @input="onInput" />
        </div>

        <div class="flex select-none items-center" :class="!isChipMode && 'absolute inset-y-0 right-1.5'">
          <span v-if="isPercentage && hasValue" class="pb-px" :class="!showNumberEditor && 'pr-3.5'">%</span>
          <div
            v-if="showNumberEditor"
            class="flex-col justify-center pl-0.5 opacity-0 group-focus-within/number-input:opacity-100 group-hover/number-input:opacity-100">
            <div
              class="cursor-pointer rounded bg-lt hover:bg-md"
              @mousedown="startIncrement($event)"
              @mouseup="stopAction"
              @mouseleave="stopAction"
              @keydown.enter="startIncrement($event)"
              @focusout="stopAction">
              <ChevronUpIcon
                :class="{
                  'size-3': !isChipMode,
                  'size-2.5': isChipMode,
                }" />
            </div>

            <div
              class="cursor-pointer rounded bg-lt hover:bg-md"
              @mousedown="startDecrement($event)"
              @mouseup="stopAction"
              @mouseleave="stopAction"
              @keydown.enter="startDecrement($event)"
              @focusout="stopAction">
              <ChevronDownIcon
                :class="{
                  'size-3': !isChipMode,
                  'size-2.5': isChipMode,
                }" />
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  </Tooltip>
</template>
