import moment from "moment";
import { nextTick } from "vue";
import type { RouteLocationNamedRaw } from "vue-router";

import { removeFiltersThatDoNotPassForTask } from "~/components/filters/utils";
import { LayoutKind } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { getTaskLink } from "~/utils/common";
import { normalizeDate } from "~/utils/date";

import type { Actions } from "..";

/** Command related actions */
export default (actions: Actions) => ({
  jumpToDescription: (task?: Task) => {
    const appStore = useAppStore();

    let targetTask = task;
    if (!targetTask) {
      const currents = actions.visualization.getCurrentRows(true);
      if (currents.length !== 1) {
        return;
      }
      targetTask = currents[0].data;
      if (!targetTask) {
        return;
      }
    }

    if (!appStore.taskOpenInDetail) {
      appStore.setTaskDetailOpen(true);
    }
    // eslint-disable-next-line no-restricted-syntax
    setTimeout(() => {
      appStore.taskDetail?.jumpToDescription();
    }, 100);
  },
  jumpToComments: (task?: Task) => {
    const appStore = useAppStore();

    let targetTask = task;
    if (!targetTask) {
      const currents = actions.visualization.getCurrentRows(true);
      if (currents.length !== 1) {
        return;
      }
      targetTask = currents[0].data;
      if (!targetTask) {
        return;
      }
    }

    if (!appStore.taskOpenInDetail) {
      appStore.setTaskDetailOpen(true);
    }
    // eslint-disable-next-line no-restricted-syntax
    setTimeout(() => {
      appStore.taskDetail?.jumpToComments();
    }, 100);
  },
  navigateToTask: async (taskDuid: string, maintainQuery = false) => {
    const appStore = useAppStore();
    const dataStore = useDataStore();

    const task = dataStore.getTaskByDuid(taskDuid);
    if (!task) {
      return;
    }

    const newRoute: RouteLocationNamedRaw = getTaskLink(task);
    if (maintainQuery) {
      newRoute.query = appStore.$router.currentRoute.value.query;
    }
    await appStore.$router.push(newRoute);

    if (removeFiltersThatDoNotPassForTask(dataStore, task)) {
      await nextTick();
    }

    // eslint-disable-next-line no-restricted-syntax
    setTimeout(() => {
      const visualization = appStore.getBaseVisualization();
      visualization.selectAndScrollTo(task.duid);
      appStore.setTaskDetailOpen(true);
    });
  },
  createTaskQuickly: () => {
    const appStore = useAppStore();

    // TODO handle more cases here, add it to visualization
    if (appStore.layoutKind === LayoutKind.BOARD) {
      appStore.board?.createTask();
    } else if (appStore.newTask) {
      appStore.newTask.focus();
    }
  },
  contextualCreate: () => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const tenantStore = useTenantStore();

    if (appStore.spaceOrFolder) {
      appStore.spaceOrFolder.createDoc();
      return;
    }

    if (appStore.viewsRoot) {
      appStore.viewsRoot.createView();
      return;
    }

    if (appStore.dashboardsRoot) {
      appStore.dashboardsRoot.createDashboard();
      return;
    }

    appStore.setTcmOpen(true);

    if (appStore.layoutKind !== LayoutKind.CALENDAR) {
      return;
    }

    nextTick(() => {
      if (!dataStore.taskDraft) {
        return;
      }
      const date = normalizeDate(moment());
      const updates = [
        { duid: dataStore.taskDraft.duid, startAt: tenantStore.startDateEnabled ? date : undefined, dueAt: date },
      ];
      dataStore.updateTasks(updates, { noUndo: true, noBackend: true });
      // eslint-disable-next-line no-restricted-syntax
      setTimeout(() => {
        dataStore.updateTasks(updates, { noUndo: true });
      }, 1000);
    });
  },
  startEditingTitle: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    const currents = actions.visualization.getCurrentRows(true);
    if (actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    } // TODO should actually select the title tho
    if (currents.length !== 1 || !currents[0].data) {
      return;
    }

    visualization.editTitle(currents[0].data.duid);
  },
  forceDeleteTask: (task: Task) => {
    const dataStore = useDataStore();

    dataStore.deleteTasks([task]);
  },
  deleteTask: () => {
    const appStore = useAppStore();
    const route = appStore.$router.currentRoute.value;

    if (route.name !== "trash") {
      actions.visualization.trashTasks();
      return;
    }
    actions.visualization.deleteTasks();
  },
  openKeyboardIfIos: () => {
    const pageStore = usePageStore();
    if (!pageStore.isIos) {
      return;
    }

    const tempInput = document.createElement("input");
    tempInput.style.position = "absolute";
    tempInput.style.opacity = "0";
    tempInput.style.fontSize = "16px"; // iOS wont zoom in with font size >= 16px
    tempInput.readOnly = true;

    document.body.prepend(tempInput);
    tempInput.focus();

    tempInput.addEventListener("blur", () => {
      tempInput.remove();
    });
  },
});
