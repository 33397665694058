<script setup lang="ts">
import moment, { type Moment } from "moment";
import { computed } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import Avatar from "~/components/dumb/Avatar.vue";
import Button from "~/components/dumb/Button.vue";
import Countdown from "~/components/dumb/Countdown.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import NavigationButtons from "~/components/dumb/NavigationButtons.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import TutorialMessage from "~/components/dumb/TutorialMessage.vue";
import {
  AccountSettingsIcon,
  ChevronDownIcon,
  LogOutIcon,
  SettingsIcon,
  TaskCreateIcon,
  TrashIcon,
  UsersInviteIcon,
} from "~/icons";
import { makeLinkToSettingsRef } from "~/router/common";
import {
  ButtonStyle,
  CommandId,
  DropdownMenuItemKind,
  IconSize,
  PageKind,
  Placement,
  TutorialName,
  UserRole,
} from "~/shared/enums";
import type { DropdownMenuSection } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";

const NEXT_LAUNCH_DATE = moment("2023-04-10");

defineProps<{
  isMobileLeftbar?: boolean;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const openSettings = (page: string) => router.push(makeLinkToSettingsRef(page).value);

const pageKind = computed(() => appStore.currentPage?.pageKind);
const actionNewText = computed(() =>
  pageKind.value === PageKind.SPACE
    ? "New report"
    : pageKind.value === PageKind.FOLDER
      ? "New doc"
      : pageKind.value === PageKind.DASHBOARDS_ROOT
        ? "New dashboard"
        : dataStore.taskDraft
          ? "Resume draft"
          : "New task"
);
const actionNewCommand = computed(() =>
  pageKind.value === PageKind.SPACE
    ? CommandId.CREATE_REPORT
    : pageKind.value === PageKind.FOLDER
      ? CommandId.CREATE_DOC
      : dataStore.taskDraft
        ? CommandId.RESUME_DRAFT
        : CommandId.CREATE_TASK
);

const contextualCreate = () => {
  if (appStore.mobileLeftbarOpen) {
    appStore.setMobileLeftbarOpen(false);
  }
  actions.visualization.contextualCreate();
};

const profileOptions = computed((): DropdownMenuSection[] => [
  {
    title: "Basics",
    items: [
      {
        title: "Account",
        kind: DropdownMenuItemKind.BUTTON,
        icon: AccountSettingsIcon,
        onClick: () => openSettings("account"),
      },
      {
        title: "Invite teammates",
        kind: DropdownMenuItemKind.BUTTON,
        icon: UsersInviteIcon,
        dataTestid: "invite-teammates",
        hidden: !userStore.isRoleGreaterOrEqual(UserRole.MEMBER),
        onClick: () => openSettings("teammates"),
      },
      {
        title: "Settings",
        kind: DropdownMenuItemKind.BUTTON,
        icon: SettingsIcon,
        hidden: !userStore.isRoleGreaterOrEqual(UserRole.ADMIN),
        onClick: () => openSettings("workspace"),
      },
      {
        title: "Trash",
        commandId: CommandId.GO_TO_TRASH,
        kind: DropdownMenuItemKind.INTERNAL_LINK,
        icon: TrashIcon,
        navigate: { to: { name: "trash" } },
      },
    ],
  },
  {
    title: "Log out",
    items: [
      {
        title: "Log out",
        commandId: CommandId.LOG_OUT,
        kind: DropdownMenuItemKind.INTERNAL_LINK,
        icon: LogOutIcon,
        navigate: { to: { name: "logout" } },
      },
    ],
  },
]);

const formDropdownOptions = computed((): DropdownMenuSection[] => [
  {
    title: "Forms",
    showTitle: true,
    items: dataStore.formList
      .filter((f) => !f.hidden)
      .map((form) => ({
        title: form.title,
        kind: DropdownMenuItemKind.BUTTON,
        icon: PageIcon,
        iconArgs: { page: form },
        onClick: () => appStore.setFormOpenInInternalModal(form),
      })),
  },
]);

const showForms = computed(
  () =>
    pageKind.value !== PageKind.SPACE && pageKind.value !== PageKind.FOLDER && dataStore.formList.some((e) => !e.hidden)
);

let lastClick: Moment;
const dblclickMaybe = () => {
  const now = moment();
  if (lastClick && now.diff(lastClick) < 300) {
    pageStore.adminHidden = !pageStore.adminHidden;
  }
  lastClick = now;
};
</script>

<template>
  <header
    aria-label="Left navigation"
    class="mb-3"
    :class="pageStore.isDesktopApp && pageStore.isMac && 'mt-3 app-drag'">
    <!-- Nav buttons -->
    <NavigationButtons v-if="pageStore.isDesktopApp && pageStore.isMac" class="mr-3 justify-end" />

    <div class="w-full px-2.5 pt-2.5 app-drag-none">
      <DropdownMenu
        class="relative"
        :sections="profileOptions"
        :distance="0"
        block
        is-contrast
        @after-open="dblclickMaybe"
        @after-close="dblclickMaybe">
        <Tooltip text="Controls" block>
          <div class="flex w-full items-center justify-between gap-2 rounded p-1 hover:bg-md">
            <div class="flex items-center overflow-hidden">
              <Avatar :abrev="tenantStore.name[0] ?? 'D'" :image-url="tenantStore.imageUrl" class="mr-2 icon-lg" />
              <span :title="tenantStore.name" class="inline-block select-none truncate text-sm font-semibold text-lt">
                {{ tenantStore.name || `${userStore.firstName}'s Dart` }}
              </span>
            </div>

            <!-- Profile Menu -->
            <Avatar
              :abrev="userStore.abrev"
              circle
              :color-hex="userStore.colorHex"
              :image-url="userStore.imageUrl"
              img-border
              class="icon-lg" />
          </div>
        </Tooltip>
      </DropdownMenu>
    </div>

    <div v-if="pageStore.showAlpha" class="px-2">
      <Countdown :target-date="NEXT_LAUNCH_DATE" milestone="launch day" />
    </div>

    <!-- Task Actions -->
    <div v-if="!pageStore.isMobile" class="mx-3.5 mt-5 flex app-drag-none">
      <!-- Add task -->
      <TutorialMessage
        :name="TutorialName.CREATE_TASK_WITH_SUBTASK_RECS"
        :step="1"
        :alternate="appStore.mobileLeftbarOpen ? 2 : undefined"
        :disabled="
          pageStore.isMobile ||
          pageKind === PageKind.SPACE ||
          pageKind === PageKind.FOLDER ||
          !!dataStore.taskDraft ||
          (appStore.leftbarInCollapsibleMode && !(isMobileLeftbar && appStore.mobileLeftbarOpen))
        "
        complete-on-click
        :placement="Placement.RIGHT_TOP"
        :skidding="-10"
        block
        :shadow-styles="showForms ? 'rounded-r-none' : undefined">
        <Tooltip :command-id="actionNewCommand" block>
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :text="actionNewText"
            :icon="TaskCreateIcon"
            block
            text-style="text-xs text-lt"
            :icon-size="IconSize.S"
            is-contrast
            class="!justify-start py-1.5 pl-2"
            :class="showForms ? 'rounded-r-none' : ''"
           
            @mousedown.prevent
            @click="contextualCreate" />
        </Tooltip>
      </TutorialMessage>
      <DropdownMenu
        v-if="showForms"
        :sections="formDropdownOptions"
        :placement="Placement.BOTTOM_LEFT"
        :distance="2"
        is-contrast>
        <Tooltip text="Create a task from a form" height-block>
          <Button
            class="rounded-l-none border-l-0 px-0.5"
            :btn-style="ButtonStyle.SECONDARY"
            :icon="ChevronDownIcon"
            :icon-size="IconSize.S"
            height-block
            is-contrast
            a11y-label="Forms" />
        </Tooltip>
      </DropdownMenu>
    </div>
  </header>
</template>
