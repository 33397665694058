import type { Option, ValidationFunctionResult } from "~/shared/types";

export const DUID_REGEX = /^[a-zA-Z0-9]{12}$/;
export const CONTAINS_EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
export const EMAIL_REGEX = new RegExp(`^${CONTAINS_EMAIL_REGEX.source}$`);
export const CONTAINS_URL_REGEX =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#={}|^`<>"\\[\]]{1,256}\.[a-zA-Z0-9]{2,6}\b(?<suffix>[-a-zA-Z0-9@:%_+.~#?&/=]*)/;
export const URL_REGEX = new RegExp(`^${CONTAINS_URL_REGEX.source}$`);
export const CONTAINS_LOCAL_URL_REGEX =
  /https?:\/\/(?:localhost|0|10|127|192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}|\[::1?\])(?::\d{4,6})?\b(?<suffixLocal>[-a-zA-Z0-9@:%_+.~#?&/=]*)/;
export const LOCAL_URL_REGEX = new RegExp(`^${CONTAINS_LOCAL_URL_REGEX.source}$`);
export const CONTAINS_URL_INCLUDING_LOCAL_REGEX = new RegExp(
  `${CONTAINS_URL_REGEX.source}|${CONTAINS_LOCAL_URL_REGEX.source}`
);
export const URL_INCLUDING_LOCAL_REGEX = new RegExp(`^${CONTAINS_URL_INCLUDING_LOCAL_REGEX.source}$`);
export const COLOR_HEX_REGEX = /^#[a-fA-F0-9]{6}$/;

export const CONTAINS_DART_URL_REGEX = /https:\/\/(?:app|stag)\.itsdart\.com\b(?<suffix>[-a-zA-Z0-9@:%_+.~#?&/=]*)/;
export const DART_URL_REGEX = new RegExp(`^${CONTAINS_DART_URL_REGEX.source}$`);
export const DART_URL_INCLUDING_LOCAL_REGEX = new RegExp(
  `^${CONTAINS_DART_URL_REGEX.source}|${CONTAINS_LOCAL_URL_REGEX.source}$`
);

const AGENT_EMAIL_REGEX = /^[a-z.]*\.agent\+[a-zA-Z0-9]{12}@itsdart\.com$/;

export const validateAlwaysTrue = (): ValidationFunctionResult => ({ isValid: true });

export const validateIsNotEmpty = (value: string): ValidationFunctionResult => {
  if (value.trim().length === 0) {
    return { isValid: false, error: "Enter a value" };
  }

  return { isValid: true };
};

export const isValidDuid = (duid: string) => DUID_REGEX.test(duid);

export const isValidEmail = (email: string) => email.length <= 254 && EMAIL_REGEX.test(email);

export const validateEmail = (email: string): ValidationFunctionResult => {
  if (email.length === 0) {
    return { isValid: false, error: "Enter an email" };
  }

  if (!isValidEmail(email)) {
    return { isValid: false, error: "Enter a valid email" };
  }

  return { isValid: true };
};

export const isAgentEmail = (email: string) => AGENT_EMAIL_REGEX.test(email);

export const validatePassword = (password: string): ValidationFunctionResult => {
  if (password.length === 0) {
    return { isValid: false, error: "Enter a password" };
  }

  if (password.length < 12) {
    return { isValid: false, error: "Enter a longer password" };
  }

  if (password.length > 100) {
    return { isValid: false, error: "Enter a shorter password" };
  }

  if (!/[^a-zA-Z]/.test(password)) {
    return { isValid: false, error: "Enter a password with at least one number or symbol" };
  }

  return { isValid: true };
};

export const validatePasswordBasic = (password: string): ValidationFunctionResult => {
  if (password.length === 0) {
    return { isValid: false, error: "Enter your password" };
  }
  return { isValid: true };
};

export const validateName = (name: string): ValidationFunctionResult => {
  if (name.length === 0) {
    return { isValid: false, error: "Enter your name" };
  }

  return { isValid: true };
};

export const makeValidateTenantName =
  (isDart: boolean) =>
  (name: string): ValidationFunctionResult => {
    if (name.length === 0) {
      return { isValid: false, error: "Enter a team name" };
    }

    if (isDart && name !== "Dart") {
      return { isValid: false, error: "The Dart tenant must be named Dart" };
    }

    return { isValid: true };
  };

export const makeValidateOptionTitle =
  (getOptionByTitle: (title: string) => Option | undefined) =>
  (optionTitle: string): ValidationFunctionResult => {
    const trimmedTitle = optionTitle.trim();
    if (trimmedTitle.length === 0) {
      return { isValid: false, error: "Enter an option name" };
    }

    if (getOptionByTitle(trimmedTitle) !== undefined) {
      return { isValid: false, error: "Enter a unique option name" };
    }

    return { isValid: true };
  };

export const isValidDartUrl = (url: string, isLocal: boolean) =>
  (isLocal ? DART_URL_INCLUDING_LOCAL_REGEX : DART_URL_REGEX).test(url);

export const isValidUrl = (url: string) => URL_REGEX.test(url);

export const validateUrl = (url: string): ValidationFunctionResult => {
  if (url.length === 0) {
    return { isValid: false, error: "Enter a URL" };
  }

  if (!isValidUrl(url)) {
    return { isValid: false, error: "Enter a valid URL" };
  }

  return { isValid: true };
};

export const isValidUrlIncludingLocal = (url: string) => URL_INCLUDING_LOCAL_REGEX.test(url);

export const isValidLocalUrl = (url: string) => LOCAL_URL_REGEX.test(url);

export const isValidXml = (xml: string) => {
  const parser = new DOMParser();
  let res;
  try {
    res = parser.parseFromString(xml, "application/xml");
  } catch {
    return false;
  }
  return res.getElementsByTagName("parsererror").length === 0;
};

export const validateXml = (xml: string): ValidationFunctionResult => {
  if (xml.length === 0) {
    return { isValid: false, error: "Enter some XML" };
  }

  if (!isValidXml(xml)) {
    return { isValid: false, error: "Enter valid XML" };
  }

  return { isValid: true };
};

export const validateColorHex = (hex: string): ValidationFunctionResult => {
  if (!COLOR_HEX_REGEX.test(hex)) {
    return { isValid: false, error: "Invalid color hex" };
  }
  return { isValid: true };
};
