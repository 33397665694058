<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";

import { ArrowLeftIcon } from "~/icons";
import { usePageStore } from "~/stores";
import { handleOneStepTowardHomeState } from "~/utils/globalEventManager";

const pageStore = usePageStore();

const MIN_SWIPE_THRESHOLD = 10;
const MAX_SWIPE_RANGE = 100;
const BACKGROUND_MAX_OPACITY = 0.25;

const swipeDistance = ref(0);
const touchStartX = ref(0);
const isSwiping = ref(false);

const arrowOpacity = computed(() => {
  if (swipeDistance.value < MIN_SWIPE_THRESHOLD) {
    return 0;
  }

  const normalizedDistance = Math.min(1, (swipeDistance.value - MIN_SWIPE_THRESHOLD) / MAX_SWIPE_RANGE);
  return normalizedDistance;
});

const backgroundOpacity = computed(() => {
  if (swipeDistance.value < MIN_SWIPE_THRESHOLD) {
    return 0;
  }

  const normalizedDistance = Math.min(1, (swipeDistance.value - MIN_SWIPE_THRESHOLD) / MAX_SWIPE_RANGE);
  return normalizedDistance * BACKGROUND_MAX_OPACITY;
});

const onTouchStart = (e: TouchEvent) => {
  if (e.touches[0].clientX < 50) {
    touchStartX.value = e.touches[0].clientX;
    isSwiping.value = true;
  }
};

const onTouchMove = (e: TouchEvent) => {
  if (!isSwiping.value) {
    return;
  }

  const currentX = e.touches[0].clientX;
  const diff = currentX - touchStartX.value;

  if (diff > 0) {
    swipeDistance.value = diff;
    e.preventDefault();
  }
};

const onTouchEnd = () => {
  if (isSwiping.value) {
    if (swipeDistance.value > MIN_SWIPE_THRESHOLD + MAX_SWIPE_RANGE) {
      handleOneStepTowardHomeState(pageStore);
    }

    swipeDistance.value = 0;
    isSwiping.value = false;
  }
};

onMounted(() => {
  document.addEventListener("touchstart", onTouchStart, { passive: false });
  document.addEventListener("touchmove", onTouchMove, { passive: false });
  document.addEventListener("touchend", onTouchEnd, { passive: true });
  window.addEventListener("popstate", (e) => e.preventDefault());

  document.body.style.overscrollBehaviorX = "none";
});

onUnmounted(() => {
  document.removeEventListener("touchstart", onTouchStart);
  document.removeEventListener("touchmove", onTouchMove);
  document.removeEventListener("touchend", onTouchEnd);
  window.addEventListener("popstate", (e) => e.preventDefault());

  document.body.style.overscrollBehaviorX = "";
});
</script>

<template>
  <div class="pointer-events-none flex bg-std">
    <div class="fixed inset-0 bg-hvy" :style="{ opacity: backgroundOpacity }" />

    <div class="fixed left-4 top-1/2 -translate-y-1/2 rounded-full p-1 bg-std" :style="{ opacity: arrowOpacity }">
      <ArrowLeftIcon class="size-8 shrink-0 text-oncolor" />
    </div>
  </div>
</template>

<style>
html,
body {
  overscroll-behavior-x: none;
}
</style>
