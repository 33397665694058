import type { EventBusKey } from "@vueuse/core";
import { createCommand, type NodeKey, type TextFormatType } from "lexical";
import type { Component } from "vue";

import {
  RecommendActionItemsIcon,
  RecommendBrainstormIcon,
  RecommendConvertToTasksIcon,
  RecommendImproveIcon,
  RecommendPropertiesIcon,
  RecommendSimplifyIcon,
  RecommendSplitIcon,
  RecommendSubtasksIcon,
  RecommendTranslateIcon,
  RecommendWriteIcon,
} from "~/icons";
import { RecommendationKind, ReportKind, TypeaheadSpecialEntity } from "~/shared/enums";
import type { User } from "~/shared/types";
import { CONTAINS_EMAIL_REGEX, CONTAINS_URL_INCLUDING_LOCAL_REGEX } from "~/utils/validation";

export const TEXT_FORMATS: TextFormatType[] = ["bold", "italic", "underline", "strikethrough", "highlight", "code"];

const URL_SCHEMA_START_REGEX = /^https?:\/\//;

export const URL_MATCHERS = [
  (text: string) => {
    const match = CONTAINS_URL_INCLUDING_LOCAL_REGEX.exec(text);
    if (!match) {
      return null;
    }

    const content = match[0];
    return {
      index: match.index,
      length: content.length,
      text: content,
      url: URL_SCHEMA_START_REGEX.test(content) ? content : `https://${content}`,
    };
  },
  (text: string) => {
    const match = CONTAINS_EMAIL_REGEX.exec(text);
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: `mailto:${match[0]}`,
      }
    );
  },
];

export const TEXT_REPLACEMENT_RULES = [
  ["...", "…"],
  ["--", "—"],
  ["->", "→"],
  ["<-", "←"],
  ["←>", "↔"],
  ["<->", "↔"],
  ["<>", "↔"],
  ["<=", "≤"],
  [">=", "≥"],
  ["+/-", "±"],
  ["!=", "≠"],
  ["~=", "≈"],
  ["(p)", "§"],
  ["(c)", "©"],
  ["(r)", "®"],
  ["(sm)", "℠"],
  ["(tm)", "™"],
  [":)", "🙂"],
  [";)", "😉"],
  [":(", "🙁"],
  [":D", "😃"],
  [";p", "😜"],
  [":p", "😛"],
  [":o", "😮"],
  ["<3", "❤️"],
];

export const RECOMMENDATION_KIND_TO_ICON_MAP = new Map<RecommendationKind, Component>([
  [RecommendationKind.ACTION_ITEMS, RecommendActionItemsIcon],
  [RecommendationKind.CONVERT_TO_TASKS, RecommendConvertToTasksIcon],
  [RecommendationKind.BRAINSTORM, RecommendBrainstormIcon],
  [RecommendationKind.PROPERTIES, RecommendPropertiesIcon],
  [RecommendationKind.IMPROVE, RecommendImproveIcon],
  [RecommendationKind.SIMPLIFY, RecommendSimplifyIcon],
  [RecommendationKind.SPLIT, RecommendSplitIcon],
  [RecommendationKind.SUBTASKS, RecommendSubtasksIcon],
  [RecommendationKind.TRANSLATE, RecommendTranslateIcon],
  [RecommendationKind.WRITE, RecommendWriteIcon],
]);

export const RECOMMENDATION_KINDS_THAT_REPLACE = new Set([
  RecommendationKind.IMPROVE,
  RecommendationKind.SIMPLIFY,
  RecommendationKind.TRANSLATE,
]);

export const EVENT_BLUR_OVERALL = createCommand("EVENT_BLUR_OVERALL");
export const EVENT_SUBSCRIBE_USER = createCommand<User>("EVENT_SUBSCRIBE_USER");
export const EVENT_SPLIT_TASK = createCommand("EVENT_SPLIT_TASK");
export const EVENT_CONVERT_TO_TASKS = createCommand("EVENT_CONVERT_TO_TASKS");
export const EVENT_GENERATE_RECOMMENDATION = createCommand("EVENT_GENERATE_RECOMMENDATION");
export const EVENT_CLEANUP_RECOMMENDATION_NODE = createCommand("EVENT_CLEANUP_RECOMMENDATION_NODE");
export const EVENT_CREATE_TASK_TASK_RELATES_TO_RELATIONSHIP = createCommand<string>(
  "EVENT_CREATE_TASK_TASK_RELATES_TO_RELATIONSHIP"
);
export const EVENT_CREATE_TASK_DOC_RELATIONSHIP = createCommand<{
  specialEntity: TypeaheadSpecialEntity;
  entityDuid: string;
}>("EVENT_CREATE_TASK_DOC_RELATIONSHIP");
export const EVENT_CREATE_DOC_DOC_RELATIONSHIP = createCommand<string>("EVENT_CREATE_DOC_DOC_RELATIONSHIP");

export const EVENT_BUS_GENERATE_REPORT: EventBusKey<{
  docDuid: string;
  reportKind: ReportKind;
  startDate: string;
  endDate: string;
}> = Symbol("EVENT_BUS_GENERATE_REPORT");

export const EVENT_INSERT_TOGGLE_COMMAND = createCommand("EVENT_INSERT_TOGGLE_COMMAND");
export const EVENT_TOGGLE_TOGGLE_COMMAND = createCommand<NodeKey>("EVENT_TOGGLE_TOGGLE_COMMAND");
export const EVENT_CHANGE_TOGGLE_EMPTY = createCommand("EVENT_CHANGE_TOGGLE_EMPTY");

export const EVENT_INSERT_HORIZONTAL_RULE = createCommand("EVENT_INSERT_HORIZONTAL_RULE");

export const LEXICAL_OUTSIDE_CLASS = "dart-lexical-outside";

export const TASK_DETAIL_TELEPORT_KEY = "rightbar-toolbar";
export const TCM_TELEPORT_KEY = "tcm-toolbar";
export const DOC_TELEPORT_KEY = "doc-toolbar";
export const FORM_TELEPORT_KEY = "form-toolbar";
export const DEFAULT_EDITOR_TELEPORT_KEY = "default-editor-toolbar";
export const TASK_DETAIL_TITLE_WRAPPER_ID = "task-detail-title-wrapper";
