<script setup lang="ts">
import { computed, ref } from "vue";

import { FIELDS_ALWAYS_AVAILABLE_SET, NO_VALUE_APPLICABILITIES } from "~/common/filter";
import { getProperty } from "~/common/properties";
import { filterHasApplicability } from "~/shared/common";
import { FilterType } from "~/shared/enums";
import type { Filter, FilterDefinition, FilterValue } from "~/shared/types";
import { hasClassInPath } from "~/utils/common";

import AiFilter from "./AiFilter.vue";
import DateFilter from "./DateFilter";
import InputFilter from "./InputFilter.vue";
import NumberFilter from "./NumberFilter.vue";
import ValueFilter from "./ValueFilter";

const INNER_DROPDOWN_DIV_CLASSES = new Set(["dart-menu-connector"]);

const FILTER_TYPE_TO_COMPONENT = {
  [FilterType.AI]: AiFilter,
  [FilterType.DATE]: DateFilter,
  [FilterType.INPUT]: InputFilter,
  [FilterType.NUMBER]: NumberFilter,
  [FilterType.VALUE]: ValueFilter,
};

const props = defineProps<{
  filter: Filter;
  definition: FilterDefinition | undefined;
  readOnly?: boolean;
}>();

const emit = defineEmits<{
  select: [values: FilterValue[]];
  afterClose: [];
  replaceFilter: [];
  removeFilter: [];
}>();

const filterRef = ref<
  InstanceType<typeof ValueFilter> | InstanceType<typeof DateFilter> | InstanceType<typeof InputFilter> | null
>(null);

const property = computed(() => getProperty(props.filter.propertyDuid));
const alwaysAvailable = computed(() => property.value && FIELDS_ALWAYS_AVAILABLE_SET.has(property.value.kind));
const isAi = computed(() => props.definition?.config.type === FilterType.AI);
const showValues = computed(
  () => !filterHasApplicability(props.filter) || !NO_VALUE_APPLICABILITIES.has(props.filter.applicability)
);

const selectedValues = computed(() => props.filter.values || []);

const editing = ref(false);

const enabled = ref(true);
const onClick = (event: Event) => {
  if (hasClassInPath(event, INNER_DROPDOWN_DIV_CLASSES)) {
    enabled.value = false;
    return;
  }

  enabled.value = true;
};

const selectField = () => {
  if (selectedValues.value.length === 0 && !alwaysAvailable.value) {
    emit("replaceFilter");
    return;
  }
  if (!filterRef.value) {
    return;
  }
  filterRef.value.open();
  editing.value = true;
};

const openDropdown = () => {
  if (!filterRef.value || props.readOnly) {
    return;
  }
  filterRef.value.open();
  editing.value = true;
};

const onAfterClose = () => {
  editing.value = false;
  emit("afterClose");
};

const working = computed(() => !!filterRef.value && "working" in filterRef.value && filterRef.value.working);

defineExpose({
  editing,
  openDropdown,
  working,
});
</script>

<template>
  <div
    v-if="!!definition"
    class="flex h-full flex-nowrap items-center justify-center gap-1"
    :class="[!isAi && 'pl-0.5', filter.locked ? 'cursor-default' : 'cursor-pointer']"
    @click="onClick"
    @keydown.enter="onClick">
    <!-- Wrap everything in value dropdown -->
    <slot v-if="!isAi" name="content" :select-field="selectField" :definition="definition" />

    <component
      :is="FILTER_TYPE_TO_COMPONENT[definition.config.type]"
      v-if="showValues"
      ref="filterRef"
      :filter="filter"
      :definition="definition"
      :enabled="enabled"
      :read-only="readOnly"
      @select="(v: FilterValue[]) => emit('select', v)"
      @after-close="onAfterClose"
      @replace-filter="emit('replaceFilter')"
      @remove-filter="emit('removeFilter')">
      <template #connector>
        <slot name="connector" :definition="definition" />
      </template>
    </component>
  </div>
</template>
