import { useElementSize, useScroll } from "@vueuse/core";
import moment from "moment";
import { computed, type ComputedRef, type Ref, ref, watch } from "vue";

import type { IntervalUnit } from "~/shared/types";

import { BASE_RANGE_DEFINITION, RANGE_DEFINITION_STOPS } from "./constants";
import type { Interval, RangeDefinition, RoadmapConfig } from "./shared";

const NOW_REFRESH_MS = 1000 * 60;

export const getDefinition = (pxPerDay: number): RangeDefinition => {
  let res = { ...BASE_RANGE_DEFINITION };
  RANGE_DEFINITION_STOPS.forEach(([stop, definition]) => {
    if (pxPerDay <= stop) {
      res = { ...res, ...definition };
    }
  });
  return res;
};

export const getRangeOrigin = (intervalUnit: IntervalUnit): string =>
  moment().startOf(intervalUnit).startOf("day").toISOString();

export const getDiffPx = (roadmapConfig: RoadmapConfig, start: string, end: string, precise = false): number =>
  moment(end).diff(moment(start), "day", precise) * roadmapConfig.pxPerDay;

export const getIntervalPx = (roadmapConfig: RoadmapConfig, date: string, interval: Interval): number =>
  getDiffPx(roadmapConfig, date, moment(date).add(interval[0], interval[1]).toISOString());

export const getPixelsTo = (roadmapConfig: RoadmapConfig, date: string, precise = false): number =>
  getDiffPx(roadmapConfig, roadmapConfig.startDate, date, precise);

/* Get amount pixels from start of range to today */
export const getPixelsToNow = (roadmapConfig: RoadmapConfig): number =>
  getPixelsTo(roadmapConfig, moment().toISOString(), true);

/* Create array with start N units + end N units, around current date. With interval and interval units */
export const getDatesForInterval = (roadmapConfig: RoadmapConfig, interval: Interval): string[] => {
  const startDate = moment(roadmapConfig.startDate);
  const endDate = moment(roadmapConfig.endDate);
  const [intervalAmount, intervalUnit] = interval;

  const res = [getRangeOrigin(intervalUnit)];

  /* Units before start unit */
  for (let i = 0; i < 10000; i += 1) {
    const next = moment(res[0]).subtract(intervalAmount, intervalUnit);
    if (next.isSameOrBefore(startDate, "day")) {
      break;
    }
    res.unshift(next.toISOString());
  }

  /* Units after start unit */
  for (let i = 0; i < 10000; i += 1) {
    const next = moment(res[res.length - 1]).add(intervalAmount, intervalUnit);
    if (next.isAfter(endDate, "day")) {
      break;
    }
    res.push(next.toISOString());
  }

  return res;
};

export const makeTimeForPixelsToNowRef = (
  roadmapConfig: Ref<RoadmapConfig>
): Ref<{ pixels: number; destroy: () => void }> => {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  const res = ref({
    pixels: 0,
    destroy: () => {
      clearTimeout(timeout);
    },
  });

  const resetValuesAndTimeout = () => {
    clearTimeout(timeout);

    res.value.pixels = getPixelsToNow(roadmapConfig.value);
    // eslint-disable-next-line no-restricted-syntax
    timeout = setTimeout(resetValuesAndTimeout, NOW_REFRESH_MS);
  };

  resetValuesAndTimeout();

  watch(() => roadmapConfig.value, resetValuesAndTimeout, { deep: true });

  return res;
};

export const useVirtualized = <T extends { width?: number; height?: number }>(
  scrollContainer: Ref<HTMLElement | null>,
  items: ComputedRef<T[]>,
  direction: "horizontal" | "vertical" = "horizontal"
) => {
  const BUFFER_PX = 1000;
  const { x: left, y: top } = useScroll(scrollContainer);
  const { width, height } = useElementSize(scrollContainer);

  const virtualSize = computed<number>(() =>
    items.value.reduce((sum, item) => sum + ((direction === "horizontal" ? item.width : item.height) ?? 0), 0)
  );

  const virtualList = computed<(T & { start: number })[]>(() => {
    const { value: itemsValue } = items;
    const startOffset = (direction === "horizontal" ? left.value : top.value) - BUFFER_PX;
    const endOffset = (direction === "horizontal" ? left.value + width.value : top.value + height.value) + BUFFER_PX;

    let startIndex = 0;
    let endIndex = itemsValue.length - 1;
    let cumulativeSize = 0;

    for (let i = 0; i < itemsValue.length; i += 1) {
      if (cumulativeSize < startOffset) {
        startIndex = i;
      }
      if (cumulativeSize <= endOffset) {
        endIndex = i;
      } else {
        break;
      }
      cumulativeSize += (direction === "horizontal" ? itemsValue[i].width : itemsValue[i].height) ?? 0;
    }

    cumulativeSize = itemsValue
      .slice(0, startIndex)
      .reduce((sum, item) => sum + ((direction === "horizontal" ? item.width : item.height) ?? 0), 0);
    return itemsValue.slice(startIndex, endIndex + 1).map((item) => {
      const newItem = { ...item, start: cumulativeSize };
      cumulativeSize += (direction === "horizontal" ? item.width : item.height) ?? 0;
      return newItem;
    });
  });

  return { virtualList, virtualSize };
};
