import { useDark, useNetwork } from "@vueuse/core";
import type { GridApi, IRowNode } from "ag-grid-community";
import equal from "deep-equal";
import type { SerializedEditorState } from "lexical";
import moment from "moment";
import { defineStore } from "pinia";
import { markRaw, nextTick, watch } from "vue";

import type { WebsocketManager } from "~/actions/websockets";
import { UNCOLORED_PSEUDO_COLOR_BY } from "~/common/colorBy";
import { CURRENT_USER_PSEUDO_USER_KEY } from "~/common/filter";
import {
  getDefaultBoardGroupBy,
  getDefaultListGroupBy,
  getGroupByDefinitionList,
  NO_VALUE_GROUP_BY_KEY,
  UNGROUPED_PSEUDO_GROUP_BY,
} from "~/common/groupBy";
import {
  getPropertyValueFromTaskByDuid,
  getPropertyValueStr,
  getShownPropertyWithConfigList,
} from "~/common/properties";
import type FilterArea from "~/components/filters/FilterArea.vue";
import { doesTaskPass } from "~/components/filters/utils";
import type TaskDetail from "~/components/task/TaskDetail.vue";
import type Tcm from "~/components/task/Tcm.vue";
import type ProjectAiDropdown from "~/components/topbar/ProjectAiDropdown.vue";
import type Topbar from "~/components/topbar/Topbar.vue";
import type Board from "~/components/visualization/board/Board.vue";
import type Calendar from "~/components/visualization/calendar/Calendar.vue";
import {
  COMPLETED_STATUS_KINDS,
  DEFAULT_ASSIGNEES_FILTER_PARTIAL,
  DEFAULT_DARTBOARD_FILTER_PARTIAL,
  DEFAULT_STATUS_FILTER_PARTIAL,
  DEFAULT_TRASH_DARTBOARD_FILTER_PARTIAL,
} from "~/components/visualization/constants";
import columns from "~/components/visualization/list/columns";
import type List from "~/components/visualization/list/List.vue";
import type Roadmap from "~/components/visualization/roadmap/Roadmap.vue";
import type SubtasksList from "~/components/visualization/subtasks/SubtasksList.vue";
import {
  getBoardOrCalendarVisualization,
  getDefaultVisualization,
  getInboxVisualization,
  getListVisualization,
  type Visualization,
} from "~/components/visualization/visualization";
import { COLOR_BY_LAYOUT_KINDS, GROUP_BY_LAYOUT_KINDS } from "~/constants/layout";
import {
  DASHBOARDS_PSEUDO_PAGE,
  HOME_PSEUDO_PAGE,
  INBOX_PSEUDO_PAGE,
  NOT_FOUND_PSEUDO_PAGE,
  VIEWS_PSEUDO_PAGE,
} from "~/constants/page";
import {
  AI_PROPERTY_PSUEDO_DUID,
  ASSIGNEES_COLUMN_MOBILE_WIDTH,
  CONFIG_PROPERTY_PSUEDO_DUID,
  DEFAULT_COLUMN_WIDTH_LIMITS,
  PROPERTY_KIND_TO_DEFAULT_WIDTH_MAP,
  STATUS_COLUMN_MOBILE_WIDTH,
  TITLE_COLUMN_MAX_MIN_WIDTH,
  TITLE_COLUMN_MIN_WIDTH,
} from "~/constants/property";
import {
  DartboardKind,
  EmojiSkinTone,
  EventKind,
  LayoutKind,
  ModalKind,
  PageKind,
  Placement,
  PropertyKind,
  ReportKind,
  StatusKind,
  SubtaskDisplayMode,
  SummaryStatisticKind,
  TaskDetailMode,
  TutorialName,
  ViewKind,
  WebhookEventKind,
} from "~/shared/enums";
import type {
  AttachmentCreate,
  Brainstorm,
  CalendarDisplayPeriod,
  Comparator,
  ContextMenu,
  Dartboard,
  DartboardUpdate,
  Doc,
  DropdownMenuSection,
  EventKindFull,
  ExtendedColDef,
  FeedbackTooltip,
  Filter,
  Form,
  GroupByDefinition,
  Layout,
  LayoutConfig,
  LayoutConfigBoard,
  LayoutConfigCalendar,
  LayoutConfigList,
  LayoutConfigRoadmap,
  Page,
  PageIconOption,
  PageWithPermissions,
  Property,
  PropertyConfig,
  PropertyValue,
  RemapModalConfig,
  RowItem,
  SearchFilter,
  Sort,
  Space,
  Task,
  TaskAbsenteeMaybe,
  TaskGroupSummaryStatistics,
  TaskLinkInModal,
  TaskPreviewTooltip,
  ViewUpdate,
} from "~/shared/types";
import {
  deepCopy,
  getDartboardLink,
  getDashboardLink,
  getDocLink,
  getFolderLink,
  getPageDisplayName,
  getPageLink,
  getPublicFormLink,
  getReportsLink,
  getTaskLink,
  getViewLink,
  unionOfAllSets,
} from "~/utils/common";
import { makeListComparatorOrganizedBySize, makeStringComparator, numberComparator } from "~/utils/comparator";
import {
  CREATE_TASK_CREATE_MORE_ON_KEY,
  lsGet,
  lsSet,
  RECENT_DOC_STACK_KEY,
  RECENT_EMOJI_STACK_KEY,
  RECENT_ICON_STACK_KEY,
  RECENT_SEARCH_STACK_KEY,
  RECENT_SKIN_TONE_KEY as SKIN_TONE_KEY,
  RECENT_TASK_STACK_KEY,
} from "~/utils/localStorageManager";
import { allThrottleManagers } from "~/utils/throttleManager";
import type DashboardsRoot from "~/views/DashboardsRootView.vue";
import type DocView from "~/views/DocView.vue";
import type FolderRootView from "~/views/FolderRootView.vue";
import type InboxView from "~/views/InboxView.vue";
import type ReportsView from "~/views/intelligence/ReportsView.vue";
import type ViewsRoot from "~/views/ViewsRootView.vue";

const PAGE_KINDS_WITH_DEFAULT_FILTERS = new Set<ViewKind>([ViewKind.SEARCH, ViewKind.MY_TASKS, ViewKind.TRASH]);
const TRASH_PSEUDO_DARTBOARD_KEY = "@Trash";

const TASK_DETAIL_MODE_TO_EVENT_KIND_MAP: Record<TaskDetailMode, EventKind> = {
  [TaskDetailMode.RIGHTBAR]: EventKind.USAGE_OPEN_RIGHTBAR,
  [TaskDetailMode.FULLSCREEN]: EventKind.USAGE_OPEN_FULLSCREEN,
  [TaskDetailMode.OVERLAY]: EventKind.USAGE_OPEN_TASK_OVERLAY,
};

const makeLoadingAiTaskPropertyKey = (taskDuid: string, propertyDuid: string) =>
  JSON.stringify([taskDuid, propertyDuid]);

type State = {
  // global app data
  eventKinds: EventKindFull[];
  pageIconOptions: PageIconOption[];
  webhookEventKinds: WebhookEventKind[];
  recentIconStack: string[];
  recentEmojiStack: string[];
  skinTone: EmojiSkinTone;
  recentSearchStack: SearchFilter[];
  recentTaskStack: string[];
  recentDocStack: string[];
  pendingAttachmentCreates: AttachmentCreate[];
  // components
  topbar: InstanceType<typeof Topbar> | null;
  list: InstanceType<typeof List> | null;
  board: InstanceType<typeof Board> | null;
  calendar: InstanceType<typeof Calendar> | null;
  roadmap: InstanceType<typeof Roadmap> | null;
  spaceOrFolder: InstanceType<typeof ReportsView> | InstanceType<typeof FolderRootView> | null;
  inbox: InstanceType<typeof InboxView> | null;
  tcmSubtasksList: InstanceType<typeof SubtasksList> | null;
  taskDetailSubtasksList: InstanceType<typeof SubtasksList> | null;
  taskDetail: InstanceType<typeof TaskDetail> | null;
  filterArea: InstanceType<typeof FilterArea> | null;
  searchFilterArea: InstanceType<typeof FilterArea> | null;
  tcm: InstanceType<typeof Tcm> | null;
  projectAiDropdown: InstanceType<typeof ProjectAiDropdown> | null;
  doc: InstanceType<typeof DocView> | null;
  viewsRoot: InstanceType<typeof ViewsRoot> | null;
  dashboardsRoot: InstanceType<typeof DashboardsRoot> | null;
  // managers
  // TODO managers here could probably be handled better
  websocketManager?: WebsocketManager;
  taskDropTargets: Map<string, HTMLElement>;
  cachedNewTaskInput: SerializedEditorState | null;
  newTask: { focus: () => void } | null;
  dragging: { group: string; category: string } | null;
  // instance-specific
  currentPageConfig?: { kind: PageKind; duid: string };
  layoutDuid?: string;
  selectedTaskDuids: Set<string>;
  loadingTaskProperties: Set<string>;
  search: string | null;
  // TODO improve management of hover state
  hoverClosedWithBarOpen: boolean;
  hoverRow: IRowNode<Task> | null;
  createTaskCreateMore: boolean;
  hoverGroupId: string | null;
  // modals
  contextMenu: ContextMenu | null;
  taskPreviewTooltip: TaskPreviewTooltip | null;
  feedbackTooltip: FeedbackTooltip | null;
  settingsModalOpen: boolean;
  tcmOpen: boolean;
  formInInternalModalDuid: string | null;
  upgradeModalAction: string | null;
  deleteModalConfig: { title: string; description: string; onDelete: () => void } | null;
  appDownloadModalOpen: boolean;
  feedbackModalOpen: boolean;
  shortcutsModalOpen: boolean;
  searchModalOpen: boolean;
  agentDemoModalOpen: boolean;
  planProjectModalOpen: boolean;
  brainstormModalOpen: boolean;
  switchToDesktopModalOpen: boolean;
  tasksOpenInReminderModalDuids: string[];
  pageInPermissionsModalConfig: { kind: PageKind; duid: string } | null;
  linkOpenInModalConfig: { taskDuid: string; linkDuid?: string } | null;
  taskInNotionLinkModalDuid: string | null;
  spaceOpenInReportCreationModalConfig: { spaceDuid: string; reportKind: ReportKind } | null;
  mediaOpenInFullscreen: {
    attachmentFileUrl: string;
    attachmentName: string;
    isImage: boolean;
    entityName: string;
  } | null;
  onboardingModalOpen: boolean;
  remapModal: RemapModalConfig | null;
  // appearance
  taskDetailOpen: boolean;
  taskDetailMode: TaskDetailMode | null;
  leftbarInCollapsibleMode: boolean;
  mobileLeftbarOpen: boolean;
  docOpenInFullscreenDuid: string | null;
  trialStartedSuppressed: boolean;
};

const useAppStore = defineStore("AppStore", {
  state: (): State =>
    ({
      // global app data
      eventKinds: [],
      pageIconOptions: [],
      webhookEventKinds: [],
      recentIconStack: JSON.parse(lsGet(RECENT_ICON_STACK_KEY) ?? "[]"),
      recentEmojiStack: JSON.parse(lsGet(RECENT_EMOJI_STACK_KEY) ?? "[]"),
      skinTone: (lsGet(SKIN_TONE_KEY) as EmojiSkinTone) ?? EmojiSkinTone.NONE,
      recentSearchStack: JSON.parse(lsGet(RECENT_SEARCH_STACK_KEY) ?? "[]"),
      recentTaskStack: JSON.parse(lsGet(RECENT_TASK_STACK_KEY) ?? "[]"),
      recentDocStack: JSON.parse(lsGet(RECENT_DOC_STACK_KEY) ?? "[]"),
      pendingAttachmentCreates: [],
      // components
      topbar: null,
      list: null,
      board: null,
      calendar: null,
      roadmap: null,
      spaceOrFolder: null,
      inbox: null,
      tcmSubtasksList: null,
      taskDetailSubtasksList: null,
      roadmapList: null,
      taskDetail: null,
      filterArea: null,
      searchFilterArea: null,
      tcm: null,
      internalFormModal: null,
      projectAiDropdown: null,
      doc: null,
      viewsRoot: null,
      dashboardsRoot: null,
      // managers
      websocketManager: undefined,
      taskDropTargets: new Map(),
      cachedNewTaskInput: null,
      newTask: null,
      dragging: null,
      // instance-specific
      currentPageConfig: undefined,
      layoutDuid: undefined,
      selectedTaskDuids: new Set(),
      loadingTaskProperties: new Set(),
      search: null,
      hoverClosedWithBarOpen: false,
      hoverRow: null,
      createTaskCreateMore: lsGet(CREATE_TASK_CREATE_MORE_ON_KEY) === "true",
      hoverGroupId: null,
      // modals
      contextMenu: null,
      taskPreviewTooltip: null,
      feedbackTooltip: null,
      settingsModalOpen: false,
      tcmOpen: false,
      formInInternalModalDuid: null,
      upgradeModalAction: null,
      deleteModalConfig: null,
      appDownloadModalOpen: false,
      feedbackModalOpen: false,
      shortcutsModalOpen: false,
      searchModalOpen: false,
      agentDemoModalOpen: false,
      planProjectModalOpen: false,
      brainstormModalOpen: false,
      switchToDesktopModalOpen: false,
      tasksOpenInReminderModalDuids: [],
      pageInPermissionsModalConfig: null,
      linkOpenInModalConfig: null,
      taskInNotionLinkModalDuid: null,
      spaceOpenInReportCreationModalConfig: null,
      mediaOpenInFullscreen: null,
      onboardingModalOpen: false,
      remapModal: null,
      // appearance
      leftbarInCollapsibleMode: false,
      mobileLeftbarOpen: false,
      taskDetailOpen: false,
      taskDetailMode: null,
      docOpenInFullscreenDuid: null,
      trialStartedSuppressed: false,
    }) as State,
  getters: {
    // instance-specific
    currentPage(): Page | undefined {
      const dataStore = this.$useDataStore();

      if (!this.currentPageConfig) {
        return undefined;
      }

      switch (this.currentPageConfig.kind) {
        case PageKind.DARTBOARD: {
          return dataStore.getDartboardByDuid(this.currentPageConfig.duid);
        }
        case PageKind.DASHBOARD: {
          return dataStore.getDashboardByDuid(this.currentPageConfig.duid);
        }
        case PageKind.DASHBOARDS_ROOT: {
          return DASHBOARDS_PSEUDO_PAGE;
        }
        case PageKind.DOC: {
          // TODO incremental Make currentPage return a promise, too big to change now
          return dataStore._duidsToDocs.get(this.currentPageConfig.duid);
        }
        case PageKind.FOLDER: {
          return dataStore.getFolderByDuid(this.currentPageConfig.duid);
        }
        case PageKind.HOME: {
          return HOME_PSEUDO_PAGE;
        }
        case PageKind.INBOX: {
          return INBOX_PSEUDO_PAGE;
        }
        case PageKind.NOT_FOUND: {
          return NOT_FOUND_PSEUDO_PAGE;
        }
        case PageKind.SPACE: {
          return dataStore.getSpaceByDuid(this.currentPageConfig.duid);
        }
        case PageKind.VIEW: {
          return dataStore.getViewByDuid(this.currentPageConfig.duid);
        }
        case PageKind.VIEWS_ROOT: {
          return VIEWS_PSEUDO_PAGE;
        }
        default: {
          throw new Error(`Unknown page kind: ${this.currentPageConfig.kind}`);
        }
      }
    },
    currentDartboardDuid(): string | null {
      return this.currentPage?.pageKind === PageKind.DARTBOARD ? this.currentPage.duid : null;
    },
    currentDartboardOrDefault(): Dartboard | undefined {
      return this.currentPage?.pageKind === PageKind.DARTBOARD
        ? this.currentPage
        : this.$useDataStore().defaultDartboard;
    },
    currentActiveBrainstorm(): Brainstorm | undefined {
      const dataStore = this.$useDataStore();
      if (!this.currentDartboardDuid) {
        return undefined;
      }
      return dataStore.getBrainstormByDartboardDuid(this.currentDartboardDuid);
    },
    alwaysShownPropertyDuids(): string[] {
      if (
        !this.currentPage ||
        !(this.currentPage.pageKind === PageKind.DARTBOARD || this.currentPage.pageKind === PageKind.VIEW)
      ) {
        return [];
      }
      return this.currentPage.alwaysShownPropertyDuids;
    },
    alwaysHiddenPropertyDuids(): string[] {
      if (
        !this.currentPage ||
        !(this.currentPage.pageKind === PageKind.DARTBOARD || this.currentPage.pageKind === PageKind.VIEW)
      ) {
        return [];
      }
      return this.currentPage.alwaysHiddenPropertyDuids;
    },
    propertyOrderDuids(): string[] {
      if (
        !this.currentPage ||
        !(this.currentPage.pageKind === PageKind.DARTBOARD || this.currentPage.pageKind === PageKind.VIEW)
      ) {
        return [];
      }
      return this.currentPage.propertyOrderDuids;
    },
    isPropertyShown(): (property: Property) => boolean {
      return (property) =>
        property.hidden
          ? this.alwaysShownPropertyDuids.includes(property.duid)
          : !this.alwaysHiddenPropertyDuids.includes(property.duid);
    },
    isPropertyShownInLayout(): (property: Property, propertyConfig: PropertyConfig) => boolean {
      return (property, config) => {
        if (property.kind === PropertyKind.DEFAULT_TITLE) {
          return false;
        }
        if (this.currentPage?.kind === ViewKind.TRASH) {
          return !property.hidden;
        }
        if (this.alwaysHiddenPropertyDuids.includes(property.duid)) {
          return false;
        }
        if (
          this.alwaysShownPropertyDuids.includes(property.duid) ||
          config.alwaysShowForLayouts?.includes(this.layoutKind)
        ) {
          return true;
        }
        if (config.defaultHideForLayouts?.includes(this.layoutKind)) {
          return false;
        }
        return !property.hidden;
      };
    },
    allTasksInPage(): TaskAbsenteeMaybe[] {
      const dataStore = this.$useDataStore();

      const isSearch = this.currentPage?.kind === ViewKind.SEARCH;
      if (isSearch || this.currentPage?.kind === ViewKind.MY_TASKS) {
        return dataStore.getTaskList({ includeTrashed: isSearch });
      }

      if (this.currentPage?.kind === ViewKind.TRASH) {
        return dataStore.tasksInTrash;
      }

      if (this.currentPage && this.currentPage.pageKind === PageKind.VIEW) {
        return dataStore.getTaskList();
      }

      if (this.currentPage && this.currentPage.pageKind === PageKind.DARTBOARD) {
        return dataStore.getTasksByDartboardDuidOrdered(this.currentPage.duid, {
          includeAncestors: this.showAbsentees,
        });
      }

      if (this.currentPage && this.currentPage.pageKind === PageKind.DASHBOARD) {
        const dartboardDuid = this.$router.currentRoute.value.params.dartboardDuid as string;
        return dataStore.getTasksByDartboardDuidOrdered(dartboardDuid, { includeAncestors: this.showAbsentees });
      }

      return [];
    },
    filteredAndSortedTasksInPage(): TaskAbsenteeMaybe[] {
      const dataStore = this.$useDataStore();

      const allTasks = this.allTasksInPage;

      const { columnDefs, titleColDef } = columns(dataStore);
      columnDefs.value.unshift(titleColDef.value);

      const searchNorm = this.search?.toLowerCase() ?? "";

      const searchFilters = columnDefs.value
        .filter(
          (e): e is ExtendedColDef & { getSearchValue: Exclude<ExtendedColDef["getSearchValue"], undefined> } =>
            !!e.getSearchValue
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((e): [any, Exclude<ExtendedColDef["getSearchValue"], undefined>] => [e, e.getSearchValue]);
      let filteredTasks = allTasks.filter((task) => {
        const searchPasses =
          !searchNorm ||
          searchFilters.some(([field, getSearchValue]) => {
            const rawValue = getPropertyValueFromTaskByDuid(field.field, task);
            const filterText = getSearchValue(rawValue, task);
            return filterText && filterText.toLowerCase().includes(searchNorm);
          });
        return searchPasses && doesTaskPass(dataStore, task, this.filters);
      });

      // Remove absentees that are no longer relevant
      const ancestorMap = new Map(filteredTasks.map((e) => [e.duid, dataStore.getAncestorDuids(e)]));
      if (this.subtaskDisplayMode !== SubtaskDisplayMode.FLAT) {
        const nonAbsenteeDuids = new Set(filteredTasks.filter((e) => !e.absentee).map((e) => e.duid));
        const ancestorDuids = unionOfAllSets([...ancestorMap.values()].map((e) => new Set(e)));
        filteredTasks = allTasks
          .filter((e) => nonAbsenteeDuids.has(e.duid) || ancestorDuids.has(e.duid))
          .map((e) => {
            if (nonAbsenteeDuids.has(e.duid)) {
              return e;
            }
            return { ...e, absentee: true };
          });
      }

      if (!this.layout) {
        return filteredTasks;
      }

      const sortsAndComparators: [Sort, Comparator][] = this.layout.sorts
        .map((sort) => [sort, columnDefs.value.find((e) => e.field === sort.propertyDuid)?.comparatorFn])
        .filter((e): e is [Sort, Comparator] => !!e[1]);

      filteredTasks.sort((a, b) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [sort, comparator] of sortsAndComparators) {
          const aValue = getPropertyValueFromTaskByDuid(sort.propertyDuid, a);
          const bValue = getPropertyValueFromTaskByDuid(sort.propertyDuid, b);
          const res = comparator(aValue, bValue);
          if (res === 0) {
            continue;
          }
          return sort.isAscending ? res : -res;
        }
        return 0;
      });

      // Put children after their parents
      const existingOrderMap = new Map(filteredTasks.map((e, i) => [e.duid, i]));
      const fullOrderMap = new Map(
        filteredTasks.map((task) => {
          const orders = (ancestorMap.get(task.duid) ?? []).map((f) => existingOrderMap.get(f) ?? 0);
          orders.push(existingOrderMap.get(task.duid) ?? 0);
          return [task.duid, orders];
        })
      );
      const fullOrderComparator = makeListComparatorOrganizedBySize(numberComparator);
      filteredTasks.sort((a, b) => {
        const aFullOrder = fullOrderMap.get(a.duid) ?? [];
        const bFullOrder = fullOrderMap.get(b.duid) ?? [];
        return fullOrderComparator(aFullOrder, bFullOrder);
      });
      return filteredTasks;
    },
    groupByValueToTasksMap(): Map<string, TaskAbsenteeMaybe[]> {
      const map = new Map<string, TaskAbsenteeMaybe[]>();

      if (this.groupBy === UNGROUPED_PSEUDO_GROUP_BY) {
        map.set(UNGROUPED_PSEUDO_GROUP_BY, this.filteredAndSortedTasksInPage);
        return map;
      }
      this.groupByDefinition.groups.forEach(({ value }) => map.set(getPropertyValueStr(value), []));

      this.filteredAndSortedTasksInPage.forEach((task) => {
        const propertyValue = getPropertyValueFromTaskByDuid(this.groupBy, task);
        if (propertyValue === undefined) {
          return;
        }

        const propertyValueArr = Array.isArray(propertyValue)
          ? propertyValue.length === 0
            ? [NO_VALUE_GROUP_BY_KEY]
            : propertyValue
          : [propertyValue];

        propertyValueArr.forEach((value) => {
          const groupValueStr = getPropertyValueStr(value as PropertyValue);
          let groupTasks = map.get(groupValueStr);
          if (!groupTasks) {
            groupTasks = [];
            map.set(groupValueStr, groupTasks);
          }
          groupTasks.push(task);
        });
      });

      return map;
    },
    layout(): Layout | undefined {
      if (!this.layoutDuid) {
        return undefined;
      }
      return this.$useDataStore().getLayoutByDuid(this.layoutDuid);
    },
    layoutKind(): LayoutKind {
      return this.layout?.kind ?? LayoutKind.LIST;
    },
    layoutConfig(): LayoutConfig {
      const res = this.layout?.kindConfigMap?.[this.layoutKind];
      if (res) {
        return res;
      }

      switch (this.layoutKind) {
        case LayoutKind.LIST: {
          return {
            subtaskDisplayMode: SubtaskDisplayMode.INDENTED,
            showAbsentees: true,
            groupBy: getDefaultListGroupBy(),
            collapsedGroups: [],
            hideEmptyGroups: false,
          };
        }
        case LayoutKind.BOARD: {
          return {
            subtaskDisplayMode: SubtaskDisplayMode.INDENTED,
            showAbsentees: true,
            groupBy: getDefaultBoardGroupBy(),
            collapsedGroups: [],
            hideEmptyGroups: false,
          };
        }
        case LayoutKind.CALENDAR: {
          return {
            subtaskDisplayMode: SubtaskDisplayMode.INDENTED,
            showAbsentees: true,
            displayPeriod: "month",
            displayPeriodCount: 1,
            colorBy: UNCOLORED_PSEUDO_COLOR_BY,
          };
        }
        case LayoutKind.ROADMAP: {
          return {
            subtaskDisplayMode: SubtaskDisplayMode.INDENTED,
            showAbsentees: true,
            pxPerDay: 10,
            taskListWidthPx: 550,
            colorBy: UNCOLORED_PSEUDO_COLOR_BY,
          };
        }
        default: {
          throw new Error(`Unknown layout kind: ${this.layoutKind}`);
        }
      }
    },
    showAbsentees(): boolean {
      return (
        this.currentPage?.kind !== ViewKind.TRASH &&
        this.subtaskDisplayMode !== SubtaskDisplayMode.FLAT &&
        (this.layoutConfig.showAbsentees ?? true)
      );
    },
    groupByDefinitionList(): GroupByDefinition[] {
      const propertiesWithConfig = getShownPropertyWithConfigList([PropertyKind.DEFAULT_DARTBOARD]);

      return getGroupByDefinitionList(propertiesWithConfig);
    },
    groupBy(): string {
      if (!GROUP_BY_LAYOUT_KINDS.has(this.layoutKind)) {
        return UNGROUPED_PSEUDO_GROUP_BY;
      }

      const defaultGroupBy = (this.layoutKind === LayoutKind.LIST ? getDefaultListGroupBy : getDefaultBoardGroupBy)();
      const groupBy = (this.layoutConfig as LayoutConfigList | LayoutConfigBoard).groupBy ?? defaultGroupBy;

      // Check if the groupBy is still valid
      if (this.groupByDefinitionList.find((e) => e.property.duid === groupBy)) {
        return groupBy;
      }

      return defaultGroupBy;
    },
    groupByDefinition(): GroupByDefinition {
      const { groupBy } = this;
      const res = this.groupByDefinitionList.find((e) => e.property.duid === groupBy);
      if (!res) {
        throw new Error(`Unknown groupBy: ${groupBy}`);
      }
      return res;
    },
    hideEmptyGroups(): boolean {
      if (!GROUP_BY_LAYOUT_KINDS.has(this.layoutKind)) {
        return false;
      }

      const layoutConfig = this.layoutConfig as LayoutConfigList | LayoutConfigBoard;
      return layoutConfig.hideEmptyGroups ?? false;
    },
    colorBy(): string {
      if (!COLOR_BY_LAYOUT_KINDS.has(this.layoutKind)) {
        return UNCOLORED_PSEUDO_COLOR_BY;
      }

      const defaultColorBy = UNCOLORED_PSEUDO_COLOR_BY;
      const layoutConfig = this.layoutConfig as LayoutConfigRoadmap | LayoutConfigCalendar;
      const colorBy = layoutConfig.colorBy ?? defaultColorBy;

      // Check if the colorBy is still valid
      if (this.groupByDefinitionList.find((e) => e.property.duid === colorBy)) {
        return colorBy;
      }

      return defaultColorBy;
    },
    colorByDefinition(): GroupByDefinition {
      const { colorBy } = this;
      const res = this.groupByDefinitionList.find((e) => e.property.duid === colorBy);
      if (!res) {
        throw new Error(`Unknown colorBy: ${colorBy}`);
      }
      return res;
    },
    defaultFilters(): Filter[] {
      const dataStore = this.$useDataStore();

      if (!this.currentPage) {
        return [];
      }

      const pageKind = this.currentPage.kind;
      if (!PAGE_KINDS_WITH_DEFAULT_FILTERS.has(pageKind as ViewKind)) {
        return [];
      }

      const completedStatusDuids = dataStore
        .getStatusesByKinds([...COMPLETED_STATUS_KINDS], dataStore.defaultStatusProperty)
        .map((e) => e.duid);

      if (pageKind === ViewKind.MY_TASKS) {
        return [
          {
            ...DEFAULT_DARTBOARD_FILTER_PARTIAL,
            propertyDuid: dataStore.defaultDartboardProperty.duid,
            values: [
              ...dataStore.getDartboardsByKindOrdered(DartboardKind.NEXT).map((e) => e.duid),
              ...dataStore.getDartboardsByKindOrdered(DartboardKind.BACKLOG).map((e) => e.duid),
            ],
          },
          {
            ...DEFAULT_STATUS_FILTER_PARTIAL,
            propertyDuid: dataStore.defaultStatusProperty.duid,
            values: completedStatusDuids,
          },
          {
            ...DEFAULT_ASSIGNEES_FILTER_PARTIAL,
            propertyDuid: dataStore.defaultAssigneesProperty.duid,
            values: [CURRENT_USER_PSEUDO_USER_KEY],
            locked: true,
          },
        ];
      }

      return [];
    },
    defaultSearchFilters(): Filter[] {
      const dataStore = this.$useDataStore();

      const completedStatusDuids = dataStore
        .getStatusesByKinds([...COMPLETED_STATUS_KINDS], dataStore.defaultStatusProperty)
        .map((e) => e.duid);

      return [
        {
          ...(this.currentPage?.kind === ViewKind.TRASH
            ? DEFAULT_TRASH_DARTBOARD_FILTER_PARTIAL
            : DEFAULT_DARTBOARD_FILTER_PARTIAL),
          propertyDuid: dataStore.defaultDartboardProperty.duid,
          values: [TRASH_PSEUDO_DARTBOARD_KEY],
        },
        {
          ...DEFAULT_STATUS_FILTER_PARTIAL,
          propertyDuid: dataStore.defaultStatusProperty.duid,
          values: completedStatusDuids,
        },
      ];
    },
    filters(): Filter[] {
      return this.layout?.filterGroup.filters ?? [];
    },
    isFilterEqual(): (a: Filter, b: Filter) => boolean {
      return (a, b) => {
        const aCopy = deepCopy(a);
        const bCopy = deepCopy(b);
        aCopy.values.sort();
        bCopy.values.sort();
        return equal(aCopy, bCopy, { strict: true });
      };
    },
    areFiltersEqual(): (a: Filter[], b: Filter[]) => boolean {
      return (a, b) => {
        const aCopy = deepCopy(a);
        const bCopy = deepCopy(b);

        /* Sort them so that the equality is order agnostic */
        const sort = (filterArr: Filter[]) => {
          filterArr.forEach((e) => {
            e.values.sort();
          });
          return filterArr.sort(makeStringComparator((e) => e.propertyDuid));
        };
        sort(aCopy);
        sort(bCopy);

        return equal(aCopy, bCopy, { strict: true });
      };
    },
    usingDefaultFilters(): boolean {
      return this.areFiltersEqual(this.filters, this.defaultFilters);
    },
    usingDefaultFiltersAndSorts(): boolean {
      return this.usingDefaultFilters && this.sorts.length === 0;
    },
    areDefaultSearchFilters(): (filters: Filter[]) => boolean {
      return (filters) => this.areFiltersEqual(filters, this.defaultSearchFilters);
    },
    getNonDefaultSearchFilters(): (filters: Filter[]) => Filter[] {
      return (filters) => {
        const defaultSearchFilterMap = new Map(this.defaultSearchFilters.map((e) => [e.propertyDuid, e]));
        return filters.filter((e) => {
          const defaultFilter = defaultSearchFilterMap.get(e.propertyDuid);
          return !defaultFilter || !this.isFilterEqual(defaultFilter, e);
        });
      };
    },
    getFilter(): (propertyDuid: string) => Filter | undefined {
      return (propertyDuid) => this.filters.find((e) => e.propertyDuid === propertyDuid);
    },
    sorts(): Sort[] {
      return this.layout?.sorts ?? [];
    },
    collapsedGroups(): string[] {
      if (!GROUP_BY_LAYOUT_KINDS.has(this.layoutKind)) {
        return [];
      }

      return (this.layoutConfig as LayoutConfigList | LayoutConfigBoard).collapsedGroups ?? [];
    },
    subtaskDisplayMode(): SubtaskDisplayMode {
      return this.layoutConfig.subtaskDisplayMode ?? SubtaskDisplayMode.INDENTED;
    },
    roadmapZoom(): number {
      return (this.layout?.kindConfigMap?.[LayoutKind.ROADMAP] as LayoutConfigRoadmap)?.pxPerDay ?? 10;
    },
    roadmapTaskListWidthPx(): number {
      return (this.layout?.kindConfigMap?.[LayoutKind.ROADMAP] as LayoutConfigRoadmap)?.taskListWidthPx ?? 550;
    },
    calendarDisplayPeriod(): CalendarDisplayPeriod {
      return (this.layout?.kindConfigMap?.[LayoutKind.CALENDAR] as LayoutConfigCalendar)?.displayPeriod ?? "month";
    },
    calendarDisplayPeriodCount(): number {
      return (this.layout?.kindConfigMap?.[LayoutKind.CALENDAR] as LayoutConfigCalendar)?.displayPeriodCount ?? 1;
    },
    summaryStatisticKind(): SummaryStatisticKind {
      return this.layout?.summaryStatisticKind ?? SummaryStatisticKind.NONE;
    },
    summaryStatistics(): TaskGroupSummaryStatistics {
      const dataStore = this.$useDataStore();

      const finishedStatusDuids = new Set(
        dataStore.getStatusesByKinds([StatusKind.FINISHED], dataStore.defaultStatusProperty).map((s) => s.duid)
      );
      const selecteds = this.filteredAndSortedTasksInPage.filter((task) => this.selectedTaskDuids.has(task.duid));
      const completeds = this.filteredAndSortedTasksInPage.filter((task) => finishedStatusDuids.has(task.statusDuid));
      const selectedCompleteds = selecteds.filter((e) => e.statusDuid && finishedStatusDuids.has(e.statusDuid));

      return {
        hasSelection: selecteds.length > 0,
        visibleCount: this.filteredAndSortedTasksInPage.length,
        visiblePoints: this.filteredAndSortedTasksInPage
          .map((e) => dataStore.getSize(e) ?? 0)
          .reduce((a, b) => a + b, 0),
        visibleCompletedCount: completeds.length,
        visibleCompletedPoints: completeds.map((e) => dataStore.getSize(e) ?? 0).reduce((a, b) => a + b, 0),
        selectedCount: selecteds.length,
        selectedPoints: selecteds.map((e) => dataStore.getSize(e) ?? 0).reduce((a, b) => a + b, 0),
        selectedCompletedCount: selectedCompleteds.length,
        selectedCompletedPoints: selectedCompleteds.map((e) => dataStore.getSize(e) ?? 0).reduce((a, b) => a + b, 0),
      };
    },
    isLoadingTasksProperty(): (tasks: Task[], propertyDuid: string) => boolean {
      return (tasks, propertyDuid) =>
        tasks.some(({ duid }) => this.loadingTaskProperties.has(makeLoadingAiTaskPropertyKey(duid, propertyDuid)));
    },
    // modals
    formInInternalModal(): Form | null {
      if (!this.formInInternalModalDuid) {
        return null;
      }

      return this.$useDataStore().getFormByDuid(this.formInInternalModalDuid) ?? null;
    },
    tasksOpenInReminderModal(): Task[] {
      return this.tasksOpenInReminderModalDuids.reduce((tasks, duid) => {
        const task = this.$useDataStore().getTaskByDuid(duid);
        if (task) {
          tasks.push(task);
        }
        return tasks;
      }, [] as Task[]);
    },
    pageInPermissionsModal(): PageWithPermissions | null {
      const dataStore = this.$useDataStore();

      if (!this.pageInPermissionsModalConfig) {
        return null;
      }

      switch (this.pageInPermissionsModalConfig.kind) {
        case PageKind.DASHBOARD: {
          return dataStore.getDashboardByDuid(this.pageInPermissionsModalConfig.duid) ?? null;
        }
        case PageKind.SPACE: {
          return dataStore.getSpaceByDuid(this.pageInPermissionsModalConfig.duid) ?? null;
        }
        case PageKind.VIEW: {
          return dataStore.getViewByDuid(this.pageInPermissionsModalConfig.duid) ?? null;
        }
        default: {
          throw new Error(`Unknown page with permissions kind: ${this.pageInPermissionsModalConfig.kind}`);
        }
      }

      return null;
    },
    linkOpenInModal(): TaskLinkInModal | null {
      if (!this.linkOpenInModalConfig) {
        return null;
      }

      const task = this.$useDataStore().getTaskByDuid(this.linkOpenInModalConfig.taskDuid);
      if (!task) {
        return null;
      }

      const link = task.links.find((e) => e.duid === this.linkOpenInModalConfig?.linkDuid);
      if (!link && this.linkOpenInModalConfig?.linkDuid) {
        return null;
      }

      return { taskDuid: task.duid, link };
    },
    taskInNotionLinkModal(): Task | null {
      if (!this.taskInNotionLinkModalDuid) {
        return null;
      }

      return this.$useDataStore().getTaskByDuid(this.taskInNotionLinkModalDuid) ?? null;
    },
    spaceInReportCreationModal(): Space | null {
      if (!this.spaceOpenInReportCreationModalConfig) {
        return null;
      }

      return this.$useDataStore().getSpaceByDuid(this.spaceOpenInReportCreationModalConfig.spaceDuid) ?? null;
    },
    taskOpenInDetail(): Task | null {
      if (!this.taskDetailOpen || this.selectedTaskDuids.size !== 1) {
        return null;
      }

      return this.$useDataStore().getTaskByDuid([...this.selectedTaskDuids][0]) ?? null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    activeFilterArea(): any {
      if (this.searchFilterArea) {
        return this.searchFilterArea;
      }
      return this.filterArea;
    },
    docOpenInFullscreen(): Doc | null {
      if (!this.docOpenInFullscreenDuid) {
        return null;
      }

      // TODO incremental Make docOpenInFullscreen return a promise, too big to change now
      return this.$useDataStore()._duidsToDocs.get(this.docOpenInFullscreenDuid) ?? null;
    },
    trialStartedModalOpen(): boolean {
      const tenantStore = this.$useTenantStore();
      return (
        !tenantStore.isPremiumWithoutTrial &&
        tenantStore.trialActive &&
        (this.$useUserStore().getTutorialStatus(TutorialName.TRIAL_STARTED) ?? 2) < 2 &&
        !this.onboardingModalOpen &&
        !this.trialStartedSuppressed
      );
    },
    onboardingChecklistCompleted(): boolean {
      return (this.$useUserStore().getTutorialStatus(TutorialName.CHECKLIST_COMPLETED) ?? 1) > 1;
    },
    isNonTaskModalOpen(): boolean {
      return [
        this.agentDemoModalOpen,
        this.appDownloadModalOpen,
        this.brainstormModalOpen,
        this.feedbackModalOpen,
        this.formInInternalModal,
        this.linkOpenInModal,
        this.mediaOpenInFullscreen,
        this.mobileLeftbarOpen,
        this.pageInPermissionsModal,
        this.planProjectModalOpen,
        this.settingsModalOpen,
        this.shortcutsModalOpen,
        this.spaceInReportCreationModal,
        this.switchToDesktopModalOpen,
        this.taskInNotionLinkModal,
        this.tasksOpenInReminderModal.length > 0,
        this.upgradeModalAction,
      ].some(Boolean);
    },
  },
  actions: {
    init() {
      const dataStore = this.$useDataStore();
      const pageStore = this.$usePageStore();
      const tenantStore = this.$useTenantStore();

      // update network tracking
      const network = useNetwork();
      watch(
        () => network.isOnline.value,
        (isOnline) => {
          pageStore.isOnline = isOnline;
        }
      );

      // update theme
      const dark = useDark();
      watch(dark, () => nextTick(() => pageStore.updateTheme()));

      // close the RB and fullscreen if not exactly one task is selected
      watch(
        () => this.selectedTaskDuids.size,
        (newSize) => {
          if (newSize === 1) {
            return;
          }
          if (this.taskDetailOpen) {
            this.taskDetailOpen = false;
            this.taskDetailMode = null;
          }
        }
      );

      // update selection in commandbar when it changes
      watch(
        () => this.selectedTaskDuids.size,
        (newSize) => {
          this.updateSelectionInCommandBar(newSize > 0);
        }
      );

      // manage the focused task in the URL and update recent task stack
      watch(
        () => this.taskOpenInDetail,
        (newTask) => {
          const route = this.$router.currentRoute.value;
          const params = { ...route.params };

          if (newTask && this.currentPageConfig?.kind !== PageKind.INBOX) {
            this.addRecentTask(newTask);
            params.taskDuidAndSlug = getTaskLink(newTask).params.taskDuidAndSlug;
            const newRoute = { ...route, params };
            this.$router.push(newRoute);
            return;
          }

          delete params.taskDuidAndSlug;
          const page = this.currentPage;
          if (page) {
            const newLink = getPageLink(page, dataStore.getSpaceByDuid);
            if ("params" in newLink) {
              Object.assign(params, newLink.params);
            }
          }
          const newRoute = { ...route, params };
          this.$router.push(newRoute);
        }
      );

      // remove reminder indicator when a task is opened
      watch(
        () => this.taskOpenInDetail,
        (newTask) => {
          if (!newTask || !newTask.remindAt || moment(newTask.remindAt).isAfter(moment())) {
            return;
          }
          dataStore.updateTasks([{ duid: newTask.duid, remindAt: null }], { noUndo: true });
        }
      );

      watch(
        () => tenantStore.name,
        (newTenantName) => {
          const fullTitle = [getPageDisplayName(this.currentPage, dataStore.getSpaceByDuid), newTenantName]
            .filter(Boolean)
            .join(" | ");
          pageStore.pageTitle = fullTitle;
          document.title = fullTitle;
        }
      );

      // update the URL when the title of the current page changes
      watch(
        () => getPageDisplayName(this.currentPage, dataStore.getSpaceByDuid),
        (newTitle) => {
          if (!this.currentPage) {
            return;
          }

          const router = this.$router;

          const page = this.currentPage;
          const query = { ...router.currentRoute.value.query };

          const fullTitle = [newTitle, tenantStore.name].filter(Boolean).join(" | ");
          pageStore.pageTitle = fullTitle;
          document.title = fullTitle;

          switch (page.pageKind) {
            case PageKind.DARTBOARD: {
              if (!router.currentRoute.value.params.taskDuidAndSlug) {
                router.replace({
                  params: { slug: getDartboardLink(page, dataStore.getSpaceByDuid).params.slug },
                  query,
                });
              }
              break;
            }
            case PageKind.DASHBOARD: {
              if (!router.currentRoute.value.params.taskDuidAndSlug) {
                router.replace({ params: { slug: getDashboardLink(page).params?.slug }, query });
              }
              break;
            }
            case PageKind.VIEW: {
              if (!router.currentRoute.value.params.taskDuidAndSlug) {
                router.replace({ params: { slug: getViewLink(page).params?.slug }, query });
              }
              break;
            }
            case PageKind.FOLDER: {
              if (!router.currentRoute.value.params.docDuid) {
                router.replace({ params: { slug: getFolderLink(page, dataStore.getSpaceByDuid).params.slug }, query });
              }
              break;
            }
            case PageKind.SPACE: {
              router.replace({ params: { slug: getReportsLink(page).params.slug }, query });
              break;
            }
            case PageKind.FORM: {
              router.replace({ params: { slug: getPublicFormLink(page).params.slug }, query });
              break;
            }
            case PageKind.DASHBOARDS_ROOT:
            case PageKind.HOME:
            case PageKind.INBOX:
            case PageKind.NOT_FOUND:
            case PageKind.VIEWS_ROOT: {
              break;
            }
            default: {
              throw new Error(`Unknown page kind: ${page.pageKind}`);
            }
          }
        }
      );

      // update the URL when the title of the doc changes
      watch(
        () => this.docOpenInFullscreen?.title,
        () => {
          const doc = this.docOpenInFullscreen;
          if (!doc) {
            return;
          }
          this.addRecentDoc(doc);

          this.$router.replace({ params: { slug: getDocLink(doc).params.slug } });
        }
      );

      // update the URL when the title of the task changes
      watch(
        () => this.taskOpenInDetail?.title,
        () => {
          const task = this.taskOpenInDetail;
          if (!task) {
            return;
          }

          this.$router.replace({
            params: {
              slugSep: "",
              slug: "",
              taskDuidAndSlug: getTaskLink(task).params.taskDuidAndSlug,
            },
            query: { ...this.$router.currentRoute.value.query },
          });
        }
      );
    },
    // global app data
    _addToStack<K>(el: K, stack: K[], max: number) {
      const index = stack.indexOf(el);
      if (index !== -1) {
        stack.splice(index, 1);
      }
      stack.unshift(el);
      return stack.slice(0, max);
    },
    getWorking(): boolean {
      // TODO transition back to computed--it wasn't recomputing properly
      const transactionManagerWorking = this.$requestManager.working.value;
      const someThrottleManagerWorking = allThrottleManagers.value.some((e) => e.working);
      return transactionManagerWorking || someThrottleManagerWorking;
    },
    accelerateSave() {
      allThrottleManagers.value.forEach((e) => e.finish());
    },
    setEventKinds(eventKinds: EventKindFull[]) {
      this.eventKinds = eventKinds;
    },
    setPageIconOptions(pageIconOptions: PageIconOption[]) {
      this.pageIconOptions = pageIconOptions;
    },
    setWebhookEventKinds(webhookEventKinds: WebhookEventKind[]) {
      this.webhookEventKinds = webhookEventKinds;
    },
    addToRecentIconStack(icon: string) {
      this.recentIconStack = this._addToStack(icon, this.recentIconStack, 24);
      lsSet(RECENT_ICON_STACK_KEY, JSON.stringify(this.recentIconStack));
    },
    addToRecentEmojiStack(emoji: string) {
      this.recentEmojiStack = this._addToStack(emoji, this.recentEmojiStack, 24);
      lsSet(RECENT_EMOJI_STACK_KEY, JSON.stringify(this.recentEmojiStack));
    },
    setSkinTone(skinTone: EmojiSkinTone) {
      this.skinTone = skinTone;
      lsSet(SKIN_TONE_KEY, skinTone);
    },
    addRecentSearch(search: SearchFilter) {
      this.recentSearchStack = this._addToStack(search, this.recentSearchStack, 100);
      lsSet(RECENT_SEARCH_STACK_KEY, JSON.stringify(this.recentSearchStack));
    },
    removeRecentSearch(duid: string) {
      const index = this.recentSearchStack.findIndex((e) => e.duid === duid);
      if (index !== -1) {
        this.recentSearchStack.splice(index, 1);
        lsSet(RECENT_SEARCH_STACK_KEY, JSON.stringify(this.recentSearchStack));
      }
    },
    addRecentTask(task: Task) {
      this.recentTaskStack = this._addToStack(task.duid, this.recentTaskStack, 100);
      lsSet(RECENT_TASK_STACK_KEY, JSON.stringify(this.recentTaskStack));
    },
    removeRecentTask(duid: string) {
      const index = this.recentTaskStack.findIndex((e) => e === duid);
      if (index !== -1) {
        this.recentTaskStack.splice(index, 1);
        lsSet(RECENT_TASK_STACK_KEY, JSON.stringify(this.recentTaskStack));
      }
    },
    addRecentDoc(doc: Doc) {
      this.recentDocStack = this._addToStack(doc.duid, this.recentDocStack, 100);
      lsSet(RECENT_DOC_STACK_KEY, JSON.stringify(this.recentDocStack));
    },
    removeRecentDoc(duid: string) {
      const index = this.recentDocStack.findIndex((e) => e === duid);
      if (index !== -1) {
        this.recentDocStack.splice(index, 1);
        lsSet(RECENT_DOC_STACK_KEY, JSON.stringify(this.recentDocStack));
      }
    },
    // components and managers
    connectWs(websocketManager: WebsocketManager) {
      const pageStore = this.$usePageStore();
      if (!pageStore.isLoggedIn || pageStore.isPublicView) {
        return;
      }
      this.websocketManager = websocketManager;
      this.websocketManager.connect();
    },
    disconnectWs() {
      if (!this.websocketManager) {
        return;
      }
      this.websocketManager.disconnect();
      this.websocketManager = undefined;
    },
    // visualization
    getActiveVisualization(): Visualization {
      if (this.tcmSubtasksList) {
        return getListVisualization(() => this.tcmSubtasksList?.api ?? null, this);
      }

      if (this.taskDetailSubtasksList) {
        const subtasksVisualization = getListVisualization(() => this.taskDetailSubtasksList?.api ?? null, this);
        if (subtasksVisualization.getSelectedRows().length > 0) {
          return subtasksVisualization;
        }
      }

      return this.getBaseVisualization();
    },
    hasPrimaryVisualization(): boolean {
      return !!this.list;
    },
    getBaseVisualization(): Visualization {
      if (this.list) {
        return getListVisualization(() => (this.list?.api ?? null) as GridApi<RowItem> | null, this);
      }
      if (this.board || this.calendar) {
        return getBoardOrCalendarVisualization(() => this.board ?? this.calendar, this);
      }
      if (this.roadmap) {
        return getListVisualization(() => (this.roadmap?.api ?? null) as GridApi<RowItem> | null, this);
      }
      if (this.inbox) {
        return getInboxVisualization(() => this.inbox);
      }
      return getDefaultVisualization(() => null);
    },
    getSubtasksListVisualization(): Visualization | undefined {
      if (this.tcmSubtasksList) {
        return getListVisualization(() => this.tcmSubtasksList?.api ?? null, this);
      }

      if (this.taskDetailSubtasksList) {
        return getListVisualization(() => this.taskDetailSubtasksList?.api ?? null, this);
      }

      return undefined;
    },
    // instance-specific
    _updateLayoutField(field: keyof Layout, value: string | object | object[]) {
      if (!this.layout) {
        return;
      }

      this.$useDataStore().updateLayout({ duid: this.layout.duid, [field]: value });
    },
    async setCurrentPage(page: Page) {
      const dataStore = this.$useDataStore();
      const userStore = this.$useUserStore();

      const newPageKind = page.pageKind;
      const newPageDuid = page.duid;
      if (
        this.currentPageConfig &&
        this.currentPageConfig.kind === newPageKind &&
        this.currentPageConfig.duid === newPageDuid
      ) {
        return;
      }

      if (!this.$usePageStore().isPublic) {
        const lastUrlPath = this.$router.currentRoute.value.path;
        if (lastUrlPath !== userStore.lastUrlPath) {
          userStore.lastUrlPath = lastUrlPath;
          this.$backendOld.profile.edit("lastUrlPath", lastUrlPath);
        }
      }

      this.currentPageConfig = { kind: newPageKind, duid: newPageDuid };

      this.hoverRow = null;
      this.updateSelectionInCommandBar(false);
      this.selectedTaskDuids = new Set();

      // clear the search when navigating away from a search view
      if (page.kind !== ViewKind.SEARCH) {
        this.setSearch(null);
      }

      try {
        this.getActiveVisualization().deselectAll();
      } catch (error) {
        // ignore
      }
      dataStore.clearHistoryStack();

      const existingLayout =
        newPageKind === PageKind.VIEW || newPageKind === PageKind.DASHBOARD
          ? dataStore.getLayoutByDuid(page.layoutDuid)
          : dataStore.getLayoutByDartboardDuid(newPageDuid);

      if (existingLayout) {
        this.layoutDuid = existingLayout.duid;
        return;
      }

      const newLayout = await dataStore.createLayoutForDartboard(newPageDuid, this.defaultFilters);

      this.layoutDuid = newLayout?.duid;
    },
    setLayoutKind(kind: LayoutKind) {
      if (!this.layout) {
        return;
      }

      this.layout.kind = kind;
      this._updateLayoutField("kind", this.layout.kind);

      this.selectedTaskDuids = new Set();
      this.list?.api?.deselectAll();
    },
    setPropertyDuidVisibility(propertyDuid: string, shown: boolean, propertyOrderDuids?: string[]) {
      if (
        !this.currentPage ||
        !(this.currentPage.pageKind === PageKind.DARTBOARD || this.currentPage.pageKind === PageKind.VIEW)
      ) {
        return;
      }

      const dataStore = this.$useDataStore();
      const update: DartboardUpdate | ViewUpdate = {
        duid: this.currentPage.duid,
      };

      if (shown) {
        if (propertyOrderDuids) {
          update.propertyOrderDuids = propertyOrderDuids;
        }
        if (!this.alwaysShownPropertyDuids.includes(propertyDuid)) {
          update.alwaysShownPropertyDuids = [...this.currentPage.alwaysShownPropertyDuids, propertyDuid];
        }
        if (this.currentPage.alwaysHiddenPropertyDuids.includes(propertyDuid)) {
          update.alwaysHiddenPropertyDuids = this.currentPage.alwaysHiddenPropertyDuids.filter(
            (e) => e !== propertyDuid
          );
        }
      } else {
        if (!this.currentPage.alwaysHiddenPropertyDuids.includes(propertyDuid)) {
          update.alwaysHiddenPropertyDuids = [...this.currentPage.alwaysHiddenPropertyDuids, propertyDuid];
        }
        if (this.currentPage.alwaysShownPropertyDuids.includes(propertyDuid)) {
          update.alwaysShownPropertyDuids = this.currentPage.alwaysShownPropertyDuids.filter((e) => e !== propertyDuid);
        }
      }

      if (Object.keys(update).length === 1) {
        return;
      }

      dataStore.updatePage(update, this.currentPage.pageKind);
    },
    setPropertyOrderDuids(orderDuids: string[]) {
      if (
        !this.currentPage ||
        !(this.currentPage.pageKind === PageKind.DARTBOARD || this.currentPage.pageKind === PageKind.VIEW)
      ) {
        return;
      }

      const dataStore = this.$useDataStore();
      const update: DartboardUpdate | ViewUpdate = {
        duid: this.currentPage.duid,
        propertyOrderDuids: orderDuids,
      };

      dataStore.updatePage(update, this.currentPage.pageKind);
    },
    setShowAbsentees(showAbsentees: boolean) {
      if (!this.layout) {
        return;
      }

      this.layoutConfig.showAbsentees = showAbsentees;

      this._updateLayoutField("kindConfigMap", this.layout.kindConfigMap);
    },
    setGroupBy(groupBy: string) {
      if (!this.layout || !GROUP_BY_LAYOUT_KINDS.has(this.layoutKind)) {
        return;
      }

      const layoutConfig = this.layoutConfig as LayoutConfigList | LayoutConfigBoard;
      layoutConfig.groupBy = groupBy;
      layoutConfig.collapsedGroups = [];

      this._updateLayoutField("kindConfigMap", this.layout.kindConfigMap);
    },
    setHoverGroupId(groupId: string | null) {
      this.hoverGroupId = groupId;
    },
    setHideEmptyGroups(hideEmptyGroups: boolean) {
      if (!this.layout || !GROUP_BY_LAYOUT_KINDS.has(this.layoutKind)) {
        return;
      }

      const layoutConfig = this.layoutConfig as LayoutConfigList | LayoutConfigBoard;
      layoutConfig.hideEmptyGroups = hideEmptyGroups;

      this._updateLayoutField("kindConfigMap", this.layout.kindConfigMap);
    },
    setColorBy(colorBy: string) {
      if (!this.layout || !COLOR_BY_LAYOUT_KINDS.has(this.layoutKind)) {
        return;
      }

      const layoutConfig = this.layoutConfig as LayoutConfigRoadmap | LayoutConfigCalendar;
      layoutConfig.colorBy = colorBy;

      this._updateLayoutField("kindConfigMap", this.layout.kindConfigMap);
    },
    resetFilters() {
      if (!this.layout) {
        return;
      }

      this.layout.filterGroup.filters = deepCopy(this.defaultFilters);
      this._updateLayoutField("filterGroup", this.layout.filterGroup);
    },
    setFilter(filter: Filter) {
      if (!this.layout) {
        return;
      }

      if (filter.propertyDuid !== AI_PROPERTY_PSUEDO_DUID) {
        this.removeFilter(AI_PROPERTY_PSUEDO_DUID);
      }

      const index = this.layout.filterGroup.filters.findIndex((e) => e.propertyDuid === filter.propertyDuid);
      this.layout.filterGroup.filters[index === -1 ? this.layout.filterGroup.filters.length : index] = filter;
      this._updateLayoutField("filterGroup", this.layout.filterGroup);
    },
    setAllFilters(filters: Filter[]) {
      if (!this.layout) {
        return;
      }

      this.layout.filterGroup.filters = filters;
      this._updateLayoutField("filterGroup", this.layout.filterGroup);
    },
    removeFilter(propertyDuid: string) {
      if (!this.layout) {
        return;
      }

      const index = this.layout.filterGroup.filters.findIndex((e) => e.propertyDuid === propertyDuid);
      if (index === -1) {
        return;
      }
      this.layout.filterGroup.filters.splice(index, 1);
      this._updateLayoutField("filterGroup", this.layout.filterGroup);
    },
    setAllSorts(sorts: Sort[]) {
      if (!this.layout || equal(sorts, this.layout.sorts, { strict: true })) {
        return;
      }

      this.layout.sorts = sorts;
      this._updateLayoutField("sorts", this.layout.sorts);
    },
    resetSorts() {
      this.setAllSorts([]);
    },
    setGroupCollapsed(column: string, collapsed: boolean) {
      if (!this.layout || !GROUP_BY_LAYOUT_KINDS.has(this.layoutKind)) {
        return;
      }

      let collapsedGroups = deepCopy(this.collapsedGroups);
      if (collapsed) {
        collapsedGroups.push(column);
      } else {
        collapsedGroups = collapsedGroups.filter((e) => e !== column);
      }
      (this.layoutConfig as LayoutConfigList | LayoutConfigBoard).collapsedGroups = collapsedGroups;

      this._updateLayoutField("kindConfigMap", this.layout.kindConfigMap);
    },
    getColumnWidthParams(propertyDuid: string): {
      width?: number;
      flex?: number;
      minWidth?: number;
      maxWidth?: number;
    } {
      const dataStore = this.$useDataStore();
      const pageStore = this.$usePageStore();

      if (
        !this.currentPage ||
        !(this.currentPage.pageKind === PageKind.DARTBOARD || this.currentPage.pageKind === PageKind.VIEW)
      ) {
        return {};
      }

      const propertyKind = dataStore.getPropertyByDuid(propertyDuid)?.kind ?? propertyDuid;
      if (pageStore.isMobile) {
        if (propertyKind === PropertyKind.DEFAULT_TITLE) {
          return { flex: 1 };
        }
        if (propertyKind === PropertyKind.DEFAULT_STATUS) {
          return { width: STATUS_COLUMN_MOBILE_WIDTH };
        }
        if (propertyKind === PropertyKind.DEFAULT_ASSIGNEES) {
          return { width: ASSIGNEES_COLUMN_MOBILE_WIDTH };
        }
        return {};
      }

      if (propertyDuid === CONFIG_PROPERTY_PSUEDO_DUID) {
        return {
          flex: 1,
          minWidth: 28,
        };
      }

      const width =
        this.currentPage.propertyWidthMap[propertyDuid] ?? PROPERTY_KIND_TO_DEFAULT_WIDTH_MAP.get(propertyKind);
      if (propertyKind === PropertyKind.DEFAULT_TITLE) {
        const containerWidth =
          this.layoutKind === LayoutKind.ROADMAP ? this.roadmapTaskListWidthPx : (this.list?.width ?? 1000);
        return {
          width,
          minWidth: TITLE_COLUMN_MIN_WIDTH,
          maxWidth: Math.max(containerWidth * 0.66, TITLE_COLUMN_MAX_MIN_WIDTH),
        };
      }

      return {
        width,
        ...DEFAULT_COLUMN_WIDTH_LIMITS,
      };
    },
    setSubtaskDisplayMode(subtaskDisplayMode: SubtaskDisplayMode) {
      if (!this.layout) {
        return;
      }

      this.layoutConfig.subtaskDisplayMode = subtaskDisplayMode;
      this._updateLayoutField("kindConfigMap", this.layout.kindConfigMap);

      if (subtaskDisplayMode === SubtaskDisplayMode.FLAT) {
        return;
      }
      const newExpanded = subtaskDisplayMode === SubtaskDisplayMode.INDENTED;
      this.$useDataStore().updateTasks(
        this.filteredAndSortedTasksInPage.map((e) => ({
          duid: e.duid,
          expanded: newExpanded,
        }))
      );
    },
    setRoadmapZoom(pxPerDay: number) {
      if (!this.layout || this.layout.kind !== LayoutKind.ROADMAP) {
        return;
      }

      (this.layoutConfig as LayoutConfigRoadmap).pxPerDay = pxPerDay;

      this._updateLayoutField("kindConfigMap", this.layout.kindConfigMap);
    },
    setRoadmapTaskListWidthPx(taskListWidthPx: number) {
      if (!this.layout || this.layout.kind !== LayoutKind.ROADMAP) {
        return;
      }

      (this.layoutConfig as LayoutConfigRoadmap).taskListWidthPx = taskListWidthPx;

      this._updateLayoutField("kindConfigMap", this.layout.kindConfigMap);
    },
    setCalendarDisplayPeriod(type: CalendarDisplayPeriod) {
      if (!this.layout || this.layout.kind !== LayoutKind.CALENDAR) {
        return;
      }

      (this.layoutConfig as LayoutConfigCalendar).displayPeriod = type;

      this._updateLayoutField("kindConfigMap", this.layout.kindConfigMap);
    },
    setCalendarDisplayPeriodCount(count: number) {
      if (!this.layout || this.layout.kind !== LayoutKind.CALENDAR) {
        return;
      }

      (this.layoutConfig as LayoutConfigCalendar).displayPeriodCount = count;

      this._updateLayoutField("kindConfigMap", this.layout.kindConfigMap);
    },
    setSummaryStatisticKind(summaryStatisticKind: SummaryStatisticKind) {
      if (!this.layout) {
        return;
      }

      this.layout.summaryStatisticKind = summaryStatisticKind;
      this._updateLayoutField("summaryStatisticKind", this.layout.summaryStatisticKind);
    },
    startLoadingAiTaskProperties(taskDuids: string[], propertyDuid: string) {
      taskDuids.forEach((e) => this.loadingTaskProperties.add(makeLoadingAiTaskPropertyKey(e, propertyDuid)));
    },
    finishLoadingAiTaskProperties(taskDuids: string[], propertyDuid: string) {
      taskDuids.forEach((e) => this.loadingTaskProperties.delete(makeLoadingAiTaskPropertyKey(e, propertyDuid)));
    },
    setSearch(search: string | null) {
      this.search = search;
    },
    setCreateTaskCreateMore(newCreateTaskCreateMore: boolean) {
      this.createTaskCreateMore = newCreateTaskCreateMore;
      lsSet(CREATE_TASK_CREATE_MORE_ON_KEY, newCreateTaskCreateMore.toString());
    },
    // modals
    _ensureAllModalsClosedExcept(modal?: ModalKind) {
      if (modal !== ModalKind.APP_DOWNLOAD) {
        this.setAppDownloadModalOpen(false);
      }
      if (modal !== ModalKind.BRAINSTORM) {
        this.setBrainstormModalOpen(false);
      }
      if (modal !== ModalKind.COMMAND_CENTER) {
        this.setCommandCenterOpen(false);
      }
      if (modal !== ModalKind.AGENT_DEMO) {
        this.setAgentDemoModalOpen(false);
      }
      if (modal !== ModalKind.CREATE_TASK && modal !== ModalKind.LINK && modal !== ModalKind.REMAP_MODAL) {
        this.setTcmOpen(false);
      }
      if (modal !== ModalKind.FEEDBACK) {
        this.setFeedbackModalOpen(false);
      }
      if (modal !== ModalKind.INTERNAL_FORM) {
        this.setFormOpenInInternalModal(null);
      }
      if (modal !== ModalKind.FULLSCREEN_MEDIA) {
        this.setFullscreenMediaModalOpen(null);
      }
      if (modal !== ModalKind.LINK) {
        this.setLinkOpenInModal(null);
      }
      if (modal !== ModalKind.MOBILE_LEFTBAR) {
        this.setMobileLeftbarOpen(false);
      }
      if (modal !== ModalKind.NOTION_LINK) {
        this.setTaskInNotionLinkModalDuid(null);
      }
      if (modal !== ModalKind.PAGE_PERMISSIONS) {
        this.setPageInPermissionsModal(null);
      }
      if (modal !== ModalKind.PLAN_PROJECT) {
        this.setPlanProjectModalOpen(false);
      }
      if (modal !== ModalKind.REMINDER) {
        this.setTasksOpenInReminderModal([]);
      }
      if (modal !== ModalKind.REPORT_CREATION) {
        this.setSpaceOpenInReportCreationModal(null);
      }
      if (modal !== ModalKind.REMAP_MODAL) {
        this.setRemapModal(null);
      }
      if (modal !== ModalKind.SEARCH) {
        this.setSearchModalOpen(false);
      }
      if (modal !== ModalKind.SETTINGS) {
        this.setSettingsModalOpen(false);
      }
      if (modal !== ModalKind.SHORTCUTS) {
        this.setShortcutsModalOpen(false);
      }
      if (modal !== ModalKind.SWITCH_TO_DESKTOP) {
        this.setSwitchToDesktopModalOpen(false);
      }
    },
    updateSelectionInCommandBar(hasSelection?: boolean) {
      const appStore = useAppStore();

      window.CommandBar.addMetadata("hasSelection", appStore.tcmOpen || hasSelection);
    },
    setCommandCenterOpen(open: boolean) {
      if (!open) {
        window.CommandBar.close();
      }
    },
    openContextMenu(
      event: MouseEvent,
      sections: DropdownMenuSection[],
      options: {
        widthPixels?: number;
        maxHeightPixels?: number;
      } = {}
    ) {
      const { widthPixels, maxHeightPixels } = options;

      const target = event?.target as HTMLElement;

      if (event && target) {
        event.preventDefault();
        event.stopPropagation();
        this.contextMenu = {
          node: target,
          sections: markRaw(sections),
          offsetX: event.offsetX,
          offsetY: event.offsetY,
          widthPixels,
          maxHeightPixels,
        };
      }
    },
    openTaskPreviewTooltip(target: Element, scrollableParent: Element, task: Task, placement?: Placement) {
      this.taskPreviewTooltip = {
        node: target,
        scrollableParent,
        task: markRaw(task),
        placement,
      };
    },
    showFeedbackTooltip(node: Element | null, recommendationDuids: string[]) {
      if (!node) {
        this.feedbackTooltip = null;
        return;
      }

      this.feedbackTooltip = {
        node,
        recommendationDuids,
      };
    },
    setSearchModalOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.SEARCH);
      }
      this.searchModalOpen = open;
    },
    setSettingsModalOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.SETTINGS);
      } else if (this.settingsModalOpen) {
        // eslint-disable-next-line no-restricted-syntax
        setTimeout(() => {
          this.$router.push(this.$routerUtils.makeLinkToSettingsRef(undefined).value);
        }, 300);
      }
      this.settingsModalOpen = open;
    },
    setTcmOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.CREATE_TASK);
      }
      this.tcmOpen = open;

      this.updateSelectionInCommandBar();
    },
    setFormOpenInInternalModal(form: Form | null) {
      if (form) {
        this._ensureAllModalsClosedExcept(ModalKind.INTERNAL_FORM);
      }
      this.formInInternalModalDuid = form?.duid ?? null;
    },
    setUpgradeConfirmationModalOpen(action: string | null) {
      this.upgradeModalAction = action;
    },
    setDeleteConfirmationModalOpen(config: { title: string; description: string; onDelete: () => void } | null) {
      this.deleteModalConfig = config;
    },
    setAppDownloadModalOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.APP_DOWNLOAD);
      }
      this.appDownloadModalOpen = open;
    },
    setAgentDemoModalOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.AGENT_DEMO);
      }
      this.agentDemoModalOpen = open;
    },
    setBrainstormModalOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.BRAINSTORM);
      }
      this.brainstormModalOpen = open;
    },
    setFeedbackModalOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.FEEDBACK);
      }
      this.feedbackModalOpen = open;
    },
    setFullscreenMediaModalOpen(
      mediaOpenInFullscreen: {
        attachmentFileUrl: string;
        attachmentName: string;
        isImage: boolean;
        entityName: string;
      } | null
    ) {
      if (mediaOpenInFullscreen) {
        this._ensureAllModalsClosedExcept(ModalKind.FULLSCREEN_MEDIA);
        this.mediaOpenInFullscreen = this.mediaOpenInFullscreen
          ? Object.assign(this.mediaOpenInFullscreen, mediaOpenInFullscreen)
          : mediaOpenInFullscreen;
      } else {
        this.mediaOpenInFullscreen = null;
      }
    },
    setShortcutsModalOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.SHORTCUTS);
      }
      this.shortcutsModalOpen = open;
    },
    setSwitchToDesktopModalOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.SWITCH_TO_DESKTOP);
      }
      this.switchToDesktopModalOpen = open;
    },
    setPlanProjectModalOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.PLAN_PROJECT);
      }
      this.planProjectModalOpen = open;
    },
    setTasksOpenInReminderModal(tasks: Task[]) {
      if (tasks.length !== 0) {
        this._ensureAllModalsClosedExcept(ModalKind.REMINDER);
      }
      this.tasksOpenInReminderModalDuids = tasks.map((e) => e.duid);
    },
    setPageInPermissionsModal(page: PageWithPermissions | null) {
      if (page) {
        this._ensureAllModalsClosedExcept(ModalKind.PAGE_PERMISSIONS);
      }
      this.pageInPermissionsModalConfig = page ? { kind: page.pageKind, duid: page.duid } : null;
    },
    setLinkOpenInModal(link: TaskLinkInModal | null) {
      if (link) {
        this._ensureAllModalsClosedExcept(ModalKind.LINK);
      }

      this.linkOpenInModalConfig = link ? { taskDuid: link.taskDuid, linkDuid: link.link?.duid } : null;
    },
    setTaskInNotionLinkModalDuid(task: Task | null) {
      if (task && !!task.notionDocument) {
        this._ensureAllModalsClosedExcept(ModalKind.NOTION_LINK);
      }
      this.taskInNotionLinkModalDuid = task?.duid ?? null;
    },
    setSpaceOpenInReportCreationModal(space: Space | null, reportKind?: ReportKind) {
      if (space) {
        this._ensureAllModalsClosedExcept(ModalKind.REPORT_CREATION);
      }
      this.spaceOpenInReportCreationModalConfig = space
        ? { spaceDuid: space.duid, reportKind: reportKind ?? ReportKind.STANDUP }
        : null;
    },
    setOnboardingModalOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.ONBOARDING);
      } else if (this.onboardingModalOpen) {
        this.setTrialStartedSuppressed(true);
      }
      this.onboardingModalOpen = open;
    },
    setTaskDetailOpen(open: boolean, mode?: TaskDetailMode, noLog?: boolean) {
      // Always open the rightbar on mobile
      const modeNorm = this.$usePageStore().isMobile
        ? TaskDetailMode.RIGHTBAR
        : (mode ?? this.$useUserStore().taskDetailMode);
      if (
        (!open && !this.taskDetailOpen) ||
        (open && this.taskDetailOpen && (mode === undefined || modeNorm === this.taskDetailMode))
      ) {
        return;
      }
      const eventKind = TASK_DETAIL_MODE_TO_EVENT_KIND_MAP[modeNorm];

      // If it's open and staying open but just switch modes, close and then open to give components (particularly descriptions) a chance to reset
      const wasOpen = this.taskDetailOpen;
      if (!open || wasOpen) {
        this.taskDetailOpen = false;
        this.taskDetailMode = null;
      }
      if (open) {
        const setOpen = () => {
          this.taskDetailOpen = true;
          this.taskDetailMode = modeNorm;
        };
        if (!wasOpen) {
          setOpen();
        } else {
          nextTick(setOpen);
        }
      }

      if (!this.$usePageStore().isPublicView && !noLog) {
        const taskDuid = this.taskOpenInDetail?.duid;
        this.$backend.event.create(eventKind, taskDuid ? { open: true, taskDuid } : { open: false });
      }
    },
    setMobileLeftbarOpen(open: boolean) {
      if (open) {
        this._ensureAllModalsClosedExcept(ModalKind.MOBILE_LEFTBAR);
      }
      this.mobileLeftbarOpen = open;
    },
    setDocOpenInFullscreen(doc: Doc | null) {
      this.docOpenInFullscreenDuid = doc?.duid ?? null;
    },
    setTrialStartedSuppressed(newTrialStartedSuppressed: boolean) {
      this.trialStartedSuppressed = newTrialStartedSuppressed;
    },
    setRemapModal(remap: RemapModalConfig | null) {
      if (remap) {
        this._ensureAllModalsClosedExcept(ModalKind.REMAP_MODAL);
      }
      this.remapModal = remap;
    },
  },
});

export default useAppStore;
