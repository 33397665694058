<script setup lang="ts" generic="Item extends ItemBase, T extends Constructable">
import { useMouse, useMousePressed, useWindowFocus } from "@vueuse/core";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { DynamicScroller } from "vue-virtual-scroller";

import { colorsByTheme } from "~/constants/style";
import type { Constructable } from "~/shared/typeUtils";
import { useAppStore, usePageStore } from "~/stores";
import { getAncestorDuids } from "~/utils/common";
import { makeListComparatorOrganizedBySize, numberComparator } from "~/utils/comparator";
import { getOrdersBetween } from "~/utils/orderManager";

import DragItem from "./DragItem.vue";
import useDragStore from "./DragStore";
import { type DynamicScrollerType, type ItemBase } from "./shared";

const DEFAULT_CATEGORY = "default";
const PX_PER_INDENT_LEVEL = 30;

const props = withDefaults(
  defineProps<{
    items: Item[];
    component: T;
    getComponentProps: (item: Item, index: number) => InstanceType<T>["$props"];
    minItemSize: number;
    // Group of areas. Items can be dragged between areas with the same group name.
    group: string;
    // Category of items. Used to identify the area.
    category?: string;
    disabled?: boolean;
    disableParentChange?: boolean;
    // Color for the element in the preview position.
    placeholderColor?: string;
    // Classes for the drop area.
    dropAreaClasses?: string;
    gap?: number;
    subitemGap?: number;
    itemSidePadding?: number;
  }>(),
  {
    category: DEFAULT_CATEGORY,
    disabled: false,
    disableParentChange: false,
    placeholderColor: undefined,
    dropAreaClasses: undefined,
    gap: 10,
    subitemGap: 5,
    itemSidePadding: 0,
  }
);

const emit = defineEmits<{
  change: [category: string, items: Item[]];
  click: [event: MouseEvent];
  keydown: [event: KeyboardEvent];
}>();

const appStore = useAppStore();
const dragStore = useDragStore();
const pageStore = usePageStore();

const wrapper = ref<DynamicScrollerType | null>(null);

const draggingItems = computed(() => dragStore.getDraggingItemsForCategory<Item>(props.group, props.category));
const showPlaceholders = computed(() => dragStore.showPlaceholdersForCategory(props.group, props.category));
const isDraggingGroup = computed(() => dragStore.getDraggingItemsForGroup<Item>(props.group).length > 0);
const innerItems = computed(() =>
  showPlaceholders.value ? props.items : dragStore.getListItems<Item>(props.group, props.category)
);

const disabledNorm = computed(() => props.disabled || pageStore.isMobile || pageStore.isPublicView);
const placeholderColor = computed(() => props.placeholderColor ?? colorsByTheme[pageStore.theme].bgLt);

// Item subtask order map
const fullOrderMap = computed(() => {
  const items = [...innerItems.value];
  const existingOrderMap = new Map(items.map((e, i) => [e.duid, i]));
  const ancestorMap = new Map(items.map((e) => [e.duid, getAncestorDuids(items, e)]));
  return new Map(
    items.map((task) => {
      const orders = (ancestorMap.get(task.duid) ?? []).map((f) => existingOrderMap.get(f) ?? 0);
      orders.push(existingOrderMap.get(task.duid) ?? 0);
      return [task.duid, orders];
    })
  );
});

// Flatten items and sort by indentation
const flatItems = computed(() => {
  const items = [...innerItems.value];
  const fullOrderComparator = makeListComparatorOrganizedBySize(numberComparator);
  items.sort((a, b) => {
    const aFullOrder = fullOrderMap.value.get(a.duid) ?? [];
    const bFullOrder = fullOrderMap.value.get(b.duid) ?? [];
    return fullOrderComparator(aFullOrder, bFullOrder);
  });
  return items;
});

// Sync internal items with the parent items
watch(
  [() => props.items, () => props.group, () => props.category],
  ([newItems, newGroup, newCategory]) => {
    dragStore.setListItems(newGroup, newCategory ?? DEFAULT_CATEGORY, newItems);
  },
  { immediate: true }
);

// Reset the inner list when called
const resetInnerList = () => {
  dragStore.setListItems(props.group, props.category, props.items);
};
onMounted(() => {
  dragStore.registerResetEmitter(props.group, resetInnerList);
});
onUnmounted(() => {
  dragStore.unregisterResetEmitter(props.group, resetInnerList);
});

// Save the items when dragging ends or on drop
const getItemOrder = (list: Item[], newIndex: number) => {
  const itemAboveDest = newIndex === 0 ? undefined : list[newIndex - 1]?.order;
  const itemBelowDest = newIndex < list.length - 1 ? list[newIndex + 1]?.order : undefined;

  return getOrdersBetween(itemAboveDest, itemBelowDest)[0];
};
watch(draggingItems, (newItems, oldItems) => {
  if (!isDraggingGroup.value && oldItems.length > 0 && newItems.length === 0) {
    // Check if item has been moved externally
    const currentItemDuids = new Set(innerItems.value.map((e) => e.duid));
    const items = oldItems
      .filter((e) => currentItemDuids.has(e.duid))
      .map((e) => {
        const index = innerItems.value.findIndex((innerItem) => innerItem.duid === e.duid);
        const order = getItemOrder(innerItems.value, index);
        return { ...e, order };
      });

    // Emit change if items have changed
    if (dragStore._dirty && items.length) {
      emit("change", props.category, items);

      // The change might not get applied, so we reset the entire group, and let the parent handle the change
      dragStore.resetGroup(props.group);
    }
  }
});

const setDraggedItemsParentDuid = (parentDuid: string | undefined) => {
  if (props.disableParentChange) {
    return;
  }
  dragStore.setDraggedItemsParentDuid(parentDuid);
};

// Move the dragged items to the target index
const onDragEnter = (e: DragEvent, item: Item) => {
  e.preventDefault();
  if (!isDraggingGroup.value) {
    return;
  }

  const targetIndex = innerItems.value.findIndex((innerItem) => innerItem.duid === item.duid);
  if (targetIndex === -1) {
    return;
  }

  setDraggedItemsParentDuid(item.parentDuid);
  dragStore.moveDraggedItemsToIndex(props.group, props.category, targetIndex);
};

const { x: mouseX } = useMouse();
const initialMouseX = ref<{ duid: string; x: number } | null>(null);
const onDragOver = (e: DragEvent, item: Item) => {
  e.preventDefault();
  if (!isDraggingGroup.value) {
    return;
  }

  if (initialMouseX.value === null || initialMouseX.value.duid !== item.duid) {
    initialMouseX.value = { duid: item.duid, x: mouseX.value };
  }

  // If enough to the left or right, change the indentation
  if (mouseX.value < initialMouseX.value.x - PX_PER_INDENT_LEVEL) {
    const parentItem = innerItems.value.find((innerItem) => innerItem.duid === item.parentDuid);
    setDraggedItemsParentDuid(parentItem ? parentItem.parentDuid : item.parentDuid);
    initialMouseX.value.x = mouseX.value;
  } else if (mouseX.value > initialMouseX.value.x + PX_PER_INDENT_LEVEL) {
    const index = innerItems.value.findIndex((innerItem) => innerItem.duid === item.duid);
    const itemAbove = index > 0 ? innerItems.value[index - 1] : undefined;
    let newParentDuid = item.parentDuid;
    if (itemAbove) {
      // Move by one level
      const ancestorDuids = getAncestorDuids(innerItems.value, itemAbove);
      const currentAncestorLevel = getAncestorDuids(innerItems.value, item).length;
      if (currentAncestorLevel < ancestorDuids.length) {
        newParentDuid = ancestorDuids[currentAncestorLevel];
      } else if (currentAncestorLevel === ancestorDuids.length) {
        newParentDuid = itemAbove.duid;
      }
    }

    setDraggedItemsParentDuid(newParentDuid);
    initialMouseX.value.x = mouseX.value;
  }
};

// Move the dragged items to the end of the list
const onDragOverDropzone = (e: DragEvent) => {
  e.preventDefault();
  if (!isDraggingGroup.value) {
    return;
  }

  // Don't allow dragging on dropzone if same category
  if (dragStore._draggingItems?.category === props.category) {
    return;
  }

  const targetIndex = innerItems.value.length;
  dragStore.moveDraggedItemsToIndex(props.group, props.category, targetIndex);
};

const onDragEnd = () => {
  document.body.classList.remove("dragging-item");
  if (!isDraggingGroup.value) {
    return;
  }

  dragStore.clearDraggingItems();
  appStore.dragging = null;
  initialMouseX.value = null;
};

// Stop dragging when the mouse is released, or mouse left the page, because the dragend event doesn't fire if DOM element is removed
const { pressed } = useMousePressed({ target: wrapper.value?.$el });
watch(pressed, (newPressed, oldPressed) => {
  if (isDraggingGroup.value && !newPressed && oldPressed) {
    onDragEnd();
  }
});
const isOutsidePage = useWindowFocus();
watch(isOutsidePage, (isOutside) => {
  if (isDraggingGroup.value && isOutside) {
    onDragEnd();
  }
});

// Start dragging the item
const startDrag = (item: Item) => {
  // Set the dragged item
  dragStore.setDraggingItems(props.group, props.category, [item]);
  appStore.dragging = { group: props.group, category: props.category };
};

// Max height that won't cause overflow
const dropzoneHeight = computed(() => {
  if (!wrapper.value) {
    return 0;
  }
  const offsetParentHeight = wrapper.value.$el.offsetParent?.clientHeight ?? 0;
  const totalHeight = wrapper.value.itemsWithSize.reduce((acc, e) => acc + e.size, 0);
  // TODO Better calculate the height when we reuse the component, it depends on padding and offset
  return Math.max(wrapper.value.minItemSize, offsetParentHeight - totalHeight - 107);
});

// https://github.com/Akryum/vue-virtual-scroller/issues/509#issuecomment-2231249514
// https://github.com/Akryum/vue-virtual-scroller/blob/master/packages/vue-virtual-scroller/src/components/RecycleScroller.vue#L611
onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const element = wrapper.value as unknown as any;
  if (element && element.$refs.scroller) {
    element.$refs.scroller.sortViews = () => {};
  }
});

const itemRefs = new Map<string, InstanceType<T>>();
const assignItemRef = (itemDuid: string, e: { wrapper: HTMLDivElement; itemRef: InstanceType<T> } | null) => {
  if (!e) {
    return;
  }
  itemRefs.set(itemDuid, e.itemRef);
};

defineExpose({
  itemRefs,
});
</script>

<template>
  <DynamicScroller
    ref="wrapper"
    :items="flatItems"
    :min-item-size="minItemSize + Math.min(subitemGap, gap)"
    key-field="duid"
    class="relative h-full flex-1 overflow-y-auto overflow-x-hidden"
    :class="[isDraggingGroup && (dropAreaClasses ? dropAreaClasses : 'dart-drag-drop-area')]"
    @click="emit('click', $event)"
    @keydown="emit('keydown', $event)"
    @dragover.prevent
    @drop.prevent>
    <template #before>
      <slot v-if="innerItems.length && !isDraggingGroup" name="before" />
      <!-- No items state -->
      <div
        v-if="!innerItems.length && !isDraggingGroup"
        :id="`${group}-${category}-noitems`"
        class="hidden select-none only:block">
        <slot />
      </div>
    </template>
    <template #default="{ item, active, index }">
      <DragItem
        :ref="(elem) => assignItemRef(item.duid, elem as never)"
        :key="item.duid"
        :index="index ?? 0"
        :active-in-scroll="active"
        :level="fullOrderMap.get(item.duid)?.length ?? 1"
        :item="item"
        :group="group"
        :category="category"
        :component="props.component"
        :get-component-props="props.getComponentProps"
        :placeholder-color="placeholderColor"
        :disabled="disabledNorm || !!item.disabled"
        :gap="props.gap"
        :subitem-gap="props.subitemGap"
        :item-side-padding="props.itemSidePadding"
        @drag-start="() => startDrag(item)"
        @drag-end="onDragEnd"
        @drag-over="(e) => onDragOver(e, item)"
        @drag-enter="(e) => onDragEnter(e, item)" />
    </template>
    <template #after>
      <div
        v-if="isDraggingGroup"
        id="dropzone"
        class="size-full"
        :style="{ minHeight: `${dropzoneHeight}px` }"
        @dragenter="onDragOverDropzone" />
      <slot v-if="innerItems.length && !isDraggingGroup" name="after" />
    </template>
  </DynamicScroller>
</template>

<style scoped>
/* Drop area styles */
.dart-drag-drop-area {
  @apply rounded !bg-gray-100/50 dark:!bg-zinc-800/50;
}
</style>
