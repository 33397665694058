import { type MultiWatchSources, onWatcherCleanup, ref, shallowRef, watch, type WatchSource } from "vue";

/** Creates a reactive async state handler with watching. */
export const asyncState = <T>(
  promise: () => Promise<T> | { response: Promise<T>; cancel: () => void },
  options: {
    /* The initial state of the reactive state */
    default: T;
    /* The source to watch for re-execution */
    watch: WatchSource | MultiWatchSources;
    /* Whether to keep the old state when re-executing */
    preserveState?: boolean;
    /* Callback when error is caught */
    onError?: (error: unknown) => void;
  }
) => {
  const state = shallowRef<T>(options.default);
  const isLoading = ref<boolean>(false);

  const resetState = () => {
    if (!options.preserveState) {
      state.value = options.default;
    }
  };

  const execute = async () => {
    const result = promise();

    let executePromise: Promise<T>;
    if ("cancel" in result) {
      onWatcherCleanup(result.cancel);
      executePromise = result.response;
    } else {
      executePromise = result;
    }

    // Set loading state if promise is actually async
    // eslint-disable-next-line no-restricted-syntax
    const timeout = setTimeout(() => {
      resetState();
      isLoading.value = true;
    });

    try {
      state.value = await executePromise;
    } catch (error) {
      resetState();
      if (options.onError) {
        options.onError(error);
      }
    } finally {
      clearTimeout(timeout);
      isLoading.value = false;
    }
  };

  // Watch the source for changes
  watch(options.watch, execute);

  // Execute the promise immediately
  execute();

  return { state, isLoading };
};

/**
 * Filters an array using an async predicate function, executing in parallel while maintaining original order
 * @param array The input array to filter
 * @param predicate The async function to test each element
 * @returns A new filtered array with elements in their original order
 */
export const asyncFilter = async <T>(
  array: T[],
  predicate: (item: T, index: number) => Promise<boolean>
): Promise<T[]> => {
  const results = await Promise.all(
    array.map(async (item, index) => {
      const shouldKeep = await predicate(item, index);
      return [index, shouldKeep] as const;
    })
  );

  const indexToPass = new Map(results);
  return array.filter((_, index) => indexToPass.get(index));
};
