<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import actions from "~/actions";
import { ChevronDownIcon, ChevronUpIcon } from "~/icons";
import { PropertyKind } from "~/shared/enums";
import type { Filter, FilterDefinition, FilterValue } from "~/shared/types";
import { useDataStore } from "~/stores";
import { makeUuid } from "~/utils/common";

const props = defineProps<{
  filter: Filter;
  definition: FilterDefinition | undefined;
  enabled: boolean;
  readOnly?: boolean;
}>();

const emit = defineEmits<{
  select: [values: FilterValue[]];
  afterClose: [];
  removeFilter: [];
  replaceFilter: [];
}>();

const dataStore = useDataStore();

const input = ref<HTMLInputElement | null>(null);

const value = computed(() => (props.filter.values.length ? props.filter.values[0] : ""));

const property = computed(() => dataStore.getPropertyByDuid(props.filter.propertyDuid));
const isTimeTracking = computed(
  () =>
    property.value &&
    (property.value.kind === PropertyKind.DEFAULT_TIME_TRACKING || property.value.kind === PropertyKind.TIME_TRACKING)
);

const focus = () => {
  if (props.readOnly) {
    return;
  }
  actions.visualization.openKeyboardIfIos();
  input.value?.focus();
};

const change = (v: string) => {
  emit("select", [v]);
};

const increment = () => {
  change((parseInt((value.value ?? "0").toString(), 10) + 1).toString());
};

const decrement = () => {
  change((parseInt((value.value ?? "0").toString(), 10) - 1).toString());
};

onMounted(() => {
  if (value.value === "") {
    emit("select", [""]);
    focus();
  }
});

defineExpose({
  open: focus,
});

const id = ref(`filter-number-input-${makeUuid()}`);
</script>

<template>
  <div class="group/number-input relative">
    <label :for="id" class="sr-only">Filter number</label>
    <input
      :id="id"
      ref="input"
      v-auto-width="{ minWidthPx: isTimeTracking ? 9 : 20 }"
      type="number"
      :disabled="!enabled || filter.locked"
      :value="value"
      class="appearance-none truncate border-none bg-transparent px-0 pb-1 pt-0 text-sm font-normal text-md focus-ring-none"
      :class="isTimeTracking ? 'mr-[36px]' : 'mr-[11px]'"
      @blur="() => value === '' && emit('replaceFilter')"
      @input="(e) => change((e.target as HTMLInputElement).value)" />
    <div class="absolute inset-y-0 right-0 flex items-center">
      <span v-if="isTimeTracking" class="select-none pr-0.5 text-xs text-lt">min</span>
      <div
        class="flex-col justify-center opacity-0 text-md group-focus-within/number-input:opacity-100 group-hover/number-input:opacity-100">
        <div class="cursor-pointer rounded hover:bg-md" @click="increment" @keydown.enter="increment">
          <ChevronUpIcon class="size-3" />
        </div>
        <div class="cursor-pointer rounded hover:bg-md" @click="decrement" @keydown.enter="decrement">
          <ChevronDownIcon class="size-3" />
        </div>
      </div>
    </div>
  </div>
</template>
