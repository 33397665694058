import { capitalize, computed } from "vue";

import { CURRENT_USER_PSEUDO_USER_KEY, getFilterDefinitionList } from "~/common/filter";
import { getPropertyWithConfig, getPropertyWithConfigList } from "~/common/properties";
import {
  AI_PROPERTY_PSUEDO_DUID,
  COMMENTS_PROPERTY_PSUEDO_DUID,
  DOCS_PROPERTY_PSUEDO_DUID,
  LINKS_PROPERTY_PSUEDO_DUID,
  RELATIONSHIPS_PROPERTY_PSUEDO_DUID,
} from "~/constants/property";
import { filterHasApplicability, filterHasConnector } from "~/shared/common";
import { FilterApplicability, FilterConnector, NotificationType, PropertyKind, TaskSourceType } from "~/shared/enums";
import type { Filter, FilterValue, PropertyValueForKind, Task, TaskCreate } from "~/shared/types";
import { type DataStore } from "~/stores/DataStore";
import { getItemCountText, isLexicalStateEmpty, isTimeTracking } from "~/utils/common";

import { notify } from "../notifications";

const OVERRIDE_TASK_SOURCE_TYPES = [
  TaskSourceType.APP_QUICK_ADD,
  TaskSourceType.APP_ROADMAP_LIST,
  TaskSourceType.APP_ROADMAP_TIMELINE,
  TaskSourceType.APP_BOARD,
  TaskSourceType.APP_ENTER,
];

const normalizeValue = (dataStore: DataStore, value: FilterValue) => {
  const userStore = dataStore.$useUserStore();

  if (value === CURRENT_USER_PSEUDO_USER_KEY) {
    return userStore.duid;
  }

  return value;
};

const propertiesWithConfig = computed(() => getPropertyWithConfigList());
const definitions = computed(
  () => new Map(getFilterDefinitionList(propertiesWithConfig.value).map((e) => [e.propertyDuid, e]))
);

export const doesTaskPass = (dataStore: DataStore, task: Task, filters: Filter[]): boolean => {
  for (let i = 0; i < filters.length; i += 1) {
    const filter = filters[i];
    const definition = definitions.value.get(filter.propertyDuid);
    if (!definition) {
      return true;
    }

    if (filter.values?.length !== 0) {
      // Capture test result.
      const result = (() => {
        const everyMustMatch = filterHasConnector(filter) && filter.connector === FilterConnector.AND;
        const taskValue = definition.getValue(task);

        for (let j = 0; j < filter.values.length; j += 1) {
          const valueNorm = normalizeValue(dataStore, filter.values[j]);
          const testResult = definition.config.test(
            taskValue,
            valueNorm,
            task,
            filterHasApplicability(filter) ? filter.applicability : null
          );

          // If every value must match, none can't be false.
          if (everyMustMatch) {
            if (!testResult) {
              return false;
            }
            // If every value mustn't match, only one is enough to be true.
          } else if (testResult) {
            return true;
          }
        }
        // If every value must match, and none failed, then all passed. Else if every value mustn't match, and none passed, then all failed.
        return everyMustMatch;
      })();

      // If filter passed but is not applicable, return inverted result.
      // Note: This should never return true! That would skip other filters.
      if (
        filterHasApplicability(filter) &&
        [FilterApplicability.IS_NOT, FilterApplicability.DO_NOT_INCLUDE].includes(filter.applicability)
      ) {
        if (result === true) {
          return false;
        }
      } else if (result === false) {
        // If filter failed, return false.
        return false;
      }
    } else if (filter.propertyDuid === RELATIONSHIPS_PROPERTY_PSUEDO_DUID) {
      if (task.relationships.length === 0) {
        // Relationship filter is special case. Return false if no relationships.
        return false;
      }
    } else if (filter.propertyDuid === COMMENTS_PROPERTY_PSUEDO_DUID) {
      if (dataStore.getCommentsByTaskDuid(task.duid).filter((e) => !e.isDraft).length === 0) {
        // Comment filter is special case. Return false if no comments.
        return false;
      }
    } else if (filter.propertyDuid === LINKS_PROPERTY_PSUEDO_DUID) {
      if (task.links.length === 0) {
        // Links filter is special case. Return false if no links.
        return false;
      }
    } else if (filter.propertyDuid === AI_PROPERTY_PSUEDO_DUID) {
      // AI filter is special case. Return true always
      return true;
    } else if (filter.propertyDuid === DOCS_PROPERTY_PSUEDO_DUID) {
      if (
        // TODO incremental change this to dataStore
        Array.from(dataStore._taskDuidsToDocDuidsToRelationships.get(task.duid)?.keys() ?? []).map((duid) =>
          dataStore._duidsToDocs.get(duid)
        ).length === 0
      ) {
        // Docs filter is special case. Return false if no docs.
        return false;
      }
    } else if (filterHasApplicability(filter)) {
      const value = definition.getValue(task);
      switch (filter.applicability) {
        case FilterApplicability.ARE_NOT_SET:
        case FilterApplicability.IS_NOT_SET: {
          const { propertyDuid } = filter;
          const isDateStart = propertyDuid.endsWith("/@start");
          const isDateEnd = propertyDuid.endsWith("/@end");
          if ((isDateStart || isDateEnd) && Array.isArray(value) && value.length === 2) {
            if ((isDateStart && value[0] !== null) || (isDateEnd && value[1] !== null)) {
              return false;
            }
          } else if (
            !(
              value === null ||
              value === undefined ||
              (typeof value === "object" && Object.keys(value).length === 0) ||
              (Array.isArray(value) && value.length === 0)
            )
          ) {
            return false;
          }
          break;
        }
        case FilterApplicability.EXIST:
        case FilterApplicability.EXISTS: {
          if (
            value === null ||
            value === undefined ||
            (typeof value === "object" && Object.keys(value).length === 0) ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === "object" && "root" in value && isLexicalStateEmpty(value))
          ) {
            return false;
          }
          break;
        }
        case FilterApplicability.IS_CHECKED: {
          return value === true;
        }
        case FilterApplicability.IS_UNCHECKED: {
          return value === false;
        }
        default: {
          break;
        }
      }
    }
  }

  // All filters passed.
  return true;
};

export const overrideTaskToMatchFilters = (dataStore: DataStore, originalTask: Task): Task => {
  const appStore = dataStore.$useAppStore();

  const task: Task = { ...originalTask };

  appStore.filters.forEach((filter) => {
    /* If filter passes, don't need to do anything. */
    if (doesTaskPass(dataStore, task, [filter])) {
      return;
    }

    const filterDefinition = definitions.value.get(filter.propertyDuid);
    const propertyWithConfig = propertiesWithConfig.value.find(([p]) => p.duid === filter.propertyDuid);
    if (!filterDefinition || !propertyWithConfig) {
      return;
    }

    const filterValues = filter.values?.map((e) => normalizeValue(dataStore, e));
    const taskValue = filterDefinition.getValue(task);
    const [property, propertyConfig] = propertyWithConfig;
    const overrideTask = (overrideValue: PropertyValueForKind<typeof property>) => {
      Object.assign(task, propertyConfig.getPartialTask(property, task, overrideValue));
    };

    /* Override task to match filter if possible. */
    if (filterValues.length !== 0 && !isTimeTracking(taskValue)) {
      if (
        filterHasApplicability(filter) &&
        filter.applicability === FilterApplicability.DO_NOT_INCLUDE &&
        Array.isArray(taskValue)
      ) {
        /* Filter out values that don't match. */
        overrideTask(taskValue.filter((e) => !filterValues.includes(e)) as string[]);
      } else if (filterHasConnector(filter) && filter.connector === FilterConnector.AND) {
        /* Add values that are missing. */
        overrideTask(filterValues as string[]);
      } else {
        /* Set value to match filter. */
        overrideTask(filterValues[0]);
      }
    } else if (filterHasApplicability(filter)) {
      const value = filterDefinition.getValue(task);
      switch (filter.applicability) {
        case FilterApplicability.ARE_NOT_SET:
        case FilterApplicability.IS_NOT_SET: {
          const { propertyDuid } = filter;
          const isDateStart = propertyDuid.endsWith("/@start");
          const isDateEnd = propertyDuid.endsWith("/@end");
          if ((isDateStart || isDateEnd) && Array.isArray(value) && value.length === 2) {
            /* Set date to value. */
            overrideTask(isDateStart ? filterValues[0] : filterValues[1]);
          } else if (
            !(
              value === null ||
              value === undefined ||
              (typeof value === "object" && Object.keys(value).length === 0) ||
              (Array.isArray(value) && value.length !== 0)
            )
          ) {
            /* Set value to null or empty array. */
            overrideTask(Array.isArray(value) ? [] : null);
          } else if (property?.kind === PropertyKind.NUMBER && value !== null) {
            /* Set value to null for number. */
            overrideTask(null);
          }
          break;
        }
        case FilterApplicability.EXIST:
        case FilterApplicability.EXISTS: {
          if (
            value === null ||
            value === undefined ||
            (typeof value === "object" && Object.keys(value).length === 0) ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === "object" && "root" in value && isLexicalStateEmpty(value))
          ) {
            /* Set value to match filter. */
            overrideTask(value);
          }
          break;
        }
        case FilterApplicability.IS_CHECKED: {
          overrideTask(true);
          break;
        }
        case FilterApplicability.IS_UNCHECKED: {
          overrideTask(false);
          break;
        }
        default: {
          break;
        }
      }
    }
  });

  return task;
};

export const removeFiltersThatDoNotPassForTask = (dataStore: DataStore, task: Task): boolean => {
  const appStore = dataStore.$useAppStore();

  const oldFilters = appStore.filters;
  const newFilters = appStore.filters.filter((e) => doesTaskPass(dataStore, task, [e]));
  const disabledFiltersCount = oldFilters.length - newFilters.length;
  const disabledAny = disabledFiltersCount > 0;

  if (disabledAny && appStore.currentDartboardDuid === task.dartboardDuid) {
    appStore.setAllFilters(newFilters);
    const countText = getItemCountText(disabledFiltersCount, "filter");
    notify({
      message: `${capitalize(countText)} ${disabledFiltersCount === 1 ? "was" : "were"} removed so that the task is visible`,
      type: NotificationType.WARNING,
      actions: [
        {
          label: "Undo",
          onClick: (dismissFn) => {
            appStore.setAllFilters(oldFilters);
            dismissFn();
          },
        },
      ],
    });
  }
  return disabledAny;
};

export const overrideTaskAndAdjustFilters = (
  dataStore: DataStore,
  originalTask: Task,
  taskCreate: TaskCreate,
  source: TaskSourceType
): Task => {
  if (!OVERRIDE_TASK_SOURCE_TYPES.includes(source)) {
    return originalTask;
  }

  const task: Task = overrideTaskToMatchFilters(dataStore, { ...originalTask, ...taskCreate });

  /* Task values that must be set. */
  Object.assign(task, taskCreate);

  /* Disable filters that don't pass. */
  removeFiltersThatDoNotPassForTask(dataStore, task);

  return task;
};

export const overrideTaskWithDefaults = (dataStore: DataStore, originalCreate: TaskCreate): TaskCreate => {
  if (!originalCreate.dartboardDuid) {
    return originalCreate;
  }

  const dartboard = dataStore.getDartboardByDuid(originalCreate.dartboardDuid);
  if (!dartboard) {
    return originalCreate;
  }

  const newCreate: TaskCreate = { ...originalCreate };

  Object.entries(dartboard.defaultPropertyMap).forEach(([propertyDuid, value]) => {
    const propertyWithConfig = getPropertyWithConfig(propertyDuid);
    if (!propertyWithConfig) {
      return;
    }
    const [property, propertyConfig] = propertyWithConfig;
    if (!propertyConfig) {
      return;
    }

    Object.assign(newCreate, propertyConfig.getPartialTask(property, newCreate, value));
  });

  if (originalCreate.sourceType === TaskSourceType.APP_INTERNAL_FORM) {
    return { ...newCreate, ...originalCreate };
  }

  return newCreate;
};
