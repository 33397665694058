<script setup lang="ts">
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import { PremiumFeatureIcon } from "~/icons";
import { makeLinkToSettingsRef } from "~/router/common";
import { ButtonSize, ButtonStyle } from "~/shared/enums";
import { checkout } from "~/utils/billing";

defineProps<{
  upgradeOnly?: boolean;
}>();

const router = useRouter();

const redirectToBilling = () => router.push(makeLinkToSettingsRef("billing").value);
</script>

<template>
  <div class="flex items-center gap-2" :class="upgradeOnly ? 'justify-center' : 'justify-end'">
    <Button
      v-if="!upgradeOnly"
      :btn-style="ButtonStyle.SECONDARY"
      text="See plans"
      :size="ButtonSize.LARGE"
      @click="redirectToBilling" />
    <Button
      :btn-style="upgradeOnly ? ButtonStyle.SECONDARY : ButtonStyle.PRIMARY"
      :icon="PremiumFeatureIcon"
      text="Upgrade!"
      @click="checkout(router.currentRoute.value.fullPath)" />
  </div>
</template>
