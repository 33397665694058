<script setup lang="ts">
import { useLexicalComposer } from "lexical-vue";
import { computed, ref } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import {
  ChevronDownIcon,
  CodeBlockIcon,
  HorizontalRuleIcon,
  LargeHeaderIcon,
  MediumHeaderIcon,
  QuoteBlockIcon,
  SmallHeaderIcon,
  StandardTextIcon,
  ToggleSectionIcon,
} from "~/icons";
import { CommandId, DropdownMenuItemKind } from "~/shared/enums";
import type { TextBlockType } from "~/shared/types";
import { when } from "~/utils/wait";

import { EVENT_INSERT_HORIZONTAL_RULE, EVENT_INSERT_TOGGLE_COMMAND } from "../const";
import { createTextBlockNode } from "../utils";

const props = defineProps<{
  blockType: TextBlockType;
}>();

const editor = useLexicalComposer();

const menu = ref<InstanceType<typeof DropdownMenu> | null>(null);

const formatBlock = async (blockType: TextBlockType) => {
  if (props.blockType === blockType) {
    return;
  }

  editor.update(() => {
    createTextBlockNode(blockType);
  });

  await when(computed(() => !menu.value?.isOpen));
  editor.focus();
};

const blockTypeToBlockName: Record<string, string> = {
  paragraph: "Standard text",
  h1: "Large header",
  h2: "Medium header",
  h3: "Small header",
  code: "Code",
  quote: "Quote",
  horizontalRule: "Horizontal rule",
};

const textStyleDropdownSections = computed(() => [
  {
    title: "Text style",
    items: [
      {
        title: "Standard text",
        kind: DropdownMenuItemKind.BUTTON,
        icon: StandardTextIcon,
        disabled: props.blockType === "paragraph",
        commandId: CommandId.EDITOR_SET_STANDARD_TEXT,
        onClick: () => formatBlock("paragraph"),
      },
      {
        title: "Large header",
        kind: DropdownMenuItemKind.BUTTON,
        icon: LargeHeaderIcon,
        disabled: props.blockType === "h1",
        commandId: CommandId.EDITOR_SET_LARGE_HEADER,
        onClick: () => formatBlock("h1"),
      },
      {
        title: "Medium header",
        kind: DropdownMenuItemKind.BUTTON,
        icon: MediumHeaderIcon,
        disabled: props.blockType === "h2",
        commandId: CommandId.EDITOR_SET_MEDIUM_HEADER,
        onClick: () => formatBlock("h2"),
      },
      {
        title: "Small header",
        kind: DropdownMenuItemKind.BUTTON,
        icon: SmallHeaderIcon,
        disabled: props.blockType === "h3",
        commandId: CommandId.EDITOR_SET_SMALL_HEADER,
        onClick: () => formatBlock("h3"),
      },
      {
        title: "Code block",
        kind: DropdownMenuItemKind.BUTTON,
        icon: CodeBlockIcon,
        disabled: props.blockType === "code",
        commandId: CommandId.EDITOR_SET_CODE_BLOCK,
        onClick: () => formatBlock("code"),
      },
      {
        title: "Quote",
        kind: DropdownMenuItemKind.BUTTON,
        icon: QuoteBlockIcon,
        disabled: props.blockType === "quote",
        commandId: CommandId.EDITOR_SET_QUOTE,
        onClick: () => formatBlock("quote"),
      },
      {
        title: "Toggle block",
        kind: DropdownMenuItemKind.BUTTON,
        icon: ToggleSectionIcon,
        commandId: CommandId.EDITOR_SET_TOGGLE_BLOCK,
        onClick: () => editor.dispatchCommand(EVENT_INSERT_TOGGLE_COMMAND, null),
      },
      {
        title: "Horizontal rule",
        kind: DropdownMenuItemKind.BUTTON,
        icon: HorizontalRuleIcon,
        commandId: CommandId.EDITOR_SET_HORIZONTAL_RULE,
        onClick: () => editor.dispatchCommand(EVENT_INSERT_HORIZONTAL_RULE, null),
      },
    ],
  },
]);
</script>

<template>
  <DropdownMenu ref="menu" :sections="textStyleDropdownSections">
    <button type="button" class="flex w-32 items-center justify-between gap-1 rounded px-1 py-0.5 text-sm hover:bg-lt">
      <span class="truncate">{{ blockTypeToBlockName[blockType] ?? blockTypeToBlockName.paragraph }}</span>
      <ChevronDownIcon class="text-vlt icon-sm" />
    </button>
  </DropdownMenu>
</template>
