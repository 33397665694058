import type { AxiosResponse } from "axios";

import type { Entity, EntityType } from "~/shared/types";
import { encodeArray } from "~/utils/api";

import backendOld from "./backendOld";

export const MAX_BATCH_SIZE = 50;
export const BATCH_TIMEOUT_MS = 50;

type Resolvers<T extends EntityType> = Set<(value: Entity<T> | undefined) => void>;
type BatchRequest<T extends EntityType> = {
  duids: Set<string>;
  resolversMap: Map<string, Resolvers<T>>;
};

/* Wraps the requestManager to batch requests for entities */
class EntityListManager {
  #executingBatches = new Map<EntityType, Set<BatchRequest<EntityType>>>();

  #pendingBatches = new Map<EntityType, BatchRequest<EntityType>>();

  #timeouts = new Map<EntityType, NodeJS.Timeout>();

  async getEntityByDuid<T extends EntityType>(entityType: T, duid: string): Promise<Entity<T> | undefined> {
    return new Promise<Entity<T> | undefined>((resolve) => {
      if (duid === "") {
        resolve(undefined);
        return;
      }

      // Check if there is an executing batch for the entity type, and if so, add the resolver to the batch
      const executingBatches = this.#executingBatches.get(entityType);
      if (executingBatches) {
        for (let i = 0; i < executingBatches.size; i += 1) {
          const batch = Array.from(executingBatches)[i];
          const resolvers = batch.resolversMap.get(duid);
          if (resolvers) {
            resolvers.add(resolve);
            return;
          }
        }
      }

      const batch = this.#pendingBatches.get(entityType) ?? {
        duids: new Set<string>(),
        resolversMap: new Map<string, Resolvers<T>>(),
      };

      batch.duids.add(duid);
      const currentResolvers = batch.resolversMap.get(duid) ?? new Set();
      currentResolvers.add(resolve);
      batch.resolversMap.set(duid, currentResolvers);
      this.#pendingBatches.set(entityType, batch);

      // Reuse existing timeout if there is one
      if (!this.#timeouts.has(entityType)) {
        this.#timeouts.set(
          entityType,
          // eslint-disable-next-line no-restricted-syntax
          setTimeout(() => this.#executeBatch(entityType), BATCH_TIMEOUT_MS)
        );
      }
    });
  }

  async #executeBatch<T extends EntityType>(entityType: T) {
    const batch = this.#pendingBatches.get(entityType);
    if (!batch) {
      return;
    }

    const duidsList = Array.from(batch.duids);
    const chunks = [];

    // Split duids into chunks
    for (let i = 0; i < duidsList.length; i += MAX_BATCH_SIZE) {
      chunks.push(duidsList.slice(i, i + MAX_BATCH_SIZE));
    }

    // Remove batch from pending batches
    this.#pendingBatches.delete(entityType);
    this.#timeouts.delete(entityType);

    // Add batch to executing batches
    const executingBatch = this.#executingBatches.get(entityType) ?? new Set();
    executingBatch.add(batch);
    this.#executingBatches.set(entityType, executingBatch);

    try {
      const responses = await Promise.all(
        chunks.map((duids) => backendOld._get(`${entityType}?${encodeArray("duids", duids)}`, { background: true }))
      );

      const entities = new Map<string, Entity<T>>(
        responses.flatMap((response?: AxiosResponse<{ results: Entity<T>[] }>) =>
          response ? response.data.results.map((entity: Entity<T>) => [entity.duid, entity]) : []
        )
      );

      batch.resolversMap.forEach((resolvers, duid) => {
        resolvers.forEach((resolve) => resolve(entities.get(duid)));
      });
    } catch (error) {
      batch.resolversMap.forEach((resolvers) => {
        resolvers.forEach((resolve) => resolve(undefined));
      });
      throw error;
    } finally {
      executingBatch.delete(batch);
    }
  }
}

export const entityListManager = new EntityListManager();
