<script setup lang="ts">
import type { EditorState, SerializedEditorState } from "lexical";
import { onUnmounted, watch } from "vue";

import { THROTTLE_MS } from "~/constants/app";
import { areLexicalStatesSame } from "~/utils/common";
import { ThrottleManager } from "~/utils/throttleManager";

import OnChangePlugin from "./OnChangePlugin.vue";

const props = defineProps<{
  duid: string;
  content: SerializedEditorState;
  hasFocus: boolean;
  immediate?: boolean;
}>();

const emit = defineEmits<{
  save: [duid: string, newContent: SerializedEditorState];
}>();

const saveManager = new ThrottleManager(
  (duid: string, value: SerializedEditorState) => {
    if (areLexicalStatesSame(value, props.content)) {
      return;
    }

    emit("save", duid, value);
  },
  props.immediate ? 0 : THROTTLE_MS
);

const handleEditorChanges = (newEditorState: EditorState) => {
  saveManager.run(props.duid, newEditorState.toJSON());
};

watch(
  () => props.duid,
  () => {
    saveManager.finish();
  }
);

onUnmounted(() => {
  saveManager.destroy();
});
</script>

<template>
  <OnChangePlugin :has-focus="hasFocus" @change="handleEditorChanges" />
</template>
