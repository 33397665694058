import moment from "moment";

import { COMPLETED_STATUS_KINDS, SPRINT_DARTBOARD_KINDS_SET } from "~/components/visualization/constants";
import { DARTBOARD_KIND_TO_ICON_INFO_MAP } from "~/constants/dartboard";
import {
  DartboardKind,
  FilterConnector,
  IconKind,
  PageKind,
  SprintMode,
  TaskLinkKind,
  TaskSourceType,
  UserRole,
} from "~/shared/enums";
import type {
  Attachment,
  AttachmentCreate,
  Dartboard,
  DartboardUpdate,
  Layout,
  RelationshipCreate,
  RelationshipKind,
  Task,
  TaskAndUpdate,
  TaskCreate,
  TaskLinkCreate,
  TaskUpdate,
  View,
} from "~/shared/types";
import { createFilesFromAttachments } from "~/utils/api";
import { makeRandomColorHex } from "~/utils/color";
import { deepCopy, getNextTitleInSequence, makeDuid, randomSample } from "~/utils/common";
import { makeDartboardComparator } from "~/utils/comparator";
import { getOrdersBetween } from "~/utils/orderManager";
import { getNextRecursNextAt } from "~/utils/time";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  getDartboardList: (options?: { includeHidden?: boolean; excludeFinished?: boolean }) => Dartboard[];
  getDartboardByDuid: (duid: string) => Dartboard | undefined;
  getDartboardsByDuidsOrdered: (duids: string[]) => Dartboard[];
  getDartboardsBySpaceDuidOrdered: (
    spaceDuid: string,
    options?: { includeHidden?: boolean; excludeFinished?: boolean }
  ) => Dartboard[];
  getDartboardsByKindOrdered: (kind: DartboardKind, options?: { includeHidden?: boolean }) => Dartboard[];
  getWorkspaceDartboardByKind: (kind: DartboardKind) => Dartboard | undefined;
  defaultDartboard: Dartboard | undefined;
};

export type Actions = {
  /** Create a new dartboard. */
  createDartboard: (
    spaceDuid: string,
    order: string,
    partialDartboard?: Partial<Dartboard>,
    options?: { noBackend?: boolean; awaitBackend?: boolean }
  ) => Promise<Dartboard>;
  /** Update a dartboard. */
  updateDartboard: (
    update: DartboardUpdate,
    options?: { awaitBackend?: boolean; noBackend?: boolean }
  ) => Promise<Dartboard | undefined>;
  /* Replicate a dartboard. */
  replicateDartboard: (
    dartboard: Dartboard,
    order: string,
    partialDartboard?: Partial<Dartboard>,
    options?: { awaitBackend?: boolean; maintainTitle?: boolean }
  ) => Promise<Dartboard | undefined>;
  /** Import a view as a dartboard. */
  importViewAsDartboard: (
    view: View,
    layout: Layout,
    relationshipKinds: RelationshipKind[],
    tasks: Task[],
    attachments: Attachment[],
    spaceDuid: string,
    order: string
  ) => Promise<Dartboard | undefined>;
  /** Delete a dartboard. */
  deleteDartboard: (dartboard: Dartboard, options?: { noBackend?: boolean; awaitBackend?: boolean }) => Promise<void>;
  /** Create sprint dartboards. */
  createSprintDartboardsNoBackend: (spaceDuid: string) => Promise<Dartboard[]>;
  /** Delete sprint dartboards. */
  deleteSprintDartboardsNoBackend: (spaceDuid: string) => Promise<string[]>;
  /** Rollover next into active, and archive active. */
  rollover: (spaceDuid: string) => Promise<void>;
  /** Reverse rollover. */
  reverseRollover: (spaceDuid: string) => Promise<void>;
  /**
   * Remove entity duids from dartboard property default values
   */
  $updateDartboardsPropertyDefault: (
    entityDuids: string[],
    propertyDuid: string[],
    options?: { includeBackend?: boolean; removeAllValues?: boolean }
  ) => DartboardUpdate[];
  /** Sort dartboards.
   * @PRIVATE */
  $sortDartboards: () => void;
  /** Set dartboards initially.
   * @PRIVATE */
  $setDartboards: (dartboards: Dartboard[]) => void;
  /** Create or update dartboard from WS.
   * @PRIVATE */
  $createOrUpdateDartboard: (dartboard: Dartboard) => void;
  /** Delete dartboard from WS.
   * @PRIVATE */
  $deleteDartboard: (dartboard: Dartboard) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  getDartboardList() {
    return (options = {}) => {
      const { includeHidden = false, excludeFinished = false } = options;
      const tenantStore = this.$useTenantStore();
      return [...this._duidsToDartboards.values()].filter(
        (e) =>
          (!excludeFinished || e.kind !== DartboardKind.FINISHED) &&
          (includeHidden || !(e.kind === DartboardKind.BACKLOG && !tenantStore.backlogEnabled))
      );
    };
  },
  getDartboardByDuid() {
    return (duid) => this._duidsToDartboards.get(duid);
  },
  getDartboardsByDuidsOrdered() {
    return (duids) => this.getDartboardList({ includeHidden: true }).filter((e) => duids.includes(e.duid));
  },
  getDartboardsBySpaceDuidOrdered() {
    return (spaceDuid, options) => this.getDartboardList(options).filter((e) => e.spaceDuid === spaceDuid);
  },
  getDartboardsByKindOrdered() {
    return (kind, options) => this.getDartboardList(options).filter((db) => db.kind === kind);
  },
  getWorkspaceDartboardByKind() {
    return (kind) => {
      const { workspaceSpace } = this;
      if (workspaceSpace === undefined) {
        return undefined;
      }
      return this.getDartboardsBySpaceDuidOrdered(workspaceSpace.duid, { includeHidden: true }).filter(
        (e) => e.kind === kind
      )[0];
    };
  },
  defaultDartboard() {
    if (!this.$useUserStore().isRoleGreaterOrEqual(UserRole.MEMBER)) {
      return this.getDartboardList()[0];
    }
    return this.workspaceSpace?.sprintMode === SprintMode.ANBA
      ? this.getWorkspaceDartboardByKind(DartboardKind.ACTIVE)
      : this.getWorkspaceDartboardByKind(DartboardKind.CUSTOM);
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createDartboard(spaceDuid, order, partialDartboard, options = {}) {
    const { noBackend = false, awaitBackend = false } = options;
    const appStore = this.$useAppStore();

    const newLayout = this.makeLayoutNoBackend();

    const userDuid = this.$useUserStore().duid;
    const newDartboard: Dartboard = {
      pageKind: PageKind.DARTBOARD,
      spaceDuid,
      duid: makeDuid(),
      kind: DartboardKind.CUSTOM,
      order,
      title: "",
      description: "",
      iconKind: IconKind.NONE,
      iconNameOrEmoji: randomSample(appStore.pageIconOptions)[0].name,
      colorHex: makeRandomColorHex(),
      userDuidsToLayoutDuids: [{ userDuid, layoutDuid: newLayout.duid }],
      index: null,
      startedAt: null,
      finishedAt: null,
      plannedFinishAt: null,
      defaultPropertyMap: {},
      alwaysShownPropertyDuids: [],
      alwaysHiddenPropertyDuids: [],
      propertyOrderDuids: [],
      propertyWidthMap: {},
      ...partialDartboard,
    };

    this.$createOrUpdateDartboard(newDartboard);

    if (!noBackend) {
      const userDartboardLayout = {
        userDuid,
        dartboardDuid: newDartboard.duid,
        layoutDuid: newLayout.duid,
      };

      const backendAction = this.$backend.dartboard.create(newDartboard, newLayout, userDartboardLayout);
      if (awaitBackend) {
        await backendAction;
      }
    }

    return newDartboard;
  },
  async updateDartboard(update, options = {}) {
    const { awaitBackend = false, noBackend = false } = options;
    const dartboard = this.getDartboardByDuid(update.duid);
    if (!dartboard) {
      return undefined;
    }

    Object.assign(dartboard, update);
    this.$sortDartboards();

    if (!noBackend) {
      const backendAction = this.$backend.dartboard.update(update);
      if (awaitBackend) {
        await backendAction;
      }
    }

    return dartboard;
  },
  async replicateDartboard(dartboard, order, partialDartboard, options = {}) {
    const { awaitBackend = false, maintainTitle = false } = options;
    const oldLayout = this.getLayoutByDartboardDuid(dartboard.duid);
    let newLayout;
    if (oldLayout) {
      newLayout = {
        ...deepCopy(oldLayout),
        duid: makeDuid(),
      };
      this.$createOrUpdateLayout(newLayout);
    } else {
      newLayout = this.makeLayoutNoBackend();
    }

    const userDuid = this.$useUserStore().duid;
    const newDartboard: Dartboard = {
      ...deepCopy(dartboard),
      duid: makeDuid(),
      kind: DartboardKind.CUSTOM,
      order,
      userDuidsToLayoutDuids: [{ userDuid, layoutDuid: newLayout.duid }],
      ...partialDartboard,
    };
    if (!maintainTitle && partialDartboard?.title === undefined) {
      newDartboard.title = getNextTitleInSequence(
        dartboard.title,
        this.getDartboardsBySpaceDuidOrdered(newDartboard.spaceDuid).map((e) => e.title)
      );
    }

    this.$createOrUpdateDartboard(newDartboard);

    const userDartboardLayout = {
      userDuid,
      dartboardDuid: newDartboard.duid,
      layoutDuid: newLayout.duid,
    };

    // TODO transactionify these two calls
    const backendAction = (async () => {
      await this.$backend.dartboard.create(newDartboard, newLayout, userDartboardLayout);
      await this.$backendOld.dartboards.replicate(dartboard.duid, { targetDuid: newDartboard.duid });
    })();

    if (awaitBackend) {
      await backendAction;
    }

    return newDartboard;
  },
  async importViewAsDartboard(view, layout, relationshipKinds, tasks, attachments, spaceDuid, order) {
    const newLayout = {
      ...layout,
      duid: makeDuid(),
      filterGroup: {
        filters: [],
        connector: FilterConnector.AND,
      },
    };
    this.$createOrUpdateLayout(newLayout);

    const userDuid = this.$useUserStore().duid;
    const newDartboard: Dartboard = {
      pageKind: PageKind.DARTBOARD,
      spaceDuid,
      duid: makeDuid(),
      kind: DartboardKind.CUSTOM,
      order,
      title: view.title,
      description: view.description,
      iconKind: view.iconKind,
      iconNameOrEmoji: view.iconNameOrEmoji,
      colorHex: view.colorHex,
      userDuidsToLayoutDuids: [{ userDuid, layoutDuid: newLayout.duid }],
      index: null,
      startedAt: null,
      finishedAt: null,
      plannedFinishAt: null,
      defaultPropertyMap: {},
      alwaysShownPropertyDuids: [],
      alwaysHiddenPropertyDuids: [],
      propertyOrderDuids: [],
      propertyWidthMap: {},
    };
    this.$createOrUpdateDartboard(newDartboard);

    // TODO transactionify these two calls
    const userDartboardLayout = {
      userDuid,
      dartboardDuid: newDartboard.duid,
      layoutDuid: newLayout.duid,
    };
    await this.$backend.dartboard.create(newDartboard, newLayout, userDartboardLayout);

    const now = new Date().toISOString();
    const statusDuid = this.defaultDefaultUnstartedStatus.duid;
    const kindDuid = this.defaultTaskKind.duid;
    const taskDuidRemap = new Map();
    const tasksToCreate: TaskCreate[] = [];
    const newTasks: Task[] = tasks.map((oldTask) => {
      const newDuid = makeDuid();
      taskDuidRemap.set(oldTask.duid, newDuid);
      const newTask = {
        ...oldTask,
        duid: newDuid,
        sourceType: TaskSourceType.TEMPLATE,
        createdAt: now,
        createdByDuid: userDuid,
        updatedAt: now,
        updatedByDuid: userDuid,
        drafterDuid: null,
        inTrash: false,
        dartboardDuid: newDartboard.duid,
        kindDuid,
        statusDuid,
        assignedToAi: false,
        assigneeDuids: [userDuid],
        subscriberDuids: [userDuid],
        tagDuids: [],
        relationships: [],
        links: [],
        attachmentDuids: [],
        properties: {},
        notionDocument: null,
      };
      this.$createOrUpdateTask(newTask);
      tasksToCreate.push({ ...newTask, sourceTemplateViewDuid: view.duid, sourceTemplateTaskDuid: oldTask.duid });
      return newTask;
    });

    const relationshipDuidRemap = new Map(
      relationshipKinds.map((oldRelationshipKind) => [
        oldRelationshipKind.duid,
        this.getRelationshipKindByKind(oldRelationshipKind.kind).duid,
      ])
    );

    const relationshipsToCreate: RelationshipCreate[] = [];
    const linksToCreate: TaskLinkCreate[] = [];
    const attachmentsToCreate: AttachmentCreate[] = [];
    await Promise.all(
      tasks.map(async (oldTask, i) => {
        const newTask = newTasks[i];

        relationshipsToCreate.push(
          ...oldTask.relationships
            .filter((e) => e.isForward)
            .map(({ kindDuid: oldKindDuid, targetDuid: oldTargetDuid, isForward }) => {
              const newKindDuid = relationshipDuidRemap.get(oldKindDuid);
              const newTargetDuid = taskDuidRemap.get(oldTargetDuid);
              if (!newKindDuid || !newTargetDuid) {
                return undefined;
              }

              const newDuid = makeDuid();
              this.$createRelationshipOneDirectionNoBackend(
                newDuid,
                newTask.duid,
                newTargetDuid,
                newKindDuid,
                isForward
              );
              this.$createRelationshipOneDirectionNoBackend(
                newDuid,
                newTargetDuid,
                newTask.duid,
                newKindDuid,
                !isForward
              );
              return {
                duid: newDuid,
                sourceDuid: newTask.duid,
                targetDuid: newTargetDuid,
                kindDuid: newKindDuid,
              };
            })
            .filter((e): e is RelationshipCreate => !!e)
        );

        linksToCreate.push(
          ...oldTask.links
            .filter((e) => e.kind === TaskLinkKind.STANDARD)
            .map((oldLink) => {
              const taskLinkCreate = { ...oldLink, duid: makeDuid(), taskDuid: newTask.duid };
              this.$addLinksNoBackend(newTask, [taskLinkCreate]);
              return taskLinkCreate;
            })
        );

        const oldAttachmentDuids = oldTask.attachmentDuids;
        const oldAttachments = attachments.filter((e) => oldAttachmentDuids.includes(e.duid));
        const files = await createFilesFromAttachments(oldAttachments);
        const attachmentConfigs = oldAttachments.map((oldAttachment, j) => ({
          file: files[j],
          order: oldAttachment.order,
          partialAttachment: { colorHex: oldAttachments[i].colorHex },
        }));
        const attachmentsAndCreates = await this.createAttachments(attachmentConfigs, { noBackend: true });
        newTask.attachmentDuids.push(...attachmentsAndCreates.map((e) => e.attachment.duid));
        attachmentsToCreate.push(...attachmentsAndCreates.map((e) => e.create));
      })
    );

    this.$backend.task.createMany(tasksToCreate, relationshipsToCreate, [], linksToCreate, attachmentsToCreate);

    return newDartboard;
  },
  async deleteDartboard(dartboard, options = {}) {
    const { noBackend = false, awaitBackend = false } = options;
    const destDartboardDuid = this.defaultDartboard?.duid;

    // TODO transactionify
    const updates = this.getTasksByDartboardDuidOrdered(dartboard.duid, {
      includeTrashed: true,
      includeDraft: true,
    }).map((e) => {
      const res: TaskUpdate = {
        duid: e.duid,
      };
      if (destDartboardDuid) {
        res.dartboardDuid = destDartboardDuid;
      }
      if (!e.inTrash && !e.drafterDuid) {
        res.inTrash = true;
      }
      return res;
    });

    this.updateTasks(updates, { noUndo: true });

    const layoutUpdates = this.$updateLayoutsRemoveEntityDuidOrFilters(
      [dartboard.duid],
      [this.defaultDartboardProperty.duid]
    );

    this.$deleteDartboard(dartboard);

    if (!noBackend) {
      const backendAction = this.$backend.dartboard.delete(dartboard.duid, layoutUpdates);
      if (awaitBackend) {
        await backendAction;
      }
    }
  },
  async createSprintDartboardsNoBackend(spaceDuid) {
    const topOrder = this.getDartboardsBySpaceDuidOrdered(spaceDuid)[0]?.order;
    const [activeOrder, nextOrder, backlogOrder] = getOrdersBetween(undefined, topOrder, 3);

    const activeConfig = DARTBOARD_KIND_TO_ICON_INFO_MAP[DartboardKind.ACTIVE];
    const partialActive = {
      kind: DartboardKind.ACTIVE,
      title: "Sprint 1",
      iconKind: IconKind.ICON,
      iconNameOrEmoji: activeConfig.iconNameOrEmoji,
      colorHex: activeConfig.colorHex,
      index: 1,
      startedAt: new Date().toISOString(),
    };

    const nextConfig = DARTBOARD_KIND_TO_ICON_INFO_MAP[DartboardKind.NEXT];
    const partialNext = {
      kind: DartboardKind.NEXT,
      title: "Sprint 2",
      iconKind: IconKind.ICON,
      iconNameOrEmoji: nextConfig.iconNameOrEmoji,
      colorHex: nextConfig.colorHex,
      index: 2,
    };

    const backlogConfig = DARTBOARD_KIND_TO_ICON_INFO_MAP[DartboardKind.BACKLOG];
    const partialBacklog = {
      kind: DartboardKind.BACKLOG,
      title: "Backlog Tasks",
      iconKind: IconKind.ICON,
      iconNameOrEmoji: backlogConfig.iconNameOrEmoji,
      colorHex: backlogConfig.colorHex,
    };

    const active = await this.createDartboard(spaceDuid, activeOrder, partialActive, { noBackend: true });
    const next = await this.createDartboard(spaceDuid, nextOrder, partialNext, { noBackend: true });
    const backlog = await this.createDartboard(spaceDuid, backlogOrder, partialBacklog, { noBackend: true });

    return [active, next, backlog];
  },
  async deleteSprintDartboardsNoBackend(spaceDuid) {
    const dartboards = this.getDartboardsBySpaceDuidOrdered(spaceDuid, { includeHidden: true }).filter((e) =>
      SPRINT_DARTBOARD_KINDS_SET.has(e.kind)
    );
    dartboards.forEach((e) => this.deleteDartboard(e, { noBackend: true }));

    // If no dartboards are left in workspace space, create a default dartboard
    if (this.workspaceSpace?.duid === spaceDuid && this.getDartboardsBySpaceDuidOrdered(spaceDuid).length === 0) {
      await this.createDartboard(spaceDuid, String.fromCharCode(129), {
        title: "Tasks",
        iconKind: IconKind.ICON,
        iconNameOrEmoji: "TaskIcon",
        colorHex: "#b49bf8",
      });
    }

    return dartboards.map((e) => e.duid);
  },
  async rollover(spaceDuid) {
    const space = this.getSpaceByDuid(spaceDuid);
    const spaceDartboards = this.getDartboardsBySpaceDuidOrdered(spaceDuid);
    const oldActiveDartboard = spaceDartboards.find((db) => db.kind === DartboardKind.ACTIVE);
    const oldNextDartboard = spaceDartboards.find((db) => db.kind === DartboardKind.NEXT);
    if (!space || !oldActiveDartboard || !oldNextDartboard) {
      return;
    }

    const now = new Date().toISOString();
    const newNextDartboardDuid = makeDuid();

    // move all tasks that aren’t completed (i.e. finished or cancelled status kind) from active to next
    const taskMovesToNext: TaskUpdate[] = [];
    const taskReplicates: TaskAndUpdate[] = [];
    this.getTasksByDartboardDuidOrdered(oldActiveDartboard.duid).forEach((task) => {
      const statusKind = this.getStatusByDuid(task.statusDuid)?.kind;
      if (!statusKind) {
        return;
      }

      if (!COMPLETED_STATUS_KINDS.has(statusKind)) {
        if (space.sprintReplicateOnRollover) {
          taskReplicates.push({
            task,
            order: task.order,
            partialTask: { dartboardDuid: oldNextDartboard.duid },
          });
        } else {
          // eslint-disable-next-line no-param-reassign
          task.dartboardDuid = oldNextDartboard.duid;

          taskMovesToNext.push({
            duid: task.duid,
            dartboardDuid: oldNextDartboard.duid,
          });
        }
      }
    });

    // replicate layouts for the new next
    const {
      layoutCreates: newNextLayoutCreates,
      userDartboardLayouts: newNextUserDartboardLayouts,
      userDartboardLayoutCreates: newNextUserDartboardLayoutCreates,
    } = await this.$replicateLayoutsForDartboardNoBackend(oldNextDartboard.duid, newNextDartboardDuid);

    // replicate layouts for the new active
    const {
      layoutUpdates: newActiveLayoutUpdates,
      layoutCreates: newActiveLayoutCreates,
      userDartboardLayouts: newActiveUserDartboardLayouts,
      userDartboardLayoutCreates: newActiveLayoutsUserDartboardLayoutCreates,
    } = await this.$replicateLayoutsForDartboardNoBackend(oldActiveDartboard.duid, oldNextDartboard.duid);

    // update old active
    const finishedConfig = DARTBOARD_KIND_TO_ICON_INFO_MAP[DartboardKind.FINISHED];
    const oldActiveUpdate = {
      duid: oldActiveDartboard.duid,
      kind: DartboardKind.FINISHED,
      iconNameOrEmoji: finishedConfig.iconNameOrEmoji,
      colorHex: finishedConfig.colorHex,
      finishedAt: now,
    };
    Object.assign(oldActiveDartboard, oldActiveUpdate);

    let oldNextNewPlannedFinishedAt = oldNextDartboard.plannedFinishAt;
    let newNextPlannedFinishAt = null;

    if (space.rolloverRecurrence) {
      oldNextNewPlannedFinishedAt = space.rolloverRecursNextAt;
      newNextPlannedFinishAt = getNextRecursNextAt(
        space.rolloverRecurrence,
        moment(space.rolloverRecursNextAt)
      ).toISOString();
    }

    // update old next
    const activeConfig = DARTBOARD_KIND_TO_ICON_INFO_MAP[DartboardKind.ACTIVE];
    const oldNextUpdate = {
      duid: oldNextDartboard.duid,
      kind: DartboardKind.ACTIVE,
      iconNameOrEmoji: activeConfig.iconNameOrEmoji,
      colorHex: activeConfig.colorHex,
      startedAt: now,
      plannedFinishAt: oldNextNewPlannedFinishedAt,
      alwaysShownPropertyDuids: [...oldActiveDartboard.alwaysShownPropertyDuids],
      alwaysHiddenPropertyDuids: [...oldActiveDartboard.alwaysHiddenPropertyDuids],
      propertyOrderDuids: [...oldActiveDartboard.propertyOrderDuids],
    };
    Object.assign(oldNextDartboard, oldNextUpdate);
    oldNextDartboard.userDuidsToLayoutDuids.push(...newActiveUserDartboardLayouts);

    // make a new dartboard with next kind and index and name one higher than the last next
    const nextConfig = DARTBOARD_KIND_TO_ICON_INFO_MAP[DartboardKind.NEXT];
    const newNextIndex = (oldNextDartboard.index ?? 0) + 1;
    const newNextDartboard = {
      ...oldNextDartboard,
      duid: newNextDartboardDuid,
      kind: DartboardKind.NEXT,
      order: getOrdersBetween(undefined, oldNextDartboard.order)[0],
      title: `Sprint ${newNextIndex}`,
      iconNameOrEmoji: nextConfig.iconNameOrEmoji,
      colorHex: nextConfig.colorHex,
      userDuidsToLayoutDuids: newNextUserDartboardLayouts,
      index: newNextIndex,
      startedAt: null,
      plannedFinishAt: newNextPlannedFinishAt,
    };

    this.$createOrUpdateDartboard(newNextDartboard);

    // Find any layouts that use the old next & active duids and update them to use the new duids
    const filterLayoutUpdates = this.$updateLayoutsRemapDartboardFiltersNoBackend(
      new Map([
        [oldNextDartboard.duid, newNextDartboard.duid],
        [oldActiveDartboard.duid, oldNextDartboard.duid],
      ])
    );

    await this.$backend.dartboard.rollover(
      taskMovesToNext,
      [oldActiveUpdate, oldNextUpdate],
      newNextDartboard,
      [...newActiveLayoutUpdates, ...filterLayoutUpdates],
      [...newActiveLayoutCreates, ...newNextLayoutCreates],
      [...newActiveLayoutsUserDartboardLayoutCreates, ...newNextUserDartboardLayoutCreates]
    );
    await this.replicateTasks(taskReplicates, {
      noUndo: true,
      includeDuplicate: true,
      maintainTitle: true,
      maintainStatus: true,
    });
  },
  async reverseRollover(spaceDuid) {
    const dartboards = this.getDartboardsBySpaceDuidOrdered(spaceDuid);
    const oldActiveDartboard = dartboards.find((e) => e.kind === DartboardKind.ACTIVE);
    const oldNextDartboard = dartboards.find((e) => e.kind === DartboardKind.NEXT);
    const oldFinishedDartboard = dartboards.find((e) => e.kind === DartboardKind.FINISHED);
    if (!oldActiveDartboard || !oldNextDartboard || !oldFinishedDartboard) {
      return;
    }

    const tasksMovesToFinished: TaskUpdate[] = [];
    const tasksMovesToActive: TaskUpdate[] = [];

    // replicate layouts for the new active
    const {
      layoutUpdates: newActiveLayoutUpdates,
      layoutCreates: newActiveLayoutCreates,
      userDartboardLayouts: newActiveUserDartboardLayouts,
      userDartboardLayoutCreates: newActiveLayoutsUserDartboardLayoutCreates,
    } = await this.$replicateLayoutsForDartboardNoBackend(oldActiveDartboard.duid, oldFinishedDartboard.duid);

    // replicate layouts for the new next
    const {
      layoutUpdates: newNextLayoutUpdates,
      layoutCreates: newNextLayoutCreates,
      userDartboardLayoutCreates: newNextUserDartboardLayoutCreates,
    } = await this.$replicateLayoutsForDartboardNoBackend(oldNextDartboard.duid, oldActiveDartboard.duid);

    // update old finished
    const activeConfig = DARTBOARD_KIND_TO_ICON_INFO_MAP[DartboardKind.ACTIVE];
    const newActiveUpdate = {
      duid: oldFinishedDartboard.duid,
      kind: DartboardKind.ACTIVE,
      iconNameOrEmoji: activeConfig.iconNameOrEmoji,
      colorHex: activeConfig.colorHex,
      finishedAt: null,
    };
    Object.assign(oldFinishedDartboard, newActiveUpdate);
    oldFinishedDartboard.userDuidsToLayoutDuids.push(...newActiveUserDartboardLayouts);

    // move all tasks from next to active
    this.getTasksByDartboardDuidOrdered(oldNextDartboard.duid).forEach((task) => {
      // eslint-disable-next-line no-param-reassign
      task.dartboardDuid = oldActiveDartboard.duid;
      tasksMovesToActive.push({
        duid: task.duid,
        dartboardDuid: oldActiveDartboard.duid,
      });
    });

    // update old active
    const nextConfig = DARTBOARD_KIND_TO_ICON_INFO_MAP[DartboardKind.NEXT];
    const newNextUpdate = {
      duid: oldActiveDartboard.duid,
      kind: DartboardKind.NEXT,
      iconNameOrEmoji: nextConfig.iconNameOrEmoji,
      colorHex: nextConfig.colorHex,
      startedAt: null,
    };
    Object.assign(oldActiveDartboard, newNextUpdate);

    // delete old next
    this.$deleteDartboard(oldNextDartboard);

    await this.$backend.dartboard.reverseRollover(
      [...tasksMovesToFinished, ...tasksMovesToActive],
      [newActiveUpdate, newNextUpdate],
      [...newActiveLayoutUpdates, ...newNextLayoutUpdates],
      [...newActiveLayoutCreates, ...newNextLayoutCreates],
      [...newActiveLayoutsUserDartboardLayoutCreates, ...newNextUserDartboardLayoutCreates],
      oldNextDartboard.duid
    );
  },
  $updateDartboardsPropertyDefault(
    entityDuids,
    propertyDuids,
    { removeAllValues = false, includeBackend = false } = {}
  ) {
    const dartboardUpdates: DartboardUpdate[] = [];
    // Update the default property map for each dartboard
    this.getDartboardList().forEach((dartboard) => {
      const defaultPropertyMap = { ...dartboard.defaultPropertyMap };
      propertyDuids.forEach((propertyDuid) => {
        const propertyDefault = defaultPropertyMap[propertyDuid];
        if (!propertyDefault) {
          return;
        }

        if (removeAllValues) {
          delete defaultPropertyMap[propertyDuid];
          return;
        }

        if (Array.isArray(propertyDefault)) {
          defaultPropertyMap[propertyDuid] = (propertyDefault as string[]).filter((val) => !entityDuids.includes(val));
          if ((defaultPropertyMap[propertyDuid] as string[]).length === 0) {
            delete defaultPropertyMap[propertyDuid];
          }
          return;
        }

        if (entityDuids.includes(propertyDefault as string)) {
          delete defaultPropertyMap[propertyDuid];
        }
      });

      dartboardUpdates.push({
        duid: dartboard.duid,
        defaultPropertyMap,
      });
    });

    dartboardUpdates.forEach((update) => this.updateDartboard(update, { noBackend: !includeBackend }));

    return dartboardUpdates;
  },
  $sortDartboards() {
    this._duidsToDartboards = new Map(
      [...this._duidsToDartboards].sort((a, b) => makeDartboardComparator(this.getSpaceByDuid)(a[1], b[1]))
    );
  },
  $setDartboards(dartboards) {
    this._duidsToDartboards = new Map(dartboards.map((d) => [d.duid, { ...d, pageKind: PageKind.DARTBOARD }]));
    this.$sortDartboards();
  },
  async $createOrUpdateDartboard(dartboard) {
    const currentDartboard = this.getDartboardByDuid(dartboard.duid);

    // TODO some of this logic will change when we are filtering out spurious updates on BE of WS
    const space = this.getSpaceByDuid(dartboard.spaceDuid);
    if (!space) {
      return;
    }
    if (!(space.accessibleByTeam || space.accessibleByUserDuids.includes(this.$useUserStore().duid))) {
      if (currentDartboard) {
        this.$deleteDartboard(dartboard);
      }
      return;
    }

    // eslint-disable-next-line no-param-reassign
    dartboard.pageKind = PageKind.DARTBOARD;

    if (!currentDartboard) {
      this._duidsToDartboards.set(dartboard.duid, dartboard);
    } else {
      Object.assign(currentDartboard, dartboard);
    }

    this.$sortDartboards();

    /* Create */
    // TODO shouldn't rely on client to get the tasks separately, this should probably come over WS
    if (!currentDartboard) {
      const { data } = await this.$backendOld.tasks.getByDartboard(dartboard.duid);
      data.items.forEach((task: Task) => {
        this.$createOrUpdateTask(task);
      });
    }
  },
  $deleteDartboard(dartboard) {
    const deletingCurrentPage = dartboard.duid === this.$useAppStore().currentPage?.duid;

    this._duidsToDartboards.delete(dartboard.duid);

    // TODO shouldn't rely on client to delete these, this should probably come over WS; also this is bad because it can be bad for the normal delete flow
    this.getTasksByDartboardDuidOrdered(dartboard.duid, { includeTrashed: true, includeDraft: true }).forEach(
      this.$deleteTask
    );

    if (deletingCurrentPage) {
      this.$routerUtils.goHome();
    }
  },
};

export { actions, getters };
