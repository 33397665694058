<script setup lang="ts">
import { useElementBounding, useMediaQuery, useMouse, useMousePressed } from "@vueuse/core";
import type {
  CellMouseOutEvent,
  CellMouseOverEvent,
  ColDef,
  ColumnResizedEvent,
  GridApi,
  GridReadyEvent,
  IRowNode,
  IsFullWidthRowParams,
  IsGroupOpenByDefaultParams,
  RowClassParams,
  RowDragEndEvent,
  RowDragEnterEvent,
  RowDragMoveEvent,
  RowGroupOpenedEvent,
  RowHeightParams,
  RowNode,
} from "ag-grid-community";
import { AgGridVue } from "ag-grid-vue3";
import type { Component } from "vue";
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue";

import actions from "~/actions";
import { isFillerRow, isGroupContainer, NO_VALUE_GROUP_BY_KEY, UNGROUPED_PSEUDO_GROUP_BY } from "~/common/groupBy";
import { getPropertyConfig } from "~/common/properties";
import { DROP_HOVER_STYLE } from "~/components/visualization/constants";
import { getReactiveTaskNode } from "~/components/visualization/list/common";
import Styles from "~/components/visualization/list/Styles.vue";
import { calcPropertyValueFromGroupStr, useListColumnChange } from "~/components/visualization/list/utils";
import { EditorMode, PageKind, RelationshipKindKind } from "~/shared/enums";
import type { PropertyValue, RowItem, Task, TaskAbsenteeMaybe, TaskUpdate, TaskWithGroup } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { makeStringComparator } from "~/utils/comparator";

// Copied from ~/icons/VerticalDragHandle.vue
const VERTICAL_DRAG_HANDLE_HTML_STR = `
<svg class="text-vlt"
  width="16" height="16" viewBox="0 0 24 24" fill="none" aria-hidden="true">
  <path
    d="m 8.5,6 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z m 0,7 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z m 0,7 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z M 15.5,6 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z m 0,7 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z m 0,7 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
`.trim();

const PX_PER_INDENT_LEVEL = 30;

const props = defineProps<{
  editorMode: EditorMode;
  domLayout?: "normal" | "autoHeight" | "print";
  defaultColDef: ColDef;
  columns: ColDef[];
  tasks: RowItem[];
  indentDrag?: boolean;
  getRowId?: (params: IRowNode) => string | undefined;
  treeData?: boolean;
  getDataPath?: (task: RowItem) => string[];
  isFullWidthRow?: (params: IsFullWidthRowParams) => boolean;
  isGroupOpenByDefault?: (params: IsGroupOpenByDefaultParams) => boolean | undefined;
  fullWidthCellRenderer?: Component;
  autoGroupColumnDef?: ColDef;
  suppressColumnVirtualisation?: boolean;
  suppressRowVirtualisation?: boolean;
  isExternalFilterPresent?: () => boolean;
  doesExternalFilterPass?: (node: RowNode) => boolean;
  ignoreSelectionAndHover?: boolean;
  headerHeight?: number;
  rowHeight?: number;
  getRowHeight?: (params: RowHeightParams<RowItem>) => number;
}>();

const emit = defineEmits<{
  gridReady: [event: GridReadyEvent<RowItem>];
  onMounted: [instance: unknown | null];
  cellMouseOver: [event: CellMouseOverEvent<Task>];
  rowGroupOpened: [event: RowGroupOpenedEvent];
}>();

// Don't inherit attrs to wrapper element
defineOptions({
  inheritAttrs: false,
});

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const gridApi = ref<GridApi<RowItem> | null>(null);

const isUngrouped = computed(() => appStore.groupBy === UNGROUPED_PSEUDO_GROUP_BY);
const isPrinting = useMediaQuery("print");
const domLayoutNorm = computed(() => (isPrinting.value ? "print" : (props.domLayout ?? "normal")));

const grid = ref<InstanceType<typeof AgGridVue> | null>(null);
const { pressed: mousePressed } = useMousePressed({ target: grid });
const { x: mouseX, y: mouseY } = useMouse();
const { top: gridTop } = useElementBounding(grid);

const justDragSelected = ref(false);
watch(mousePressed, (value) => {
  if (value) {
    return;
  }
  // eslint-disable-next-line no-restricted-syntax
  setTimeout(() => {
    justDragSelected.value = false;
  }, 100);
});

const isColumnResizing = ref(false);
const dragStartX = ref<number | undefined>();
const taskToOriginalPropertyValueMap = new Map<string, PropertyValue>();
const taskToOriginalParentRelationshipDuidMap = new Map<string, string | null>();
let lastMouseY: number | undefined;
let lastMoveWasUp: boolean | undefined;
let lastOverId: string | undefined;

const tasksToUpdateOnDragEnd = ref<TaskUpdate[]>([]);

const resetDragging = () => {
  dragStartX.value = undefined;
  taskToOriginalPropertyValueMap.clear();
  taskToOriginalParentRelationshipDuidMap.clear();

  lastMouseY = undefined;
  lastMoveWasUp = undefined;
  lastOverId = undefined;
};

// TODO cleanup this function and watcher
const updateTaskDropTargets = (newTargetMap: Map<string, HTMLElement>, oldTargetMap: Map<string, HTMLElement>) => {
  const api = gridApi.value;
  if (!api) {
    return;
  }

  // Remove old elements
  oldTargetMap.forEach((elem) =>
    api.removeRowDropZone({
      getContainer: () => elem,
    })
  );

  // Add new elements
  newTargetMap.forEach((elem, dartboardDuid) =>
    api.addRowDropZone({
      getContainer: () => elem,
      onDragEnter: () => elem.classList.add(DROP_HOVER_STYLE),
      onDragLeave: () => elem.classList.remove(DROP_HOVER_STYLE),
      onDragStop: () => {
        resetDragging();
        elem.classList.remove(DROP_HOVER_STYLE);
        actions.visualization.moveTasksToDartboardOrTrash(dartboardDuid);
      },
    })
  );
};
watch(() => appStore.taskDropTargets, updateTaskDropTargets, { deep: true });

const setVisualColumnWidths = () => {
  const api = gridApi.value;
  if (!api || api.isDestroyed()) {
    return;
  }

  const updatedColState = api
    .getAllDisplayedColumns()
    .map((col) => {
      const { field } = col.getColDef();
      if (!field) {
        return undefined;
      }
      return {
        colId: col.getColId(),
        ...appStore.getColumnWidthParams(field),
      };
    })
    .filter((e) => !!e);

  api.applyColumnState({ state: updatedColState });
};

const onColumnResized = (event: ColumnResizedEvent) => {
  if (!event.finished) {
    isColumnResizing.value = true;
    return;
  }
  isColumnResizing.value = false;

  const dartboardOrView = appStore.currentPage;
  const { column } = event;

  if (
    !column ||
    !dartboardOrView ||
    !gridApi.value ||
    (dartboardOrView.pageKind !== PageKind.DARTBOARD && dartboardOrView.pageKind !== PageKind.VIEW)
  ) {
    return;
  }

  const { field } = column.getColDef();
  if (!field) {
    return;
  }

  dataStore.updatePage(
    {
      duid: dartboardOrView.duid,
      propertyWidthMap: {
        ...dartboardOrView.propertyWidthMap,
        [field]: column.getActualWidth(),
      },
    },
    dartboardOrView.pageKind
  );
};

const rowClassRules = {
  "opacity-50": (params: RowClassParams<TaskAbsenteeMaybe>) => params.data?.absentee,
};

const { isDraggingColumn, onDragStarted, onDragStopped } = useListColumnChange((newOrderDuids) =>
  appStore.setPropertyOrderDuids(newOrderDuids)
);

const onGridReady = (params: GridReadyEvent<RowItem>) => {
  emit("gridReady", params);
  gridApi.value = params.api;

  updateTaskDropTargets(appStore.taskDropTargets, appStore.taskDropTargets);
};

const dragBetweenProperties = (
  movingRows: IRowNode<TaskWithGroup>[],
  overRow: IRowNode<RowItem> | undefined,
  isOverGroupContainer: boolean
) => {
  if (appStore.groupBy === UNGROUPED_PSEUDO_GROUP_BY) {
    return;
  }

  tasksToUpdateOnDragEnd.value = [];
  let overGroupRow = overRow ?? null;
  while (overGroupRow?.data && !overGroupRow.data.isRoot) {
    overGroupRow = overGroupRow.parent;
  }
  let overGroupId = overGroupRow?.data?.id;
  if (isOverGroupContainer) {
    const groupElement = [...document.querySelectorAll(".dart-collapsed-header")].find((elem) => {
      const element = elem as HTMLElement;
      const { top, bottom, left, right } = element.getBoundingClientRect();
      return top <= mouseY.value && mouseY.value <= bottom && left <= mouseX.value && mouseX.value <= right;
    });

    if (groupElement) {
      overGroupId = groupElement.getAttribute("data-group") ?? undefined;
    } else {
      return;
    }
  }
  if (!overGroupId || isUngrouped.value) {
    return;
  }

  appStore.setHoverGroupId(overGroupId);
  const { property } = appStore.groupByDefinition;
  const propertyConfig = getPropertyConfig(property.kind);
  const newValue = calcPropertyValueFromGroupStr(overGroupId, property.kind);

  const movingTasks = movingRows.map((e) => e.data).filter((e): e is TaskWithGroup => !!e);
  const updates: TaskUpdate[] = movingTasks
    .filter((e) => propertyConfig.getValue(property, e) !== newValue)
    .map((task) => ({
      duid: task.duid,
      ...propertyConfig.getPartialTask(property, task, newValue),
    }));

  const isToCollapsedGroup = appStore.collapsedGroups.includes(overGroupId);
  if (isToCollapsedGroup) {
    tasksToUpdateOnDragEnd.value = updates;
    return;
  }

  dataStore.updateTasks(updates, { noBackend: true, noCelebrate: true, skipRemap: true });
};

const onRowDragEnter = (event: RowDragEnterEvent<Task>) => {
  const movingRow = getReactiveTaskNode(event.node, appStore);
  let movingRows = event.nodes.map((e) => getReactiveTaskNode(e, appStore));

  if (
    (movingRows.some((e) => e.uiLevel === 0) && !isUngrouped.value) ||
    movingRows.some((e) => !e.id || isFillerRow(e.id))
  ) {
    return;
  }

  if (!movingRow.isSelected()) {
    movingRow.setSelected(true, true);
    movingRows = [movingRow];
  }

  let text = `${movingRows.length} tasks`;
  if (movingRows.length === 1) {
    text = movingRows[0].data?.title ?? "";
    if (text.length > 12) {
      text = `${text.substring(0, 11)}…`;
    }
  }
  actions.visualization.updateGhostStyle({ text });

  const { property } = appStore.groupByDefinition;
  const propertyConfig = getPropertyConfig(property.kind);

  dragStartX.value = event.event.clientX;
  lastMouseY = mouseY.value;

  movingRows.forEach((row) => {
    const task = row.data;
    if (!task) {
      return;
    }

    if (!isUngrouped.value) {
      taskToOriginalPropertyValueMap.set(task.duid, propertyConfig.getValue(property, task));
    }
    taskToOriginalParentRelationshipDuidMap.set(
      task.duid,
      dataStore.getRelationshipsByKindKind(task, RelationshipKindKind.PARENT_OF, false)[0]?.duid ?? null
    );
  });
};

const floorToZero = (value: number): number => (value > 0 ? Math.floor(value) : Math.ceil(value));

const onRowDragMove = (event: RowDragMoveEvent<RowItem>) => {
  if (dragStartX.value === undefined) {
    return;
  }

  const movingRow = getReactiveTaskNode(event.node as IRowNode<TaskWithGroup>, appStore);
  const movingRows = (event.nodes as IRowNode<TaskWithGroup>[]).map((e) => getReactiveTaskNode(e, appStore));

  if (
    (movingRows.some((e) => e.uiLevel === 0) && !isUngrouped.value) ||
    movingRows.some((e) => !e.id || isFillerRow(e.id))
  ) {
    return;
  }
  const overRow = event.overNode && getReactiveTaskNode(event.overNode as IRowNode<TaskWithGroup>, appStore);
  const indentLevelChange = props.indentDrag
    ? floorToZero((event.event.clientX - dragStartX.value) / PX_PER_INDENT_LEVEL)
    : 0;
  if (indentLevelChange === 0 && overRow && movingRows.includes(overRow)) {
    return;
  }

  const newOverId = overRow?.id;
  const newMouseY = mouseY.value;
  const newMoveWasUp =
    (lastMouseY ? (newMouseY === lastMouseY ? lastMoveWasUp : newMouseY < lastMouseY) : undefined) ??
    (movingRow.data?.order ?? "") > (overRow?.data?.order ?? "");
  const isOverGroupContainer = isGroupContainer(overRow?.data?.id ?? "");
  if (
    !isOverGroupContainer &&
    newOverId === lastOverId &&
    newMoveWasUp !== undefined &&
    newMoveWasUp === lastMoveWasUp
  ) {
    return;
  }
  lastOverId = newOverId;
  lastMouseY = newMouseY;
  lastMoveWasUp = newMoveWasUp;

  dragStartX.value = event.event.clientX;
  dragBetweenProperties(movingRows, overRow, isOverGroupContainer); // may be better to move this into reorderRows
  movingRows.sort(makeStringComparator((row) => row.data?.order ?? ""));
  actions.visualization.reorderRows(movingRow, movingRows, overRow, {
    noBackend: true,
    indentLevelChange,
  });
};

const onRowDragEnd = (event: RowDragEndEvent<RowItem>) => {
  const movingRows = (event.nodes as IRowNode<Task>[]).map((e) => getReactiveTaskNode(e, appStore));
  if (
    (movingRows.some((e) => e.uiLevel === 0) && !isUngrouped.value) ||
    movingRows.some((e) => !e.id || isFillerRow(e.id))
  ) {
    resetDragging();
    return;
  }

  if (tasksToUpdateOnDragEnd.value.length > 0) {
    dataStore.updateTasks(tasksToUpdateOnDragEnd.value, { noBackend: true, noCelebrate: true, skipRemap: true });
    tasksToUpdateOnDragEnd.value = [];
  }

  const movingTasks = movingRows
    .map((e) => e.data)
    // need to do this because collapsed groups are just updated above
    // and movingRow data will not have been updated by the time this runs
    .map((e) => (e !== undefined ? dataStore.getTaskByDuid(e.duid) : undefined))
    .filter((e): e is Task => !!e);

  const { property } = appStore.groupByDefinition;
  const propertyConfig = getPropertyConfig(property.kind);

  const parentKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;

  const updatedGroupIds: Set<string> = new Set();

  // TODO transactionify
  movingTasks.forEach((task) => {
    const update = {
      duid: task.duid,
      order: task.order,
    };

    if (!isUngrouped.value) {
      const oldPropertyValue = taskToOriginalPropertyValueMap.get(task.duid);
      const newPropertyValue = propertyConfig.getValue(property, task);
      if (oldPropertyValue !== undefined && newPropertyValue !== undefined && oldPropertyValue !== newPropertyValue) {
        updatedGroupIds.add((oldPropertyValue ?? NO_VALUE_GROUP_BY_KEY).toString());
        updatedGroupIds.add((newPropertyValue ?? NO_VALUE_GROUP_BY_KEY).toString());
        Object.assign(update, propertyConfig.getPartialTask(property, task, newPropertyValue));
      }
    }

    const oldParentRelationshipDuid = taskToOriginalParentRelationshipDuidMap.get(task.duid);
    const newParentRelationship = dataStore.getRelationshipsByKindKind(task, RelationshipKindKind.PARENT_OF, false)[0];
    const relationshipDelete = oldParentRelationshipDuid
      ? { taskDuid: task.duid, relationshipDuid: oldParentRelationshipDuid }
      : undefined;
    const relationshipCreate = newParentRelationship
      ? {
          sourceDuid: newParentRelationship.targetDuid,
          targetDuid: task.duid,
          relationshipKindDuid: parentKindDuid,
        }
      : undefined;
    dataStore.deleteAndCreateRelationshipAndUpdateTask(relationshipDelete, relationshipCreate, update, {
      celebrateOverride: true,
    });
  });

  appStore.setHoverGroupId(null);
  resetDragging();
};

const onSelectionChanged = () => {
  if (props.ignoreSelectionAndHover || isDraggingColumn.value) {
    return;
  }

  const visualization = appStore.getActiveVisualization();

  appStore.selectedTaskDuids = new Set(
    visualization
      .getSelectedRows()
      .map((e) => e?.data?.duid)
      .filter((e): e is string => e !== undefined)
  );
};

const onCellMouseOut = (event: CellMouseOutEvent) => {
  const node = getReactiveTaskNode(event.node, appStore);
  if (props.ignoreSelectionAndHover || isDraggingColumn.value) {
    return;
  }

  const visualization = appStore.getActiveVisualization();

  if (node.data?.duid !== appStore.hoverRow?.data?.duid) {
    return;
  }

  if (window.CommandBar.isOpen()) {
    appStore.hoverClosedWithBarOpen = true;
    return;
  }
  appStore.hoverRow = null;
  appStore.updateSelectionInCommandBar(visualization.getSelectedRows().length > 0);
};

const pressStartX = ref<number | undefined>();
const pressStartY = ref<number | undefined>();
const pressSecondFrame = ref(false);
watch(mousePressed, (value) => {
  pressStartX.value = value ? mouseX.value : undefined;
  pressStartY.value = value ? mouseY.value : undefined;
  if (!value) {
    pressSecondFrame.value = false;
  }
});

const onCellMouseOver = (event: CellMouseOverEvent<Task>) => {
  emit("cellMouseOver", event);
  if (props.ignoreSelectionAndHover || isDraggingColumn.value || isColumnResizing.value) {
    return;
  }

  const row = event.node;
  appStore.hoverRow = row;
  appStore.updateSelectionInCommandBar(true);

  if (dragStartX.value !== undefined || !mousePressed.value) {
    pressSecondFrame.value = false;
    return;
  }

  const visualization = appStore.getActiveVisualization();

  const { rowIndex } = row;
  const gridScrollTop = document.getElementsByClassName("ag-body-viewport")[0]?.scrollTop;
  if (pressStartY.value === undefined || rowIndex === null || gridScrollTop === undefined) {
    return;
  }
  if (!pressSecondFrame.value) {
    pressSecondFrame.value = true;
    return;
  }
  justDragSelected.value = true;

  if (pressStartY.value > mouseY.value) {
    const rowCount = visualization.count();
    for (let i = rowIndex; i < rowCount; i += 1) {
      const nextRow = visualization.getRowByIndex(i);
      if (!nextRow) {
        continue;
      }
      const { rowTop: nextRowTop, rowHeight: nextRowHeight } = nextRow;
      if (
        nextRowTop === null ||
        nextRowHeight === undefined ||
        nextRowHeight === null ||
        pressStartY.value <= nextRowTop + nextRowHeight + gridTop.value - gridScrollTop
      ) {
        break;
      }
      nextRow.setSelected(true, false);
    }
  } else {
    for (let i = rowIndex; i >= 0; i -= 1) {
      const prevRow = visualization.getRowByIndex(i);
      if (!prevRow) {
        continue;
      }
      prevRow.setSelected(true, false);
      const { rowTop: prevRowTop, rowHeight: prevRowHeight } = prevRow;
      if (
        prevRowTop === null ||
        prevRowHeight === undefined ||
        prevRowHeight === null ||
        pressStartY.value >= prevRowTop + prevRowHeight + gridTop.value - gridScrollTop
      ) {
        break;
      }
    }
  }
};

const selectionStyle = computed(() => {
  if (
    isColumnResizing.value ||
    dragStartX.value !== undefined ||
    !pressSecondFrame.value ||
    pressStartX.value === undefined ||
    pressStartY.value === undefined
  ) {
    return undefined;
  }
  return {
    left: `${Math.min(mouseX.value, pressStartX.value)}px`,
    top: `${Math.min(mouseY.value, pressStartY.value)}px`,
    width: `${Math.abs(mouseX.value - pressStartX.value)}px`,
    height: `${Math.abs(mouseY.value - pressStartY.value)}px`,
  };
});

const isRowSelectable = (row: RowNode) => !row.data.isRoot && row.id !== undefined && !isFillerRow(row.id);

watch(
  () => appStore.currentPage,
  () => {
    setVisualColumnWidths();
  }
);

onMounted(() => {
  emit("onMounted", currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null);
});

defineExpose({
  api: gridApi,
  justDragSelected,
});
</script>

<template>
  <Styles :editor-mode="editorMode">
    <!--
      sections below are
      - meta
      - columns
      - rows
      - drag
      - subtasks
      - context menu
      - selection
      - appearance
    -->
    <AgGridVue
      ref="grid"
      v-bind="$attrs"
      :dom-layout="domLayoutNorm"
      :default-col-def="defaultColDef"
      :column-defs="columns"
      :row-data="tasks"
      :get-row-id="getRowId"
      single-click-edit
      :row-drag="!pageStore.isPublicView && !pageStore.isMobile"
      :row-drag-entire-row="!pageStore.hasTouch && !pageStore.isPublicView && !pageStore.isMobile"
      row-drag-multi-row
      suppress-drag-leave-hides-columns
      suppress-context-menu
      suppress-no-rows-overlay
      suppress-header-focus
      :tree-data="treeData"
      :get-data-path="getDataPath"
      :is-full-width-row="isFullWidthRow"
      :full-width-cell-renderer="fullWidthCellRenderer"
      :is-group-open-by-default="isGroupOpenByDefault"
      :auto-group-column-def="autoGroupColumnDef"
      :suppress-column-virtualisation="suppressColumnVirtualisation"
      :suppress-row-virtualisation="suppressRowVirtualisation"
      :is-external-filter-present="isExternalFilterPresent"
      :does-external-filter-pass="doesExternalFilterPass"
      :row-selection="{
        mode: 'multiRow',
        checkboxes: false,
        headerCheckbox: false,
        enableClickSelection: true,
        isRowSelectable,
      }"
      :row-class-rules="rowClassRules"
      :header-height="headerHeight"
      :row-height="rowHeight"
      :get-row-height="getRowHeight"
      :suppress-row-drag="pageStore.isMobile"
      :icons="{ ['rowDrag']: VERTICAL_DRAG_HANDLE_HTML_STR }"
      class="ag-theme-material"
      animate-rows
      suppress-cell-focus
      @grid-ready="onGridReady"
      @column-resized="onColumnResized"
      @row-drag-enter="onRowDragEnter"
      @row-drag-move="onRowDragMove"
      @row-drag-end="onRowDragEnd"
      @row-group-opened="emit('rowGroupOpened', $event)"
      @selection-changed="onSelectionChanged"
      @cell-mouse-out="onCellMouseOut"
      @cell-mouse-over="onCellMouseOver"
      @drag-started="onDragStarted"
      @drag-stopped="onDragStopped" />
    <slot />
  </Styles>
  <Teleport v-if="selectionStyle" to="body">
    <div class="absolute bg-primary-base/20" :style="selectionStyle" />
  </Teleport>
</template>
