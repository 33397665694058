<script setup lang="ts">
import { useLexicalComposer } from "lexical-vue";
import { ref } from "vue";

import { XIcon } from "~/icons";
import { RecommendationAction, type RecommendationKind } from "~/shared/enums";

import { EVENT_CLEANUP_RECOMMENDATION_NODE, RECOMMENDATION_KIND_TO_ICON_MAP } from "../const";

const props = defineProps<{
  nodeKey: string;
  kind: RecommendationKind;
}>();

const editor = useLexicalComposer();

const showCancel = ref(false);

// eslint-disable-next-line no-restricted-syntax
setTimeout(() => {
  showCancel.value = true;
}, 3000);

const discard = () => {
  editor.dispatchCommand(EVENT_CLEANUP_RECOMMENDATION_NODE, {
    key: props.nodeKey,
    action: RecommendationAction.DISCARD,
  });
};
</script>

<template>
  <div class="relative flex h-[60px] flex-col items-center justify-center">
    <div class="absolute right-0.5 top-2">
      <button
        type="button"
        :class="!showCancel && 'invisible'"
        class="flex items-center rounded p-0.5 text-recommendation-base hover:bg-recommendation-base/20"
        aria-label="Discard recommendation"
        @click="discard"
        @keydown.enter="discard">
        <XIcon class="icon-sm" />
      </button>
    </div>
    <component :is="RECOMMENDATION_KIND_TO_ICON_MAP.get(kind)" class="size-8 animate-pulse text-recommendation-base" />
  </div>
</template>
