<script setup lang="ts">
import { useMediaQuery } from "@vueuse/core";

import AddPropertyDropdown from "~/components/dumb/AddPropertyDropdown.vue";
import { ViewKind } from "~/shared/enums";
import { useAppStore, usePageStore } from "~/stores";

const appStore = useAppStore();
const pageStore = usePageStore();

const isPrinting = useMediaQuery("print");
</script>

<template>
  <AddPropertyDropdown
    v-if="!isPrinting && !pageStore.isPublicView && appStore.currentPage?.kind !== ViewKind.TRASH"
    is-dots-menu
    @set-property-duid-visibility="appStore.setPropertyDuidVisibility" />
</template>
