<script setup lang="ts">
import LoadingSpinner from "~/components/dumb/LoadingSpinner.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import { useDataStore } from "~/stores";
import { asyncState } from "~/utils/async";
import { getDocLink } from "~/utils/common";

const props = defineProps<{
  duid: string;
}>();

const dataStore = useDataStore();

const { state: doc, isLoading } = asyncState(() => dataStore.getDocByDuid(props.duid), {
  default: undefined,
  watch: () => props.duid,
});
</script>

<template>
  <component
    :is="isLoading ? 'div' : 'RouterLink'"
    v-if="isLoading || (doc && !doc.inTrash)"
    class="flex h-9 w-full items-center gap-3 truncate rounded px-3 bg-std"
    :class="isLoading ? 'cursor-default' : 'cursor-pointer hover:bg-md'"
    :to="isLoading || !doc ? undefined : getDocLink(doc)">
    <LoadingSpinner v-if="isLoading" small />
    <template v-else-if="doc">
      <span class="grow select-none truncate text-sm font-medium text-md">
        {{ doc.title }}
      </span>

      <PageIcon :page="doc" class="icon-md" />
    </template>
  </component>
</template>
