<template>
  <svg
    viewBox="-0.75 -0.75 24 24"
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    height="24"
    width="24">
    <path
      d="M5.9390625 4.8225C4.3884375 3.6984375 1.875 2.8284374999999997 1.875 5.596875c0 0.553125 0.328125 4.6434375 0.52125 5.3071874999999995C3.0646875000000002 13.213125 5.3306249999999995 13.4821875 7.5 13.125c-3.7921875 0.6234375000000001 -4.5834375000000005 3.0075000000000003 -2.5003124999999997 5.071875C5.9653125000000005 19.15125 6.793125000000001 19.6875 7.5 19.6875c1.875 0 2.938125 -2.5959375000000002 3.28125 -3.28125 0.3121875 -0.6253125 0.46875 -1.0940625 0.46875 -1.40625 0 0.3121875 0.15656250000000002 0.7809375 0.46875 1.40625 0.343125 0.6853125 1.40625 3.28125 3.28125 3.28125 0.706875 0 1.5346875 -0.5353125 2.5003124999999997 -1.490625C19.5834375 16.1315625 18.7921875 13.7475 15 13.125c2.169375 0.35625 4.434375 0.088125 5.10375 -2.2209375000000002 0.193125 -0.66375 0.52125 -4.755 0.52125 -5.3071874999999995 0 -2.7684374999999997 -2.5125 -1.8984375 -4.0640625 -0.7743749999999999C14.411249999999999 6.380625 12.0984375 9.555 11.25 11.25c-0.8484375000000001 -1.695 -3.16125 -4.869375 -5.3109375 -6.4275z"
      stroke-width="1.5" />
  </svg>
</template>
