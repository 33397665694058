import { computed, ref } from "vue";

import { makeUuid } from "./common";

export class ThrottleManager<Args extends unknown[]> {
  static allThrottleManagers = ref<Map<string, ThrottleManager<unknown[]>>>(new Map());

  #id = makeUuid();

  // TODO func, args, and timeout should be private but can't be right now because private fields don't work through proxies, which this becomes when it is computed at the bottom of the file
  func: (...args: Args) => void;

  args = ref<Args | undefined>();

  #delayMs: number;

  timeout: ReturnType<typeof setTimeout> | null;

  working = computed(() => this.args.value !== undefined);

  constructor(func: (...args: Args) => void, delayMs: number) {
    this.func = func;
    this.#delayMs = delayMs;
    this.timeout = null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ThrottleManager.allThrottleManagers.value.set(this.#id, this as any);
  }

  destroy() {
    this.finish();
    ThrottleManager.allThrottleManagers.value.delete(this.#id);
  }

  #execute() {
    if (!this.args.value) {
      return;
    }
    this.timeout = null;
    this.func(...this.args.value);
    this.args.value = undefined;
  }

  run(...args: Args) {
    this.args.value = args;
    if (this.timeout) {
      return;
    }
    // eslint-disable-next-line no-restricted-syntax
    this.timeout = setTimeout(() => this.#execute(), this.#delayMs);
  }

  finish() {
    if (!this.timeout) {
      return;
    }
    clearTimeout(this.timeout);
    this.#execute();
  }

  cancel() {
    const args = this.args.value;
    this.args.value = undefined;
    if (!this.timeout) {
      return args;
    }
    clearTimeout(this.timeout);
    this.timeout = null;
    return args;
  }
}

export const allThrottleManagers = computed(() => [...ThrottleManager.allThrottleManagers.value.values()]);
