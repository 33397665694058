import { TaskLinkKind } from "~/shared/enums";

export const MAC_APP_DOWNLOAD_LINK = "https://d1n6pl8ua4do9k.cloudfront.net/desktop-builds/mac/Dart.dmg";
export const WINDOWS_APP_DOWNLOAD_LINK =
  "https://d1n6pl8ua4do9k.cloudfront.net/desktop-builds/windows/Dart%20setup.exe";
export const LINUX_APP_DOWNLOAD_LINK = "https://d1n6pl8ua4do9k.cloudfront.net/desktop-builds/linux/Dart.AppImage";

export const IOS_APP_STORE_URL = "https://apps.apple.com/us/app/dart-task-management/id1614157527";
export const ANDROID_APP_STORE_URL = "https://play.google.com/store/apps/details?id=com.itsdart.app.dart";

export const GITHUB_LINK_KINDS = new Set([
  TaskLinkKind.GITHUB_BRANCH,
  TaskLinkKind.GITHUB_PULL_REQUEST,
  TaskLinkKind.GITHUB_EXPANSION,
]);
