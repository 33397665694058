<script setup lang="ts">
import { useWindowSize } from "@vueuse/core";
import type { SerializedEditorState } from "lexical";
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref } from "vue";

import DraggableDivider from "~/components/dumb/DraggableDivider.vue";
import DropTarget from "~/components/dumb/DropTarget.vue";
import TaskDetailContent from "~/components/task/taskDetail/TaskDetailContent.vue";
import TaskDetailPropsAndDesc from "~/components/task/taskDetail/TaskDetailPropsAndDesc.vue";
import Title from "~/components/task/Title.vue";
import { TASK_DETAIL_TITLE_WRAPPER_ID } from "~/components/text/const";
import { DropFilesIllustration } from "~/illustrations";
import router from "~/router/router";
import { EditorMode, RelationshipKindKind, TaskDetailMode } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const content = ref<InstanceType<typeof TaskDetailContent> | null>(null);

const id = computed(() => currentInstance?.uid);
const isFullscreen = computed(() => appStore.taskDetailMode === TaskDetailMode.FULLSCREEN);
const isOverlay = computed(() => appStore.taskDetailMode === TaskDetailMode.OVERLAY);

const { width: screenWidth } = useWindowSize();

const showPaneOnly = computed(() => screenWidth.value <= 768);

const onSavePaneSize = (width: number) => {
  userStore.setFullscreenRightbarWidthPx(width);
};

const addAttachments = (files: File[]) => content.value?.addAttachments(files);

const createSubtask = () => content.value?.createSubtask();

const deselectSubtasks = () => content.value?.deselectSubtasks();

const generatePropertyRecommendations = () =>
  content.value?.recommendationButton && content.value?.generatePropertyRecommendations();

const generateSubtaskRecommendations = () => content.value?.generateSubtaskRecommendations();

const jumpToDescription = () => content.value?.jumpToDescription();

const setDescription = (newDescription: SerializedEditorState) => content.value?.setDescription(newDescription);

const trimDescription = () => content.value?.trimDescription();

const jumpToComments = (selectLast: boolean = true) => {
  if (selectLast) {
    nextTick(() => {
      if (!appStore.taskOpenInDetail) {
        return;
      }
      const lastRealRoot = dataStore
        .getCommentsByTaskDuid(appStore.taskOpenInDetail.duid)
        .findLast((e) => !e.rootDuid && !e.isDraft);
      if (lastRealRoot) {
        router.push({ query: { c: lastRealRoot.duid } });
      }
    });
  }
  content.value?.focusNewComment();
};

const openFilePicker = () => content.value?.openFilePicker();

const setRelationship = (kind: RelationshipKindKind, isForward?: boolean) =>
  content.value?.setRelationship(kind, isForward);

const setSubtask = () => content.value?.setSubtask();

const setDocRelationship = () => content.value?.setDocRelationship();

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.taskDetail = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  if (id.value !== appStore.taskDetail?.id) {
    return;
  }
  appStore.taskDetail = null;
});

defineExpose({
  createSubtask,
  generatePropertyRecommendations,
  generateSubtaskRecommendations,
  id,
  isDescriptionReady: computed(() => content.value?.isDescriptionReady ?? false),
  jumpToComments,
  jumpToDescription,
  openFilePicker,
  setDescription,
  setDocRelationship,
  setRelationship,
  setSubtask,
  trimDescription,
});
</script>

<template>
  <div
    v-if="appStore.taskOpenInDetail"
    :id="appStore.taskOpenInDetail.inTrash ? 'trash-rightbar' : undefined"
    class="flex size-full justify-center bg-std"
    @click="deselectSubtasks"
    @keydown.enter="deselectSubtasks">
    <DropTarget :disabled="appStore.taskOpenInDetail.inTrash || pageStore.isPublicView" @drop="addAttachments">
      <template #main>
        <DraggableDivider
          v-if="(isFullscreen || isOverlay) && !showPaneOnly"
          :pane-width-px="userStore.fullscreenRightbarWidthPx"
          :pane-min-px="400"
          :content-min-px="400"
          styles="flex"
          @save="onSavePaneSize">
          <template #default>
            <div class="flex size-full flex-col gap-3.5 pt-12">
              <div :id="TASK_DETAIL_TITLE_WRAPPER_ID" :class="isFullscreen ? 'px-[73px]' : 'px-[57px]'">
                <Title :task="appStore.taskOpenInDetail" :editor-mode="EditorMode.DETAIL" @enter="jumpToDescription" />
              </div>

              <TaskDetailPropsAndDesc
                ref="propsAndDesc"
                class="overflow-y-auto extra-overscroll print:overflow-hidden"
                :class="isFullscreen ? 'pl-16 pr-[49px]' : 'pl-12 pr-[33px]'"
                :task="appStore.taskOpenInDetail" />
            </div>
          </template>

          <template #pane>
            <div class="flex size-full flex-col items-center gap-3 border-l pt-1 border-lt">
              <TaskDetailContent
                ref="content"
                show-pane-only
                @create-subtask="createSubtask"
                @generate-subtask-recommendations="generateSubtaskRecommendations"
                @set-doc-relationship="setDocRelationship"
                @set-relationship="setRelationship"
                @set-subtask="setSubtask"
                @generate-property-recommendations="generatePropertyRecommendations" />
            </div>
          </template>
        </DraggableDivider>
        <div v-else class="flex h-full flex-col items-center">
          <TaskDetailContent
            ref="content"
            @create-subtask="createSubtask"
            @generate-subtask-recommendations="generateSubtaskRecommendations"
            @set-doc-relationship="setDocRelationship"
            @set-relationship="setRelationship"
            @set-subtask="setSubtask"
            @generate-property-recommendations="generatePropertyRecommendations" />
        </div>
      </template>
      <template #dragOverlay>
        <div class="flex w-full flex-col items-center justify-evenly">
          <div class="flex flex-col items-center">
            <DropFilesIllustration />
            <span class="mt-6 text-center text-2xl text-md">Drop to attach</span>
          </div>
          <div />
        </div>
      </template>
    </DropTarget>
  </div>
</template>

<style>
#trash-rightbar .ag-root .ag-cell,
#trash-rightbar .group\/text-editor,
#trash-rightbar .group\/title,
#trash-rightbar .dart-large-chips {
  @apply !pointer-events-none !cursor-default !select-none;
}
</style>
