import { type Transaction as TransactionGenerated } from "~/api/generated";

import type { Filter, FilterAssignee, FilterSet } from "./types";

export { type TransactionResponse, TransactionsService } from "~/api/generated";
export { type ApiResult } from "~/api/generated/core/ApiResult";

export type Transaction = Omit<TransactionGenerated, "duid"> & {
  duid: string;
};

export const filterHasApplicability = (filter: Filter): filter is FilterSet | FilterAssignee =>
  "applicability" in filter;

export const filterHasConnector = (filter: Filter): filter is FilterSet | FilterAssignee => "connector" in filter;
