import type { Store } from "pinia";

import { dismissAllTemporaryNotifications, getTemporaryNotificationCount, notify } from "~/components/notifications";
import { goBackOrHome } from "~/router/common";
import { ViewKind } from "~/shared/enums";

export const handleOneStepTowardHomeState = (store: Store) => {
  const appStore = store.$useAppStore();

  const router = store.$router;
  const route = router.currentRoute.value;

  // Close the fullscreen pages if they're open
  if (route.name === "console" || route.name === "latency") {
    goBackOrHome();
    return true;
  }

  // Dismiss all notifications if there are any
  if (getTemporaryNotificationCount() > 0) {
    dismissAllTemporaryNotifications();
    return true;
  }

  // Close the mobile leftbar if it's open
  if (appStore.mobileLeftbarOpen) {
    appStore.setMobileLeftbarOpen(false);
    return true;
  }

  // Close the settings modal if it's open
  if (appStore.settingsModalOpen) {
    appStore.setSettingsModalOpen(false);
    return true;
  }

  // Close app download modal if it's open
  if (appStore.appDownloadModalOpen) {
    appStore.setAppDownloadModalOpen(false);
    return true;
  }

  // Close the feedback modal if it's open
  if (appStore.feedbackModalOpen) {
    appStore.setFeedbackModalOpen(false);
    return true;
  }

  // Close the shortcuts modal if its open
  if (appStore.shortcutsModalOpen) {
    appStore.setShortcutsModalOpen(false);
    return true;
  }

  // Close the dartboard permissions modal if it's open
  if (appStore.pageInPermissionsModal) {
    appStore.setPageInPermissionsModal(null);
    return true;
  }

  // Close the link modal if it's open
  if (appStore.linkOpenInModal) {
    appStore.setLinkOpenInModal(null);
    return true;
  }

  // Close the notion link modal if it's open
  if (appStore.taskInNotionLinkModal) {
    appStore.setTaskInNotionLinkModalDuid(null);
    return true;
  }

  // Close the reminder modal if it's open
  if (appStore.tasksOpenInReminderModal.length !== 0) {
    appStore.setTasksOpenInReminderModal([]);
    return true;
  }

  // Close the form modal if it's open
  if (appStore.formInInternalModal) {
    appStore.setFormOpenInInternalModal(null);
    return true;
  }

  // Close the new task modal if it's open
  if (appStore.tcmOpen) {
    appStore.setTcmOpen(false);
    return true;
  }

  // Close the media modal if it's open
  if (appStore.mediaOpenInFullscreen) {
    appStore.setFullscreenMediaModalOpen(null);
    return true;
  }

  // Close detail
  if (appStore.taskDetailOpen) {
    appStore.setTaskDetailOpen(false);
    return true;
  }

  // If the visualization isn't loaded (e.g. on login page), don't continue
  if (!appStore.hasPrimaryVisualization()) {
    return false;
  }

  const visualization = appStore.getActiveVisualization();

  // Deselect all tasks
  if (visualization.getSelectedRows().length > 0) {
    visualization.deselectAll();
    return true;
  }

  // Navigate from search to last page (or home)
  if (appStore.currentPage?.kind === ViewKind.SEARCH) {
    goBackOrHome();
    return true;
  }

  return false;
};

const stepTowardHomeState = (event: KeyboardEvent, store: Store) => {
  const actionHappened = handleOneStepTowardHomeState(store);
  if (actionHappened) {
    event.stopImmediatePropagation();
    event.preventDefault();
  }
};

// Register Global Event Listeners
const registerGlobalEventListeners = (store: Store) => {
  const pageStore = store.$usePageStore();

  window.addEventListener("keydown", (event) => {
    switch (event.key) {
      case "Escape": {
        stepTowardHomeState(event, store);
        break;
      }
      case "s": {
        if (pageStore.isMac ? event.metaKey : event.ctrlKey) {
          event.preventDefault();
          notify({
            id: "auto-save-notification",
            message: "Dart automatically saves all of your work, you don't have to do anything to save",
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  });
};

export default registerGlobalEventListeners;
