<script setup lang="ts">
import { type Edge, MarkerType, type Node, VueFlow } from "@vue-flow/core";
import moment from "moment";
import { computed } from "vue";

import { colorsByTheme } from "~/constants/style";
import { RelationshipKindKind } from "~/shared/enums";
import type { Relationship, Task } from "~/shared/types";
import { useDataStore, usePageStore } from "~/stores";

import { COMPLETED_STATUS_KINDS } from "../constants";
import { getDiffPx, getPixelsTo } from "./common";
import { DEFAULT_BLOCK_WIDTH_PX, TASK_ROW_HEIGHT } from "./constants";
import RoadmapTaskNode from "./RoadmapTaskNode.vue";
import type { MovingTaskOverride, RoadmapConfig, RoadmapValues } from "./shared";

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  roadmapValues: RoadmapValues;
  movingTaskOverride: MovingTaskOverride;
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();

const tasks = computed(() =>
  !props.movingTaskOverride
    ? props.roadmapValues.tasks
    : props.roadmapValues.tasks.map((task) =>
        props.movingTaskOverride && task.duid === props.movingTaskOverride.duid
          ? { ...task, ...props.movingTaskOverride }
          : task
      )
);
const taskMap = computed(() => new Map(tasks.value.map((task) => [task.duid, task])));
const colors = computed(() => colorsByTheme[pageStore.theme]);
const elements = computed(() => {
  const nodes: Node[] = [];
  const taskRelationships = new Map<Task, Relationship[]>();

  tasks.value.forEach((task, index) => {
    const { rolledUpStartAt, rolledUpDueAt } = dataStore.getTaskDates(task);
    if (!rolledUpStartAt && !rolledUpDueAt) {
      return;
    }
    taskRelationships.set(task, dataStore.getRelationshipsByKindKind(task, RelationshipKindKind.BLOCKS));

    const left = rolledUpStartAt
      ? getPixelsTo(props.roadmapConfig, rolledUpStartAt)
      : getPixelsTo(props.roadmapConfig, rolledUpDueAt as string) - DEFAULT_BLOCK_WIDTH_PX;
    const width =
      rolledUpStartAt && rolledUpDueAt
        ? getDiffPx(props.roadmapConfig, rolledUpStartAt, rolledUpDueAt)
        : DEFAULT_BLOCK_WIDTH_PX;

    nodes.push({
      id: task.duid,
      label: task.duid,
      type: "custom",
      width: width - 4,
      height: TASK_ROW_HEIGHT,
      position: { x: left + 2, y: TASK_ROW_HEIGHT * index },
    });
  });

  const edges: Edge<string>[] = [];
  taskRelationships.forEach((relationships, task) => {
    relationships.forEach((relationship) => {
      if (!relationship.isForward || !taskMap.value.has(relationship.targetDuid)) {
        return;
      }

      const targetTask = taskMap.value.get(relationship.targetDuid);
      const { rolledUpDueAt } = dataStore.getTaskDates(task);
      const targetDates = targetTask ? dataStore.getTaskDates(targetTask) : null;

      const isAfterBlockee =
        !!rolledUpDueAt &&
        !!targetDates?.rolledUpStartAt &&
        moment(rolledUpDueAt).isAfter(targetDates.rolledUpStartAt, "day");

      const statusKind = dataStore.getStatusByDuid(task.statusDuid)?.kind;
      const isCompletedStatus = statusKind && COMPLETED_STATUS_KINDS.has(statusKind);
      const color =
        isAfterBlockee && !isCompletedStatus
          ? colors.value.danger
          : isCompletedStatus
            ? colors.value.borderLt
            : colors.value.borderMd;

      edges.push({
        id: `${task.duid}-${relationship.targetDuid}`,
        source: task.duid,
        target: relationship.targetDuid,
        style: {
          stroke: color,
          strokeWidth: 2,
        },
        data: color, // Used for sorting
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color,
          height: 10,
          width: 10,
        },
      });
    });
  });

  // Sort edges by color
  const edgeSortOrder = [colors.value.danger, colors.value.borderMd, colors.value.borderLt];
  edges.sort((a, b) => {
    const aIndex = a.data ? edgeSortOrder.indexOf(a.data) : 0;
    const bIndex = b.data ? edgeSortOrder.indexOf(b.data) : 0;
    return bIndex - aIndex;
  });

  return { nodes, edges };
});
</script>

<template>
  <VueFlow
    :nodes="elements.nodes"
    :edges="elements.edges"
    :pan-activation-key-code="null"
    class="pointer-events-none absolute inset-0 size-full select-none"
    :style="{
      background: 'transparent',
      minWidth: `${roadmapValues.scrollWidth}px`,
      maxWidth: `${roadmapValues.scrollWidth}px`,
      minHeight: `${roadmapValues.scrollHeight}px`,
    }">
    <template #node-custom="{ data }">
      <RoadmapTaskNode :data="data" />
    </template>
  </VueFlow>
</template>

<style>
/* import the necessary styles for Vue Flow to work */
@import "@vue-flow/core/dist/style.css";

.vue-flow__node,
.vue-flow__handle {
  background: transparent;
  border: none;
}
</style>
