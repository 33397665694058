// See docs/Wait.md for more information
/* eslint-disable no-restricted-syntax */
import { type Ref, watch } from "vue";

const MAX_TIMEOUT = 2 ** 31 - 1;

const whenRef = (condition: Ref, callback: (success: boolean) => void, timeoutMs: number | undefined = 5000) => {
  if (condition.value) {
    callback(true);
    return;
  }

  const { stop } = watch(condition, (value) => {
    if (!value) {
      return;
    }
    stop();
    callback(true);
  });

  if (timeoutMs) {
    setTimeout(() => {
      stop();
      callback(false);
    }, timeoutMs);
  }
};

const whenFn = (
  condition: () => boolean,
  callback: (success: boolean) => void,
  timeoutMs: number | undefined = 5000
) => {
  const start = Date.now();
  if (condition()) {
    callback(true);
    return;
  }

  const interval = setInterval(() => {
    if (condition()) {
      clearInterval(interval);
      callback(true);
      return;
    }
    if (Date.now() - start > timeoutMs) {
      clearInterval(interval);
      callback(false);
    }
  }, 50);
};

export function when(condition: Ref, timeoutMs?: number): Promise<boolean>;
export function when(condition: Ref, callback: (success: boolean) => void, timeoutMs?: number): void;
export function when(condition: () => boolean, timeoutMs?: number): Promise<boolean>;
export function when(condition: () => boolean, callback: (success: boolean) => void, timeoutMs?: number): void;
export function when(
  condition: Ref | (() => boolean),
  callbackOrTimeout: ((success: boolean) => void) | number | undefined = 5000,
  timeoutMsMaybe: number | undefined = 5000
): Promise<boolean> | void {
  let callback: ((success: boolean) => void) | undefined;
  let timeoutMs: number | undefined;
  if (typeof callbackOrTimeout === "function") {
    callback = callbackOrTimeout;
    timeoutMs = timeoutMsMaybe;
  } else {
    timeoutMs = callbackOrTimeout;
  }

  if (typeof condition === "function") {
    if (callback) {
      return whenFn(condition, callback, timeoutMs);
    }
    return new Promise<boolean>((resolve) => {
      whenFn(condition, resolve, timeoutMs);
    });
  }

  if (callback) {
    return whenRef(condition, callback, timeoutMs);
  }
  return new Promise<boolean>((resolve) => {
    whenRef(condition, resolve, timeoutMs);
  });
}

export const runAtSpecificDatetime = (callback: () => void, datetime: Date) => {
  const targetTime = datetime.getTime();
  let delay = targetTime - new Date().getTime();
  if (delay <= 0) {
    callback();
    return;
  }
  if (delay <= MAX_TIMEOUT) {
    setTimeout(callback, delay);
    return;
  }

  const interval = setInterval(() => {
    const now = new Date().getTime();
    delay = targetTime - now;
    if (delay <= MAX_TIMEOUT) {
      clearInterval(interval);
      setTimeout(callback, delay);
    }
  }, MAX_TIMEOUT);
};

// This is a non-ideal use of setTimeout. It should be used when absolutely necessary to delay the current code a bit.
export const runAtEndOfEventQueue = (callback: () => void) => setTimeout(callback);

// This is an async function that is a non-ideal use of setTimeout. It should be used when absolutely necessary to delay the current code a bit.
export const timeout = (ms?: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
