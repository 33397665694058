import defaultTheme from "tailwindcss/defaultTheme";

import { fromHexToHexWithAlpha } from "~/utils/color";

const addAlphaPercent = (color: string, alphaPercent: number) => fromHexToHexWithAlpha(color, alphaPercent / 100);

// TODO pull this from tailwind according to https://tailwindcss.com/docs/configuration#referencing-in-java-script
export const styleConfig = {
  colors: {
    white: "#ffffff",
    "white/30": addAlphaPercent("#ffffff", 30),
    black: "#000000",
    "gray-50": "#f9fafb",
    "gray-100": "#f3f4f6",
    "gray-200": "#e5e7eb",
    "gray-300": "#d1d5db",
    "gray-400": "#9ca3af",
    "gray-500": "#6b7280",
    "gray-500/20": addAlphaPercent("#6b7280", 20),
    "gray-600": "#4b5563",
    "gray-700": "#374151",
    "gray-800": "#1f2937",
    "gray-900": "#111827",
    "gray-950": "#030712",
    "zinc-50": "#fafafa",
    "zinc-100": "#f4f4f5",
    "zinc-200": "#e4e4e7",
    "zinc-300": "#d4d4d8",
    "zinc-400": "#a1a1aa",
    "zinc-400/20": addAlphaPercent("#a1a1aa", 20),
    "zinc-500": "#71717a",
    "zinc-600": "#52525b",
    "zinc-700": "#3f3f46",
    "zinc-800": "#27272a",
    "zinc-850": "#1e1e21",
    "zinc-900": "#18181b",
    "zinc-950": "#09090b",
    "indigo-50": "#eef2ff",
    "indigo-100": "#e0e7ff",
    "indigo-200": "#c7d2fe",
    "indigo-300": "#a5b4fc",
    "indigo-400": "#818cf8",
    "indigo-500": "#6366f1",
    "indigo-500/15": addAlphaPercent("#6366f1", 15),
    "indigo-500/25": addAlphaPercent("#6366f1", 25),
    "indigo-600": "#4f46e5",
    "indigo-700": "#4338ca",
    "indigo-800": "#3730a3",
    "indigo-900": "#312e81",
    "indigo-950": "#1e1b4b",
    "green-50": "#f0fdf4",
    "green-100": "#dcfce7",
    "green-200": "#bbf7d0",
    "green-300": "#86efac",
    "green-400": "#4ade80",
    "green-500": "#22c55e",
    "green-600": "#16a34a",
    "green-700": "#15803d",
    "green-800": "#166534",
    "green-900": "#14532d",
    "green-950": "#052e16",
    "orange-50": "#fff7ed",
    "orange-100": "#ffedd5",
    "orange-200": "#fed7aa",
    "orange-300": "#fdba74",
    "orange-400": "#fb923c",
    "orange-500": "#f97316",
    "orange-600": "#ea580c",
    "orange-700": "#c2410c",
    "orange-800": "#9a3412",
    "orange-900": "#7c2d12",
    "orange-950": "#431407",
    "red-50": "#fef2f2",
    "red-100": "#fee2e2",
    "red-200": "#fecaca",
    "red-300": "#fca5a5",
    "red-400": "#f87171",
    "red-500": "#ef4444",
    "red-600": "#dc2626",
    "red-700": "#b91c1c",
    "red-800": "#991b1b",
    "red-900": "#7f1d1d",
    "red-950": "#450a0a",
    "purple-50": "#faf5ff",
    "purple-100": "#f3e8ff",
    "purple-200": "#e9d5ff",
    "purple-300": "#d8b4fe",
    "purple-400": "#c084fc",
    "purple-500": "#a855f7",
    "purple-600": "#9333ea",
    "purple-700": "#7e22ce",
    "purple-800": "#6b21a8",
    "purple-900": "#581c87",
    "purple-950": "#3b0764",
  },
};

export const colorsByTheme = {
  light: {
    bgStd: styleConfig.colors.white,
    bgLt: styleConfig.colors["gray-100"],
    bgMd: styleConfig.colors["gray-200"],
    bgHvy: styleConfig.colors["gray-300"],
    borderVlt: styleConfig.colors["gray-100"],
    borderLt: styleConfig.colors["gray-200"],
    borderMd: styleConfig.colors["gray-300"],
    borderHvy: styleConfig.colors["gray-500"],
    textVlt: styleConfig.colors["gray-400"],
    textLt: styleConfig.colors["gray-500"],
    textMd: styleConfig.colors["gray-700"],
    textHvy: styleConfig.colors["gray-900"],
    // TODO look more carefully at anything below this
    highlight: styleConfig.colors["gray-200"],
    highlightLt: styleConfig.colors["gray-100"],
    btn: styleConfig.colors["gray-300"],
    btnHover: styleConfig.colors["gray-400"],
    btnHvy: styleConfig.colors["gray-400"],
    btnHvyHover: styleConfig.colors["gray-500"],
    btnVlt: styleConfig.colors["gray-200"],
    btnVltHover: styleConfig.colors["gray-300"],
    actionBtn: styleConfig.colors["indigo-500"],
    rowSoftSelection: styleConfig.colors["gray-100"],
    rowHardSelection: styleConfig.colors["indigo-500/15"],
    rowBothSelection: styleConfig.colors["indigo-500/25"],
    miniRowHardSelection: styleConfig.colors["gray-100"],
    miniRowBothSelection: styleConfig.colors["gray-200"],
    opposite: styleConfig.colors["gray-900"],
    primary: styleConfig.colors["indigo-500"],
    primaryHover: styleConfig.colors["indigo-600"],
    primaryHighContrast: styleConfig.colors["indigo-800"],
    success: styleConfig.colors["green-500"],
    warning: styleConfig.colors["orange-500"],
    danger: styleConfig.colors["red-500"],
    dangerHighContrast: styleConfig.colors["red-800"],
    recommendation: styleConfig.colors["purple-500"],
    recommendationHighContrast: styleConfig.colors["purple-800"],
  },
  dark: {
    bgStd: styleConfig.colors["zinc-850"],
    bgLt: styleConfig.colors["zinc-800"],
    bgMd: styleConfig.colors["zinc-700"],
    bgHvy: styleConfig.colors["zinc-600"],
    borderVlt: styleConfig.colors["zinc-800"],
    borderLt: styleConfig.colors["zinc-700"],
    borderMd: styleConfig.colors["zinc-600"],
    borderHvy: styleConfig.colors["zinc-400"],
    textVlt: styleConfig.colors["zinc-500"],
    textLt: styleConfig.colors["zinc-400"],
    textMd: styleConfig.colors["zinc-300"],
    textHvy: styleConfig.colors["zinc-50"],
    // TODO see above
    highlight: styleConfig.colors["zinc-700"],
    highlightLt: styleConfig.colors["zinc-800"],
    button: styleConfig.colors["zinc-600"],
    btnHover: styleConfig.colors["zinc-500"],
    btnHvy: styleConfig.colors["zinc-500"],
    btnHvyHover: styleConfig.colors["zinc-400"],
    btnLtHover: styleConfig.colors["zinc-700"],
    btnVltHover: styleConfig.colors["zinc-600"],
    actionBtn: styleConfig.colors["indigo-500"],
    rowSoftSelection: styleConfig.colors["zinc-800"],
    rowHardSelection: styleConfig.colors["indigo-500/15"],
    rowBothSelection: styleConfig.colors["indigo-500/25"],
    miniRowHardSelection: styleConfig.colors["zinc-800"],
    miniRowBothSelection: styleConfig.colors["zinc-700"],
    opposite: styleConfig.colors["zinc-50"],
    primary: styleConfig.colors["indigo-500"],
    primaryHover: styleConfig.colors["indigo-400"],
    primaryHighContrast: styleConfig.colors["indigo-200"],
    success: styleConfig.colors["green-500"],
    warning: styleConfig.colors["orange-500"],
    danger: styleConfig.colors["red-500"],
    dangerHighContrast: styleConfig.colors["red-200"],
    recommendation: styleConfig.colors["purple-500"],
    recommendationHighContrast: styleConfig.colors["purple-200"],
  },
};

export const DEFAULT_CHIP_COLOR = styleConfig.colors["gray-200"];
export const DEFAULT_BLOCK_COLOR = styleConfig.colors["gray-400"];
export const PRIMARY_COLOR = styleConfig.colors["indigo-500"];
export const RECOMMENDATION_COLOR = styleConfig.colors["purple-500"];
export const BACKUP_FONTS = defaultTheme.fontFamily.sans;
