<script setup lang="ts">
import { type Component, computed, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import { DotsHorizontalIcon, SprintRolloverIcon } from "~/icons";
import { makeLinkToSpaceSettingsPageRef } from "~/router/common";
import { Placement } from "~/shared/enums";
import type { Space } from "~/shared/types";
import { useDataStore } from "~/stores";

import SpacesSidebar from "./SpacesSidebar.vue";
import BasicsView from "./subpages/BasicsView.vue";
import ReportsView from "./subpages/ReportsView.vue";
import SharingView from "./subpages/SharingView.vue";
import SprintsView from "./subpages/SprintsView.vue";

const router = useRouter();
const dataStore = useDataStore();

const views: Record<string, Component> = {
  basics: BasicsView,
  reports: ReportsView,
  sharing: SharingView,
  sprints: SprintsView,
};

const page = computed(() => router.currentRoute.value.query.page as string);
const space = computed(() => dataStore.spaceList.find((e) => e.duid === router.currentRoute.value.query.space));
const emit = defineEmits<{
  rollover: [];
  editSpace: [space: Space];
}>();

const rolloverDialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const dialogTitle = computed(() => "Start next sprint");
const dialogDescription = computed(
  () =>
    "Starting the next sprint will move completed tasks in Active to Archive and move tasks in Next to Active. Tasks in Active that are not completed will stay there. This can't be undone."
);

const openRolloverDialog = () => {
  rolloverDialog.value?.openModal();
};
const rollover = () => {
  if (!space.value) {
    return;
  }
  dataStore.rollover(space.value.duid);
};

const spaceMetaDropdownSections = computed(() =>
  actions.context.page(space.value as Space, false, (eventKind: string) => {
    switch (eventKind) {
      case "startNextSprint": {
        openRolloverDialog();
        break;
      }
      case "startEditingSpace": {
        emit("editSpace", space.value as Space);
        router.replace(makeLinkToSpaceSettingsPageRef(space.value?.duid, "basics").value);
        break;
      }
      default: {
        throw new Error(`Unknown event kind: ${eventKind}`);
      }
    }
  })
);

onMounted(() => {
  if (!space.value && dataStore.spaceList.length > 0) {
    router.replace(makeLinkToSpaceSettingsPageRef(dataStore.spaceList[0].duid, "basics").value);
  }
});

onUnmounted(() => {
  router.replace({
    query: {
      ...router.currentRoute.value.query,
      space: undefined,
      page: undefined,
    },
  });
});
</script>

<template>
  <div class="!m-0 flex size-full overflow-hidden !p-0">
    <SpacesSidebar />
    <ConfirmationDialog
      ref="rolloverDialog"
      cancel-text="Cancel"
      confirm-text="Proceed"
      :icon="SprintRolloverIcon"
      :title="dialogTitle"
      :description="dialogDescription"
      @confirm="rollover" />
    <div class="relative mt-10 flex flex-1 flex-col overflow-y-auto px-8 pb-72 lg:px-16">
      <DropdownMenu
        :sections="spaceMetaDropdownSections"
        :placement="Placement.BOTTOM_RIGHT"
        :distance="0"
        class="absolute right-8 top-0.5 lg:right-16">
        <div class="rounded text-lt hover:bg-lt">
          <span class="sr-only">Manage space</span>
          <DotsHorizontalIcon class="icon-md" />
        </div>
      </DropdownMenu>
      <component :is="views[page]" v-if="space" :key="page" :space="space" />
    </div>
  </div>
</template>
