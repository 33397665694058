<script setup lang="ts">
import { clamp, useEventBus } from "@vueuse/core";
import { computed, onUnmounted, ref } from "vue";

import { EVENT_BUS_FILE_UPLOAD_PROGRESS, filePreviews } from "~/utils/fileUpload";

const props = defineProps<{
  duid: string;
}>();

const previewFileUrl = computed(() => filePreviews.get(props.duid));
const isUploading = computed(() => !!previewFileUrl.value);
const percentage = ref(1);
const dashOffset = computed(() => 251.2 - (251.2 * percentage.value) / 100);

const progressReportBus = useEventBus(EVENT_BUS_FILE_UPLOAD_PROGRESS);
const unsubscribe = progressReportBus.on((event) => {
  const { duid, progress } = event;
  if (duid !== props.duid || progress === undefined) {
    return;
  }

  percentage.value = Math.round(clamp(progress * 100, 1, 99));
});

onUnmounted(unsubscribe);
</script>

<template>
  <slot :preview-file-url="previewFileUrl" :uploading="isUploading" />
  <div v-if="isUploading" class="pointer-events-none absolute inset-0 flex items-center justify-center">
    <svg class="size-12 text-oncolor" viewBox="0 0 100 100" aria-hidden="true">
      <circle class="stroke-current" stroke-width="10" cx="50" cy="50" r="40" fill="transparent" />
      <circle
        class="origin-center -rotate-90 stroke-current text-primary-base transition-[stroke-dashoffset]"
        stroke-width="10"
        stroke-linecap="round"
        cx="50"
        cy="50"
        r="40"
        fill="transparent"
        stroke-dasharray="251.2"
        :stroke-dashoffset="dashOffset" />
    </svg>
  </div>
  <slot :uploading="isUploading" name="actions" />
</template>
