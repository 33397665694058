<script setup lang="ts">
import { ref } from "vue";

import actions from "~/actions";
import { useDataStore } from "~/stores";
import { when } from "~/utils/wait";

import TaskKindsContent from "./TaskKindsContent.vue";
import TaskKindsSidebar from "./TaskKindsSidebar.vue";

const dataStore = useDataStore();

const content = ref<InstanceType<typeof TaskKindsContent> | null>(null);

const focusNewTaskKind = async () => {
  actions.visualization.openKeyboardIfIos();
  await when(content);
  content.value?.focusTitle();
};
</script>

<template>
  <div class="!m-0 flex size-full overflow-hidden !p-0">
    <TaskKindsSidebar v-if="dataStore.taskKindList.length > 0" @create-task-kind="focusNewTaskKind" />
    <TaskKindsContent ref="content" />
  </div>
</template>
