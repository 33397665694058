<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { PlusIcon } from "~/icons";
import { ButtonSize, ButtonStyle, IconSize } from "~/shared/enums";
import type { Option, PropertyAnySelect, PropertyConfig } from "~/shared/types";
import { useDataStore } from "~/stores";
import { makeRandomColorHex } from "~/utils/color";
import { makeDuid } from "~/utils/common";

import OptionItem from "./OptionItem.vue";

export type OptionWithChildren = { option: Option; children: OptionWithChildren[] };

const props = defineProps<{
  property: PropertyAnySelect;
  propertyConfig: PropertyConfig;
}>();

const dataStore = useDataStore();

const optionDuidsAtTop = ref<string[]>([]);
const optionDuidsAtBottom = ref<string[]>([]);
const previousOptionDuid = ref<string | null>(null);

const addingOptions = ref<Option[]>([]);

const isAddingAtTop = ref(false);

const options = computed(() => {
  const allOptions = dataStore.getOptionList(props.property);
  const optionsByDuid = new Map(allOptions.map((e) => [e.duid, e]));

  const middleOptions = allOptions.filter(
    (e) => !optionDuidsAtTop.value.includes(e.duid) && !optionDuidsAtBottom.value.includes(e.duid)
  );
  const topOptions = optionDuidsAtTop.value.map((duid) => optionsByDuid.get(duid)).filter((e): e is Option => !!e);
  const bottomOptions = optionDuidsAtBottom.value
    .map((duid) => optionsByDuid.get(duid))
    .filter((e): e is Option => !!e);

  const combinedOptions = [...topOptions, ...middleOptions, ...bottomOptions];

  if (addingOptions.value.length) {
    const previousOption = previousOptionDuid.value
      ? combinedOptions.findIndex((e) => e.duid === previousOptionDuid.value) + 1
      : isAddingAtTop.value
        ? 0
        : combinedOptions.length;

    combinedOptions.splice(previousOption, 0, ...addingOptions.value);
  }

  return combinedOptions;
});

const addOption = (parentDuid: string | null, bottom: boolean) => {
  isAddingAtTop.value = !bottom;
  previousOptionDuid.value = null;

  const newOption = {
    duid: makeDuid(),
    title: "",
    propertyDuid: props.property.duid,
    parentDuid,
    colorHex: makeRandomColorHex(),
  };
  addingOptions.value = [...addingOptions.value, newOption];
};

const onAdd = async (title: string, parentDuid: string | null, addingDuid?: string) => {
  if (addingOptions.value.length === 0) {
    return;
  }

  if (!title) {
    addingOptions.value = addingOptions.value.filter((e) => e.duid !== addingDuid);
    previousOptionDuid.value = null;
    return;
  }

  const isDuplicate = dataStore
    .getOptionList(props.property)
    .some((e) => e.title === title && e.parentDuid === parentDuid);
  if (isDuplicate) {
    if (addingDuid) {
      addingOptions.value = addingOptions.value.filter((e) => e.duid !== addingDuid);
    }
    previousOptionDuid.value = null;
    return;
  }

  const addingOption = addingOptions.value.find((e) => e.duid === addingDuid) ?? addingOptions.value[0];

  if (!addingOption) {
    return;
  }

  const newOption = await dataStore.createOption(title, props.property, {
    colorHex: addingOption.colorHex,
    parentDuid: addingOption.parentDuid,
  });

  if (newOption) {
    const previousDuidList =
      previousOptionDuid.value && optionDuidsAtTop.value.includes(previousOptionDuid.value)
        ? optionDuidsAtTop
        : optionDuidsAtBottom;

    if (previousOptionDuid.value && previousDuidList.value.includes(previousOptionDuid.value)) {
      const previousDuidIndex = previousDuidList.value.indexOf(previousOptionDuid.value);
      previousDuidList.value.splice(previousDuidIndex + 1, 0, newOption.duid);
    } else if (isAddingAtTop.value) {
      optionDuidsAtTop.value.splice(0, 0, newOption.duid);
    } else {
      optionDuidsAtBottom.value.push(newOption.duid);
    }
  }

  addingOptions.value = addingOptions.value.filter((e) => e.duid !== addingOption.duid);
  previousOptionDuid.value = null;
};

const optionsWithChildren = computed<OptionWithChildren[]>(() => {
  const items = [...options.value];
  const rootItems = items.filter((e) => !e.parentDuid);

  const parentToChildren = items.reduce(
    (acc, e) => {
      if (!e.parentDuid) {
        return acc;
      }
      // eslint-disable-next-line no-param-reassign
      acc[e.parentDuid] = acc[e.parentDuid] || [];
      acc[e.parentDuid].push(e);
      return acc;
    },
    {} as Record<string, Option[]>
  );

  const getChildren = (parentDuid: string): OptionWithChildren[] => {
    const children = parentToChildren[parentDuid] || [];
    return children.map((e) => ({ option: e, children: getChildren(e.duid) }));
  };

  return rootItems.map((e) => ({ option: e, children: getChildren(e.duid) }));
});

const addSubitem = (parentDuid: string) => {
  addOption(parentDuid, true);
};

const createOptionUnderneath = async (title: string, parentDuid: string | null, currentOption?: Option) => {
  if (!title.trim()) {
    return;
  }

  await onAdd(title, null, currentOption?.duid);
  await nextTick();

  const createdOption = dataStore
    .getOptionList(props.property)
    .find((e) => e.title === title && e.parentDuid === parentDuid);

  if (createdOption) {
    previousOptionDuid.value = createdOption.duid;
  }

  addingOptions.value = [
    {
      duid: makeDuid(),
      title: "",
      propertyDuid: props.property.duid,
      parentDuid,
      colorHex: makeRandomColorHex(),
    },
  ];
};

watch(
  () => dataStore.getOptionList(props.property),
  (newOptions) => {
    if (!addingOptions.value.length) {
      const newDuids = newOptions.map((e) => e.duid);

      optionDuidsAtTop.value = optionDuidsAtTop.value.filter((e) => newDuids.includes(e));
      optionDuidsAtBottom.value = optionDuidsAtBottom.value.filter((e) => newDuids.includes(e));

      const newOptionDuids = newDuids.filter(
        (e) => !optionDuidsAtTop.value.includes(e) && !optionDuidsAtBottom.value.includes(e)
      );

      optionDuidsAtBottom.value.push(...newOptionDuids);
    }
  }
);
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="-mr-0.5 flex flex-row justify-between">
      <span class="select-none text-sm text-lt">Options</span>
      <Tooltip v-if="options.length > 0" text="Create an option">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          :icon="PlusIcon"
          a11y-label="New option"
          :icon-args="{ class: 'text-lt' }"
          borderless
          class="!p-px hover:bg-lt"
          :icon-size="IconSize.S"
          :size="ButtonSize.SMALL"
          @click="addOption(null, false)" />
      </Tooltip>
    </div>
    <div class="flex flex-col gap-2">
      <OptionItem
        v-for="item in optionsWithChildren"
        :key="item.option.duid"
        :property="property"
        :option="item.option"
        :parent-duid="null"
        :children="item.children"
        :adding-duids="addingOptions.map((e) => e.duid)"
        @add="onAdd"
        @create-option-underneath="createOptionUnderneath"
        @add-subitem="addSubitem" />
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        :icon="PlusIcon"
        :icon-size="IconSize.XS"
        text="New option"
        borderless
        :size="ButtonSize.SMALL"
        text-style="text-xs text-md"
        class="-ml-1.5 w-max !gap-1 !py-px pl-1 pr-1.5"
        @click="() => addOption(null, true)" />
    </div>
  </div>
</template>
