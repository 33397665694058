<script setup lang="ts">
import { computed, ref } from "vue";

import Comments from "~/components/comments";
import CollapsibleSection from "~/components/dumb/CollapsibleSection.vue";
import SubscribersEditor from "~/components/dumb/SubscribersEditor.vue";
import Peripherals from "~/components/task/Peripherals.vue";
import Update from "~/components/task/taskDetail/Update.vue";
import { ActivityKind, EditorMode, RelationshipKindKind, SectionKind } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { usePageStore } from "~/stores";

defineProps<{
  task: Task;
}>();

const pageStore = usePageStore();

const comments = ref<InstanceType<typeof Comments> | null>(null);

const peripheralsEditor = ref<InstanceType<typeof Peripherals> | null>(null);

const generatingRecommendations = computed(() => peripheralsEditor.value?.generatingSubtaskRecommendations);

const setRelationship = (kind: RelationshipKindKind, isForward?: boolean) =>
  peripheralsEditor.value?.setRelationships(kind, isForward);

const generateSubtaskRecommendations = () => peripheralsEditor.value?.generateSubtaskRecommendations();

const setDocRelationship = () => peripheralsEditor.value?.setDocRelationship();

const createSubtask = () => peripheralsEditor.value?.createSubtask();

const setSubtask = () => peripheralsEditor.value?.setSubtask();

const deselectSubtasks = () => peripheralsEditor.value?.deselectSubtasks();

const addAttachments = (files: File[]) => peripheralsEditor.value?.addAttachments(files);

const editLastComment = () => comments.value?.startEditingLast();

defineExpose({
  addAttachments,
  commentsReady: computed(() => comments.value?.isReady),
  createSubtask,
  deselectSubtasks,
  editLastComment,
  generateSubtaskRecommendations,
  generatingRecommendations,
  setDocRelationship,
  setRelationship,
  setSubtask,
});
</script>

<template>
  <div class="flex grow flex-col justify-between gap-6">
    <Peripherals ref="peripheralsEditor" :task="task" :editor-mode="EditorMode.DETAIL" />

    <CollapsibleSection
      title="Activity"
      class="flex flex-col text-lt"
      :kind="pageStore.isPublicView ? SectionKind.DEFAULT : SectionKind.COMPONENT"
      :component="SubscribersEditor"
      :component-args="{
        taskDuid: task.duid,
        value: task.subscriberDuids,
      }">
      <Update :activity-kind="ActivityKind.CREATED" :at="task.createdAt" />
      <Update :activity-kind="ActivityKind.UPDATED" :at="task.updatedAt" />
      <Comments ref="comments" :task="task" />
    </CollapsibleSection>
  </div>
</template>
