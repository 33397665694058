<script setup lang="ts">
import moment from "moment";
import { computed, onUnmounted, type Ref, ref } from "vue";

import { useAppStore, useDataStore } from "~/stores";
import { getRelativeTimeForDatesDate } from "~/utils/time";

import PanArea from "../PanArea.vue";
import {
  getDatesForInterval,
  getDiffPx,
  getIntervalPx,
  getPixelsTo,
  makeTimeForPixelsToNowRef,
  useVirtualized,
} from "./common";
import { DEFAULT_BLOCK_WIDTH_PX, RANGE_HEIGHT_PX } from "./constants";
import RangeActions from "./RangeActions.vue";
import type { Interval, RangeDefinition, RoadmapConfig, RoadmapValues } from "./shared";

const appStore = useAppStore();
const dataStore = useDataStore();

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  roadmapValues: RoadmapValues;
  timelineScrollX: number;
  timelineWidth: number;
}>();

const emit = defineEmits<{
  zoom: [pxPerDay: number];
}>();

const definition = computed<RangeDefinition>(() => props.roadmapValues.definition);

/* Main range */
const mainRange = computed(() => {
  const today = moment();
  return [
    {
      value: props.roadmapConfig.startDate,
      label: "",
      extra: null,
      width: getPixelsTo(props.roadmapConfig, props.roadmapValues.rangeDates[0]),
      today: false,
      highlighted: false,
    },
    ...props.roadmapValues.rangeDates.map((value) => ({
      value,
      label: moment(value).format(definition.value.rangeFormat ?? ""),
      extra: definition.value.rangeExtraFormatFn ? definition.value.rangeExtraFormatFn(moment(value)) : null,
      width: getIntervalPx(props.roadmapConfig, value, definition.value.rangeInterval),
      today: definition.value.exact && moment(value).isSame(today, "day"),
      highlighted: moment(value).isSameOrAfter(today, "day"),
    })),
  ];
});

/* Extra range */
const extraRange = computed(() => {
  const { unit, format, stickyFormat } = definition.value.extraRange;
  const interval: Interval = [1, unit];
  const today = moment();

  const dates = getDatesForInterval(props.roadmapConfig, interval);
  return [
    {
      value: props.roadmapConfig.startDate,
      label: "",
      stickyLabel: null,
      width: getPixelsTo(props.roadmapConfig, dates[0]),
      highlighted: false,
    },
    ...dates.map((value) => ({
      value,
      label: format(moment(value)),
      stickyLabel: stickyFormat(moment(value)),
      width: getIntervalPx(props.roadmapConfig, value, interval),
      highlighted: moment(value).isSameOrAfter(today, unit),
    })),
  ];
});

/* Get the nearest extra to the current scroll position */
const stickyExtra = computed<string | null>(() => {
  const position = props.timelineScrollX;

  let value: string | null = null;
  let combinedWidth = 0;
  for (let i = 0; i < extraRange.value.length; i += 1) {
    const current = extraRange.value[i];
    combinedWidth += current.width;
    if (position + 60 < combinedWidth) {
      value = current.stickyLabel;
      break;
    }
  }

  return value;
});

/* Today indicator */
const todayPixels = makeTimeForPixelsToNowRef(computed(() => props.roadmapConfig));
const todayIndicator = computed(() => ({
  exact: definition.value.exact,
  left: `${todayPixels.value.pixels}px`,
  label: moment().format(definition.value.rangeFormat ?? ""),
}));

/* Preview range */
const selectedTasks = computed(() => dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids]));
const preview = computed(() => {
  const { startDate, endDate } = props.roadmapConfig.previewRange ?? {};
  let startDateNorm: string = "";
  let endDateNorm: string = "";

  /* If no preview range is set, and there is only one task selected, use its start and end dates */
  if (!startDate && !endDate) {
    if (selectedTasks.value.length !== 1) {
      return null;
    }
    const task = selectedTasks.value[0];
    const { rolledUpStartAt, rolledUpDueAt } = dataStore.getTaskDates(task);

    startDateNorm = rolledUpStartAt ? moment(rolledUpStartAt).startOf("day").toISOString() : "";
    endDateNorm = rolledUpDueAt ? moment(rolledUpDueAt).startOf("day").toISOString() : "";
  } else {
    startDateNorm = startDate ? moment(startDate).startOf("day").toISOString() : "";
    endDateNorm = endDate ? moment(endDate).startOf("day").toISOString() : "";
  }

  const left = startDateNorm
    ? getPixelsTo(props.roadmapConfig, startDateNorm)
    : endDateNorm
      ? getPixelsTo(props.roadmapConfig, endDateNorm) - DEFAULT_BLOCK_WIDTH_PX
      : 0;
  const width =
    startDateNorm && endDateNorm ? getDiffPx(props.roadmapConfig, startDateNorm, endDateNorm) : DEFAULT_BLOCK_WIDTH_PX;

  /* extra padding for margins on L/R */
  return {
    left: `${left - 12}px`,
    width: `${width + 24}px`,
    start: startDateNorm ? getRelativeTimeForDatesDate(startDateNorm) : null,
    end: endDateNorm ? getRelativeTimeForDatesDate(endDateNorm) : null,
    duration: startDateNorm && endDateNorm ? moment(startDateNorm).to(moment(endDateNorm), true) : null,
  };
});

/* Scrolling */
const scrollContainer = ref<InstanceType<typeof PanArea> | null>(null);
const scrollTo = (x: number): void => {
  if (!scrollContainer.value?.wrapper) {
    return;
  }

  const maximum = scrollContainer.value.wrapper.scrollWidth - scrollContainer.value.wrapper.clientWidth;
  scrollContainer.value.wrapper.scrollTo({
    left: Math.min(Math.max(0, x), maximum),
  });
};

const { virtualSize: virtualWidthExtraRange, virtualList: virtualListExtraRange } = useVirtualized(
  scrollContainer as Ref<HTMLElement | null>,
  extraRange
);
const { virtualSize: virtualWidthMainRange, virtualList: virtualListMainRange } = useVirtualized(
  scrollContainer as Ref<HTMLElement | null>,
  mainRange
);

onUnmounted(() => {
  todayPixels.value.destroy();
});
</script>

<template>
  <div
    :style="{ minHeight: RANGE_HEIGHT_PX, maxHeight: RANGE_HEIGHT_PX }"
    class="relative flex size-full flex-1 select-none flex-col overflow-hidden pt-1.5 outline outline-1 outline-gray-200 dark:outline-zinc-700">
    <PanArea ref="scrollContainer" v-scroll-sync:roadmap.x class="flex flex-1 flex-col items-center overflow-hidden">
      <!-- Extra range -->
      <div
        class="flex size-full flex-1 items-center"
        :style="{ minWidth: `${virtualWidthExtraRange}px`, maxWidth: `${virtualWidthExtraRange}px` }">
        <div
          v-for="unit in virtualListExtraRange"
          :id="`extra-range-${unit.value}`"
          :key="unit.value"
          :class="{ 'text-md': unit.highlighted, 'text-vlt': !unit.highlighted }"
          class="absolute flex w-full items-center text-xs font-medium"
          :style="{ left: `${unit.start}px`, minWidth: `${unit.width}px`, maxWidth: `${unit.width}px` }">
          {{ unit.label }}
        </div>
      </div>

      <!-- Main range -->
      <div
        class="flex size-full flex-1 items-center"
        :style="{ minWidth: `${virtualWidthMainRange}px`, maxWidth: `${virtualWidthMainRange}px` }">
        <!-- Units -->
        <div
          v-for="unit in virtualListMainRange"
          :id="`range-${unit.value}`"
          :key="unit.value"
          class="absolute flex w-full items-center gap-1 text-xs"
          :class="{ 'justify-center': definition.exact, 'text-md': unit.highlighted, 'text-vlt': !unit.highlighted }"
          :style="{ left: `${unit.start}px`, minWidth: `${unit.width}px`, maxWidth: `${unit.width}px` }">
          <span v-if="unit.extra">{{ unit.extra }}</span>
          <span
            :class="[
              unit.today &&
                'flex items-center justify-center rounded-full border-2 border-primary-base text-xs bg-std icon-lg',
            ]">
            {{ unit.label }}
          </span>
        </div>

        <!-- Today indicator; full and small -->
        <div
          v-if="!todayIndicator.exact"
          class="absolute ml-[-19px] flex h-6 w-10"
          :style="{ left: todayIndicator.left }">
          <div class="h-full w-2 bg-gradient-to-l from-std" />
          <div class="bg-std icon-lg">
            <div
              class="flex size-full items-center justify-center rounded-full border-2 border-primary-base text-xs text-md">
              <span>{{ todayIndicator.label }}</span>
            </div>
          </div>
          <div class="h-full w-2 bg-gradient-to-r from-std" />
          <!-- Line beginning -->
          <span class="absolute -bottom-2 left-[19px] h-2 w-0.5 bg-primary-base" />
        </div>
        <div
          v-else
          class="absolute bottom-0 -mb-1.5 ml-[-5px] flex size-3 rounded-full bg-primary-base"
          :style="{ left: todayIndicator.left }" />

        <!-- Preview range -->
        <div
          v-if="preview"
          class="absolute bottom-0 flex h-full select-none flex-col items-center justify-center"
          :style="{
            left: preview.left,
            minWidth: preview.width,
            maxWidth: preview.width,
          }">
          <!-- Preview range dates -->
          <div class="flex h-5 w-full">
            <div class="h-full w-2 min-w-2 bg-gradient-to-l from-std" />
            <div
              class="flex h-full grow items-center justify-between gap-2 rounded px-1 py-1.5 text-xs text-md"
              :class="{
                'bg-gradient-to-l from-transparent to-30% to-lt': !preview.end,
                'bg-gradient-to-r from-transparent to-30% to-lt': !preview.start,
                'bg-lt': preview.start && preview.end,
              }">
              <div v-if="preview.start" class="sticky left-1 whitespace-nowrap">
                {{ preview.start }}
              </div>

              <!-- Preview range duration -->
              <div
                v-if="preview.duration"
                class="relative -top-2.5 flex h-4 shrink-0 rounded-t px-1 pt-0.5 text-xs bg-lt text-md">
                {{ preview.duration }}
              </div>
              <div v-else />

              <div v-if="preview.end" class="sticky right-1 whitespace-nowrap pl-1 bg-lt">
                {{ preview.end }}
              </div>
            </div>
            <div class="h-full w-2 min-w-2 bg-gradient-to-r from-std" />
          </div>
        </div>
      </div>
    </PanArea>

    <!-- Sticky extra -->
    <div v-if="stickyExtra" class="absolute left-0 flex h-8 items-center">
      <div class="mb-2 flex pl-3 pr-1 pt-2 text-xs font-medium bg-std text-md">
        {{ stickyExtra }}
      </div>
      <div class="mb-2 h-full w-2 bg-gradient-to-r from-std" />
    </div>

    <RangeActions
      :roadmap-config="roadmapConfig"
      :roadmap-values="roadmapValues"
      :timeline-scroll-x="timelineScrollX"
      :timeline-width="timelineWidth"
      @scroll-to="scrollTo"
      @zoom="(e) => emit('zoom', e)" />
  </div>
</template>
