<script setup lang="ts">
import { Switch } from "@headlessui/vue";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import Modal from "~/components/dumb/Modal.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import { convertFormValuesToTask, isFieldInvalid } from "~/components/forms/common";
import FormFieldCard from "~/components/forms/FormFieldCard.vue";
import { FORM_TELEPORT_KEY } from "~/components/text/const";
import { DotsHorizontalIcon, EditIcon, TrashIcon } from "~/icons";
import { makeLinkToFormsSettingsRef } from "~/router/common";
import { ButtonStyle, DialogMode, DropdownMenuItemKind, ModalWidth, TaskSourceType, UserRole } from "~/shared/enums";
import type { Form, FormField, PropertyValue } from "~/shared/types";
import { useAppStore, useDataStore, useUserStore } from "~/stores";

import DropdownMenu from "../dumb/DropdownMenu.vue";

const DISCARD_DRAFT_DIALOG_DESCRIPTION =
  "Discarding the draft will clear the task information you've put in so far. This can't be undone. Are you sure you want to proceed?";

const router = useRouter();

const appStore = useAppStore();
const dataStore = useDataStore();
const userStore = useUserStore();

const discardDraftModal = ref<typeof ConfirmationDialog | null>(null);

let isUnedited = true;

const userIsMemberOrGreater = computed(() => userStore.isRoleGreaterOrEqual(UserRole.MEMBER));

const getFormFields = (form: Form | null) =>
  form ? dataStore.getFormFieldRelatedToFormOrdered(form.duid).filter((e) => !e.hidden) : [];

const form = computed(() => appStore.formInInternalModal);
const fieldDuidToValueMap = ref<Map<string, PropertyValue>>(new Map());

const checkIsFormInvalid = (latestForm: Form | null) =>
  getFormFields(latestForm).some((field) => isFieldInvalid(field, fieldDuidToValueMap.value.get(field.duid)));

const updateField = (duid: string, value: PropertyValue) => {
  fieldDuidToValueMap.value.set(duid, value);
  isUnedited = false;
};

const closeModal = () => {
  appStore.setFormOpenInInternalModal(null);
};

const resetValues = () => {
  if (!form.value) {
    return;
  }

  fieldDuidToValueMap.value = new Map();
  dataStore.getFormFieldRelatedToFormOrdered(form.value.duid).forEach((field: FormField) => {
    fieldDuidToValueMap.value.set(field.duid, field.default.value);
  });
  isUnedited = true;
};

const createTaskFromForm = async () => {
  if (!form.value) {
    return;
  }

  const task = convertFormValuesToTask(form.value, fieldDuidToValueMap.value);
  if (!task.dartboardDuid || !task.order) {
    return;
  }

  dataStore.createTask(task.title ?? "", task.dartboardDuid, task.order, TaskSourceType.APP_INTERNAL_FORM, task);
  resetValues();

  if (!appStore.createTaskCreateMore) {
    closeModal();
  }
};

const discardDraftOrOpenModal = () => {
  if (isUnedited) {
    closeModal();
    return;
  }

  discardDraftModal.value?.openModal();
};

const dropdownSections = [
  {
    title: "Form actions",
    items: [
      {
        title: "Edit Form",
        icon: EditIcon,
        kind: DropdownMenuItemKind.BUTTON,
        onClick: () => router.replace(makeLinkToFormsSettingsRef(form.value?.duid).value),
      },
    ],
  },
];

watch(
  () => appStore.formInInternalModal,
  (newForm, oldForm) => {
    if (newForm?.duid !== oldForm?.duid) {
      resetValues();
    }
  }
);
</script>

<template>
  <Modal
    id="dart-form-wrapper"
    :entity="appStore.formInInternalModal"
    :title="(f) => f?.title ?? 'Form'"
    :width="ModalWidth.M"
    :title-icon="PageIcon"
    :title-icon-args="(page) => ({ page })"
    close-text="Discard draft"
    @close="discardDraftOrOpenModal">
    <template #default="{ entity: formInInternalModal }">
      <ConfirmationDialog
        ref="discardDraftModal"
        :mode="DialogMode.DELETE"
        title="Discard draft"
        :description="DISCARD_DRAFT_DIALOG_DESCRIPTION"
        confirm-text="Discard"
        cancel-text="Keep"
        :icon="TrashIcon"
        @confirm="closeModal" />

      <div
        :data-toolbar="FORM_TELEPORT_KEY"
        class="flex h-full flex-col overflow-hidden text-left align-middle"
        @click.stop
        @keydown.stop>
        <DropdownMenu v-if="userIsMemberOrGreater" class="absolute right-9 top-3" :sections="dropdownSections">
          <span class="flex cursor-pointer items-center rounded p-0.5 text-vlt hover:bg-md">
            <DotsHorizontalIcon class="size-4" />
          </span>
        </DropdownMenu>

        <div class="my-4 -ml-3 -mr-6 flex grow flex-col overflow-y-scroll" @keydown.stop @click.stop>
          <FormFieldCard
            v-for="field in getFormFields(formInInternalModal)"
            :key="field.duid"
            :field="field"
            :value="fieldDuidToValueMap.get(field.duid)"
            is-submit-mode
            @update="updateField" />
        </div>
      </div>
    </template>

    <template #actions="{ entity: formInInternalModal }">
      <div class="flex items-center gap-5">
        <div class="flex gap-2" @click.stop>
          <Switch
            :model-value="appStore.createTaskCreateMore"
            :class="appStore.createTaskCreateMore ? 'bg-primary-base' : 'bg-md'"
            class="relative inline-flex h-5 w-8 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-ring-std"
            aria-label="Create more toggle"
            @update:model-value="(e) => appStore.setCreateTaskCreateMore(e)">
            <span
              aria-hidden="true"
              :class="appStore.createTaskCreateMore ? 'translate-x-3' : 'translate-x-0'"
              class="pointer-events-none inline-block rounded-full shadow ring-0 transition duration-200 ease-in-out bg-std icon-sm" />
          </Switch>
          <span class="text-sm text-lt">Create more</span>
        </div>

        <Button
          :disabled="checkIsFormInvalid(formInInternalModal)"
          :btn-style="ButtonStyle.PRIMARY"
          text="Submit"
          @click="createTaskFromForm" />
      </div>
    </template>
  </Modal>
</template>
