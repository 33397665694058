<script setup lang="ts">
import type { SerializedEditorState } from "lexical";
import moment from "moment";
import { computed, nextTick, onUnmounted, ref, watch } from "vue";
import VueDragResize from "vue-drag-resize/src/components/vue-drag-resize.vue";

import actions from "~/actions";
import { getPropertyConfig } from "~/common/properties";
import Tooltip from "~/components/dumb/Tooltip.vue";
import TitleEditor from "~/components/text/TitleEditor.vue";
import { convertToTask } from "~/components/text/utils";
import { colorsByTheme } from "~/constants/style";
import { DueDateFieldIcon } from "~/icons";
import { EditorMode, SubtaskDisplayMode } from "~/shared/enums";
import type { TaskAbsenteeMaybe } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { fromHexToHexWithAlpha } from "~/utils/color";
import { isTimeTracking, makeLexicalStateWithText } from "~/utils/common";
import { normalizeDateToDate } from "~/utils/date";
import { getMsUntilNext } from "~/utils/time";

import TaskClickWrapper from "../components/TaskClickWrapper.vue";
import { COMPLETED_STATUS_KINDS } from "../constants";
import { getDiffPx, getPixelsTo } from "./common";
import { DEFAULT_BLOCK_WIDTH_PX, TASK_ROW_HEIGHT } from "./constants";
import NewOrSetTaskRow from "./NewOrSetTaskRow.vue";
import type { DragResizeEvent, PreviewRange, RoadmapConfig, RoadmapValues } from "./shared";

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  roadmapValues: RoadmapValues;
  task: TaskAbsenteeMaybe;
  start: number;
  index: number;
  timelineScrollX: number;
  timelineScrollY: number;
  timelineWidth: number;
  timelineHeight: number;
}>();

const emit = defineEmits<{
  activeChange: [activeDuid: string | null];
  change: [index: number, startAt: string | null, dueAt: string | null, ended: boolean];
  updatePreviewRange: [previewRange: PreviewRange];
  scrollTo: [x: number, y: number];
}>();

/* Properties */
const isActive = computed(() => props.task.duid === props.roadmapConfig.activeDuid);

const finalDates = computed(() => dataStore.getTaskDates(props.task));

const left = computed(() => {
  const { rolledUpStartAt, rolledUpDueAt } = finalDates.value;
  return rolledUpStartAt
    ? getPixelsTo(props.roadmapConfig, rolledUpStartAt)
    : rolledUpDueAt
      ? getPixelsTo(props.roadmapConfig, rolledUpDueAt) - DEFAULT_BLOCK_WIDTH_PX
      : 0;
});

const width = computed(() => {
  const { rolledUpStartAt, rolledUpDueAt } = finalDates.value;
  return rolledUpStartAt && rolledUpDueAt
    ? getDiffPx(props.roadmapConfig, rolledUpStartAt, rolledUpDueAt)
    : DEFAULT_BLOCK_WIDTH_PX;
});
const parentHeight = computed(() => props.roadmapValues.tasks.length * TASK_ROW_HEIGHT);
const parentWidth = computed(() => props.roadmapValues.scrollWidth);
const canResizeStart = computed(() => !pageStore.isPublicView && !!finalDates.value.rolledUpDueAt);
const canResizeEnd = computed(() => !pageStore.isPublicView && !!finalDates.value.rolledUpStartAt);

let isMidChange = false;

const keyIndex = ref(0);
watch([() => finalDates.value.rolledUpStartAt, () => finalDates.value.rolledUpDueAt, () => props.index], () => {
  if (isActive.value) {
    return;
  }
  keyIndex.value += 1;
});
const activate = () => {
  if (isActive.value) {
    return;
  }
  emit("activeChange", props.task.duid);
};
const deactivate = () => {
  if (!isActive.value) {
    return;
  }
  emit("activeChange", null);
};

/* Show range preview on hover */
const onHover = (e: MouseEvent) => {
  if (e.buttons === 1) {
    return;
  }

  activate();
  emit("updatePreviewRange", {
    startDate: finalDates.value.rolledUpStartAt,
    endDate: finalDates.value.rolledUpDueAt,
  });
};
const onLeave = (e: MouseEvent) => {
  if (e.buttons === 1) {
    return;
  }

  deactivate();
  emit("updatePreviewRange", null);
};

/* Drag and resize */
const getDragResizeValues = (event: DragResizeEvent) => {
  /* Vertical drag */
  const newIndex = Math.round(event.top / TASK_ROW_HEIGHT);

  /* Horizontal drag and resize */
  const { rolledUpStartAt, rolledUpDueAt } = finalDates.value;
  const dayDifference = Math.round((event.left - left.value) / props.roadmapConfig.pxPerDay);
  const resizeDayDifference = Math.round((event.width - width.value) / props.roadmapConfig.pxPerDay);
  if (dayDifference === 0 && resizeDayDifference === 0) {
    return { newIndex, startAt: rolledUpStartAt, dueAt: rolledUpDueAt };
  }

  let startAt = rolledUpStartAt ? normalizeDateToDate(moment(rolledUpStartAt)) : null;
  let dueAt = rolledUpDueAt ? normalizeDateToDate(moment(rolledUpDueAt)) : null;
  if (startAt) {
    if (dayDifference !== 0) {
      /* Drag or resize start */
      startAt = normalizeDateToDate(moment(startAt).add(dayDifference, "day"));
    }
  } else if (dayDifference !== 0 && resizeDayDifference !== 0) {
    /* Resize start from no start */
    startAt = normalizeDateToDate(
      moment(dueAt).add(dayDifference - Math.round(DEFAULT_BLOCK_WIDTH_PX / props.roadmapConfig.pxPerDay), "day")
    );
  }

  if (dueAt) {
    if (dayDifference !== 0 && resizeDayDifference === 0) {
      /* Drag */
      dueAt = normalizeDateToDate(moment(dueAt).add(dayDifference, "day"));
    } else if (dayDifference === 0 && resizeDayDifference !== 0) {
      /* Resize end */
      dueAt = normalizeDateToDate(moment(dueAt).add(resizeDayDifference, "day"));
    }
  } else if (dayDifference === 0 && resizeDayDifference !== 0) {
    /* Resize end from no end */
    dueAt = normalizeDateToDate(
      moment(startAt).add(
        resizeDayDifference + Math.round(DEFAULT_BLOCK_WIDTH_PX / props.roadmapConfig.pxPerDay),
        "day"
      )
    );
  }

  return { newIndex, startAt: startAt?.toISOString() ?? null, dueAt: dueAt?.toISOString() ?? null };
};

let scrollInterval: ReturnType<typeof setInterval> | undefined;
let xScrollAmount = 0;
let yScrollAmount = 0;

const resetInterval = () => {
  clearInterval(scrollInterval);
  scrollInterval = undefined;
  xScrollAmount = 0;
  yScrollAmount = 0;
};

const handleDragResize = (event: DragResizeEvent, ended: boolean): void => {
  if (
    pageStore.isPublicView ||
    !isActive.value ||
    props.roadmapValues.resizing ||
    [event.left, event.top, event.width].some((v) => Number.isNaN(v))
  ) {
    return;
  }

  if (ended) {
    resetInterval();
  }

  const { newIndex, startAt, dueAt } = getDragResizeValues(event);
  if (
    (!ended &&
      newIndex === props.index &&
      startAt === finalDates.value.rolledUpStartAt &&
      dueAt === finalDates.value.rolledUpDueAt) ||
    (ended && !isMidChange)
  ) {
    return;
  }

  if (!isMidChange) {
    isMidChange = true;
    appStore.getBaseVisualization().selectAndScrollTo(props.task.duid);
  }
  emit("change", newIndex, startAt, dueAt, ended);
  if (!ended) {
    emit("updatePreviewRange", { startDate: startAt, endDate: dueAt });

    /* Scroll if going offscreen horizontally */
    if (event.left < props.timelineScrollX + 50) {
      xScrollAmount = event.left - props.timelineScrollX - 50;
    } else if (event.left + event.width > props.timelineScrollX + props.timelineWidth - 50) {
      xScrollAmount = event.left + event.width - props.timelineScrollX - props.timelineWidth + 50;
    } else {
      xScrollAmount = 0;
    }

    /* Scroll if going offscreen vertically */
    if (event.top < props.timelineScrollY + 50) {
      yScrollAmount = event.top - props.timelineScrollY - 50;
    } else if (event.top + event.height > props.timelineScrollY + props.timelineHeight - 50) {
      yScrollAmount = event.top + event.height - props.timelineScrollY - props.timelineHeight + 50;
    } else {
      yScrollAmount = 0;
    }

    if (!scrollInterval && (xScrollAmount !== 0 || yScrollAmount !== 0)) {
      scrollInterval = setInterval(() => {
        emit("scrollTo", props.timelineScrollX + xScrollAmount / 10, props.timelineScrollY + yScrollAmount / 10);
      }, 10);
    } else if (scrollInterval && xScrollAmount === 0 && yScrollAmount === 0) {
      resetInterval();
    }
  }
};

// Selection
const colors = computed(() => colorsByTheme[pageStore.theme]);
const colorByDefinition = computed(() => appStore.colorByDefinition);
const propertyConfig = computed(() => getPropertyConfig(colorByDefinition.value.property.kind));
const taskColor = computed(() => {
  const defaultColor = colors.value.bgHvy;
  if (!propertyConfig.value) {
    return defaultColor;
  }

  const { groups, property } = colorByDefinition.value;
  let value = propertyConfig.value.getValue(property, props.task);

  if (Array.isArray(value) && !isTimeTracking(value)) {
    if (value.length !== 1) {
      return defaultColor;
    }
    value = value[0];
  }

  const group = groups.find((e) => e.value === value);
  return group?.colorHex ?? defaultColor;
});
const modeAlphaMod = computed(() => (pageStore.theme === "light" ? 0 : -0.05));
const baseColor = computed(() => fromHexToHexWithAlpha(taskColor.value, 0.2 + modeAlphaMod.value));
const hoverColor = computed(() => fromHexToHexWithAlpha(taskColor.value, 0.25 + modeAlphaMod.value));
const selectedColor = computed(() => fromHexToHexWithAlpha(taskColor.value, 0.3 + modeAlphaMod.value));
const bothColor = computed(() => fromHexToHexWithAlpha(taskColor.value, 0.35 + modeAlphaMod.value));
const selected = computed(() => appStore.selectedTaskDuids.has(props.task.duid));

/* Is overdue and incomplete */
const getIsOverdue = () =>
  !!finalDates.value.rolledUpDueAt && moment(finalDates.value.rolledUpDueAt).isBefore(moment(), "day");
const isOverdue = ref<boolean>(getIsOverdue());
let timeout: ReturnType<typeof setTimeout> | undefined;
const resetValuesAndTimeout = () => {
  isOverdue.value = getIsOverdue();
  clearTimeout(timeout);
  if (!finalDates.value.rolledUpDueAt) {
    return;
  }
  // eslint-disable-next-line no-restricted-syntax
  timeout = setTimeout(resetValuesAndTimeout, getMsUntilNext("day"));
};
resetValuesAndTimeout();
watch([() => finalDates.value.rolledUpDueAt], resetValuesAndTimeout);
const isCompletedStatus = computed(() => {
  const statusKind = dataStore.getStatusByDuid(props.task.statusDuid)?.kind;
  if (!statusKind) {
    return false;
  }
  return COMPLETED_STATUS_KINDS.has(statusKind);
});
const isOverdueAndIncomplete = computed(() => isOverdue.value && !isCompletedStatus.value);

/* Title properties */
const titleEditor = ref<InstanceType<typeof TitleEditor> | null>(null);
const titleState = ref<SerializedEditorState>(makeLexicalStateWithText(props.task.title));
const isEditingTitle = ref(false);
const globalTask = computed(() => dataStore.getTaskByDuid(props.task.duid));

/* Actions */
const openTask = (): void => {
  if (isMidChange) {
    isMidChange = false;
    return;
  }

  if (isEditingTitle.value) {
    return;
  }
  actions.visualization.selectRowByIdAndScroll(props.task.duid);
  appStore.setTaskDetailOpen(true);
};

/* Task creation title editing */
const startEditingTitle = () => {
  isEditingTitle.value = true;
  nextTick(() => {
    titleEditor.value?.focus();
  });
};
const updateTitle = (newTitle: SerializedEditorState) => {
  titleState.value = newTitle;

  if (!globalTask.value) {
    return;
  }
  globalTask.value.title = convertToTask(titleState.value).title ?? "";
};
const saveIfNotEmpty = () => {
  isEditingTitle.value = false;
  const partialTask = convertToTask(titleState.value);
  const titleText = partialTask.title ?? "";
  if (titleText === "") {
    dataStore.deleteTasks([props.task]);
    return false;
  }
  titleEditor.value?.setEditorState(makeLexicalStateWithText(titleText));

  dataStore.updateTaskFromNlp(props.task, partialTask);
  return true;
};
const onEnter = (event: KeyboardEvent) => {
  event.stopPropagation();
  saveIfNotEmpty();
};

onUnmounted(() => {
  clearTimeout(timeout);
});

defineExpose({
  startEditingTitle,
});
</script>

<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <template v-if="finalDates.rolledUpStartAt || finalDates.rolledUpDueAt">
    <VueDragResize
      :key="`${task.duid}-${roadmapConfig.pxPerDay}-${keyIndex}`"
      :is-active="isActive"
      :x="left"
      :y="start"
      :w="width"
      :h="TASK_ROW_HEIGHT"
      :minh="TASK_ROW_HEIGHT"
      :minw="roadmapConfig.pxPerDay"
      :parent-h="parentHeight"
      :parent-w="parentWidth"
      :is-resizable="!pageStore.isPublicView"
      parent-limitation
      :stick-size="20"
      :sticks="[...(canResizeStart ? ['ml'] : []), ...(canResizeEnd ? ['mr'] : [])]"
      :is-draggable="!pageStore.isPublicView"
      :axis="appStore.subtaskDisplayMode === SubtaskDisplayMode.FLAT ? 'both' : 'x'"
      snap-to-grid
      :grid-y="TASK_ROW_HEIGHT"
      :grid-x="roadmapConfig.pxPerDay"
      content-class="group/roadmap-task"
      @mouseenter="onHover"
      @mouseleave="onLeave"
      @dragging="(event: DragResizeEvent) => handleDragResize(event, false)"
      @dragstop="(event: DragResizeEvent) => handleDragResize(event, true)"
      @resizing="(event: DragResizeEvent) => handleDragResize(event, false)"
      @resizestop="(event: DragResizeEvent) => handleDragResize(event, true)">
      <!-- Overdue indicator -->
      <Tooltip v-if="isOverdueAndIncomplete" text="Overdue and incomplete" class="absolute -left-6">
        <DueDateFieldIcon class="text-red-500 outline-none icon-sm" />
      </Tooltip>
      <div class="pointer-events-none absolute inset-x-0 inset-y-1 rounded bg-std" />

      <!-- Hover indicators -->
      <div
        v-if="canResizeStart"
        class="absolute inset-y-1.5 -left-1.5 hidden w-0.5 rounded bg-lt group-hover/roadmap-task:block" />
      <div
        v-if="canResizeEnd"
        class="absolute inset-y-1.5 -right-1.5 hidden w-0.5 rounded bg-lt group-hover/roadmap-task:block" />

      <!-- Item-->
      <TaskClickWrapper :task="task" :editor-mode="EditorMode.ROADMAP" :disabled="isEditingTitle">
        <div
          :class="{
            'border-[--selectedColor] bg-[--selectedColor] hover:bg-[--bothColor] dark:bg-[--selectedColor] dark:hover:bg-[--bothColor]':
              selected,
            'border-transparent bg-[--baseColor] hover:bg-[--hoverColor] dark:bg-[--baseColor] dark:hover:bg-[--hoverColor]':
              !selected,
            'px-1': !isEditingTitle,
            'pt-1': isEditingTitle,
            'opacity-50': task.absentee,
          }"
          class="drag flex size-full cursor-pointer select-none items-center rounded border transition-colors focus:outline-none"
          :style="{
            '--baseColor': baseColor,
            '--hoverColor': hoverColor,
            '--selectedColor': selectedColor,
            '--bothColor': bothColor,
          }"
          @click.stop="openTask"
          @keydown.enter.stop="openTask">
          <!-- No start or due fade -->
          <div
            v-if="!finalDates.rolledUpStartAt || !finalDates.rolledUpDueAt"
            :class="{
              'bg-gradient-to-l': !finalDates.rolledUpStartAt,
              'bg-gradient-to-r': !finalDates.rolledUpDueAt,
            }"
            class="pointer-events-none absolute inset-0 from-transparent from-30% to-std" />

          <!-- Title editor on create -->
          <TitleEditor
            v-if="isEditingTitle"
            ref="titleEditor"
            :initial-title="titleState"
            :editor-mode="EditorMode.ROADMAP"
            @change="updateTitle"
            @blur="saveIfNotEmpty"
            @enter="onEnter" />
          <!-- Title text -->
          <div v-else class="pointer-events-none sticky left-0 z-0 whitespace-nowrap px-1 text-sm text-md">
            {{ task.title }}
          </div>
        </div>
      </TaskClickWrapper>
    </VueDragResize>
  </template>

  <!-- Set date -->
  <NewOrSetTaskRow
    v-else
    :roadmap-config="roadmapConfig"
    :roadmap-values="roadmapValues"
    :task="task"
    :start="start"
    @update-preview-range="(e) => emit('updatePreviewRange', e)" />
</template>

<style>
.content-container {
  @apply flex items-center py-1;
}
.vdr.active:before {
  content: none !important;
}
.vdr-stick.vdr-stick-mr,
.vdr-stick.vdr-stick-ml {
  @apply !top-0 !m-0 !h-full !cursor-col-resize !opacity-0;
}
</style>
