<script setup lang="ts">
import { computed } from "vue";

import { useAppStore, useDataStore } from "~/stores";

import HomeTaskRow from "./HomeTaskRow.vue";
import RecentWidgetDocItem from "./RecentWidgetDocItem.vue";

const appStore = useAppStore();
const dataStore = useDataStore();

const recentTasks = computed(() => dataStore.getTasksByDuids(appStore.recentTaskStack).slice(0, 10));
const recentDocs = computed(() => appStore.recentDocStack.slice(0, 10));

const areRecentTasks = computed(() => recentTasks.value.length > 0);
const areRecentDocs = computed(() => recentDocs.value.length > 0);
</script>

<template>
  <div class="h-80 w-full flex-col rounded-xl py-4 pl-4 bg-lt">
    <div v-if="areRecentTasks || areRecentDocs" class="flex size-full flex-col gap-5 overflow-y-scroll pr-px">
      <!-- Tasks -->
      <template v-if="areRecentTasks">
        <div class="flex flex-col gap-3">
          <div class="flex h-6 items-center">
            <span class="select-none truncate text-sm/[18px] text-lt">Tasks</span>
          </div>
          <div class="flex select-none flex-col gap-2">
            <HomeTaskRow v-for="task in recentTasks" :key="task.duid" :task="task" />
          </div>
        </div>
      </template>

      <!-- Docs -->
      <div v-if="areRecentDocs" class="flex flex-col gap-3">
        <div class="flex h-6 items-center">
          <span class="select-none truncate text-sm/[18px] text-lt">Docs</span>
        </div>
        <div class="flex flex-col gap-2">
          <RecentWidgetDocItem v-for="duid in recentDocs" :key="duid" :duid="duid" />
        </div>
      </div>

      <div class="min-h-12 w-full" />
    </div>
    <div v-else class="flex h-full select-none items-center justify-center px-4 pb-6 text-center text-lg text-vlt">
      You haven't worked on any tasks or docs recently
    </div>
  </div>
</template>
