<script setup lang="ts">
import { useRouter } from "vue-router";

import {
  ANDROID_APP_STORE_URL,
  IOS_APP_STORE_URL,
  LINUX_APP_DOWNLOAD_LINK,
  MAC_APP_DOWNLOAD_LINK,
  WINDOWS_APP_DOWNLOAD_LINK,
} from "~/constants/link";
import { goHome } from "~/router/common";
import { usePageStore } from "~/stores";

const router = useRouter();
const pageStore = usePageStore();

const defaultRedirect = () => {
  goHome();
};

const mobileAppRedirect = () => {
  if (pageStore.isIos || pageStore.isAndroid) {
    window.location.href = pageStore.isIos ? IOS_APP_STORE_URL : ANDROID_APP_STORE_URL;
    return;
  }
  defaultRedirect();
};

const desktopAppRedirect = () => {
  if (pageStore.isMac || pageStore.isWindows || pageStore.isLinux) {
    window.location.href = pageStore.isMac
      ? MAC_APP_DOWNLOAD_LINK
      : pageStore.isWindows
        ? WINDOWS_APP_DOWNLOAD_LINK
        : LINUX_APP_DOWNLOAD_LINK;
    // eslint-disable-next-line no-restricted-syntax
    setTimeout(() => {
      window.close();
      defaultRedirect();
    }, 5000);
    return;
  }
  defaultRedirect();
};

const redirectSlugToFunc = new Map([
  ["mobile-app", mobileAppRedirect],
  ["desktop-app", desktopAppRedirect],
]);

const redirectSlugs = router.currentRoute.value.path.split("/");
const redirectFunc = redirectSlugToFunc.get(redirectSlugs[redirectSlugs.length - 1]);
if (redirectFunc) {
  redirectFunc();
} else {
  defaultRedirect();
}
</script>

<template>
  <slot />
</template>
