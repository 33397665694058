import { PropertyKind } from "~/shared/enums";
import type { ExtendedColDef, Task } from "~/shared/types";

export const AI_PROPERTY_PSUEDO_DUID = "@ai";
export const QUERY_PROPERTY_PSUEDO_DUID = "@query";
export const RELATIONSHIPS_PROPERTY_PSUEDO_DUID = "@relationships";
export const DOCS_PROPERTY_PSUEDO_DUID = "@docs";
export const COMMENTS_PROPERTY_PSUEDO_DUID = "@comments";
export const LINKS_PROPERTY_PSUEDO_DUID = "@links";
export const ATTACHMENTS_PROPERTY_PSUEDO_DUID = "@attachments";
export const SUBSCRIBERS_PROPERTY_PSUEDO_DUID = "@subscriberDuids";
export const REMIND_AT_PROPERTY_PSUEDO_DUID = "@remindAt";
export const DUID_PROPERTY_PSUEDO_DUID = "duid";
export const ORDER_PROPERTY_PSUEDO_DUID = "order";
export const CONFIG_PROPERTY_PSUEDO_DUID = "config";

export const DEFAULT_COLUMN_WIDTH_LIMITS = {
  minWidth: 100,
  maxWidth: 400,
};
export const TITLE_COLUMN_MIN_WIDTH = 400;
export const TITLE_COLUMN_MAX_MIN_WIDTH = 600;

export const STATUS_COLUMN_MOBILE_WIDTH = 20;
export const ASSIGNEES_COLUMN_MOBILE_WIDTH = 58;

export const DUID_COLUMN_DEF: ExtendedColDef = {
  field: DUID_PROPERTY_PSUEDO_DUID,
  headerName: "DUID",
  suppressMovable: true,
  sortable: true,
};

export const ORDER_COLUMN_DEF: ExtendedColDef = {
  field: ORDER_PROPERTY_PSUEDO_DUID,
  headerName: "Order",
  suppressMovable: true,
  sortable: true,
};

export const CONFIG_COLUMN_DEF: ExtendedColDef = {
  field: CONFIG_PROPERTY_PSUEDO_DUID as keyof Task,
  suppressMovable: true,
  lockPosition: "right",
  resizable: false,
  sortable: false,
  valueGetter: () => "",
};

export const PROPERTY_KIND_TO_DEFAULT_WIDTH_MAP = new Map([
  [DUID_PROPERTY_PSUEDO_DUID, 100],
  [ORDER_PROPERTY_PSUEDO_DUID, 100],
  [PropertyKind.DEFAULT_KIND, 100],
  [PropertyKind.DEFAULT_TITLE, 500],
  [PropertyKind.DEFAULT_DARTBOARD, 100],
  [PropertyKind.DEFAULT_STATUS, 100],
  [PropertyKind.DEFAULT_ASSIGNEES, 120],
  [PropertyKind.DEFAULT_DATES, 180],
  [PropertyKind.DEFAULT_PRIORITY, 100],
  [PropertyKind.DEFAULT_TAGS, 160],
  [PropertyKind.DEFAULT_SIZE, 100],
  [PropertyKind.DEFAULT_TIME_TRACKING, 100],
  [PropertyKind.DEFAULT_CREATED_AT, 100],
  [PropertyKind.DEFAULT_CREATED_BY, 100],
  [PropertyKind.DEFAULT_UPDATED_AT, 100],
  [PropertyKind.DEFAULT_UPDATED_BY, 100],
  [PropertyKind.SELECT, 160],
  [PropertyKind.MULTISELECT, 160],
  [PropertyKind.STATUS, 100],
  [PropertyKind.USER, 100],
  [PropertyKind.DATES, 180],
  [PropertyKind.TIME_TRACKING, 100],
  [PropertyKind.TEXT, 160],
  [PropertyKind.NUMBER, 100],
  [PropertyKind.CHECKBOX, 100],
]);

export const NO_DROPDOWN_PROPERTY_KIND_SET = new Set([
  PropertyKind.DEFAULT_TITLE,
  PropertyKind.DEFAULT_DESCRIPTION,
  PropertyKind.DEFAULT_ATTACHMENTS,
  PropertyKind.CHECKBOX,
  PropertyKind.NUMBER,
  PropertyKind.TEXT,
]);

export const NO_SETTINGS_PROPERTY_KIND_SET = new Set([
  PropertyKind.DEFAULT_TITLE,
  PropertyKind.DEFAULT_DESCRIPTION,
  PropertyKind.DEFAULT_DARTBOARD,
  PropertyKind.DEFAULT_ATTACHMENTS,
]);

export const NOT_SHOWN_PROPERTY_KINDS = new Set([
  PropertyKind.DEFAULT_DARTBOARD,
  PropertyKind.DEFAULT_DESCRIPTION,
  PropertyKind.DEFAULT_ATTACHMENTS,
]);
