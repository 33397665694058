<script setup lang="ts">
import { Switch } from "@headlessui/vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import BILLING_FEATURES from "~/constants/billing";
import { BillingPeriod, ButtonSize, ButtonStyle, Subscription } from "~/shared/enums";
import { usePageStore, useTenantStore } from "~/stores";
import { checkout, manageBilling } from "~/utils/billing";

const BUSINESS_TIER_TENANT_DUIDS = new Set(["JKmK80te1OnO"]);

const router = useRouter();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const isBillingAnnually = ref(true);
const monthlyCostForPremium = computed(() => (isBillingAnnually.value ? 8 : 10));
const monthlyCostForBusiness = computed(() => (isBillingAnnually.value ? 12 : 15));

const tenantOnBusiness = computed(() => BUSINESS_TIER_TENANT_DUIDS.has(tenantStore.duid));
const tenantOnPersonal = computed(() => tenantStore.subscription === Subscription.PERSONAL && !tenantOnBusiness.value);
const tenantOnPremium = computed(() => tenantStore.subscription === Subscription.PREMIUM && !tenantOnBusiness.value);

const premiumButtonText = computed(() => (tenantOnPremium.value ? "Current plan" : "Upgrade"));
const premiumButtonEnabled = computed(() => !tenantOnPremium.value);
const premiumButtonWorking = ref(false);

const clickPremiumButton = () => {
  premiumButtonWorking.value = true;
  checkout(
    router.currentRoute.value.fullPath,
    isBillingAnnually.value ? BillingPeriod.ANNUALLY : BillingPeriod.MONTHLY
  );
};
</script>

<template>
  <div>
    <table v-if="!pageStore.isMobileApp" class="w-full">
      <tbody>
        <tr>
          <th class="w-[34%] select-none text-left align-bottom font-normal text-lt">
            <div class="flex items-center py-2 pr-3">
              <div>Monthly</div>
              <Switch
                v-model="isBillingAnnually"
                :class="isBillingAnnually ? 'bg-primary-base' : 'bg-md'"
                class="relative mx-2 inline-flex h-5 w-8 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-ring-std"
                aria-label="Monthly/Annually toggle">
                <span
                  aria-hidden="true"
                  :class="isBillingAnnually ? 'translate-x-3' : 'translate-x-0'"
                  class="pointer-events-none inline-block rounded-full shadow ring-0 transition duration-200 ease-in-out bg-std icon-sm" />
              </Switch>
              <div>Annually</div>
            </div>
          </th>
          <th
            :class="tenantOnPersonal && 'bg-lt'"
            class="w-[22%] min-w-40 border text-left align-top font-normal border-md">
            <div class="px-3 py-2">
              <div class="flex items-center justify-between">
                <div class="select-none text-xl text-md">Personal</div>
              </div>
              <div class="mb-2 flex h-11 items-center">
                <div class="w-9 select-none text-xl text-md">$0</div>
              </div>
              <Button
                v-if="tenantOnPersonal"
                :btn-style="ButtonStyle.SECONDARY"
                text="Current plan"
                disabled
                block
                :size="ButtonSize.LARGE" />
            </div>
          </th>
          <th
            :class="tenantOnPremium && 'bg-lt'"
            class="w-[22%] min-w-40 border text-left align-top font-normal border-md">
            <div class="px-3 py-2">
              <div class="flex items-center justify-between">
                <div class="select-none text-xl text-md">Premium</div>
                <div
                  v-if="isBillingAnnually"
                  class="ml-1 select-none rounded border px-1 py-0.5 text-xs text-lt border-hvy">
                  -20%
                </div>
              </div>
              <div class="mb-2 flex h-11 items-center">
                <div class="w-9 select-none text-xl text-md">${{ monthlyCostForPremium }}</div>
                <div class="ml-2 flex flex-col">
                  <div class="flex-1 select-none text-xs text-lt">per teammate</div>
                  <div class="flex-1 select-none text-xs text-lt">per month</div>
                </div>
              </div>
              <Button
                v-if="!tenantOnBusiness"
                :btn-style="premiumButtonEnabled ? ButtonStyle.PRIMARY : ButtonStyle.SECONDARY"
                :text="premiumButtonText"
                :disabled="!premiumButtonEnabled"
                :working="premiumButtonWorking"
                block
                :size="ButtonSize.LARGE"
                @click="clickPremiumButton" />
              <div class="my-2 h-4">
                <button
                  v-if="tenantOnPremium && !tenantOnBusiness"
                  type="button"
                  class="text-xs underline text-vlt"
                  @click="manageBilling">
                  Manage
                </button>
              </div>
            </div>
          </th>
          <th
            :class="tenantOnBusiness && 'bg-lt'"
            class="w-[22%] min-w-40 border text-left align-top font-normal border-md">
            <div class="px-3 py-2">
              <div class="flex items-center justify-between">
                <div class="select-none text-xl text-md">Business</div>
                <div
                  v-if="isBillingAnnually"
                  class="ml-1 select-none rounded border px-1 py-0.5 text-xs text-lt border-hvy">
                  -20%
                </div>
              </div>
              <div class="flex h-11 items-center">
                <div class="w-9 select-none text-xl text-md">${{ monthlyCostForBusiness }}</div>
                <div class="ml-2 flex flex-col">
                  <div class="flex-1 select-none text-xs text-lt">per teammate</div>
                  <div class="flex-1 select-none text-xs text-lt">per month</div>
                </div>
              </div>
              <a
                v-if="!tenantOnBusiness"
                href="mailto:support@itsdart.com?subject=Business%20tier%20upgrade"
                class="mt-2 block w-full select-none rounded border px-3 py-2 text-center text-sm shadow-sm text-md border-md focus-ring-std hover:bg-lt">
                Contact sales
              </a>
              <template v-else>
                <Button
                  :btn-style="ButtonStyle.SECONDARY"
                  text="Current plan"
                  disabled
                  block
                  :size="ButtonSize.LARGE" />
                <div class="my-2 h-4">
                  <button type="button" class="text-xs underline text-vlt" @click="manageBilling">Manage</button>
                </div>
              </template>
            </div>
          </th>
        </tr>
        <tr v-for="feature in BILLING_FEATURES" :key="feature.id" class="select-none text-md">
          <td class="border-y border-md">
            <div class="select-none py-2 pr-3 text-lt">
              {{ feature.title }}
            </div>
          </td>
          <td :class="tenantOnPersonal && 'bg-lt'" class="border border-md">
            <div class="px-3 py-2">
              {{ feature.personal }}
            </div>
          </td>
          <td :class="tenantOnPremium && 'bg-lt'" class="border border-md">
            <div class="px-3 py-2">
              {{ feature.premium }}
            </div>
          </td>
          <td :class="tenantOnBusiness && 'bg-lt'" class="border border-md">
            <div class="px-3 py-2">
              {{ feature.business }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <span class="text-md">
        To manage plans and billing for your workspace, use Dart in a browser or on a computer.
      </span>
    </div>
  </div>
</template>
