<script setup lang="ts">
import { capitalize, type Component, computed, nextTick, onUnmounted, ref } from "vue";
import { RouterLink, useRouter } from "vue-router";

import actions from "~/actions";
import DropZone from "~/components/dumb/DropZone.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import PageIconPicker from "~/components/dumb/PageIconPicker.vue";
import TextInput from "~/components/dumb/TextInput.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import PagePropertiesEditor from "~/components/PagePropertiesEditor.vue";
import { DROP_HOVER_STYLE } from "~/components/visualization/constants";
import { PAGES_PARTIALLY_EDITABLE } from "~/constants/page";
import { DotsHorizontalIcon, LockIcon, ShowIcon } from "~/icons";
import { makeLinkToSpaceSettingsPageRef } from "~/router/common";
import { CommandId, FolderKind, IconKind, PageKind, Placement, ViewKind } from "~/shared/enums";
import type { Dartboard, Doc, Page, PageTabConfig, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { getPageLink } from "~/utils/common";
import { getEmojiRecommendation } from "~/utils/recommendation";

const PAGE_KIND_TO_GO_TO_COMMAND_ID = new Map([
  [PageKind.DARTBOARD, CommandId.GO_TO_DARTBOARD],
  [PageKind.DASHBOARD, CommandId.GO_TO_DASHBOARD],
  [PageKind.FOLDER, CommandId.GO_TO_FOLDER],
  [PageKind.VIEW, CommandId.GO_TO_VIEW],
]);

const props = defineProps<{
  pageConfig: PageTabConfig;
  narrowLeft?: boolean;
  wideLeft?: boolean;
}>();

const emit = defineEmits<{
  rollover: [];
  reverseRollover: [];
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const propertiesEditorRef = ref<InstanceType<typeof PagePropertiesEditor> | null>(null);

const commandId = computed(
  () => props.pageConfig.commandId ?? PAGE_KIND_TO_GO_TO_COMMAND_ID.get(props.pageConfig.pageKind)
);
const linkDestination = computed(() => getPageLink(props.pageConfig, dataStore.getSpaceByDuid));

const taskDroppable = computed(
  () =>
    props.pageConfig.pageKind === PageKind.DARTBOARD ||
    props.pageConfig.pageKind === PageKind.FOLDER ||
    props.pageConfig.kind === ViewKind.TRASH
);

const onDrop = (_: string, item: unknown) => {
  if (!taskDroppable.value) {
    return;
  }

  if (props.pageConfig.pageKind === PageKind.DARTBOARD) {
    const task = item as Partial<Task>;
    if (!task.duid) {
      return;
    }

    actions.visualization.selectRowByIdAndScroll(task.duid);
    actions.visualization.moveTasksToDartboardOrTrash(props.pageConfig.duid);

    return;
  }

  if (props.pageConfig.pageKind === PageKind.FOLDER) {
    const doc = item as Partial<Doc>;
    if (!doc.duid) {
      return;
    }

    dataStore.updateDocs([{ duid: doc.duid, folderDuid: props.pageConfig.duid }]);
  }
};

const isEditable = computed(() => PAGES_PARTIALLY_EDITABLE.has(props.pageConfig.kind));

const inputTitle = ref<InstanceType<typeof TextInput> | null>(null);

const preventDefaultIfEditing = (event: Event) => {
  if (inputTitle.value === null || !inputTitle.value.isEditing) {
    return;
  }
  event.preventDefault();
};

const startEditingTitle = async () => {
  if (!isEditable.value) {
    return;
  }
  if (appStore.contextMenu) {
    appStore.contextMenu = null;
    await nextTick();
  }

  inputTitle.value?.startEditing();
};

const finishEditingTitle = async (newTitle: string) => {
  if (!props.pageConfig) {
    return;
  }

  let value = newTitle.trim();
  if (value === "" && props.pageConfig.title === "") {
    dataStore.deletePage(props.pageConfig);
    return;
  }
  if (value === props.pageConfig.title) {
    return;
  }

  if (value === "") {
    value = props.pageConfig.title;
    inputTitle.value?.refreshText();
    return;
  }

  if (props.pageConfig.iconKind !== IconKind.NONE) {
    dataStore.updatePage({ duid: props.pageConfig.duid, title: value }, props.pageConfig.pageKind);
    return;
  }

  const emojiRecUpdate = await getEmojiRecommendation(props.pageConfig.duid, value);
  dataStore.updatePage({ duid: props.pageConfig.duid, title: value, ...emojiRecUpdate }, props.pageConfig.pageKind);
};

const tooltipPlacement = computed(() => (props.pageConfig.kind === ViewKind.TRASH ? Placement.TOP : Placement.BOTTOM));

const preventIfEditable = (e: Event) => {
  if (!isEditable.value) {
    return;
  }
  e.preventDefault();
};

const mountDropTarget = (dropTarget: Component | null) => {
  if (!dropTarget || props.pageConfig.pageKind !== PageKind.DARTBOARD) {
    appStore.taskDropTargets.delete(props.pageConfig.duid);
    return;
  }
  appStore.taskDropTargets.set(props.pageConfig.duid, (dropTarget as InstanceType<typeof DropZone>).$el);
};

const onContextMenu = (event: MouseEvent) => {
  if ((tenantStore.isDart && !pageStore.adminHidden && event.altKey) || props.pageConfig.kind === ViewKind.TRASH) {
    return;
  }

  appStore.openContextMenu(
    event as PointerEvent,
    actions.context.page(props.pageConfig, false, (eventKind: string) => {
      switch (eventKind) {
        case "startEditingTitle": {
          startEditingTitle();
          break;
        }
        case "resetToActive": {
          emit("reverseRollover");
          break;
        }
        case "startNextSprint": {
          emit("rollover");
          break;
        }
        case "startEditingSpace": {
          router.replace(makeLinkToSpaceSettingsPageRef((props.pageConfig as Dartboard).spaceDuid, "sprints").value);
          break;
        }
        default: {
          throw new Error(`Unknown event kind: ${eventKind}`);
        }
      }
    })
  );
};

onUnmounted(() => {
  appStore.taskDropTargets.delete(props.pageConfig.duid);
});

defineExpose({
  startEditingTitle,
});
</script>

<template>
  <DropZone
    :ref="mountDropTarget"
    v-slot="{ hovering }"
    class="rounded"
    :group="pageConfig.pageKind === PageKind.FOLDER ? ['doc-editor'] : ['board']"
    :disabled="!taskDroppable"
    @on-drop="onDrop">
    <RouterLink
      :to="linkDestination"
     
      class="group/page-tab flex h-7 w-full select-none items-center gap-1 rounded pr-2.5 text-sm font-medium transition-colors drag-none text-lt focus-ring-lt"
      :class="[
        pageConfig.active ? 'bg-md hover:bg-hvy' : 'hover:bg-md',
        narrowLeft ? 'pl-0.5' : 'pl-3',
        wideLeft && 'pl-1.5',
        hovering && DROP_HOVER_STYLE,
      ]"
      @contextmenu="onContextMenu">
      <PageIconPicker
        :page="pageConfig"
        is-contrast
        :disabled="!isEditable"
        @click="preventIfEditable"
        @keydown.enter="preventIfEditable">
        <Tooltip text="Change icon" :disabled="!isEditable">
          <span class="flex items-center justify-center rounded p-0.5" :class="isEditable && 'hover:bg-opposite/10'">
            <PageIcon :page="pageConfig" />
          </span>
        </Tooltip>
      </PageIconPicker>
      <div class="h-full select-none overflow-x-hidden">
        <Tooltip :command-id="commandId" :placement="tooltipPlacement" block height-block>
          <TextInput
            ref="inputTitle"
            :text="pageConfig.title"
            label="Page title"
            :editable="isEditable"
           
            dblclick-to-edit
            @click="preventDefaultIfEditing"
            @save="finishEditingTitle" />
        </Tooltip>
      </div>
      <Tooltip
        v-if="pageConfig.pageKind === PageKind.FOLDER && pageConfig.kind === FolderKind.DEFAULT"
        text="This is a default folder so it cannot be moved or deleted">
        <div class="-mt-px hidden items-center justify-center p-0.5 text-vlt group-hover/page-tab:flex">
          <LockIcon class="icon-sm" />
        </div>
      </Tooltip>
      <Tooltip
        v-if="
          pageConfig.pageKind === PageKind.VIEW && !pageConfig.accessibleByTeam && pageConfig.kind !== ViewKind.TRASH
        "
        :text="`Only you${
          pageConfig.accessibleByUserDuids.length > 1 ? ' and specific teammates' : ''
        } have access to this page`">
        <div class="hidden items-center justify-center p-0.5 text-lt group-hover/page-tab:flex">
          <ShowIcon class="icon-sm" />
        </div>
      </Tooltip>
      <div
        class="w-4 flex-1 justify-end"
        :class="propertiesEditorRef?.isOpen ? 'flex' : 'hidden group-hover/page-tab:flex'">
        <PagePropertiesEditor
          v-if="pageConfig.kind !== ViewKind.TRASH"
          ref="propertiesEditorRef"
          :page="pageConfig as Page"
          :placement="Placement.RIGHT_TOP"
          @start-editing-title="startEditingTitle">
          <button
            type="button"
           
            class="-mt-px items-center justify-center rounded p-0.5 focus-ring-lt hover:bg-opposite/10 focus:outline-none"
            :aria-label="`${capitalize(pageConfig.pageKind)} options`">
            <DotsHorizontalIcon class="text-lt icon-sm" />
          </button>
        </PagePropertiesEditor>
      </div>
    </RouterLink>
  </DropZone>
</template>
