<script setup lang="ts">
import { useParentElement } from "@vueuse/core";
import type { SerializedEditorState } from "lexical";
import { computed, nextTick, ref, watch } from "vue";

import actions from "~/actions";
import { getPropertyPartialTask, getPropertyValueFromTask, getShownPropertyWithConfigList } from "~/common/properties";
import Animated from "~/components/dumb/Animated.vue";
import AvatarGroup from "~/components/dumb/AvatarGroup.vue";
import DartboardChip from "~/components/dumb/DartboardChip.vue";
import StatusIcon from "~/components/dumb/StatusIcon.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import TitleEditor from "~/components/text/TitleEditor.vue";
import { convertToTask } from "~/components/text/utils";
import TaskChips from "~/components/visualization/components/TaskChips.vue";
import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import { ChevronDownIcon, ChildRelationshipIcon } from "~/icons";
import {
  DartboardKind,
  EditorMode,
  PageKind,
  PropertyKind,
  RelationshipKindKind,
  SubtaskDisplayMode,
} from "~/shared/enums";
import type { Property, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { getCharacterPosition, makeLexicalStateWithText, someInHierarchy } from "~/utils/common";

const props = defineProps<{
  task: Task;
  hasShownParent?: boolean;
  includeDartboard?: boolean;
  excludeProperty?: Property;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const parent = useParentElement();

const showAssigneeLabel = computed(
  () =>
    !props.task.assignedToAi &&
    props.task.assigneeDuids.length === 1 &&
    dataStore.defaultAssigneesProperty.adtl.isNameShown
);

const excludedPropertyKinds = computed(
  () =>
    new Set([
      ...(showAssigneeLabel.value ? [] : [PropertyKind.DEFAULT_ASSIGNEES]),
      ...(dataStore.defaultStatusProperty.adtl.isNameShown ? [] : [PropertyKind.DEFAULT_STATUS]),
    ])
);

const properties = computed(() =>
  getShownPropertyWithConfigList()
    .filter(
      ([property]) => !excludedPropertyKinds.value.has(property.kind) && property.duid !== props.excludeProperty?.duid
    )
    .map(([property, config]) => {
      const chip = config.chip();
      return {
        duid: property.duid,
        title: property.title,
        component: chip.component,
        values: chip.getValues(property, props.task),
      };
    })
);

const titleEditor = ref<InstanceType<typeof TitleEditor> | null>(null);
const titleDiv = ref<HTMLDivElement | null>(null);
const titleState = ref<SerializedEditorState>(makeLexicalStateWithText(props.task.title));
const isEditingTitle = ref(false);
const globalTask = computed(() => dataStore.getTaskByDuid(props.task.duid));

const canAddTasks = computed(
  () => appStore.currentPage?.pageKind !== PageKind.VIEW && appStore.currentPage?.kind !== DartboardKind.FINISHED
);

const openTaskInDetail = () => {
  if (isEditingTitle.value) {
    return;
  }

  actions.visualization.selectRowByIdAndScroll(props.task.duid);
  appStore.setTaskDetailOpen(true);
};

const clickReminderOpen = () => {
  actions.visualization.selectRowByIdAndScroll(props.task.duid);
  appStore.setTasksOpenInReminderModal([props.task]);
};

const jumpToDescription = () => {
  actions.visualization.selectRowByIdAndScroll(props.task.duid);
  actions.visualization.jumpToDescription(props.task);
};

const jumpToComments = () => {
  actions.visualization.selectRowByIdAndScroll(props.task.duid);
  actions.visualization.jumpToComments(props.task);
};

const absentee = computed(() => "absentee" in props.task && props.task.absentee);
const notFlat = computed(() => appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT);

const subtasks = computed(() =>
  dataStore.getTasksByDuidsOrdered(
    dataStore.getRelationshipsByKindKind(props.task, RelationshipKindKind.PARENT_OF, true).map((e) => e.targetDuid)
  )
);
const hasSubtasks = computed(() => subtasks.value.length > 0);
const completedStatusDuids = computed(
  () =>
    new Set(
      dataStore.getStatusesByKinds([...COMPLETED_STATUS_KINDS], dataStore.defaultStatusProperty).map((e) => e.duid)
    )
);
const numSubtasksCompleted = computed(
  () => subtasks.value.filter((task) => completedStatusDuids.value.has(task.statusDuid)).length
);

// Update title if it changes elsewhere
watch(
  () => props.task.title,
  (newTitle) => {
    if (newTitle === undefined || titleEditor.value?.hasFocus) {
      return;
    }

    const editorState = makeLexicalStateWithText(newTitle ?? "");
    if (JSON.stringify(titleState.value) === JSON.stringify(editorState)) {
      return;
    }

    titleState.value = editorState;
    titleEditor.value?.setEditorState(titleState.value);
  }
);

// Update local title and globally
const updateTitle = (newTitle: SerializedEditorState) => {
  titleState.value = newTitle;

  if (!globalTask.value) {
    return;
  }
  globalTask.value.title = convertToTask(titleState.value).title ?? "";
};

const toggleExpanded = () => {
  if (pageStore.isPublicView) {
    return;
  }
  if (!notFlat.value) {
    openTaskInDetail();
    return;
  }
  dataStore.updateTasks([{ duid: props.task.duid, expanded: !props.task.expanded }]);
};

const startEditingTitle = (event?: MouseEvent) => {
  // eslint-disable-next-line no-restricted-syntax
  setTimeout(() => {
    actions.visualization.selectRowByIdAndScroll(props.task.duid);
  });

  const position = getCharacterPosition(titleDiv.value, event);
  isEditingTitle.value = true;
  nextTick(() => {
    const editor = titleEditor.value;
    if (!editor) {
      return;
    }
    editor.focus();
    if (position === undefined) {
      return;
    }
    editor.select(position);
  });
};

const saveIfNotEmpty = async (event?: FocusEvent) => {
  isEditingTitle.value = false;
  const partialTask = convertToTask(titleState.value);
  const titleText = partialTask.title ?? "";
  if (
    titleText === "" &&
    !(event && someInHierarchy(event.relatedTarget as HTMLElement | null, (e) => e === parent.value))
  ) {
    dataStore.deleteTasks([props.task]);
    return false;
  }
  titleEditor.value?.setEditorState(makeLexicalStateWithText(titleText));

  let deleted = false;
  await dataStore.updateTaskFromNlp(props.task, partialTask, {
    onRemapFailed: () => {
      dataStore.deleteTasks([props.task]);
      deleted = true;
    },
  });
  return !deleted;
};

const onEnter = async (event: KeyboardEvent) => {
  event.stopPropagation();
  const created = await saveIfNotEmpty();
  if (!created || !canAddTasks.value) {
    return;
  }
  const partial = props.excludeProperty
    ? getPropertyPartialTask(props.excludeProperty, {}, getPropertyValueFromTask(props.excludeProperty, props.task))
    : {};
  const newTask = await actions.visualization.createTaskUnderneath(partial, { preventStartEditing: true });
  if (!newTask) {
    return;
  }
  appStore.board?.startEditingTask(newTask.duid);
};

const onTab = async (event: KeyboardEvent) => {
  event.preventDefault();
  await saveIfNotEmpty();
  if (event.shiftKey) {
    actions.visualization.subtaskOutdent();
  } else {
    actions.visualization.subtaskIndent();
  }
};

const onPaste = (text: string) => {
  const visualization = appStore.getActiveVisualization();
  const allRows = visualization.getAll();
  const indexOfTask = allRows.findIndex((row: Task) => row.duid === props.task.duid);
  const belowTaskOrder = allRows[indexOfTask + 1]?.order ?? "";

  const parentKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;
  const parentTaskDuid = dataStore
    .getRelationships(props.task)
    .find((e) => e.kindDuid === parentKindDuid && !e.isForward)?.targetDuid;

  const remainingText = actions.task.createTasksFromText(
    text,
    props.task.dartboardDuid,
    props.task.order,
    belowTaskOrder,
    { statusDuid: props.task.statusDuid },
    { parentTaskDuid }
  );
  titleState.value = makeLexicalStateWithText(remainingText ?? "");
  titleEditor.value?.setEditorState(titleState.value);
};

defineExpose({
  isEditingTitle,
  startEditingTitle,
});
</script>

<template>
  <div class="flex w-full gap-2">
    <div class="flex w-0 flex-1 flex-col !leading-5" :class="absentee && 'opacity-50'">
      <TitleEditor
        v-if="isEditingTitle"
        ref="titleEditor"
        :initial-title="titleState"
        :editor-mode="EditorMode.BOARD"
        @paste-multiline-text="onPaste"
        @change="updateTitle"
        @blur="saveIfNotEmpty"
        @tab="onTab"
        @enter="onEnter" />
      <div
        v-else
        ref="titleDiv"
        class="-mx-px min-h-[22px] w-auto !min-w-[40px] cursor-text hyphens-auto rounded border border-transparent px-px text-sm text-md break-words hover:border-oncolor"
        @click.stop="startEditingTitle"
        @keydown.enter="() => startEditingTitle()">
        {{ task.title }}
      </div>
      <Animated
        v-if="!absentee"
        class="flex flex-wrap gap-1"
        :class="isEditingTitle ? 'mt-[5px] empty:-mt-[3px]' : 'mt-2 empty:mt-0'">
        <Tooltip
          v-if="notFlat && hasSubtasks"
          class="dart-subtask-expand"
          :text="`${task.expanded ? 'Hide' : 'Show'} subtasks`">
          <button
            type="button"
            class="flex h-5 items-center justify-center gap-1 rounded border pl-1 pr-1.5 text-gray-900/30 border-oncolor focus-ring-none hover:bg-opposite/10 dark:text-white/20"
            tabindex="-1"
            @click.stop="toggleExpanded"
            @keydown.enter.stop="toggleExpanded">
            <span class="sr-only">Toggle subtasks</span>
            <ChevronDownIcon class="transition-transform icon-xs" :class="!task.expanded && '-rotate-90'" />
            <ChildRelationshipIcon class="icon-xs" />
            <span class="text-xs">{{ `${numSubtasksCompleted}/${subtasks.length}` }}</span>
          </button>
        </Tooltip>
        <DartboardChip
          v-if="includeDartboard && !pageStore.isPublicView"
          class="w-max max-w-full truncate"
          :selected-dartboard-duid="task.dartboardDuid"
          :editor-mode="EditorMode.BOARD" />
        <template v-if="!pageStore.isMobile">
          <component
            :is="property.component"
            v-for="property in properties"
            :key="property.duid"
            class="truncate"
            v-bind="property.values"
            :editor-mode="EditorMode.BOARD" />
          <TaskChips
            :task="task"
            :editor-mode="EditorMode.BOARD"
            :has-shown-parent="!!hasShownParent"
            :subtasks-expanded="task.expanded"
            :hide-subtasks="notFlat && hasSubtasks"
            @open-task-in-detail="openTaskInDetail"
            @jump-to-description="jumpToDescription"
            @jump-to-comments="jumpToComments"
            @open-reminder="clickReminderOpen" />
        </template>
      </Animated>
    </div>
    <div class="flex flex-col gap-2">
      <template v-if="!absentee">
        <Tooltip
          v-if="excludeProperty?.kind !== PropertyKind.DEFAULT_STATUS"
          :text="dataStore.defaultStatusProperty.title">
          <StatusIcon :duid="task.statusDuid" class="icon-lg" />
        </Tooltip>
        <Tooltip
          v-if="tenantStore.assigneeEnabled && excludeProperty?.kind !== PropertyKind.DEFAULT_ASSIGNEES"
          :text="dataStore.defaultAssigneesProperty.title">
          <AvatarGroup
            :ai="task.assignedToAi"
            show-ai-working
            :duids="task.assigneeDuids"
            :editor-mode="EditorMode.BOARD"
            :limit="3"
            :first-user-only="!tenantStore.multipleAssigneesEnabled"
            vertical
            large
            :class="absentee && 'opacity-50'" />
        </Tooltip>
      </template>
      <Tooltip
        v-else
        class="dart-subtask-expand"
        :text="`${task.expanded ? 'Hide' : 'Show'} subtasks`"
        :class="absentee && 'opacity-50'">
        <button
          type="button"
          class="flex h-5 items-center justify-center gap-0.5 rounded border px-0.5 text-gray-900/30 border-oncolor focus-ring-none hover:bg-opposite/10 dark:text-white/20"
          tabindex="-1"
          @click.stop="toggleExpanded"
          @keydown.enter.stop="toggleExpanded">
          <span class="sr-only">Toggle subtasks</span>
          <ChevronDownIcon class="transition-transform icon-xs" :class="!task.expanded && '-rotate-90'" />
          <ChildRelationshipIcon class="icon-xs" />
          <span class="text-xs">{{ `${numSubtasksCompleted}/${subtasks.length}` }}</span>
        </button>
      </Tooltip>
    </div>
  </div>
</template>
