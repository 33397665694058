import type { IRowNode } from "ag-grid-community";
import { computed } from "vue";

import { colorsByTheme } from "~/constants/style";
import type { Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { makeStringComparator } from "~/utils/comparator";
import { getOrdersBetween } from "~/utils/orderManager";

import type { Actions } from "..";

/** Drag related actions */
export default (actions: Actions) => ({
  updateGhostStyle: (options?: { text?: string; hide?: boolean }) => {
    const pageStore = usePageStore();
    const { text, hide } = options ?? {};
    // TODO this is all a hack because I can't figure out how to bind the styles properly
    const colors = computed(() => colorsByTheme[pageStore.theme]);
    const ghost = document.querySelectorAll(".ag-dnd-ghost")[0] as HTMLDivElement;
    if (!ghost) {
      // eslint-disable-next-line no-restricted-syntax
      setTimeout(() => actions.visualization.updateGhostStyle(options), 100);
      return;
    }

    if (hide) {
      ghost.style.cursor = "default";
      return;
    }

    ghost.style.opacity = "100";
    ghost.style.backgroundColor = colors.value.bgLt;
    ghost.style.borderColor = colors.value.borderLt;
    ghost.style.color = colors.value.textMd;
    (ghost.children[0] as HTMLDivElement).style.color = colors.value.btnHvyHover;
    if (text) {
      ghost.children[1].innerHTML = text;
    }
  },
  getOrdersForMovingNodes: (
    primaryMovingNode: IRowNode<Task>,
    movingNodes: IRowNode<Task>[],
    overRow: IRowNode<Task> | undefined
  ) => {
    const sortedSiblingNodes = primaryMovingNode.parent?.childrenAfterSort;
    const filteredSiblingNodes = primaryMovingNode.parent?.childrenAfterFilter;
    if (!sortedSiblingNodes || !filteredSiblingNodes) {
      return undefined;
    }

    const filteredSiblingDuids = new Set(filteredSiblingNodes.map((e) => e.data?.duid));
    if (overRow?.data && !filteredSiblingDuids.has(overRow.data.duid)) {
      return undefined;
    }

    const movingRowDuids = new Set(movingNodes.map((e) => e.data?.duid));
    const sortedStationarySiblingTasks = sortedSiblingNodes
      .map((e) => e.data)
      .filter((e): e is Task => !!e && filteredSiblingDuids.has(e.duid) && !movingRowDuids.has(e.duid));

    let startOrder = "";
    let endOrder = "";
    if (!overRow) {
      if (sortedStationarySiblingTasks.length > 0) {
        startOrder = sortedStationarySiblingTasks
          .map((e) => e.order)
          .reduce((prev, curr) => (prev > curr ? prev : curr));
      }
    } else {
      const goingUp = (primaryMovingNode.rowIndex ?? 0) > (overRow.rowIndex ?? 0);
      const newPos =
        sortedStationarySiblingTasks.findIndex((e) => e.duid === overRow.data?.duid) +
        ((primaryMovingNode.data?.order || 0) > (overRow.data?.order || 0) ? 0 : 1);
      if (newPos < 0 || newPos > sortedStationarySiblingTasks.length) {
        return undefined;
      }
      if (newPos !== 0) {
        startOrder = sortedStationarySiblingTasks[newPos - 1].order;
      }
      if (newPos !== sortedStationarySiblingTasks.length) {
        endOrder = sortedStationarySiblingTasks[newPos].order;
      }
      // normalize the order for weird cases with sorts
      const currentOrder = primaryMovingNode.data?.order;
      if (
        currentOrder === undefined ||
        (goingUp && (endOrder === "" || endOrder >= currentOrder)) ||
        (!goingUp && (startOrder === "" || startOrder <= currentOrder))
      ) {
        return undefined;
      }
      if (startOrder >= endOrder) {
        if (goingUp) {
          startOrder = "";
        } else {
          endOrder = "";
        }
      }
    }

    return getOrdersBetween(startOrder, endOrder, movingNodes.length);
  },
  reorderRows: (
    primaryMovingRow: IRowNode<Task>,
    movingRows: IRowNode<Task>[],
    overRow: IRowNode<Task> | undefined,
    options: { noBackend?: boolean; indentLevelChange?: number } = {}
  ) => {
    const { noBackend = false, indentLevelChange = 0 } = options;

    const appStore = useAppStore();
    const dataStore = useDataStore();
    const visualization = appStore.getActiveVisualization();

    if (primaryMovingRow.data === undefined) {
      return;
    }

    // this block handles cases where the moving nodes are not all siblings (e.g. select a parent and its children)
    const realMovingRows = movingRows.filter((e): e is IRowNode<Task> & { data: Task } => !!e.data);
    const realMovingDuids = new Set(realMovingRows.map((e) => e.data.duid));
    const movingRowToMovingAncestorMap = new Map(
      realMovingRows.map((base) => {
        let ancestor = base;
        let curr = base;
        for (let i = 0; i < 1000; i += 1) {
          const { parent } = curr;
          if (!parent?.data) {
            break;
          }
          curr = parent as IRowNode<Task> & { data: Task };
          if (realMovingDuids.has(curr.data.duid)) {
            ancestor = curr;
          }
        }
        return [base.data.duid, ancestor];
      })
    );
    const levelizedPrimaryMovingRow = movingRowToMovingAncestorMap.get(primaryMovingRow.data.duid);
    if (!levelizedPrimaryMovingRow) {
      return;
    }
    const levelizedMovingRows = realMovingRows.filter(
      (e) => movingRowToMovingAncestorMap.get(e.data.duid)?.data?.duid === e.data.duid
    );

    const parentDuid = levelizedPrimaryMovingRow.parent?.data?.duid;
    if (levelizedMovingRows.some((e) => e.parent?.data?.duid !== parentDuid)) {
      return;
    }

    if (indentLevelChange !== 0) {
      let startRow = overRow ?? null;
      if (startRow === null || (startRow.data !== undefined && realMovingDuids.has(startRow.data.duid))) {
        const totalRows = startRow === null ? visualization.count() : (startRow.rowIndex ?? visualization.count());
        for (let i = totalRows - 1; i >= 0; i -= 1) {
          const row = visualization.getRowByIndex(i);
          if (row?.data !== undefined && !realMovingDuids.has(row.data.duid)) {
            startRow = row;
            break;
          }
        }
      }
      const stack = [startRow];
      while (stack[0] !== null) {
        const curr = stack[0] as IRowNode<Task>;
        stack.unshift(curr.parent ?? null);
        if (curr.data?.duid === levelizedPrimaryMovingRow.data.duid) {
          return;
        }
      }
      const newLevel = levelizedPrimaryMovingRow.uiLevel + indentLevelChange;
      const newParent = stack[Math.min(newLevel + 1, stack.length - 1)];
      dataStore.changeParent(
        levelizedMovingRows.map(({ data }) => ({ task: data, order: data.order })),
        newParent?.data ?? null,
        { noBackend }
      );
    }

    const newOrders = actions.visualization.getOrdersForMovingNodes(
      levelizedPrimaryMovingRow,
      levelizedMovingRows,
      overRow
    );
    if (newOrders === undefined) {
      return;
    }

    dataStore.updateTasks(
      levelizedMovingRows
        .map((e) => e.data)
        .sort(makeStringComparator((e) => e.order))
        .map((e, i) => ({
          duid: e.duid,
          order: newOrders[i],
        })),
      noBackend
        ? { noUndo: true, noBackend: true, skipRemap: true }
        : {
            makeDescription: (isActive, content) => `mov${isActive ? "ing" : "ed"} ${content}`,
          }
    );
  },
  getSiblingOverRow: (primaryMovingNode: IRowNode<Task>, rowIndex: number): IRowNode<Task> | undefined => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    let curr = visualization.getRowByIndex(rowIndex);
    while (curr) {
      if (curr === primaryMovingNode) {
        return undefined;
      }
      if (curr.parent === primaryMovingNode.parent) {
        return curr;
      }
      curr = curr.parent ?? undefined;
    }
    return curr;
  },
  moveTaskUp: () => {
    const currents = actions.visualization.getCurrentRows();
    if (actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    }
    const topMovingRow = currents.reduce((prev, curr) =>
      (prev.rowIndex as number) < (curr.rowIndex as number) ? prev : curr
    );
    if (topMovingRow.rowIndex === null || topMovingRow.rowIndex === 0) {
      return;
    }
    const overRow = actions.visualization.getSiblingOverRow(topMovingRow, topMovingRow.rowIndex - 1);
    if (!overRow) {
      return;
    }

    actions.visualization.reorderRows(topMovingRow, currents, overRow);
  },
  moveTaskDown: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    const currents = actions.visualization.getCurrentRows();
    if (actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    }
    const bottomMovingRow = currents.reduce((prev, curr) =>
      (prev.rowIndex as number) > (curr.rowIndex as number) ? prev : curr
    );
    const rowCount = visualization.count();
    if (bottomMovingRow.rowIndex === null || bottomMovingRow.rowIndex === rowCount - 1) {
      return;
    }
    const bottomMovingRowIndex = bottomMovingRow.rowIndex;
    let overRow;
    for (let i = bottomMovingRowIndex + 1; i < rowCount; i += 1) {
      const row = visualization.getRowByIndex(i);
      if (row === undefined) {
        break;
      }
      if (row.parent === bottomMovingRow.parent) {
        overRow = row;
        break;
      }
    }
    if (overRow === undefined) {
      return;
    }

    actions.visualization.reorderRows(bottomMovingRow, currents, overRow);
  },
  moveTaskToTop: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    const currents = actions.visualization.getCurrentRows();
    if (actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    }
    const topMovingRow = currents
      .filter((e): e is IRowNode<Task> & { rowIndex: number } => e.rowIndex !== null)
      .reduce((prev, curr) => (prev.rowIndex < curr.rowIndex ? prev : curr));
    const rowCount = visualization.count();
    if (rowCount === 0) {
      return;
    }
    const overRow = actions.visualization.getSiblingOverRow(topMovingRow, 0);
    if (overRow === undefined || currents.includes(overRow)) {
      return;
    }

    actions.visualization.reorderRows(topMovingRow, currents, overRow);
  },
  moveTaskToBottom: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    const currents = actions.visualization.getCurrentRows();
    if (actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    }
    const bottomMovingRow = currents
      .filter((e): e is IRowNode<Task> & { rowIndex: number } => e.rowIndex !== null)
      .reduce((prev, curr) => (prev.rowIndex > curr.rowIndex ? prev : curr));
    const rowCount = visualization.count();
    if (rowCount === 0) {
      return;
    }
    const overRow = actions.visualization.getSiblingOverRow(bottomMovingRow, rowCount - 1);
    if (overRow === undefined || currents.includes(overRow)) {
      return;
    }

    actions.visualization.reorderRows(bottomMovingRow, currents, overRow);
  },
});
