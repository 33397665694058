<script setup lang="ts">
import { computed, ref, useSlots, watch } from "vue";
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { PremiumFeatureIcon } from "~/icons";
import { makeLinkToSettingsRef } from "~/router/common";
import { ButtonStyle, DialogMode } from "~/shared/enums";
import { useAppStore } from "~/stores";
import { checkout } from "~/utils/billing";

const props = defineProps<{
  featureAction?: string;
  disabled?: boolean;
  isGlobal?: boolean;
  noButton?: boolean;
}>();

const router = useRouter();
const appStore = useAppStore();

const dialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);
const calcFeatureAction = computed(() => (props.isGlobal ? appStore.upgradeModalAction : props.featureAction));

const redirectToBilling = () => router.push(makeLinkToSettingsRef("billing").value);

const hasSlot = computed(() => !!useSlots().default || props.noButton);

const onConfirmAction = () => {
  if (props.isGlobal) {
    appStore.setUpgradeConfirmationModalOpen(null);
  }
  checkout(router.currentRoute.value.fullPath);
};

const onSecondaryAction = () => {
  if (props.isGlobal) {
    appStore.setUpgradeConfirmationModalOpen(null);
  }
  redirectToBilling();
};

const onCancelAction = () => {
  if (props.isGlobal) {
    appStore.setUpgradeConfirmationModalOpen(null);
  }
};

watch(
  () => appStore.upgradeModalAction,
  (open) => {
    if (!open || !props.isGlobal) {
      return;
    }
    dialog.value?.openModal();
  }
);

defineExpose({
  openModal: () => dialog.value?.openModal(),
});
</script>

<template>
  <ConfirmationDialog
    ref="dialog"
    :mode="DialogMode.SECONDARY_ACTION"
    title="Upgrade to premium"
    :description="`Your workspace is on the free plan. To ${calcFeatureAction} and enjoy other benefits, upgrade to premium.`"
    confirm-text="Upgrade!"
    cancel-text="See plans"
    :disabled="disabled"
    :main-action-icon="PremiumFeatureIcon"
    @secondary-action="onSecondaryAction"
    @confirm="onConfirmAction"
    @cancel="onCancelAction">
    <slot v-if="hasSlot" />
    <Tooltip v-else-if="!isGlobal" :text="`Upgrade your plan to ${calcFeatureAction}`">
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        :icon="PremiumFeatureIcon"
        :icon-args="{ class: 'text-vlt' }"
        borderless
        class="!px-1"
        a11y-label="Upgrade" />
    </Tooltip>
  </ConfirmationDialog>
</template>
