<script setup lang="ts">
import { useEyeDropper } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { VColorPicker } from "vuetify/components";

import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ChevronDownIcon, DropperIcon, SelectFieldIcon, ShuffleIcon } from "~/icons";
import { ButtonStyle, ColorHex } from "~/shared/enums";
import { makeRandomColorHex } from "~/utils/color";
import { validateColorHex } from "~/utils/validation";

const props = defineProps<{
  value: string;
  isContrast?: boolean;
}>();

const emit = defineEmits<{
  select: [color: string];
}>();

const colors = Object.values(ColorHex);

const expanded = ref(true);

const toggleExpanded = () => {
  expanded.value = !expanded.value;
};

const customColor = ref(props.value);
const isDefaultColor = computed(() =>
  colors.map((color) => color.toLowerCase()).includes(customColor.value.toLowerCase())
);

const changeColor = () => {
  const colorHex = customColor.value;
  if (!validateColorHex(colorHex).isValid) {
    return;
  }
  emit("select", colorHex.toLowerCase());
};

const mode = ref<"hex" | "rgb" | "hsl">("hex");
const switchModes = () => {
  mode.value = mode.value === "hex" ? "rgb" : mode.value === "rgb" ? "hsl" : "hex";
};

const activateEyeDropper = async () => {
  const color = await useEyeDropper().open();
  if (color) {
    emit("select", (customColor.value = color.sRGBHex.toLowerCase()));
  }
};

const randomizeColor = () => {
  emit("select", (customColor.value = makeRandomColorHex()));
};

watch(
  () => props.value,
  (newValue) => {
    customColor.value = newValue;
  }
);

watch(customColor, changeColor);
</script>

<template>
  <div class="flex flex-col pb-1">
    <div class="grid grid-cols-6 justify-items-center gap-1 p-2">
      <div
        v-for="color in colors"
        :key="color"
        :title="color.toUpperCase()"
        class="flex size-8 shrink-0 cursor-pointer items-center justify-center rounded-full border-2 hover:border-gray-500 dark:hover:border-zinc-400"
        :style="{ borderColor: color === customColor ? customColor : 'transparent' }"
        @click="emit('select', (customColor = color))"
        @keyup.enter="emit('select', (customColor = color))">
        <div class="rounded-full icon-lg" :style="{ backgroundColor: color }" />
      </div>
    </div>
    <div class="mt-2 flex items-center gap-1">
      <Tooltip text="Change a custom color">
        <button
          type="button"
          class="ml-1.5 flex w-min cursor-pointer items-center justify-center gap-1 rounded p-1 pl-1.5 hover:bg-opposite/10"
          @click="toggleExpanded">
          <span class="select-none text-sm font-normal">Custom</span>
          <ChevronDownIcon class="rounded-full transition-transform icon-md" :class="expanded && '-rotate-90'" />
        </button>
      </Tooltip>
      <div
        class="mr-3 flex w-full items-center"
        :class="expanded && !isDefaultColor ? 'justify-between' : 'justify-end'">
        <span
          v-if="expanded && !isDefaultColor"
          class="rounded-full icon-lg"
          :style="{ backgroundColor: customColor }" />
        <Tooltip text="Try a random color">
          <div
            class="flex size-6 cursor-pointer items-center justify-center rounded border text-lt border-hvy focus-ring-std"
            :class="isContrast ? 'hover:bg-md' : 'hover:bg-lt'"
            @click="randomizeColor"
            @keydown.enter="randomizeColor">
            <ShuffleIcon class="icon-sm" />
          </div>
        </Tooltip>
      </div>
    </div>
    <div v-if="!expanded" class="relative ml-3 mt-2">
      <VColorPicker v-model="customColor" :modes="['hex', 'rgb', 'hsl']" :mode="mode" @keydown.stop @click.stop />
      <div class="absolute bottom-[84px]">
        <Button
          :btn-style="ButtonStyle.CHIP"
          :icon="DropperIcon"
          borderless
          a11y-label="Activate eye dropper"
          @click="activateEyeDropper" />
      </div>
      <div class="absolute bottom-[34px] right-3">
        <Button
          :btn-style="ButtonStyle.CHIP"
          :icon="SelectFieldIcon"
          borderless
          a11y-label="Switch color mode"
          @click="switchModes" />
      </div>
    </div>
  </div>
</template>

<style>
.v-sheet {
  @apply !rounded-none bg-transparent !shadow-none;
}

.v-color-picker {
  @apply !w-[198px] text-md;
}

.v-color-picker-preview {
  @apply pl-8;
}

.v-color-picker-preview__dot {
  @apply me-4 size-6 bg-none;
}

.v-color-picker-preview__sliders {
  @apply pe-0;
}

.v-input__control {
  @apply !min-h-6;
}

.v-slider-thumb {
  @apply text-lt;
}

.v-slider-thumb__surface.elevation-2,
.v-slider-thumb__surface.elevation-2::after {
  @apply size-[13px];
}

.v-slider-track__background {
  @apply !h-[10px];
}

.v-color-picker__controls {
  @apply pb-1 pl-1 pr-3.5;
}

.v-color-picker-edit {
  @apply pr-3;
}

.v-color-picker-edit__input input {
  @apply border bg-transparent p-0 text-sm leading-5 text-md border-md focus-ring-none focus:border-primary-base;
}

.v-btn,
.v-color-picker-preview__eye-dropper,
.v-slider-thumb__ripple {
  @apply hidden;
}
</style>
