<script setup lang="ts">
import { ref } from "vue";
import { RouterLink, useRouter } from "vue-router";

import { backendOld } from "~/api";
import { finishLogin, getQueryParam } from "~/router/common";
import type { EmailAuthState } from "~/shared/types";
import { usePageStore } from "~/stores";
import { run } from "~/utils/common";

import AuthBaseView from "./AuthBaseView.vue";

const router = useRouter();
const pageStore = usePageStore();

const tokenDuid = ref(getQueryParam("t") ?? "");
const query = { ...router.currentRoute.value.query };
delete query.t;
router.replace({ query });

const errorMsg = ref<string | null>(null);
run(async () => {
  const { data } = await backendOld.auth.verifyEmail(tokenDuid.value);
  if ("valid" in data) {
    pageStore.pageLoaded = true;
    if (!data.valid) {
      errorMsg.value = data.errors?.[0] ?? "Invalid email verification link";
    }
  } else {
    const authState = data.authState as EmailAuthState;
    finishLogin(data, authState.next);
  }
});
</script>

<template>
  <AuthBaseView>
    <template #heading>Verifying email with Dart</template>

    <template #default>
      <p v-if="errorMsg" class="text-center text-2xl text-md">
        {{ errorMsg }}
      </p>
    </template>

    <template #footer>
      <RouterLink :to="{ name: 'login' }">Back to login</RouterLink>
    </template>
  </AuthBaseView>
</template>
