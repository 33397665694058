import type { Chat, ChatUpdate } from "~/shared/types";
import { makeDuid } from "~/utils/common";
import { chatComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  chatList: Chat[];
  getChatByDuid: (duid: string) => Chat | undefined;
  getChatsByDuidsOrdered: (duids: string[]) => Chat[];
};

export type Actions = {
  /* Create a new chat. */
  createChat: (partialChat?: Partial<Chat>, options?: { awaitBackend?: boolean }) => Promise<Chat | undefined>;
  /* Update an existing chat. */
  updateChat: (update: ChatUpdate, options?: { awaitBackend?: boolean }) => Promise<Chat | undefined>;
  /** Delete a chat. */
  deleteChat: (chat: Chat, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Create a chat without changing the backend.
   * @PRIVATE */
  $createChatNoBackend: (partialChat?: Partial<Chat>) => Chat | undefined;
  /** Sort chats.
   * @PRIVATE */
  $sortChats: () => void;
  /** Set chats internally.
   * @PRIVATE */
  $setChats: (chats: Chat[]) => void;
  /** Create or update chat from WS.
   * @PRIVATE */
  $createOrUpdateChat: (chat: Chat) => void;
  /** Delete chat from WS.
   * @PRIVATE */
  $deleteChat: (chat: Chat) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  chatList() {
    return [...this._duidsToChats.values()];
  },
  getChatByDuid() {
    return (duid) => this._duidsToChats.get(duid);
  },
  getChatsByDuidsOrdered() {
    return (duids) => this.chatList.filter((e) => duids.includes(e.duid));
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createChat(partialChat = {}, options = {}) {
    const { awaitBackend = false } = options;
    const chat = this.$createChatNoBackend(partialChat);
    if (!chat) {
      return undefined;
    }

    const backendAction = this.$backend.chat.create(chat);
    if (awaitBackend) {
      await backendAction;
    }
    return chat;
  },
  async updateChat(update, options = {}) {
    const { awaitBackend = false } = options;
    const chat = this.getChatByDuid(update.duid);
    if (!chat) {
      return undefined;
    }

    Object.assign(chat, update);
    this.$sortChats();

    const backendAction = this.$backend.chat.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return chat;
  },
  async deleteChat(chat, options = {}) {
    const { awaitBackend = false } = options;

    this.$deleteChat(chat);

    const backendAction = this.$backend.chat.delete(chat.duid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $createChatNoBackend(partialChat = {}) {
    const chat: Chat = {
      duid: makeDuid(),
      updatedAt: new Date().toISOString(),
      messages: [],
      draft: null,
      ...partialChat,
    };

    this.$createOrUpdateChat(chat);

    return chat;
  },
  $sortChats() {
    this._duidsToChats = new Map([...this._duidsToChats].sort((a, b) => chatComparator(a[1], b[1])));
  },
  $setChats(chats) {
    this._duidsToChats = new Map(chats.map((e) => [e.duid, e]));
    this.$sortChats();
  },
  $createOrUpdateChat(chat) {
    const currentChat = this.getChatByDuid(chat.duid);

    if (!currentChat) {
      this._duidsToChats.set(chat.duid, chat);
    } else {
      Object.assign(currentChat, chat);
    }

    this.$sortChats();
  },
  $deleteChat(chat) {
    this._duidsToChats.delete(chat.duid);
  },
};

export { actions, getters };
