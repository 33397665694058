import type { Agent, AgentUpdate } from "~/shared/types";
import { makeRandomColorHex } from "~/utils/color";
import { makeDuid } from "~/utils/common";
import { agentComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  agentList: Agent[];
  getAgentByDuid: (duid: string) => Agent | undefined;
  getAgentsByDuidsOrdered: (duids: string[]) => Agent[];
};

export type Actions = {
  /* Create a new agent. */
  createAgent: (partialAgent?: Partial<Agent>, options?: { awaitBackend?: boolean }) => Promise<Agent | undefined>;
  /* Update an existing agent. */
  updateAgent: (update: AgentUpdate, options?: { awaitBackend?: boolean }) => Promise<Agent | undefined>;
  /** Delete a agent. */
  deleteAgent: (agent: Agent, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Create a agent without changing the backend.
   * @PRIVATE */
  $createAgentNoBackend: (partialAgent?: Partial<Agent>) => Agent | undefined;
  /** Sort agents.
   * @PRIVATE */
  $sortAgents: () => void;
  /** Set agents internally.
   * @PRIVATE */
  $setAgents: (agents: Agent[]) => void;
  /** Create or update agent from WS.
   * @PRIVATE */
  $createOrUpdateAgent: (agent: Agent) => void;
  /** Delete agent from WS.
   * @PRIVATE */
  $deleteAgent: (agent: Agent) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  agentList() {
    return [...this._duidsToAgents.values()];
  },
  getAgentByDuid() {
    return (duid) => this._duidsToAgents.get(duid);
  },
  getAgentsByDuidsOrdered() {
    return (duids) => this.agentList.filter((e) => duids.includes(e.duid));
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createAgent(partialAgent = {}, options = {}) {
    const { awaitBackend = false } = options;
    const agent = this.$createAgentNoBackend(partialAgent);
    if (!agent) {
      return undefined;
    }

    const backendAction = this.$backend.agent.create(agent);
    if (awaitBackend) {
      await backendAction;
    }
    return agent;
  },
  async updateAgent(update, chats = {}) {
    const { awaitBackend = false } = chats;
    const agent = this.getAgentByDuid(update.duid);
    if (!agent) {
      return undefined;
    }

    Object.assign(agent, update);
    this.$sortAgents();

    const backendAction = this.$backend.agent.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return agent;
  },
  async deleteAgent(agent, chats = {}) {
    const { awaitBackend = false } = chats;

    // TODO agent figure this out
    // const layoutUpdates = this.$updateLayoutsRemoveEntityDuidOrFilters([agent.duid], [agent.propertyDuid]);
    // const dartboardUpdates = this.$updateDartboardsPropertyDefault([agent.duid], [agent.propertyDuid]);

    this.$deleteAgent(agent);

    const backendAction = this.$backend.agent.delete(agent.duid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $createAgentNoBackend(partialAgent = {}) {
    const agent: Agent = {
      duid: makeDuid(),
      enabled: true,
      name: "",
      abrev: "A",
      colorHex: makeRandomColorHex(),
      imageUrl: null,
      onAssign: null,
      onSubscribedUpdate: null,
      onAt: null,
      ...partialAgent,
    };

    this.$createOrUpdateAgent(agent);

    return agent;
  },
  $sortAgents() {
    this._duidsToAgents = new Map([...this._duidsToAgents].sort((a, b) => agentComparator(a[1], b[1])));
  },
  $setAgents(agents) {
    this._duidsToAgents = new Map(agents.map((e) => [e.duid, e]));
    this.$sortAgents();
  },
  $createOrUpdateAgent(agent) {
    const currentAgent = this.getAgentByDuid(agent.duid);

    if (!currentAgent) {
      this._duidsToAgents.set(agent.duid, agent);
    } else {
      Object.assign(currentAgent, agent);
    }

    this.$sortAgents();
  },
  $deleteAgent(agent) {
    this._duidsToAgents.delete(agent.duid);
  },
};

export { actions, getters };
