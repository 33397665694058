<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import type { SerializedEditorState } from "lexical";
import { computed, nextTick, ref } from "vue";

import TaskDetailFooter from "~/components/task/taskDetail/TaskDetailFooter.vue";
import TaskDetailHeader from "~/components/task/taskDetail/TaskDetailHeader.vue";
import TaskDetailPeriphAndAct from "~/components/task/taskDetail/TaskDetailPeriphAndAct.vue";
import TaskDetailPropsAndDesc from "~/components/task/taskDetail/TaskDetailPropsAndDesc.vue";
import Title from "~/components/task/Title.vue";
import { TASK_DETAIL_TITLE_WRAPPER_ID } from "~/components/text/const";
import router from "~/router/router";
import { EditorMode, RelationshipKindKind } from "~/shared/enums";
import { useAppStore, useDataStore } from "~/stores";
import { when } from "~/utils/wait";

defineProps<{
  showPaneOnly?: boolean;
}>();

const emit = defineEmits<{
  createSubtask: [];
  generatePropertyRecommendations: [];
  generateSubtaskRecommendations: [];
  setDocRelationship: [];
  setRelationship: [kind: RelationshipKindKind, isForward?: boolean];
  setSubtask: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

const header = ref<InstanceType<typeof TaskDetailHeader> | null>(null);
const propsAndDesc = ref<InstanceType<typeof TaskDetailPropsAndDesc> | null>(null);
const periphAndAct = ref<InstanceType<typeof TaskDetailPeriphAndAct> | null>(null);
const footer = ref<InstanceType<typeof TaskDetailFooter> | null>(null);

const wrapper = ref<HTMLElement | null>(null);

const canScrollToBottom = ref(false);
const recalculateCanScroll = () => {
  if (!wrapper.value) {
    return;
  }
  canScrollToBottom.value = wrapper.value.scrollTop + wrapper.value.clientHeight < wrapper.value.scrollHeight;
};
const commentsReady = computed(() => periphAndAct.value?.commentsReady);
const scrollToBottom = () => {
  when(commentsReady, () => {
    if (!wrapper.value) {
      return;
    }
    wrapper.value.scrollTop = wrapper.value.scrollHeight;
  });
};
useResizeObserver(wrapper, recalculateCanScroll);

const addAttachments = (files: File[]) => periphAndAct.value?.addAttachments(files);

const createSubtask = () => periphAndAct.value?.createSubtask();

const deselectSubtasks = () => periphAndAct.value?.deselectSubtasks();

const editLastComment = () => periphAndAct.value?.editLastComment();

const generatePropertyRecommendations = () =>
  header.value?.recommendationButton &&
  propsAndDesc.value?.generatePropertyRecommendations(header.value?.recommendationButton);

const generateSubtaskRecommendations = () => periphAndAct.value?.generateSubtaskRecommendations();

const improveDescription = () => propsAndDesc.value?.improveDescription();
const jumpToDescription = () => propsAndDesc.value?.jumpToDescription();
const setDescription = (newDescription: SerializedEditorState) => propsAndDesc.value?.setDescription(newDescription);
const trimDescription = () => propsAndDesc.value?.trimDescription();

const jumpToComments = (selectLast: boolean = true) => {
  if (selectLast) {
    nextTick(() => {
      if (!appStore.taskOpenInDetail) {
        return;
      }
      const lastRealRoot = dataStore
        .getCommentsByTaskDuid(appStore.taskOpenInDetail.duid)
        .findLast((e) => !e.rootDuid && !e.isDraft);
      if (lastRealRoot) {
        router.push({ query: { c: lastRealRoot.duid } });
      }
    });
  }
  footer.value?.focusNewComment();
};

const openFilePicker = () => header.value?.openFilePicker();

const setRelationship = (kind: RelationshipKindKind, isForward?: boolean) =>
  periphAndAct.value?.setRelationship(kind, isForward);

const setSubtask = () => periphAndAct.value?.setSubtask();

const setDocRelationship = () => periphAndAct.value?.setDocRelationship();

defineExpose({
  addAttachments,
  createSubtask,
  deselectSubtasks,
  focusNewComment: () => footer.value?.focusNewComment(),
  generatePropertyRecommendations,
  generateSubtaskRecommendations,
  isDescriptionReady: computed(() => propsAndDesc.value?.isDescriptionReady ?? false),
  jumpToComments,
  jumpToDescription,
  openFilePicker,
  recommendationButton: computed(() => header.value?.recommendationButton),
  setDescription,
  setDocRelationship,
  setRelationship,
  setSubtask,
  trimDescription,
});
</script>

<template>
  <TaskDetailHeader
    v-if="appStore.taskOpenInDetail"
    ref="header"
    :task="appStore.taskOpenInDetail"
    :generating-recommendations="propsAndDesc?.generatingRecommendations || periphAndAct?.generatingRecommendations"
    :class="showPaneOnly ? 'px-1' : 'w-full'"
    @add-attachments="addAttachments"
    @create-subtask="emit('createSubtask')"
    @improve-description="improveDescription"
    @recommend-properties="emit('generatePropertyRecommendations')"
    @recommend-subtasks="emit('generateSubtaskRecommendations')"
    @set-doc-relationship="emit('setDocRelationship')"
    @set-relationship="(kind: RelationshipKindKind, isForward?: boolean) => emit('setRelationship', kind, isForward)"
    @set-subtask="emit('setSubtask')" />
  <div
    v-if="appStore.taskOpenInDetail"
    class="flex w-full max-w-3xl flex-col"
    :class="showPaneOnly ? 'h-full justify-between overflow-hidden' : 'grow overflow-y-hidden'">
    <div v-if="!showPaneOnly" :id="TASK_DETAIL_TITLE_WRAPPER_ID" class="mt-2 w-full px-2.5">
      <Title :task="appStore.taskOpenInDetail" :editor-mode="EditorMode.DETAIL" @enter="jumpToDescription" />
    </div>
    <div
      ref="wrapper"
      class="flex grow flex-col overflow-y-auto print:overflow-hidden"
      :class="!showPaneOnly && 'relative mt-3.5 gap-6'"
      @scroll="recalculateCanScroll">
      <TaskDetailPropsAndDesc v-if="!showPaneOnly" ref="propsAndDesc" :task="appStore.taskOpenInDetail" />

      <TaskDetailPeriphAndAct ref="periphAndAct" :task="appStore.taskOpenInDetail" />
    </div>

    <TaskDetailFooter
      ref="footer"
      :task="appStore.taskOpenInDetail"
      :can-scroll-to-bottom="canScrollToBottom"
      @edit-last-comment="editLastComment"
      @scroll-to-bottom="scrollToBottom" />
  </div>
</template>
