<script setup lang="ts">
import type { CellMouseOverEvent, ColDef, IRowNode } from "ag-grid-community";
import { computed, nextTick, onUnmounted, ref } from "vue";

import actions from "~/actions";
import { backendOld } from "~/api";
import BaseList from "~/components/visualization/list/BaseList.vue";
import StatusCellEditor from "~/components/visualization/list/cellEditors/StatusCellEditor.vue";
import TitleCellEditor from "~/components/visualization/list/cellEditors/TitleCellEditor.vue";
import UserCellEditor from "~/components/visualization/list/cellEditors/UserCellEditor.vue";
import { listValueGetter } from "~/components/visualization/list/common";
import { EditorMode, Placement, RelationshipKindKind, TaskSourceType, ViewKind } from "~/shared/enums";
import type { RowItem, Task } from "~/shared/types";
import { useAppStore, useDataStore, useTenantStore } from "~/stores";
import { makeDuid, makeEmptyLexicalState } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";
import { when } from "~/utils/wait";

const props = defineProps<{
  task: Task;
  editorMode: EditorMode;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const feedbackTooltipNode = ref<HTMLDivElement | null>(null);

const list = ref<InstanceType<typeof BaseList> | null>(null);
const gridApi = computed(() => list.value?.api ?? null);

const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);
const isListMiniTcmMode = computed(() => props.editorMode === EditorMode.LIST_MINI_TCM);

const parentKindDuid = computed(() => dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid);

const childRelationships = computed(() =>
  dataStore.getRelationshipsByKindKind(props.task, RelationshipKindKind.PARENT_OF, true, {
    includeTrashed: isTrash.value,
  })
);

const subtasks = computed(() =>
  dataStore.getTasksByDuidsOrdered(
    childRelationships.value.map((e) => e.targetDuid),
    { includeDraft: isListMiniTcmMode.value, includeTrashed: isTrash.value }
  )
);

const defaultColDef = {
  suppressMovable: true,
  cellStyle: {
    fontSize: "14px",
    paddingLeft: "0px",
    paddingRight: "0px",
    textOverflow: "clip",
  },
  valueGetter: listValueGetter,
};

const columnDefs = computed<ColDef<Task>[]>(() => [
  {
    field: "title",
    cellRenderer: TitleCellEditor,
    cellRendererParams: {
      editorMode: props.editorMode,
    },
    suppressKeyboardEvent: () => true,
    flex: 1,
  },
  {
    field: "assigneeDuids",
    hide: !tenantStore.assigneeEnabled,
    cellRenderer: UserCellEditor,
    cellRendererParams: {
      property: dataStore.defaultAssigneesProperty,
      editorMode: props.editorMode,
    },
    minWidth: 60,
    maxWidth: 60,
  },
  {
    field: "statusDuid",
    cellRenderer: StatusCellEditor,
    cellRendererParams: {
      property: dataStore.defaultStatusProperty,
      editorMode: props.editorMode,
    },
    minWidth: 48,
    maxWidth: 48,
  },
]);

const getRowId = (params: IRowNode<Task>) => params.data?.duid;

const showSectionOverride = ref(false);

const scrollIntoView = () => nextTick(() => feedbackTooltipNode.value?.scrollIntoView());

const createSubtask = async (bottom: boolean = false) => {
  const visualization = appStore.getSubtasksListVisualization();
  if (!visualization) {
    return undefined;
  }

  const parentTask = props.task;

  const subtaskOrder = getOrdersBetween(
    bottom ? subtasks.value[subtasks.value.length - 1]?.order : undefined,
    bottom ? undefined : subtasks.value[0]?.order
  )[0];

  const partialTask: Partial<Task> = {
    drafterDuid: parentTask.drafterDuid,
    relationships: [
      { duid: makeDuid(), kindDuid: parentKindDuid.value, targetDuid: parentTask.duid, isForward: false },
    ],
  };

  let subtask: Task;
  if (tenantStore.copyParentFieldsOnCreate) {
    partialTask.title = "";
    partialTask.description = makeEmptyLexicalState();
    subtask = (
      await dataStore.replicateTasks(
        [
          {
            task: parentTask,
            order: subtaskOrder,
            partialTask,
          },
        ],
        { propertiesAndParentOnly: true }
      )
    )[0];
  } else {
    subtask = await dataStore.createTask(
      "",
      parentTask.dartboardDuid,
      subtaskOrder,
      TaskSourceType.APP_SUBTASK,
      partialTask
    );
  }

  scrollIntoView();

  actions.visualization.openKeyboardIfIos();
  await when(() => gridApi.value?.getRowNode(subtask.duid) !== undefined);
  visualization.selectAndScrollTo(subtask.duid);
  visualization.editTitle(subtask.duid);

  return subtask;
};

const setSubtask = (duid: string) => {
  const subtask = dataStore.getTaskByDuid(duid);
  if (!subtask) {
    return;
  }

  // TODO switch to changeParent
  const previousParentRelationship = dataStore.getRelationshipsByKindKind(
    subtask,
    RelationshipKindKind.PARENT_OF,
    false
  )[0];
  const relationshipDelete = previousParentRelationship
    ? { taskDuid: subtask.duid, relationshipDuid: previousParentRelationship.duid }
    : undefined;
  dataStore.deleteAndCreateRelationshipAndUpdateTask(relationshipDelete, {
    sourceDuid: props.task.duid,
    targetDuid: subtask.duid,
    relationshipKindDuid: parentKindDuid.value,
  });

  nextTick(() => {
    const rowIndex = gridApi.value?.getRowNode(subtask.duid)?.rowIndex;
    if (rowIndex === undefined || rowIndex === null) {
      return;
    }
    gridApi.value?.getDisplayedRowAtIndex(rowIndex)?.setSelected(true, true);
  });
};

const deselectAll = () => {
  if (!gridApi.value) {
    return;
  }

  gridApi.value.deselectAll();
};

const generateSubtaskRecommendations = async () => {
  showSectionOverride.value = true;
  scrollIntoView();

  const { recommendationDuid }: { recommendationDuid: string } = (
    await backendOld.recommendations.streamSubtasks(props.task.duid)
  ).data.item;
  appStore.showFeedbackTooltip(feedbackTooltipNode.value, [recommendationDuid]);

  showSectionOverride.value = false;
};

const setInstance = (instance: unknown | null) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentInstanceCast = instance as any;
  if (isListMiniTcmMode.value) {
    appStore.tcmSubtasksList = currentInstanceCast;
  } else {
    appStore.taskDetailSubtasksList = currentInstanceCast;
  }
};

const onCellMouseOver = (event: CellMouseOverEvent<Task>) => {
  const target = event.event?.target as Element;
  const rowElement = target?.closest(".ag-row");
  const scrollableParent = rowElement?.closest(".ag-center-cols-viewport");
  const task = dataStore.getTaskByDuid(event.data?.duid ?? "");
  if (!rowElement || !task || !scrollableParent) {
    return;
  }
  appStore.openTaskPreviewTooltip(rowElement, scrollableParent, task, Placement.LEFT);
};

onUnmounted(() => {
  if (isListMiniTcmMode.value) {
    appStore.tcmSubtasksList = null;
  } else {
    appStore.taskDetailSubtasksList = null;
  }
});

defineExpose({
  api: gridApi,
  createSubtask,
  deselectAll,
  setSubtask,
  subtasks,
  generateSubtaskRecommendations,
});
</script>

<template>
  <div
    v-show="subtasks.length > 0 || showSectionOverride"
    ref="feedbackTooltipNode"
    class="flex flex-col gap-1.5"
    @click.stop>
    <BaseList
      ref="list"
      :editor-mode="editorMode"
      :default-col-def="defaultColDef"
      :columns="columnDefs"
      :tasks="subtasks as RowItem[]"
      :get-row-id="getRowId"
      ignore-selection-and-hover
      :header-height="0"
      :row-height="37"
      dom-layout="autoHeight"
      @on-mounted="setInstance"
      @cell-mouse-over="onCellMouseOver" />
  </div>
</template>
