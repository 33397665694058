const BILLING_FEATURES = [
  {
    title: "Teammates",
    personal: "Up to 4",
    premium: "Unlimited",
    business: "Unlimited",
  },
  {
    title: "Tasks",
    personal: "Unlimited",
    premium: "Unlimited",
    business: "Unlimited",
  },
  {
    title: "Docs",
    personal: "Unlimited",
    premium: "Unlimited",
    business: "Unlimited",
  },
  {
    title: "AI task execution",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "AI reporting",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "AI task property filling",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "AI subtask generation",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "AI slash commands",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Duplicate task detection",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "ChatGPT integration",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Claude integration",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Command center and keyboard shortcuts",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Voice-to-Dart actions",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Mac and Windows apps",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "iOS and Android apps",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Dark mode and light mode themes",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Natural language property filling",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Real-time collaboration",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Migration",
    personal: "Automated",
    premium: "Dedicated support",
    business: "Dedicated support",
  },
  {
    title: "List, board, roadmap, and calendar layouts",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Filters, views, and search",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Subtasks, blockers, dependencies, and other relationships",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Sprint management",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Support",
    personal: "✓",
    premium: "One day SLA",
    business: "Dedicated",
  },
  {
    title: "Import and export",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Notion integration",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Email integration",
    personal: "✓",
    premium: "✓",
    business: "✓",
  },
  {
    title: "AI roadmap planning",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "AI brainstorming",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Admin and guest roles",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Custom statuses and properties",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Forms",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Slack integration and automations",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Discord integration",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "GitHub integration and automations",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Zapier integration",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "ZohoFlow integration",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Pipedream integration",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "API, Python, TypeScript, JavaScript, and command line libraries",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "Webhooks",
    personal: "",
    premium: "✓",
    business: "✓",
  },
  {
    title: "SAML SSO",
    personal: "",
    premium: "",
    business: "✓",
  },
  {
    title: "SCIM provisioning",
    personal: "",
    premium: "",
    business: "✓",
  },
  {
    title: "AI-powered program management",
    personal: "",
    premium: "",
    business: "✓",
  },
  {
    title: "Public dartboards",
    personal: "",
    premium: "",
    business: "✓",
  },
  {
    title: "Advanced analytics",
    personal: "",
    premium: "",
    business: "✓",
  },
  {
    title: "Granular access management",
    personal: "",
    premium: "",
    business: "✓",
  },
  {
    title: "Release channel access",
    personal: "Standard",
    premium: "Beta",
    business: "Alpha",
  },
].map((e, i) => ({
  ...e,
  id: i,
}));

export default BILLING_FEATURES;
