<script setup lang="ts">
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";

import Toggle from "~/components/dumb/Toggle.vue";
import { ButtonSize, PropertyKind } from "~/shared/enums";
import type { PropertyAnyDatesWithRangeMaybe, PropertyConfig, PropertyUpdate } from "~/shared/types";
import { useDataStore } from "~/stores";

const SINGLE_TITLE = "Due date";
const RANGE_TITLE = "Dates";

const props = defineProps<{
  property: PropertyAnyDatesWithRangeMaybe;
  propertyConfig: PropertyConfig;
}>();

const dataStore = useDataStore();

const onToggleRangeEnabled = (value: boolean) => {
  const propertyUpdate: PropertyUpdate = {
    duid: props.property.duid,
    adtl: { ...props.property.adtl, isRange: value },
  };
  if (props.property.kind === PropertyKind.DEFAULT_DATES) {
    if (value && props.property.title === SINGLE_TITLE) {
      propertyUpdate.title = RANGE_TITLE;
    } else if (!value && props.property.title === RANGE_TITLE) {
      propertyUpdate.title = SINGLE_TITLE;
    }
  }
  dataStore.updateProperty(propertyUpdate);
};

const onToggleShowDuration = (value: boolean) => {
  const propertyUpdate: PropertyUpdate = {
    duid: props.property.duid,
    adtl: { ...props.property.adtl, showDuration: value },
  };
  dataStore.updateProperty(propertyUpdate);
};

const updateRollup = (rollup: boolean) => {
  dataStore.updateProperty({
    duid: props.property.duid,
    adtl: { ...props.property.adtl, rollup },
  });
};
</script>

<template>
  <div class="-mt-0.5 flex w-full flex-col gap-5">
    <SwitchGroup as="div" class="flex items-center justify-between">
      <span class="flex grow select-none flex-col gap-1">
        <SwitchLabel as="span" class="text-sm font-normal text-lt" passive>Date range</SwitchLabel>
        <SwitchDescription as="span" class="text-xs text-vlt">
          This will add a second date to make this property a range rather than a single date
        </SwitchDescription>
      </span>
      <Switch
        :model-value="property.adtl.isRange"
        :class="[property.adtl.isRange ? 'bg-primary-base' : 'bg-md']"
        class="relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-ring-std"
        @update:model-value="onToggleRangeEnabled">
        <span
          aria-hidden="true"
          :class="[property.adtl.isRange ? 'translate-x-4' : 'translate-x-0']"
          class="pointer-events-none inline-block rounded-full shadow ring-0 transition duration-200 ease-in-out bg-std icon-sm" />
      </Switch>
    </SwitchGroup>
    <SwitchGroup v-if="property.adtl.isRange" as="div" class="flex items-center justify-between">
      <span class="flex grow select-none flex-col gap-1">
        <SwitchLabel as="span" class="text-sm font-normal text-lt" passive>Show duration</SwitchLabel>
        <SwitchDescription as="span" class="text-xs text-vlt">
          This will display the length of the date range next to the actual dates
        </SwitchDescription>
      </span>
      <Switch
        :model-value="property.adtl.showDuration"
        :class="[property.adtl.showDuration ? 'bg-primary-base' : 'bg-md']"
        class="relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-ring-std"
        @update:model-value="onToggleShowDuration">
        <span
          aria-hidden="true"
          :class="[property.adtl.showDuration ? 'translate-x-4' : 'translate-x-0']"
          class="pointer-events-none inline-block rounded-full shadow ring-0 transition duration-200 ease-in-out bg-std icon-sm" />
      </Switch>
    </SwitchGroup>
  </div>
  <Toggle
    class="w-full"
    :size="ButtonSize.SMALL"
    :value="property.adtl.rollup"
    label="Roll up from subtasks"
    text-sm
    text-lt
    description="When a task has subtasks, this task will include their dates"
    @update="updateRollup" />
</template>
