import { computed } from "vue";

import type { TaskAbsenteeMaybe } from "~/shared/types";
import { useDataStore } from "~/stores";

import { COMPLETED_STATUS_KINDS } from "../constants";

export const COLUMN_WIDTH = "300px";

export const useStatistics = (tasksThunk: () => TaskAbsenteeMaybe[]) => {
  const dataStore = useDataStore();
  const tasks = computed(tasksThunk);

  const completedStatusDuids = computed(
    () =>
      new Set(
        dataStore.getStatusesByKinds([...COMPLETED_STATUS_KINDS], dataStore.defaultStatusProperty).map((e) => e.duid)
      )
  );

  return computed(() => {
    const completed = tasks.value.filter((e) => completedStatusDuids.value.has(e.statusDuid));
    return {
      hasSelection: false,
      visibleCount: tasks.value.length,
      visibleCompletedCount: completed.length,
      visiblePoints: tasks.value.map((e) => dataStore.getSize(e) ?? 0).reduce((a, b) => a + b, 0),
      visibleCompletedPoints: completed.map((e) => dataStore.getSize(e) ?? 0).reduce((a, b) => a + b, 0),
    };
  });
};
