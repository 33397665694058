<script setup lang="ts">
import { computed } from "vue";

import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { NUMBER_SIZE_TO_PRETTY_LABEL, NUMBER_SIZE_TO_TSHIRT_SIZE, NUMBER_SIZES } from "~/constants/size";
import { SizeFieldIcon, XIcon } from "~/icons";
import { EditorMode, Placement, SizeFormat } from "~/shared/enums";
import type { PropertyDefaultSize, Task } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getItemCountText } from "~/utils/common";

const props = defineProps<{
  task?: Task;
  value: number | null;
  property: PropertyDefaultSize;
  editorMode: EditorMode;
  showIfEmpty?: boolean;
  showBorder?: boolean;
  showIcon?: boolean;
  hoverable?: boolean;
}>();

const emit = defineEmits<{
  accept: [];
  reject: [];
}>();

const dataStore = useDataStore();

const isChipRecommendationMode = computed(() => props.editorMode === EditorMode.CHIP_RECOMMENDATION);
const isBaseChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isChipMode = computed(() => isBaseChipMode.value || isChipRecommendationMode.value);
const isDetailRecommendationMode = computed(() => props.editorMode === EditorMode.DETAIL_RECOMMENDATION);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL || isDetailRecommendationMode.value);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isRecommendationMode = computed(() => isChipRecommendationMode.value || isDetailRecommendationMode.value);
const isFormOrDefaultPropertyMode = computed(
  () => props.editorMode === EditorMode.FORM || props.editorMode === EditorMode.PROPERTY_DEFAULT
);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);

const isFreeEntry = computed(() => props.property.adtl.format === SizeFormat.FREE_ENTRY);

const findNearestSize = (value: number, sizes: number[]): number => {
  const validSizes = sizes.filter((size) => size >= value);
  return validSizes.length ? Math.min(...validSizes) : Math.max(...sizes);
};

const descendantTasks = computed(() =>
  props.task ? dataStore.getTasksByDuidsOrdered(dataStore.getDescendantDuids(props.task)) : []
);

const hasSubtasks = computed(() => descendantTasks.value.length > 0);

const totalSubtasksSize = computed(() =>
  descendantTasks.value.reduce((total, task) => total + (dataStore.getSize(task) ?? 0), 0)
);

const getPrefix = (size: number) => (size >= 9 ? ">" : "~");

const label = computed(() => {
  const parentSize = props.value ?? (props.task ? dataStore.getSize(props.task, false) : null);
  const subtasksSum = totalSubtasksSize.value;
  const totalSize = subtasksSum + (parentSize ?? 0);

  if ((!parentSize || parentSize === 0) && (!subtasksSum || subtasksSum === 0)) {
    return isChipMode.value ? "Size" : isFormOrDefaultPropertyMode.value ? "Set size" : "None";
  }

  const formatSize = (size: number) => {
    if (props.property.adtl.format === SizeFormat.TSHIRT) {
      const nearestSize = findNearestSize(
        size,
        NUMBER_SIZES.filter((s) => s !== null)
      );

      if (nearestSize === size && (isTaskDetailMode.value || isFormOrDefaultPropertyMode.value)) {
        return NUMBER_SIZE_TO_PRETTY_LABEL.get(nearestSize);
      }

      return nearestSize !== size
        ? `${getPrefix(size)}${NUMBER_SIZE_TO_TSHIRT_SIZE.get(nearestSize)}`
        : NUMBER_SIZE_TO_TSHIRT_SIZE.get(nearestSize);
    }
    return size;
  };

  if (props.property.adtl.rollup && hasSubtasks.value) {
    if (parentSize && parentSize > 0 && subtasksSum > 0) {
      return `${formatSize(totalSize)} (${formatSize(parentSize)})`;
    }
    if (parentSize && parentSize > 0) {
      return formatSize(parentSize);
    }
    return formatSize(subtasksSum);
  }

  if (props.value) {
    if (
      props.property.adtl.format &&
      [SizeFormat.FIBONACCI, SizeFormat.EXPONENTIAL, SizeFormat.LINEAR].includes(props.property.adtl.format)
    ) {
      return `${props.value}`;
    }
    return formatSize(props.value);
  }

  return isChipMode.value ? "Size" : isFormOrDefaultPropertyMode.value ? "Set size" : "None";
});

const displayValue = computed(() => {
  if (props.property.adtl.rollup && hasSubtasks.value) {
    const parentSize = props.value ?? (props.task ? dataStore.getSize(props.task, false) : null);
    const subtasksSize = totalSubtasksSize.value;

    if (parentSize || subtasksSize) {
      return (parentSize ?? 0) + (subtasksSize ?? 0);
    }
    return null;
  }

  return props.value ?? (props.task ? dataStore.getSize(props.task, false) : null);
});

const rejectRecommendation = () => {
  emit("reject");
};

const tooltipText = computed(() => {
  const size = props.value ?? (props.task ? dataStore.getSize(props.task) : null);
  const totalSize = (size ?? 0) + totalSubtasksSize.value;
  const isTshirt = props.property.adtl.format === SizeFormat.TSHIRT;
  const hasSubtasksSize = hasSubtasks.value && totalSubtasksSize.value > 0;

  const formatSizeText = (value: number) =>
    getItemCountText(value, "point", {
      noSpecial: true,
      showAsNumber: true,
    });

  if (!props.property.adtl.rollup && NUMBER_SIZES.includes(size)) {
    return [];
  }

  if (props.property.adtl.rollup && NUMBER_SIZES.includes(size) && size === null) {
    return [];
  }

  const totalText = size !== null ? `${formatSizeText(totalSize)} including subtasks` : "";
  const ownText = size !== null ? `${formatSizeText(size)} for this task` : "";

  if (isTshirt) {
    if (hasSubtasksSize && !NUMBER_SIZES.includes(totalSubtasksSize.value)) {
      if (!size) {
        return [formatSizeText(totalSubtasksSize.value)];
      }
      if (!props.property.adtl.rollup) {
        return [formatSizeText(size)];
      }
      return [totalText, ownText];
    }

    if (!NUMBER_SIZES.includes(size)) {
      if (!size) {
        return [];
      }
      if (!hasSubtasksSize) {
        return [formatSizeText(size)];
      }
    }
  }

  if (!size && hasSubtasksSize && !isTshirt) {
    return [];
  }

  if (!hasSubtasksSize) {
    return [];
  }

  return [totalText, ownText];
});
</script>

<template>
  <component
    :is="isBoardMode ? Tooltip : Template"
    v-if="displayValue || showIfEmpty"
    :text="isBoardMode ? property?.title : undefined">
    <Tooltip :text="tooltipText" :placement="Placement.TOP" :disabled="!tooltipText.length" class="size-full">
      <div
        class="flex select-none items-center justify-center rounded text-md"
        :class="{
          'border border-recommendation-base/50 bg-recommendation-base/30 hover:bg-recommendation-base/40 dark:hover:bg-recommendation-base/40':
            isChipRecommendationMode,
          'hover:bg-opposite/10': isBaseChipMode,
          'border border-oncolor': showBorder && !isChipRecommendationMode,
          'h-[26px] gap-1 py-0.5 text-sm': isChipMode,
          'gap-2': !isChipMode && !isBoardMode,
          '!w-full !justify-end !pr-[22px] !text-sm': isListMode && isFreeEntry && hasSubtasks,
          'h-5 text-xs': !isChipMode && !isTaskDetailMode && !isFormOrDefaultPropertyMode,
          'px-2 hover:bg-lt': isFormOrDefaultPropertyMode || isTaskDetailMode,
          'px-1': !isFormOrDefaultPropertyMode && !isTaskDetailMode,
          'gap-1 pl-1 pr-1.5': isBoardMode,
          '!size-full !justify-start': isListMode || isTaskDetailMode,
          'py-1': isTaskDetailMode,
        }">
        <SizeFieldIcon
          v-if="
            showIcon ||
            isChipMode ||
            (isTaskDetailMode && displayValue) ||
            (isFormOrDefaultPropertyMode && displayValue)
          "
          :class="[
            isChipMode || isFormOrDefaultPropertyMode ? 'icon-sm' : 'icon-xs',
            isChipRecommendationMode ? 'text-recommendation-base' : isChipMode && displayValue && 'text-primary-base',
          ]" />
        <span :class="(isTaskDetailMode || isFormOrDefaultPropertyMode) && !displayValue && 'text-vlt'">
          {{ label }}
        </span>
        <Tooltip v-if="isRecommendationMode" text="Clear AI recommendation">
          <span
            class="rounded hover:bg-recommendation-base/30 dark:hover:bg-recommendation-base/40"
            @click.stop="rejectRecommendation"
            @keydown.enter.stop="rejectRecommendation">
            <XIcon class="icon-xs" />
          </span>
        </Tooltip>
      </div>
    </Tooltip>
  </component>
</template>
