<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";

import { LoadingIcon, RefreshIcon } from "~/icons";

const PULL_THRESHOLD = 50;
const MAX_PULL_DISTANCE = 200;
const CANCEL_THRESHOLD = 25;
const FADE_START = 24;

const isRefreshing = ref(false);
const pullToRefreshY = ref(0);
const maxReachedPull = ref(0);
const touchStart = ref({ x: 0, y: 0 });
const swipeDirection = ref<"vertical" | "horizontal" | null>(null);
const isPullingBack = ref(false);

const pullOpacity = computed(() =>
  Math.min(1, Math.max(0, (pullToRefreshY.value - FADE_START) / (PULL_THRESHOLD - FADE_START)))
);

const pullRotation = computed(() => `rotate(${pullToRefreshY.value * 1.5}deg)`);
const displayedHeight = computed(() => {
  if (pullToRefreshY.value <= 0) {
    return 0;
  }
  return pullToRefreshY.value * 0.5;
});

const onTouchStart = (e: TouchEvent) => {
  touchStart.value = { x: e.touches[0].clientX, y: e.touches[0].clientY };
  swipeDirection.value = null;
  maxReachedPull.value = 0;
  isPullingBack.value = false;
};

const findScrollableParent = (element: HTMLElement | null) => {
  if (!element || element === document.body) {
    return null;
  }

  const style = window.getComputedStyle(element);
  const overflowY = style.getPropertyValue("overflow-y");

  if (overflowY === "auto" || overflowY === "scroll") {
    return element;
  }

  return findScrollableParent(element.parentElement);
};

const onTouchMove = (e: TouchEvent) => {
  const touch = e.touches[0];
  const touchDiffX = touch.clientX - touchStart.value.x;
  const touchDiffY = touch.clientY - touchStart.value.y;

  if (!swipeDirection.value && Math.max(Math.abs(touchDiffX), Math.abs(touchDiffY)) > 8) {
    swipeDirection.value = Math.abs(touchDiffY) > Math.abs(touchDiffX) ? "vertical" : "horizontal";
  }

  const currentElement = document.elementFromPoint(touch.clientX, touch.clientY) as HTMLElement;
  const scrollableParent = findScrollableParent(currentElement);
  const isPageTop = window.scrollY === 0;
  const isElementTop = !scrollableParent || scrollableParent.scrollTop === 0;

  if (swipeDirection.value === "vertical" && isPageTop && isElementTop) {
    const newPullDistance = Math.max(0, Math.min(MAX_PULL_DISTANCE, touchDiffY * 0.5));

    if (newPullDistance < pullToRefreshY.value) {
      isPullingBack.value = true;
    }
    if (newPullDistance > maxReachedPull.value) {
      maxReachedPull.value = newPullDistance;
    }

    pullToRefreshY.value = newPullDistance;

    if (maxReachedPull.value > PULL_THRESHOLD && maxReachedPull.value - newPullDistance > CANCEL_THRESHOLD) {
      isPullingBack.value = true;
    }

    if (touchDiffY > 0) {
      e.preventDefault();
    }
  }
};

const onTouchEnd = () => {
  if (swipeDirection.value === "vertical") {
    if (
      pullToRefreshY.value > PULL_THRESHOLD &&
      !isPullingBack.value &&
      Math.abs(pullToRefreshY.value - maxReachedPull.value) < 5
    ) {
      isRefreshing.value = true;
      window.location.reload();
    } else {
      pullToRefreshY.value = 0;
    }
  }

  swipeDirection.value = null;
  maxReachedPull.value = 0;
  isPullingBack.value = false;
};

onMounted(() => {
  document.addEventListener("touchstart", onTouchStart, { passive: true });
  document.addEventListener("touchmove", onTouchMove, { passive: false });
  document.addEventListener("touchend", onTouchEnd, { passive: true });

  document.body.style.overscrollBehaviorY = "contain";
});

onUnmounted(() => {
  document.removeEventListener("touchstart", onTouchStart);
  document.removeEventListener("touchmove", onTouchMove);
  document.removeEventListener("touchend", onTouchEnd);

  document.body.style.overscrollBehaviorY = "";
});
</script>

<template>
  <div v-if="pullToRefreshY > 0" class="pointer-events-none w-full bg-std">
    <div
      class="flex w-full items-center justify-center"
      :style="{
        height: `${displayedHeight}px`,
      }">
      <div v-if="!isRefreshing" class="mt-4" :style="{ opacity: pullOpacity, transform: pullRotation }">
        <RefreshIcon class="text-vlt icon-lg" />
      </div>
      <div v-else class="pointer-events-none mt-4 flex w-full items-center justify-center">
        <LoadingIcon class="animate-spin text-vlt icon-lg" />
      </div>
    </div>
  </div>
</template>
