<script setup lang="ts">
import { useTextareaAutosize } from "@vueuse/core";
import { computed, onUnmounted, ref, watch } from "vue";

import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import TextInput from "~/components/dumb/TextInput.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { THROTTLE_MS } from "~/constants/app";
import { colorsByTheme } from "~/constants/style";
import { TextFieldIcon } from "~/icons";
import { DropdownMenuItemKind, EditorMode, Placement } from "~/shared/enums";
import type { PropertyText, PropertyValueForKind, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { makeUuid } from "~/utils/common";
import { ThrottleManager } from "~/utils/throttleManager";

type Value = PropertyValueForKind<PropertyText>;

const props = defineProps<{
  property: PropertyText;
  tasks: Task[];
  defaultValue?: Value;
  editorMode: EditorMode;
  value?: Value;
}>();

const emit = defineEmits<{
  update: [value?: Value];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);
const id = ref(`text-${makeUuid()}`);

const text = computed(() => {
  if (props.editorMode === EditorMode.FORM) {
    return props.value ?? "";
  }

  const firstTaskValue = getPropertyValueFromTask(props.property, props.tasks[0]);
  return props.tasks.every((task) => getPropertyValueFromTask(props.property, task) === firstTaskValue)
    ? firstTaskValue
    : null;
});

const isChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const selectedTasks = computed(() =>
  isChipMode.value ? props.tasks : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);

const save = (value: Value, noBackend: boolean) => {
  dataStore.updateTasks(
    selectedTasks.value.map((task) => ({
      duid: task.duid,
      ...getPropertyPartialTask(props.property, task, value),
    })),
    { noBackend }
  );
};

const textInner = ref(text.value ?? "");
const saveManager = new ThrottleManager(() => {
  save(textInner.value, false);
}, THROTTLE_MS);

watch(
  () => text.value,
  (value) => {
    if (value === textInner.value) {
      return;
    }

    textInner.value = value ?? "";
    saveManager.cancel();
  }
);

const updateValue = (value: Value) => {
  if (isFormMode.value) {
    emit("update", value);
    return;
  }

  save(value, true);

  emit("update");
  saveManager.run();
};

const dropdownSections = computed(() => [
  {
    title: props.property.title,
    items: [
      {
        title: "Edit value",
        kind: DropdownMenuItemKind.COMPONENT,
        noFocus: true,
        component: TextInput,
        componentArgs: {
          text: textInner.value,
          label: props.property.title,
          editable: true,
          autoedit: true,
          borderless: true,
          class: "mx-1.5",
          onUpdate: (value: string) => {
            textInner.value = value;
            updateValue(value);
          },
        },
      },
    ],
  },
]);

const onInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  textInner.value = target.value;
  updateValue(target.value);
};

const { textarea } = useTextareaAutosize({ input: textInner });
const onTextareaInput = () => {
  updateValue(textInner.value);
};

const onBlur = () => {
  if (isFormMode.value) {
    return;
  }

  saveManager.finish();
};

onUnmounted(() => {
  saveManager.destroy();
});
</script>

<template>
  <DropdownMenu
    v-if="isContextMenuMode"
    :sections="dropdownSections"
    show-on-hover
    has-input
    :placement="Placement.RIGHT_TOP"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }">
    <DropdownMenuItemContent :icon="TextFieldIcon" :title="`Change ${property.title.toLowerCase()}`" is-submenu />
  </DropdownMenu>
  <Tooltip
    v-else
    :disabled="isFormMode"
    :text="`Change ${property.title.toLowerCase()}`"
    :block="isListMode || isFormMode"
    :height-block="isListMode || isFormMode"
    :class="isTaskDetailMode && 'w-full'">
    <div
      class="flex size-full max-w-full select-none items-center rounded"
      :class="{
        'h-[26px] gap-1 border py-0.5 text-sm border-oncolor hover:bg-opposite/10': isChipMode,
        'px-2 hover:bg-lt': isFormMode,
        'min-h-7 justify-start rounded px-2 hover:bg-lt': isTaskDetailMode,
        'px-1': !isFormMode,
        'justify-center pb-px': isListMode,
      }">
      <label :for="id" class="sr-only">{{ property.title }}</label>
      <input
        v-if="!isTaskDetailMode"
        :id="id"
        v-auto-width="{ disabled: !isChipMode, minWidthPx: 36, maxWidthPx: 270 }"
        :placeholder="isListMode ? undefined : property.title"
        :class="[
          isChipMode || isFormMode ? 'h-5 border border-hvy focus:border-hvy' : 'size-full',
          isFormMode && 'w-full',
        ]"
        class="cursor-text truncate border-none bg-transparent p-0 text-sm focus-ring-none placeholder:text-vlt"
        :value="text"
        @mousedown.stop
        @blur="onBlur"
        @input="onInput" />
      <textarea
        v-else
        :id="id"
        ref="textarea"
        v-model="textInner"
        class="size-full cursor-text resize-none border-none bg-transparent px-0 py-1 text-sm focus-ring-none placeholder:text-vlt"
        :placeholder="isTaskDetailMode ? 'None' : property.title"
        @keydown.esc.stop.prevent="textarea?.blur()"
        @input="onTextareaInput"
        @blur="onBlur"
        @paste="onTextareaInput" />
    </div>
  </Tooltip>
</template>
