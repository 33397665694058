<script setup lang="ts">
import moment from "moment";
import { computed, ref } from "vue";

import type { Comment, Task } from "~/shared/types";
import { useDataStore } from "~/stores";
import { makeLexicalStateWithText } from "~/utils/common";

import CommentComponent from "./Comment.vue";
import NewComment from "./NewComment.vue";

type CommentThread = {
  comments: (Comment & { hideSignature: boolean })[];
  hasReplies: boolean;
  focusAddingReply?: boolean;
};

const props = defineProps<{
  task: Task;
}>();

const dataStore = useDataStore();

const editingDuid = ref<string | null>(null);
const focusThread = ref<string | null>(null);
const showReplyToDuid = ref<string | null>(null);

const commentRefs = ref<InstanceType<typeof CommentComponent>[]>([]);

const startEditing = (duid: string) => {
  editingDuid.value = duid;
};

// Focus thread reply after reply is added
const onAfterCreate = (root: Comment) => {
  focusThread.value = root.rootDuid || root.duid;
};

const commentsByThread = computed<CommentThread[]>(() => {
  const results: CommentThread[] = [];
  const rawComments = dataStore.getCommentsByTaskDuid(props.task.duid).filter((c) => !c.isDraft);

  // Check if signature should be hidden
  const shouldHideSignature = (previous: Comment, current: Comment) =>
    previous.authorDuid === current.authorDuid &&
    previous.authoredByAi === current.authoredByAi &&
    previous.rootDuid === current.rootDuid &&
    moment(current.publishedAt).diff(moment(previous.publishedAt), "minute") <= 10;

  // Group comments by thread
  const commentThreadMap = new Map<string, Comment[]>();
  rawComments.forEach((comment) => {
    if (comment.rootDuid) {
      const thread = commentThreadMap.get(comment.rootDuid);
      if (thread) {
        thread.push(comment);
      } else {
        commentThreadMap.set(comment.rootDuid, [comment]);
      }
      return;
    }

    const thread = commentThreadMap.get(comment.duid);
    if (thread) {
      thread.unshift(comment);
      return;
    }

    commentThreadMap.set(comment.duid, [comment]);
  });

  let previousComment: Comment | null = null;
  [...commentThreadMap.values()].forEach((thread) => {
    const comments: CommentThread["comments"] = [];
    let addedDeletedRoot = false;

    // Process each comment in the thread
    thread.forEach((comment) => {
      const pushComment = (hideSignature: boolean) => {
        comments.push({ ...comment, hideSignature });
        previousComment = comment;
      };

      // If reply
      if (comment.rootDuid) {
        // If previous comment is from the same author, same thread and published within the same time, hide signature
        if (previousComment && shouldHideSignature(previousComment, comment)) {
          pushComment(true);
          return;
        }

        // If root is deleted, add a deleted comment
        if (!addedDeletedRoot && !dataStore.getCommentByDuid(comment.rootDuid)) {
          comments.unshift({
            ...comment,
            duid: comment.rootDuid,
            authorDuid: "",
            rootDuid: null,
            text: makeLexicalStateWithText("(deleted message)"),
            hideSignature: false,
          });
          addedDeletedRoot = true;
          pushComment(false);
          return;
        }

        // Normal reply
        pushComment(false);
        return;
      }

      // If previous comment is from the same author, same thread and published within the same time, hide signature
      if (
        thread.length === 1 &&
        showReplyToDuid.value !== comment.duid &&
        previousComment &&
        shouldHideSignature(previousComment, comment)
      ) {
        pushComment(true);
        return;
      }

      // Regular comment
      pushComment(false);
    });

    results.push({
      comments,
      hasReplies: comments.length > 1,
      focusAddingReply: focusThread.value === thread[0].duid,
    });
  });

  return results;
});

const startEditingLast = () => {
  editingDuid.value = commentsByThread.value[commentsByThread.value.length - 1].comments[0].duid;
};

defineExpose({
  isReady: computed(() => commentRefs.value.every((e) => e.isReady)),
  startEditingLast,
});
</script>

<template>
  <div class="flex flex-1 flex-col">
    <template v-for="{ hasReplies, focusAddingReply, comments } in commentsByThread" :key="comments[0].duid">
      <template v-for="(comment, index) in comments" :key="comment.duid">
        <CommentComponent
          ref="commentRefs"
          :task="task"
          :comment="comment"
          :editing="editingDuid === comment.duid"
          :hide-signature="comment.hideSignature"
          :show-thread-line="hasReplies || showReplyToDuid === comment.duid"
          @start-edit="editingDuid = comment.duid"
          @end-edit="editingDuid = null"
          @reply="
            index === 0 && hasReplies
              ? ((focusThread = comment.duid), (showReplyToDuid = null))
              : (showReplyToDuid = comment.duid)
          " />
        <NewComment
          v-if="
            (hasReplies && index === comments.length - 1) ||
            ((index !== 0 || !hasReplies) && showReplyToDuid === comment.duid)
          "
          :task="task"
          :thread-comment="showReplyToDuid === comment.duid ? comment : comments[0]"
          :adding-default="showReplyToDuid === comment.duid || focusAddingReply"
          @edit-last="startEditing(comment.duid)"
          @after-create="() => onAfterCreate(comments[0])" />
      </template>
    </template>
  </div>
</template>
