<script setup lang="ts">
import moment from "moment-timezone";
import { computed } from "vue";

import { backendOld } from "~/api";
import AvatarUpload from "~/components/AvatarUpload.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import EditText from "~/components/dumb/EditText.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import SettingsCopyText from "~/components/settings/SettingsCopyText.vue";
import SettingsRow from "~/components/settings/SettingsRow.vue";
import SettingsSection from "~/components/settings/SettingsSection.vue";
import { ChevronDownIcon } from "~/icons";
import { Placement } from "~/shared/enums";
import type { MultiselectDropdownMenuItem } from "~/shared/types";
import { useTenantStore } from "~/stores";
import { isBrowserLocale24h, prettifyTimezone } from "~/utils/time";
import { makeValidateTenantName } from "~/utils/validation";

type TenantStore = ReturnType<typeof useTenantStore>;

const tenantStore = useTenantStore();

const validateTenantName = computed(() => makeValidateTenantName(tenantStore.isDart));

const timezoneOptions = computed(() => {
  const userTimezone = moment.tz.guess();
  const timezones = [userTimezone, ...moment.tz.names().filter((name) => name !== userTimezone)];

  return timezones.map((name) => {
    const zone = moment.tz.zone(name) as moment.MomentZone;
    const zoneCurrentTime = moment.tz(name).format(isBrowserLocale24h() ? "HH:mm" : "h:mm A");

    return {
      value: name,
      label: name,
      selected: tenantStore.timezone === name,
      adtlSearchTerms: zone?.abbrs,
      component: DropdownMenuItemContent,
      componentArgs: {
        title: prettifyTimezone(name),
        subtitle: `${moment.tz(name).format("z")} (${moment.tz(name).format("Z")}) ${zoneCurrentTime}`,
        isMultiSelect: true,
      },
    } satisfies MultiselectDropdownMenuItem;
  });
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateWorkspaceSetting = (settingName: keyof TenantStore, newValue: any) => {
  // TODO fix this type hack
  tenantStore[settingName as "name"] = newValue;
  backendOld.workspace.edit(settingName, newValue);
};
</script>

<template>
  <div>
    <SettingsSection title="Workspace Information" class="!mt-0">
      <div class="flex gap-4">
        <AvatarUpload kind="workspace" />

        <div class="flex flex-col justify-center gap-2">
          <span class="text-sm text-vlt">Workspace name</span>
          <EditText
            :value="tenantStore.name"
            label="Workspace name"
            :validate="validateTenantName"
            @save="(e) => updateWorkspaceSetting('name', e)">
            <div
              :title="tenantStore.name"
              class="flex w-60 select-none items-center truncate rounded-md border px-3 py-2 text-left text-sm shadow-sm text-md border-md hover:bg-lt">
              <span class="truncate">{{ tenantStore.name }}</span>
            </div>
          </EditText>
        </div>
      </div>
    </SettingsSection>

    <SettingsSection title="Workspace preferences" no-divider>
      <SettingsRow title="Timezone">
        <MultiselectDropdownMenu
          ref="menu"
          :items="timezoneOptions"
          :placement="Placement.BOTTOM_RIGHT"
          cover
          :width-pixels="300"
          close-on-select
          @add="(e) => updateWorkspaceSetting('timezone', e)">
          <div
            class="flex w-48 select-none items-center justify-between rounded-md border px-2 py-1.5 text-left text-sm shadow-sm text-md border-md hover:bg-lt">
            <span>{{ prettifyTimezone(tenantStore.timezone) }}</span>
            <ChevronDownIcon class="text-lt icon-sm" />
          </div>
        </MultiselectDropdownMenu>
      </SettingsRow>
      <SettingsRow title="Disable backlogs" subtitle="This will turn all backlogs off--they can always be reenabled">
        <Toggle :value="!tenantStore.backlogEnabled" @update="(e) => updateWorkspaceSetting('backlogEnabled', !e)" />
      </SettingsRow>
      <SettingsRow title="Workspace ID">
        <SettingsCopyText :text="tenantStore.duid" label="Workspace ID" class="w-48" />
      </SettingsRow>
    </SettingsSection>
  </div>
</template>
