<script setup lang="ts">
import { type Component, computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import Modal from "~/components/dumb/Modal.vue";
import SettingsLeftbar from "~/components/settings/SettingsLeftbar.vue";
import { FORM_TELEPORT_KEY } from "~/components/text/const";
import { REVIEWER_TENANTS } from "~/constants/app";
import { getQueryParam } from "~/router/common";
import { ModalWidth, UserRole } from "~/shared/enums";
import { useAppStore, useTenantStore, useUserStore } from "~/stores";
import AgentsView from "~/views/settings/AgentsView.vue";
import AutomationsView from "~/views/settings/AutomationsView.vue";
import BillingView from "~/views/settings/BillingView.vue";
import ChatgptView from "~/views/settings/ChatgptView.vue";
import ClaudeView from "~/views/settings/ClaudeView.vue";
import CliView from "~/views/settings/CliView.vue";
import DeleteView from "~/views/settings/DeleteView.vue";
import DiscordView from "~/views/settings/DiscordView.vue";
import ExportView from "~/views/settings/ExportView.vue";
import FormsView from "~/views/settings/FormsView";
import GithubView from "~/views/settings/GithubView.vue";
import IdentityView from "~/views/settings/IdentityView.vue";
import ImportView from "~/views/settings/ImportView.vue";
import MailView from "~/views/settings/MailView.vue";
import NotificationsView from "~/views/settings/NotificationsView.vue";
import NotionView from "~/views/settings/NotionView.vue";
import PipedreamView from "~/views/settings/PipedreamView.vue";
import ProfileView from "~/views/settings/ProfileView.vue";
import PropertiesView from "~/views/settings/PropertiesView";
import SlackView from "~/views/settings/SlackView.vue";
import SpacesView from "~/views/settings/SpacesView/SpacesView.vue";
import TaskKindsView from "~/views/settings/TaskKindsView";
import TeammatesView from "~/views/settings/TeammatesView.vue";
import WebhooksView from "~/views/settings/WebhooksView.vue";
import WorkspaceView from "~/views/settings/WorkspaceView.vue";
import ZapierView from "~/views/settings/ZapierView.vue";
import ZohoFlowView from "~/views/settings/ZohoFlowView.vue";

const ROUTE_COMPONENT_CONFIG = new Map<string, [UserRole | null, UserRole[], Component, string | null]>([
  ["account", [null, [], ProfileView, "Profile"]],
  ["agents", [UserRole.MEMBER, [], AgentsView, "Agents"]],
  ["automations", [UserRole.ADMIN, [], AutomationsView, "Automations"]],
  ["billing", [UserRole.ADMIN, [UserRole.FINANCIAL_ADMIN], BillingView, "Billing"]],
  ["chatgpt", [UserRole.GUEST, [UserRole.TECHNICAL_ADMIN], ChatgptView, null]],
  ["claude", [UserRole.GUEST, [UserRole.TECHNICAL_ADMIN], ClaudeView, null]],
  ["cli", [UserRole.GUEST, [UserRole.TECHNICAL_ADMIN], CliView, null]],
  ["delete", [null, [], DeleteView, null]],
  ["discord", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], DiscordView, null]],
  ["export", [UserRole.GUEST, [], ExportView, "Export"]],
  ["forms", [UserRole.MEMBER, [], FormsView, null]],
  ["github", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], GithubView, null]],
  ["identity", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], IdentityView, "Identity"]],
  ["import", [UserRole.MEMBER, [], ImportView, "Import"]],
  ["mail", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], MailView, null]],
  ["notifications", [null, [], NotificationsView, "Notifications"]],
  ["notion", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], NotionView, null]],
  ["pipedream", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], PipedreamView, null]],
  ["properties", [UserRole.ADMIN, [], PropertiesView, null]],
  ["slack", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], SlackView, null]],
  ["spaces", [UserRole.MEMBER, [], SpacesView, null]],
  ["teammates", [UserRole.MEMBER, [], TeammatesView, "Teammates"]],
  ["types", [UserRole.ADMIN, [], TaskKindsView, null]],
  ["webhooks", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], WebhooksView, null]],
  ["workspace", [UserRole.ADMIN, [], WorkspaceView, "Preferences"]],
  ["zapier", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], ZapierView, null]],
  ["zoho-flow", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], ZohoFlowView, null]],
]);
const REVIEWER_DISABLED_ROUTES = new Set(["billing", "chatgpt", "claude"]);

const router = useRouter();
const appStore = useAppStore();
const userStore = useUserStore();
const tenantStore = useTenantStore();

const mainContent = ref<HTMLElement | null>(null);

const userIsAdmin = computed(() => userStore.isRoleGreaterOrEqual(UserRole.ADMIN));

const config = computed(() => {
  const settings = getQueryParam("settings");
  return ROUTE_COMPONENT_CONFIG.get(settings ?? "");
});

watch(
  () => router.currentRoute.value.query.settings,
  () => {
    const settings = getQueryParam("settings");
    if (!settings) {
      appStore.setSettingsModalOpen(false);
      return;
    }

    const minRole = config.value?.[0];
    const overrideRoles = config.value?.[1];
    if (
      minRole === undefined ||
      (minRole !== null && !userStore.isRoleGreaterOrEqual(minRole) && !overrideRoles?.includes(userStore.role)) ||
      (REVIEWER_TENANTS.has(tenantStore.duid) && REVIEWER_DISABLED_ROUTES.has(settings))
    ) {
      // eslint-disable-next-line no-restricted-syntax
      setTimeout(() => {
        const query = { ...router.currentRoute.value.query };
        query.settings = userIsAdmin.value ? "workspace" : "account";
        router.replace({ query });
      }, 100);
      appStore.setSettingsModalOpen(false);
      return;
    }
    appStore.setSettingsModalOpen(true);

    if (settings === "billing") {
      mainContent.value?.scrollTo({ top: 0, behavior: "smooth" });
    }
  },
  { immediate: true }
);

const currentComponent = computed(() => config.value?.[2]);
const pageTitle = computed(() => config.value?.[3]);
</script>

<template>
  <Modal
    id="dart-form-wrapper"
    :entity="appStore.settingsModalOpen"
    title="Settings"
    :width="ModalWidth.XXL"
    hide-title
    overflow-clip
    custom-styles="h-[calc(100vh-44px)] sm:h-[45rem] !p-0 overflow-hidden"
    @close="appStore.setSettingsModalOpen(false)">
    <template #default="{ entity: settingsModalOpen }">
      <template v-if="settingsModalOpen">
        <div :data-toolbar="FORM_TELEPORT_KEY" class="flex size-full flex-col text-left sm:flex-row">
          <SettingsLeftbar />

          <!-- Main content -->
          <main
            ref="mainContent"
            class="-mt-14 ml-0 size-full overflow-y-auto pl-0 pt-14 sm:-ml-56 sm:mt-0 sm:pl-56 sm:pt-0">
            <div v-if="pageTitle" class="mt-10 flex flex-col gap-3 px-10 lg:px-32">
              <div class="text-xl font-medium text-hvy">{{ pageTitle }}</div>
              <hr class="border-t border-lt" />
            </div>
            <component :is="currentComponent" class="mt-8 px-10 pb-72 lg:px-32" />
          </main>
        </div>
      </template>
    </template>
  </Modal>
</template>
