import equal from "deep-equal";
import { computed, nextTick } from "vue";
import type {
  LocationQueryValue,
  RouteLocationNamedRaw,
  RouteLocationNormalizedLoaded,
  RouteLocationPathRaw,
  RouteMeta,
  Router,
} from "vue-router";

import actions from "~/actions";
import { backendOld } from "~/api";
import { TutorialName, UserDataMode } from "~/shared/enums";
import type { Comment, Dartboard, Dashboard, Doc, Folder, Form, Space, Task, View } from "~/shared/types";
import { useAppStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import {
  getCommentLink,
  getDartboardLink,
  getDashboardLink,
  getDocLink,
  getFolderLink,
  getFormLink,
  getTaskLink,
  getViewLink,
  isString,
} from "~/utils/common";

let router: Router;

export const initRouterForUtils = (newRouter: Router) => {
  router = newRouter;
};

export const getQueryParam = (name: string) => {
  const queryParam = router.currentRoute.value.query[name];
  if (queryParam === undefined) {
    return undefined;
  }
  if (queryParam === null) {
    return "";
  }
  if (isString(queryParam)) {
    return queryParam;
  }
  // eslint-disable-next-line no-console
  console.warn(`Query param ${name} has more than one value`);
  return queryParam[0] as string;
};

export const goHome = () => router.push({ name: "home" });

export const goHomeNextTick = () => {
  nextTick(goHome);
};

export const goBackOrHome = () => {
  const { back } = router.options.history.state;
  if (back) {
    // TODO go back iteratively until we find a route that is not settings
    const backQuery = new URLSearchParams(back.toString().split("?")[1]);
    if (!backQuery.has("settings")) {
      router.back();
      return;
    }
  }
  goHome();
};

export const nextAfterLogin = (
  next: LocationQueryValue | LocationQueryValue[],
  lastUrlPath: string | null
): RouteLocationPathRaw | RouteLocationNamedRaw => {
  if (isString(next) && next) {
    let path = decodeURIComponent(next as string);
    let query = Object.create(null);
    if (path.includes("?")) {
      const spl = path.split("?");
      path = spl[0];
      query = Object.fromEntries(new URLSearchParams(spl[1]).entries());
    }
    if (!path.startsWith("/")) {
      path = `/${path}`;
    }

    if (router.getRoutes().some((e) => e.path === path)) {
      return { path, query };
    }

    const entries = Object.entries(query);
    const queryNew = entries.length > 0 ? `?${entries.map(([k, v]) => `${k}=${v}`).join("&")}` : "";
    window.location.href = `${window.location.origin}${path}${queryNew}`;
  }
  if (lastUrlPath) {
    return { path: lastUrlPath };
  }
  return { name: "home" };
};

export const finalizeNavigation = (meta: RouteMeta) => {
  const appStore = useAppStore();
  const pageStore = usePageStore();
  const tenantStore = useTenantStore();

  if (!meta.setsPageLoadedManually) {
    pageStore.pageLoaded = true;
  }
  if (!meta.setsPageTitleManually) {
    document.title = meta.title + (meta.requiresAuth !== false ? ` | ${tenantStore.name}` : "");
    pageStore.pageTitle = meta.title as string;
  }
  appStore.setMobileLeftbarOpen(false);
};

export const checkNavigationRules = (to: RouteLocationNormalizedLoaded, from?: RouteLocationNormalizedLoaded) => {
  // console.log(from?.fullPath ?? "(undefined)", to.fullPath, "navigated");
  const appStore = useAppStore();
  const pageStore = usePageStore();
  const tenantStore = useTenantStore();
  const userStore = useUserStore();

  if (pageStore.isLoggedIn) {
    const openSwitchToDesktopModal =
      pageStore.isMobile && (userStore.getTutorialStatus(TutorialName.SWITCH_TO_DESKTOP) ?? 0) < 2;

    if (openSwitchToDesktopModal) {
      appStore.setSwitchToDesktopModalOpen(true);
    }

    if (!tenantStore.name && !openSwitchToDesktopModal) {
      appStore.setOnboardingModalOpen(true);
    }

    if (to.meta.redirectIfLoggedIn) {
      return nextAfterLogin(to.query.next, userStore.lastUrlPath);
    }
  }

  if (
    to.meta.requiresAuth === false &&
    to.name !== "reset_password" &&
    from &&
    from.meta.requiresAuth === false &&
    to.name !== from.name
  ) {
    const altQuery = { ...to.query, ...from.query };
    if (!equal(to.query, altQuery, { strict: true })) {
      return { ...to, query: altQuery };
    }
  }

  if (to.name === "logout") {
    actions.app.logout();
    return { name: "login" };
  }

  if (to.meta.requiresAuth !== false && !pageStore.isLoggedIn) {
    if (to.name === "view") {
      return { name: "public_view", viewDuid: to.params.viewDuid };
    }
    if (to.redirectedFrom?.path.startsWith("/r/")) {
      return { path: to.redirectedFrom?.path.replace("/r/", "/p/r/") };
    }

    const nextLoc = { name: "login", query: {} };
    if (to.fullPath && to.fullPath !== "/") {
      if (to.fullPath.startsWith("/?next=")) {
        // the next location is already encoded
        nextLoc.query = { next: to.fullPath.substring(7) };
      } else {
        nextLoc.query = { next: encodeURIComponent(to.fullPath.substring(1)) };
      }
    }
    return nextLoc;
  }

  if ((to.name === "latency" || to.name === "console") && !tenantStore?.isDart) {
    return { name: "home" };
  }

  finalizeNavigation(to.meta);
  return true;
};

export const finishLogin = async (userBundle: Record<string, never>, next?: string) => {
  const appStore = useAppStore();
  const pageStore = usePageStore();
  const userStore = useUserStore();

  pageStore.updateCsrf();
  actions.app.setUserData(userBundle);
  actions.app.watchForIdle();
  appStore.connectWs(actions.websockets.getManager());

  const { data } = await backendOld.getUserData(UserDataMode.EXTRA);
  actions.app.setExtraUserData(data);

  router.push(nextAfterLogin(next ?? router.currentRoute.value.query.next, userStore.lastUrlPath));
};

export const makeLinkToSettingsRef = (page: string | undefined, query?: object) =>
  computed(() => {
    const route = router.currentRoute.value;
    return { path: route.path, query: { ...route.query, ...query, settings: page } };
  });

export const makeLinkToSpaceSettingsPageRef = (spaceDuid: string | undefined, subpage: string) =>
  computed(() => {
    const link = makeLinkToSettingsRef("spaces", { space: spaceDuid, page: subpage }).value;
    return {
      ...link,
      query: {
        ...link.query,
        space: spaceDuid,
        page: subpage ?? "basics",
      },
    };
  });

export const makeLinkToPropertySettingsRef = (propertyDuid: string | undefined) =>
  makeLinkToSettingsRef("properties", { property: propertyDuid });

export const makeLinkToFormsSettingsRef = (formDuid: string | undefined) =>
  makeLinkToSettingsRef("forms", { form: formDuid });

export const makeLinkToTaskKindSettingsRef = (taskKindDuid: string | undefined) =>
  makeLinkToSettingsRef("types", { type: taskKindDuid });

// TODO there are sorta three copies of these: in EnvironmentStore; in router/common; and in utils/common
export const getTaskUrl = (task: Task) => router.resolve(getTaskLink(task)).href;

export const getCommentUrl = (task: Task, comment: Comment) => router.resolve(getCommentLink(task, comment)).href;

export const getDartboardUrl = (dartboard: Dartboard, space: Space) =>
  router.resolve(getDartboardLink(dartboard, () => space)).href;

export const getDashboardUrl = (dashboard: Dashboard) => router.resolve(getDashboardLink(dashboard)).href;

export const getViewUrl = (view: View) => router.resolve(getViewLink(view)).href;

export const getFolderUrl = (folder: Folder, space: Space) => router.resolve(getFolderLink(folder, () => space)).href;

export const getFormUrl = (form: Form) => router.resolve(getFormLink(form)).href;

export const getDocUrl = (doc: Doc) => router.resolve(getDocLink(doc)).href;

export const getUserDataMode = () =>
  (router.currentRoute.value?.meta?.userDataMode as UserDataMode | undefined) ?? UserDataMode.AUTO;
