<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";

import { backendOld } from "~/api";
import { finishLogin, goHome } from "~/router/common";
import { GoogleLoginPlatform } from "~/shared/enums";
import type { GoogleAuthState } from "~/shared/types";
import { useEnvironmentStore, usePageStore } from "~/stores";
import { getGtagClientId, openPathInDesktop } from "~/utils/common";

import AuthBaseView from "./AuthBaseView.vue";

const router = useRouter();
const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();

const queryOrig = router.currentRoute.value.query;
const { error, code, state } = queryOrig;
router.replace({ query: {} });

const errorMsg = ref<string | null>(null);

const mobileLoginUrl = ref<string | null>(null);

const onLoad = async () => {
  if (error === "access_denied" || typeof code !== "string" || typeof state !== "string") {
    // Redirect if already logged in
    if (pageStore.isLoggedIn) {
      goHome();
      return;
    }

    pageStore.pageLoaded = true;
    errorMsg.value = "Logging in with Google didn't work, try again";
    return;
  }

  const authState = JSON.parse(state) as GoogleAuthState;

  // If in the correct place to actually authenticate, do so with the server
  const isAuthenticatingNative =
    (pageStore.isDesktopApp && authState.platform === GoogleLoginPlatform.DESKTOP) ||
    (pageStore.isMobileApp && authState.platform === GoogleLoginPlatform.MOBILE);
  if (authState.platform === GoogleLoginPlatform.WEB || isAuthenticatingNative) {
    // Redirect if already logged in
    if (pageStore.isLoggedIn) {
      goHome();
      return;
    }

    // Page is already loaded, so we need to set it to false to show the loading spinner
    if (isAuthenticatingNative) {
      pageStore.pageLoaded = false;
    }

    const { data } = await backendOld.auth.authenticateWithGoogle(
      code,
      environmentStore.googleRedirectUri,
      authState.token ?? null,
      getGtagClientId()
    );
    if ("valid" in data) {
      pageStore.pageLoaded = true;
      if (!data.valid) {
        errorMsg.value = data.errors[0];
      }
    } else {
      finishLogin(data, authState.next);
    }
    return;
  }

  const oauthPath = `/google-oauth?${new URLSearchParams({ code, state }).toString()}`;
  // This is the browser but we're trying to log in on desktop
  if (authState.platform === GoogleLoginPlatform.DESKTOP) {
    // Redirect to native app instead
    await openPathInDesktop(oauthPath);
    pageStore.redirectedToNative = true;
    goHome();
    return;
  }

  // This is the browser but we're trying to log in on mobile
  mobileLoginUrl.value = `dart://${environmentStore.host}${oauthPath}`;
  pageStore.pageLoaded = true;
};

onLoad();
</script>

<template>
  <AuthBaseView>
    <template #heading>Log in</template>

    <template #default>
      <template v-if="mobileLoginUrl">
        <a
          :href="mobileLoginUrl"
          class="flex w-full items-center justify-center gap-1 rounded border border-transparent bg-primary-base px-4 py-2 text-sm font-normal hover-bg-primary text-oncolor focus-ring-std">
          Finish logging in
        </a>
      </template>
      <p v-else-if="errorMsg" class="text-center text-2xl text-md">
        {{ errorMsg }}
      </p>
    </template>

    <template v-if="!mobileLoginUrl" #footer>
      <RouterLink :to="{ name: 'login' }">← Back</RouterLink>
    </template>
  </AuthBaseView>
</template>
