<script setup lang="ts">
import { computed, onUnmounted, ref } from "vue";

import { useAppStore, usePageStore } from "~/stores";

import PanArea from "../PanArea.vue";
import { getDiffPx, getIntervalPx, getPixelsTo, makeTimeForPixelsToNowRef, useVirtualized } from "./common";
import { ZOOM_MULTIPLIER_PER_SCROLL_Y } from "./constants";
import DependencyLines from "./DependencyLines.vue";
import RoadmapTasks from "./RoadmapTasks.vue";
import type { MovingTaskOverride, PreviewRange, RangeDefinition, RoadmapConfig, RoadmapValues } from "./shared";

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  roadmapValues: RoadmapValues;
}>();

const appStore = useAppStore();
const pageStore = usePageStore();

const emit = defineEmits<{
  activeChange: [activeDuid: string | null];
  scroll: [];
  zoom: [pxPerDay: number];
  updatePreviewRange: [previewRange: PreviewRange];
}>();

const definition = computed<RangeDefinition>(() => props.roadmapValues.definition);

/* Scrolling */
const panAreaRef = ref<InstanceType<typeof PanArea> | null>(null);
const scrollContainer = computed<HTMLElement | null>(() => panAreaRef.value?.wrapper ?? null);
const scrollTo = (x?: number, y?: number): void => {
  if (!scrollContainer.value) {
    return;
  }

  const maximum = scrollContainer.value.scrollWidth - scrollContainer.value.clientWidth;
  scrollContainer.value.scrollTo({
    left: x ? Math.min(Math.max(0, x), maximum) : undefined,
    top: y,
    behavior: "instant",
  });
};

/* Range */
const timeline = computed(() => [
  {
    value: props.roadmapConfig.startDate,
    width: getPixelsTo(props.roadmapConfig, props.roadmapValues.timelineDates[0]),
    left: "0px",
  },
  ...props.roadmapValues.timelineDates.map((value) => ({
    value,
    width: getIntervalPx(props.roadmapConfig, value, definition.value.timelineInterval),
    left: `${getDiffPx(props.roadmapConfig, props.roadmapConfig.startDate, value)}px`,
  })),
]);

const { virtualSize: virtualWidthTimeline, virtualList: virtualListTimeline } = useVirtualized(
  scrollContainer,
  timeline
);

/* Today line */
const todayPixels = makeTimeForPixelsToNowRef(computed(() => props.roadmapConfig));
const todayLine = computed<string>(() => `${todayPixels.value.pixels}px`);

const movingTaskOverride = ref<MovingTaskOverride>(null);

/* Scroll wheel zoom */
const zoom = (event: WheelEvent): void => {
  if (!(pageStore.isMac ? event.metaKey : event.ctrlKey)) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();

  emit("zoom", props.roadmapConfig.pxPerDay / ZOOM_MULTIPLIER_PER_SCROLL_Y ** event.deltaY);
};

const deselectAll = () => {
  appStore.getActiveVisualization().deselectAll();
};

onUnmounted(() => {
  todayPixels.value.destroy();
});

defineExpose({
  scrollTo,
  scrollContainer,
});
</script>

<template>
  <PanArea
    ref="panAreaRef"
    v-scroll-sync:roadmap.x.y
    class="flex flex-1 items-center"
    @click="deselectAll"
    @keydown.enter="deselectAll"
    @wheel="zoom"
    @scroll="emit('scroll')">
    <div
      class="absolute inset-0 flex size-full flex-1 items-center"
      :style="{
        minWidth: `${virtualWidthTimeline}px`,
        maxWidth: `${virtualWidthTimeline}px`,
        minHeight: `${roadmapValues.scrollHeight}px`,
      }">
      <!-- Sections -->
      <div
        v-for="unit in virtualListTimeline"
        :id="`timeline-${unit.value}`"
        :key="unit.value"
        class="absolute inset-y-0 size-full border-r border-lt"
        :style="{ left: `${unit.start}px`, minWidth: `${unit.width}px`, maxWidth: `${unit.width}px` }" />

      <DependencyLines
        :roadmap-config="roadmapConfig"
        :roadmap-values="roadmapValues"
        :moving-task-override="movingTaskOverride" />

      <!-- Today line -->
      <div class="absolute inset-y-0 w-0.5 bg-primary-base" :style="{ left: todayLine }" />
      <div
        v-if="definition.exact"
        class="absolute top-0 -mt-1.5 ml-[-5px] size-3 rounded-full bg-primary-base"
        :style="{ left: todayLine }" />

      <!-- Tasks -->
      <RoadmapTasks
        :roadmap-config="roadmapConfig"
        :roadmap-values="roadmapValues"
        :scroll-container="scrollContainer"
        @active-change="(e) => emit('activeChange', e)"
        @scroll-to="(x, y) => scrollTo(x, y)"
        @update-preview-range="(e) => emit('updatePreviewRange', e)"
        @moving-task-override="(e) => (movingTaskOverride = e)" />
    </div>
  </PanArea>
</template>
