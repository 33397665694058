<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";

import { backendOld } from "~/api";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { DuplicatesRelationshipIcon } from "~/icons";
import { DropdownMenuItemKind, EventActor, FeedbackRating, RelationshipKindKind } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useDataStore } from "~/stores";
import { ThrottleManager } from "~/utils/throttleManager";

import DuplicateDetectionDropdownItem from "./DuplicateDetectionDropdownItem.vue";

const props = defineProps<{
  task: Task | null;
}>();

const dataStore = useDataStore();

const duplicateKindDuid = computed(() => dataStore.getRelationshipKindByKind(RelationshipKindKind.DUPLICATES).duid);
const currentDuplicateDuids = computed(
  () =>
    new Set(
      props.task
        ? dataStore.getRelationshipsByKindKind(props.task, RelationshipKindKind.DUPLICATES).map((e) => e.targetDuid)
        : []
    )
);
const ignoredTaskDuids = ref(new Set());
const rawDuplicateTaskPredictionDuids = ref<string[]>([]);
const potentialDuplicateTasks = computed(() =>
  rawDuplicateTaskPredictionDuids.value
    .filter((e) => !currentDuplicateDuids.value.has(e) && !ignoredTaskDuids.value.has(e))
    .map((e) => dataStore.getTaskByDuid(e))
    .filter((e): e is Task => e !== undefined)
);
const enabled = computed(() => potentialDuplicateTasks.value.length > 0);
const tooltipText = computed(() => {
  const singular = potentialDuplicateTasks.value.length === 1;
  return `${EventActor.DART_AI} has identified${singular ? " a" : ""} potential duplicate task${singular ? "" : "s"}`;
});

let getDuplicatesManager: ThrottleManager<[]>;
let lastRequestFailed = false;

const throttledUpdate = async () => {
  if (props.task === null || props.task.title.trim().length === 0) {
    rawDuplicateTaskPredictionDuids.value = [];
    return;
  }

  try {
    rawDuplicateTaskPredictionDuids.value = (
      await backendOld.recommendations.getTaskDuplicates(props.task.duid)
    ).data.items;
    lastRequestFailed = false;
  } catch (error) {
    if (!lastRequestFailed) {
      getDuplicatesManager?.run();
    }
    lastRequestFailed = !lastRequestFailed;
  }
};

getDuplicatesManager = new ThrottleManager(throttledUpdate, 1000);

const update = () => {
  getDuplicatesManager.run();
};

const onMarkDuplicate = (target: Task) => {
  if (props.task === null) {
    return;
  }
  dataStore.createRelationship(props.task.duid, target.duid, duplicateKindDuid.value);
  backendOld.recommendations.provideTaskDuplicatesFeedback(props.task.duid, [target.duid], FeedbackRating.GOOD);
};

const onClear = () => {
  if (!props.task) {
    return;
  }
  potentialDuplicateTasks.value.forEach((e) => ignoredTaskDuids.value.add(e.duid));
  backendOld.recommendations.provideTaskDuplicatesFeedback(
    props.task.duid,
    potentialDuplicateTasks.value.map((e) => e.duid),
    FeedbackRating.BAD
  );
};

const sections = computed(() => [
  {
    title: "Alerts",
    items: [
      {
        title: "Duplicate detector",
        kind: DropdownMenuItemKind.COMPONENT,
        noFocus: true,
        component: DuplicateDetectionDropdownItem,
        componentArgs: {
          tasks: potentialDuplicateTasks.value,
          onMarkDuplicate,
          onClear,
        },
      },
    ],
  },
]);

// On task duid change, reset values
watch(
  () => props.task?.duid,
  () => {
    ignoredTaskDuids.value = new Set();
  },
  { immediate: true }
);

onMounted(() => {
  update();
  getDuplicatesManager.destroy();
});

defineExpose({
  update,
});
</script>

<template>
  <div class="relative flex">
    <DropdownMenu ref="iconPicker" :sections="sections" :width-pixels="350" :disabled="!enabled">
      <Tooltip :disabled="!enabled" :text="tooltipText">
        <button
          type="button"
          aria-label="See duplicates"
          class="flex size-8 items-center justify-center rounded transition-all duration-300"
          :class="
            !enabled
              ? 'cursor-default border opacity-25 text-md border-hvy focus-ring-none'
              : 'cursor-pointer border-none bg-warning-base hover-bg-warning text-oncolor focus-ring-warning'
          ">
          <span class="sr-only">Duplicate tasks</span>
          <DuplicatesRelationshipIcon class="icon-md" />
        </button>
      </Tooltip>
    </DropdownMenu>
    <div
      v-if="enabled"
      class="absolute right-[-5px] top-[-5px] flex min-h-4 min-w-4 items-center justify-center rounded-full border border-warning-base px-[3px] text-[10px]/[13px] font-semibold text-warning-base bg-std">
      {{ potentialDuplicateTasks.length }}
    </div>
  </div>
</template>
