<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";

import actions from "~/actions";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Template from "~/components/dumb/Template.vue";
import { DotsHorizontalIcon } from "~/icons";
import { Placement } from "~/shared/enums";
import type { Option, PropertyAnySelect } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { getChipColorsHex } from "~/utils/color";
import { makeUuid } from "~/utils/common";
import { when } from "~/utils/wait";

import type { OptionWithChildren } from "./AdtlSelect.vue";

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const props = defineProps<{
  property: PropertyAnySelect;
  option: Option;
  parentDuid: string | null;
  children: OptionWithChildren[];
  addingDuids?: string[];
}>();

const emit = defineEmits<{
  add: [title: string, parentDuid: string | null, addingDuid?: string];
  addSubitem: [parentDuid: string];
  createOptionUnderneath: [title: string, parentDuid: string | null, option: Option];
}>();

const titleInput = ref<HTMLInputElement | null>(null);

const adding = computed(() => props.addingDuids?.includes(props.option.duid) ?? false);

const options = computed(() => dataStore.getOptionList(props.property));

const sections = actions.context.option(props.option, {
  showTitleEditor: true,
  addSubitem: () => emit("addSubitem", props.option.duid),
});
const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, sections, { maxHeightPixels: 552 });
};

const colorSet = computed(() => getChipColorsHex(props.option.colorHex, pageStore.theme));

const title = ref(props.option.title);
watch(
  () => props.option.title,
  (newValue) => {
    title.value = newValue ?? "";
  }
);

const onBlur = () => {
  const trimmed = title.value.trim();
  if (adding.value) {
    emit("add", trimmed, null, props.option.duid);
    return;
  }

  // Reset to previous title if the input is empty
  if (trimmed === "") {
    title.value = props.option.title;
    return;
  }
  const isDuplicate = options.value.some((e) => e.title === trimmed && e.parentDuid === props.parentDuid);
  if (isDuplicate) {
    title.value = props.option.title;
    return;
  }

  if (trimmed === props.option.title) {
    return;
  }
  dataStore.updateOption({ duid: props.option.duid, title: trimmed });
};

const id = ref(`option-title-${makeUuid()}`);

const onEnterPress = () => {
  if (title.value.trim() === "" || !adding.value) {
    return;
  }

  emit("createOptionUnderneath", title.value.trim(), null, props.option);
};

watch(
  () => props.addingDuids,
  async () => {
    if (adding.value) {
      await when(titleInput);
      titleInput.value?.focus();
    }
  }
);

onMounted(async () => {
  actions.visualization.openKeyboardIfIos();
  if (adding.value) {
    await when(titleInput);
    titleInput.value?.focus();
  }
});
</script>

<template>
  <component :is="children.length === 0 ? Template : 'div'" class="flex flex-col gap-1">
    <div class="flex select-none items-center justify-between gap-1">
      <div
        class="flex h-5 max-w-full select-none items-center overflow-hidden rounded border py-1 pl-1.5 pr-0.5"
        :style="{ color: colorSet.fore, backgroundColor: colorSet.back, borderColor: colorSet.border }"
        @contextmenu="onContextMenu">
        <label :for="id" class="sr-only">Title</label>
        <input
          :id="id"
          ref="titleInput"
          v-model="title"
          v-auto-width="{ minWidthPx: 10 }"
          :title="title"
          type="text"
          class="appearance-none truncate border-none bg-transparent p-0 text-xs font-normal focus-ring-none"
          @blur="onBlur"
          @keydown.enter="onEnterPress" />
      </div>
      <DropdownMenu
        v-if="!adding"
        :sections="sections"
        :placement="Placement.BOTTOM_RIGHT"
        :distance="0"
        :max-height-pixels="552">
        <div class="rounded text-lt hover:bg-lt">
          <span class="sr-only">Options</span>
          <DotsHorizontalIcon class="icon-sm" />
        </div>
      </DropdownMenu>
    </div>
    <div v-if="children.length !== 0" class="ml-2 flex flex-col gap-1.5 rounded-b-md border-l pl-2 border-md">
      <OptionItem
        v-for="groupedItem in children"
        :key="groupedItem.option.duid"
        :property="property"
        :option="groupedItem.option"
        :parent-duid="option.duid"
        :children="groupedItem.children"
        :adding-duids="addingDuids"
        @add="(title, parentDuid) => emit('add', title, parentDuid ?? props.option.duid)"
        @add-subitem="(e) => emit('addSubitem', e)" />
    </div>
  </component>
</template>
