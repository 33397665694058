<script setup lang="ts">
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { computed, useSlots } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import { InfoIcon } from "~/icons";
import { ButtonSize, Placement } from "~/shared/enums";

const props = defineProps<{
  disabled?: boolean;
  locked?: boolean;
  value: boolean;
  label?: string;
  description?: string;
  descriptionInTooltip?: boolean;
  hideLabel?: boolean;
  size?: ButtonSize;
  textSm?: boolean;
  textLt?: boolean;
}>();

const emit = defineEmits<{
  update: [value: boolean];
}>();

const hasSlot = computed(() => !!useSlots().default);

const enabled = computed(() => props.value);
const onToggle = (e: boolean) => emit("update", e);

const sizeNorm = computed(() => props.size ?? ButtonSize.LARGE);
</script>

<template>
  <SwitchGroup as="div" class="flex items-center justify-between" :class="!hideLabel && 'gap-4'">
    <span v-if="!hideLabel" class="flex" :class="descriptionInTooltip ? 'items-center gap-1' : 'grow flex-col'">
      <SwitchLabel
        as="span"
        class="select-none"
        :class="[textSm ? 'text-sm' : 'font-semibold', textLt ? 'text-lt' : 'text-md']"
        passive>
        {{ label }}
      </SwitchLabel>
      <template v-if="description || hasSlot">
        <Tooltip v-if="descriptionInTooltip" :text="description" :placement="Placement.RIGHT_TOP" info :skidding="-10">
          <InfoIcon class="cursor-help rounded-full outline-none bg-std text-vlt icon-xs" />
        </Tooltip>
        <SwitchDescription v-else as="span" class="select-none text-xs" :class="textLt ? 'text-vlt' : 'text-lt'">
          {{ description }}
          <slot />
        </SwitchDescription>
      </template>
    </span>
    <Switch
      :model-value="enabled"
      :disabled="disabled || locked"
      :class="{
        'bg-primary-base': enabled,
        'hover-bg-primary': enabled && !disabled && !locked,
        'bg-md': !enabled,
        'hover:bg-hvy': !enabled && !disabled && !locked,
        'cursor-default opacity-25': disabled,
        'cursor-default': !disabled && locked,
        'cursor-pointer': !disabled && !locked,
        'h-6 w-11': sizeNorm === ButtonSize.LARGE,
        'h-5 w-9': sizeNorm === ButtonSize.SMALL,
      }"
      class="relative inline-flex shrink-0 rounded-full border-2 border-transparent focus-ring-std"
      @update:model-value="onToggle">
      <span
        aria-hidden="true"
        :class="{
          'icon-md': sizeNorm === ButtonSize.LARGE,
          'icon-sm': sizeNorm === ButtonSize.SMALL,
          'translate-x-0': !enabled,
          'translate-x-5': enabled && sizeNorm === ButtonSize.LARGE,
          'translate-x-4': enabled && sizeNorm === ButtonSize.SMALL,
        }"
        class="pointer-events-none inline-block rounded-full shadow ring-0 transition duration-200 ease-in-out bg-std" />
    </Switch>
  </SwitchGroup>
</template>
