<script setup lang="ts" generic="Item extends ItemBase, T extends Constructable">
import { computed, ref } from "vue";
import { DynamicScrollerItem } from "vue-virtual-scroller";

import type { Constructable } from "~/shared/typeUtils";

import useDragStore from "./DragStore";
import type { ItemBase } from "./shared";

const props = defineProps<{
  group: string;
  category: string;
  index: number;
  level: number;
  item: Item;
  component: T;
  getComponentProps: (item: Item, index: number) => InstanceType<T>["$props"];
  placeholderColor: string;
  disabled: boolean;
  gap: number;
  subitemGap: number;
  itemSidePadding: number;
  activeInScroll: boolean;
}>();

const emit = defineEmits<{
  dragStart: [event: DragEvent];
  dragEnd: [event: DragEvent];
  dragEnter: [event: DragEvent];
  dragOver: [event: DragEvent];
}>();

const dragStore = useDragStore();

const wrapper = ref<HTMLDivElement | null>(null);
const itemRef = ref<InstanceType<T> | null>(null);

const draggingItems = computed(() => dragStore.getDraggingItemsForGroup<Item>(props.group));
const descendantDraggingItems = computed(() => dragStore.getDraggingDescendantItemsForGroup<Item>(props.group));
const isDraggingGroup = computed(() => draggingItems.value.concat(descendantDraggingItems.value).length > 0);
const isDragged = computed(
  () =>
    draggingItems.value.some((e) => e.duid === props.item.duid) ||
    descendantDraggingItems.value.some((e) => e.duid === props.item.duid)
);
const isSubitem = computed(() => !!props.item.parentDuid);

const onDragStart = (e: DragEvent) => {
  if (!e.dataTransfer || !itemRef.value?.$el) {
    return;
  }
  const element: HTMLDivElement = itemRef.value.$el;

  // Create the ghost element
  const ghost = element.cloneNode(true) as HTMLDivElement;
  ghost.style.maxWidth = `${element.offsetWidth}px`;
  ghost.style.maxHeight = `${element.offsetHeight}px`;
  document.body.appendChild(ghost);
  e.dataTransfer.setDragImage(ghost, 10, 1);
  // eslint-disable-next-line no-restricted-syntax
  setTimeout(() => {
    document.body.removeChild(ghost);
  });

  // Set cursor, chrome is buggy and needs a timeout https://github.com/SortableJS/Vue.Draggable/issues/815#issuecomment-1552904628
  // eslint-disable-next-line no-restricted-syntax
  setTimeout(() => {
    document.body.classList.add("dragging-item");
  }, 50);

  // eslint-disable-next-line no-param-reassign
  e.dataTransfer.effectAllowed = "move";
  e.dataTransfer.setData("text/plain", props.item.title);
  e.dataTransfer.setData("application/json", JSON.stringify(props.item));
  emit("dragStart", e);
};

const onDragEnd = (e: DragEvent) => {
  e.preventDefault();
  if (e.dataTransfer) {
    e.dataTransfer.clearData();
  }

  emit("dragEnd", e);
};

const onDragEnter = (e: DragEvent) => {
  e.preventDefault();
  if (wrapper.value?.classList.contains("drag-list-move")) {
    return;
  }

  emit("dragEnter", e);
};

const onDragOver = (e: DragEvent) => {
  e.preventDefault();
  if (!isDragged.value || wrapper.value?.classList.contains("drag-list-move")) {
    return;
  }
  emit("dragOver", e);
};

const setRef = (elem?: { $el: HTMLDivElement }) => {
  wrapper.value = elem?.$el ?? null;
};

defineExpose({
  wrapper,
  itemRef,
});
</script>

<template>
  <DynamicScrollerItem
    :ref="setRef"
    :item="item"
    :active="activeInScroll"
    :size-dependencies="[]"
    class="w-full"
    :draggable="!disabled"
    :data-index="index"
    @dragstart="onDragStart"
    @dragenter="onDragEnter"
    @dragover="onDragOver"
    @dragend="onDragEnd">
    <div
      class="size-full"
      :style="{
        paddingLeft:
          itemSidePadding || props.level > 1 ? `${itemSidePadding * 2 + (props.level - 1) * 20}px` : undefined,
        paddingTop: props.index === 0 ? undefined : `${isSubitem ? props.subitemGap : props.gap}px`,
        paddingRight: itemSidePadding ? `${itemSidePadding}px` : undefined,
      }">
      <component
        :is="component"
        ref="itemRef"
        class="size-full"
        :class="{ 'dart-dragging': isDragged, 'pointer-events-none': isDraggingGroup }"
        v-bind="getComponentProps(props.item, index)" />
    </div>
  </DynamicScrollerItem>
</template>

<style scoped>
/* Item placeholder styles */
.dart-dragging {
  background-color: v-bind("placeholderColor");
  @apply rounded;
}

/* Item ghost styles */
.dart-dragging :deep(*) {
  @apply !pointer-events-none !opacity-0;
}
</style>
