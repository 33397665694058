<script setup lang="ts">
import moment, { type Moment } from "moment";
import { DatePicker as DatePickerExternal } from "v-calendar";
import { computed, onUnmounted, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import { BACKUP_FONTS } from "~/constants/style";
import { ButtonStyle, ModalWidth } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { getRelativeTimeForReminder, isBrowserLocale24h } from "~/utils/time";

const ONE_MINUTE_IN_MS = 1000 * 60;

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const selectedDate = ref<Date | null>(null);

const precomputedShowCancelReminder = computed(() =>
  appStore.tasksOpenInReminderModal.some((e) => e.remindAt !== null)
); // Precompute to avoid quick layout shift after selection

const updateReminder = (remindAt: Moment | null) => {
  const tasks = appStore.tasksOpenInReminderModal;
  appStore.setTasksOpenInReminderModal([]);
  selectedDate.value = null;

  const newRemindAt = remindAt ? remindAt.toISOString() : null;

  dataStore.updateTasks(
    tasks.map((e) => ({
      duid: e.duid,
      remindAt: newRemindAt,
    }))
  );
};

const at9am = (dt: Moment) => dt.startOf("day").add(9, "hours");

const now = ref(moment());
const updateNowInterval = setInterval(() => {
  now.value = moment();
}, ONE_MINUTE_IN_MS);
onUnmounted(() => {
  clearInterval(updateNowInterval);
});

const allReminderChoices = computed(() => [
  {
    title: "Today",
    choices: [
      { title: "An hour", callback: () => moment().add(1, "hour") },
      { title: "4 hours", callback: () => moment().add(4, "hours") },
      { title: "8 hours", callback: () => moment().add(8, "hours") },
    ],
  },
  {
    title: "This week",
    choices: [
      { title: "Tomorrow", callback: () => at9am(moment().add(1, "day")) },
      { title: now.value.add(2, "day").format("dddd"), callback: () => at9am(moment().add(2, "day")) },
      { title: now.value.add(3, "day").format("dddd"), callback: () => at9am(moment().add(3, "day")) },
      { title: "Next week", callback: () => at9am(moment().add(1, "week")) },
    ],
  },
  {
    title: "Coming weeks",
    choices: [
      { title: "2 weeks", callback: () => moment().add(2, "weeks") },
      { title: "4 weeks", callback: () => moment().add(4, "weeks") },
    ],
  },
  {
    title: "Someday",
    choices: [
      {
        title: "Someday",
        callback: () =>
          moment()
            .add(Math.floor(Math.random() * 90) + 30, "day")
            .startOf("day")
            .add(9, "hour"),
      },
    ],
  },
]);
</script>

<template>
  <Modal
    :entity="appStore.tasksOpenInReminderModal.length"
    title="Set reminder"
    :width="ModalWidth.M"
    @close="appStore.setTasksOpenInReminderModal([])">
    <template #default>
      <div class="-mx-6 flex">
        <div class="flex flex-col border-r border-r-gray-200 dark:border-r-zinc-700">
          <!-- Reminder options -->
          <div v-for="choiceSection in allReminderChoices" :key="choiceSection.title" class="my-1 flex flex-col">
            <button
              v-for="choice in choiceSection.choices"
              :key="choice.title"
              type="button"
              class="whitespace-nowrap px-6 py-1.5 text-left text-sm font-normal text-md focus-ring-std hover:bg-lt"
              @click="updateReminder(choice.callback())"
              @keydown.enter="updateReminder(choice.callback())">
              {{ choice.title }}
            </button>
          </div>
        </div>
        <div class="flex flex-1 flex-col">
          <div>
            <DatePickerExternal
              v-model="selectedDate"
              mode="dateTime"
              color="indigo"
              expanded
              :rules="{ minutes: { interval: 15 } }"
              :attributes="[{ key: 'today', highlight: { color: 'indigo', fillMode: 'outline' }, dates: new Date() }]"
              :is-dark="pageStore.theme === 'dark'"
              :min-date="new Date()"
              :is24hr="isBrowserLocale24h()"
              :first-day-of-week="(userStore.firstDayOfWeek ?? 1) + 1"
              :style="{ fontFamily: `Inter var ${BACKUP_FONTS}` }" />
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <Button
        v-if="precomputedShowCancelReminder && !selectedDate"
        :btn-style="ButtonStyle.PRIMARY"
        text="Cancel reminder"
        @click="updateReminder(null)" />
      <Button
        v-else
        :btn-style="ButtonStyle.PRIMARY"
        :text="
          selectedDate && moment(selectedDate).isAfter(moment())
            ? `Remind me ${getRelativeTimeForReminder(moment(selectedDate)).message}`
            : 'Set reminder'
        "
        :disabled="!selectedDate || moment(selectedDate).isBefore(moment())"
        @click="updateReminder(moment(selectedDate))" />
    </template>
  </Modal>
</template>

<style>
.vc-container {
  border: none !important;
  background-color: transparent !important;
}
.vc-time-picker.vc-bordered {
  border: none !important;
}
</style>
