<script setup lang="ts">
import { computed } from "vue";

import { getPropertyValueFromTask } from "~/common/properties";
import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { EditorMode, NumberFormat, Placement } from "~/shared/enums";
import type { PropertyNumber, Task } from "~/shared/types";
import { useDataStore } from "~/stores";

const props = defineProps<{
  task?: Task;
  value: number | null;
  format: NumberFormat;
  property?: PropertyNumber;
  editorMode: EditorMode;
}>();

const dataStore = useDataStore();

const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);

const isDollars = computed(() => props.format === NumberFormat.DOLLARS);
const isPercentage = computed(() => props.format === NumberFormat.PERCENTAGE);

const descendantTasks = computed(() =>
  props.task ? dataStore.getTasksByDuidsOrdered(dataStore.getDescendantDuids(props.task)) : []
);

const hasSubtasks = computed(() => descendantTasks.value.length > 0);
const isRollup = computed(() => props.property?.adtl.rollup);

const subtasksSum = computed(() => {
  if (!isRollup.value) {
    return 0;
  }

  const res = descendantTasks.value.reduce(
    (total, task) => total + (props.property ? Number(getPropertyValueFromTask(props.property, task) ?? 0) : 0),
    0
  );
  return isPercentage.value ? res / descendantTasks.value.length : res;
});

const totalSize = computed(() => subtasksSum.value + (isPercentage.value ? 0 : (props.value ?? 0)));

const displayedValue = computed(() => {
  if (!hasSubtasks.value || !isRollup.value) {
    return props.value;
  }
  if (subtasksSum.value === 0 && props.value === null) {
    return null;
  }
  if (props.value === null) {
    return totalSize.value;
  }
  return subtasksSum.value > 0 ? `${totalSize.value} (${props.value})` : `${totalSize.value}`;
});

const tooltipText = computed(() => {
  const hasValidSubtaskValues = descendantTasks.value.some((task) => {
    const value = props.property ? getPropertyValueFromTask(props.property, task) : null;
    return value !== null && value !== undefined;
  });

  if (!hasSubtasks.value || !isRollup.value || !hasValidSubtaskValues || props.value === null) {
    return [];
  }

  const formatValue = (value: number) => `${isDollars.value ? "$" : ""}${value}${isPercentage.value ? "%" : ""}`;

  return [
    `${formatValue(totalSize.value ?? 0)} including subtasks`,
    `${formatValue(props.value ? props.value : 0)} for this task`,
  ];
});

const show = computed(
  () => displayedValue.value !== null || (hasSubtasks.value && isRollup.value && subtasksSum.value > 0)
);
</script>

<template>
  <component :is="isBoardMode ? Tooltip : Template" v-if="show" :text="isBoardMode ? property?.title : undefined">
    <Tooltip :text="tooltipText" :placement="Placement.TOP" :disabled="!tooltipText.length">
      <div
        class="flex min-h-5 items-center justify-center hyphens-auto rounded border px-1 text-xs text-md border-oncolor break-words">
        <span v-if="isDollars" class="pr-1">$</span>
        {{ displayedValue }}
        <span v-if="isPercentage" class="pl-1">%</span>
      </div>
    </Tooltip>
  </component>
</template>
